import { operatorConstant } from '../../constants/operator_constant'
import { userConstants } from '../../constants/user_constants'
import { history } from '../../helpers/history'
import { isUserWithRole, rolesConstants } from '../../helpers/roles'
import { loginService } from '../../services/loginService'
import { userService } from '../../services/userService'
import { alertActions } from './alert_actions'
import { uiActions } from './ui_actions'

import i18next from 'i18next'

import { initNotifications } from '../../firebase'
import { allowAccessRole, isRoleAllowedToAccess } from '../../helpers/roles'
import { scooterActions } from './scooter_actions'

export const userActions = {
  login,
  totp,
  getUserInfo,
  refreshToken,
  logout,
  checkToken,
  checkMailExist,
  selectRole,
  getOperatorData,
  changePassword,
  resetPassword,
  subscribeToTopic,
}

function changePassword(body) {
  return (dispatch) => {
    userService.changePassword(body).then(
      (user) => {
        dispatch(
          alertActions.success(
            i18next.t('services.user.changePasswordSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.user.changePasswordError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function resetPassword(body, callback = null) {
  return (dispatch) => {
    userService.resetPassword(body).then(
      (user) => {
        dispatch(alertActions.success(i18next.t('services.user.resetPassword')))
        if (callback) callback()
      },
      (error) => {
        dispatch(
          alertActions.success(`${i18next.t('services.user.resetPassword')}`),
        )
        if (callback) callback()
      },
    )
  }
}

function selectRole(userSelected, redirect) {
  return (dispatch) => {
    console.log('userSelected =>', userSelected)
    if (isRoleAllowedToAccess(userSelected.role)) {
      dispatch(selectedSuccess(userSelected))
      if (
        !userSelected.canOperate &&
        userSelected.role != rolesConstants.MANAGER &&
        userSelected.role != rolesConstants.MANAGER_READONLY &&
        userSelected.role != rolesConstants.SAC_USER &&
        userSelected.role != rolesConstants.CAU_USER
      ) {
        if (userSelected.role != rolesConstants.TECHNICIAN) {
          dispatch(logout())
          dispatch(
            alertActions.error(
              i18next.t('services.user.selectRoleUserNoOperative'),
            ),
          )
        } else {
          if (userSelected.canOperate) {
            if (redirect) history.push('/home')
          } else {
            // dispatch(logout());
            dispatch(
              alertActions.error(
                i18next.t('services.user.selectRoleUserNoOperative'),
              ),
            )
          }
        }
      } else {
        if (userSelected.role === rolesConstants.OPERATOR_MANAGER) {
          dispatch(getOperatorData(userSelected.sharingOperatorId))
        } else if (userSelected.role === rolesConstants.CAU_USER) {
          history.push('/customers')
        } else {
          if (redirect) history.push('/home')
        }
      }
    } else {
      dispatch(
        alertActions.error(i18next.t('services.user.selectRoleLoginError')),
      )
      dispatch(selectedSuccess({ role: null }))
      dispatch(logout())
    }
    function selectedSuccess() {
      return { type: userConstants.SELECTED_USER_SUCCESS, userSelected }
    }
  }
}

function checkMailExist(mail) {
  return (dispatch) => {
    dispatch(requestExistMail())
    loginService.checkMailExist(mail).then(
      (res) => {
        dispatch(successExistMail)
      },
      (error) => {
        dispatch(failureExistMail)
      },
    )

    function requestExistMail() {
      return { type: userConstants.MAIL_EXIST_REQUEST }
    }
    function successExistMail() {
      return { type: userConstants.MAIL_EXIST_SUCCESS }
    }
    function failureExistMail() {
      return { type: userConstants.MAIL_EXIST_FAILURE }
    }
  }
}

function checkToken() {
  return (dispatch) => {
    const istokenPresent =
      JSON.parse(localStorage.getItem('sharing_token')) != null
    const isfirebaseUIDPresent =
      JSON.parse(localStorage.getItem('sharing_user')) != null
    const token = JSON.parse(localStorage.getItem('sharing_token'))
    const firebaseUID = JSON.parse(localStorage.getItem('sharing_user'))

    if (!istokenPresent || !isfirebaseUIDPresent) {
      history.push('/login')
    } else {
      dispatch(successToken(token.value))
      //dispatch(getUserInfo(firebaseUID.value.firebaseUID));
      dispatch(getUserInfo(false))
    }
    function successToken(token) {
      return { type: userConstants.TOKEN_SUCCESS, token }
    }
  }
}

function login(email, password, isRememberPasswordChecked) {
  return (dispatch) => {
    dispatch(requestLogin())
    var locale = findGetParameter('sharing_locale')
    localStorage.setItem('sharing_locale', locale)
    dispatch(requestToken)
    loginService.token(email, password).then(
      (token) => {
        if (token && token.hasOwnProperty('error')) {
          dispatch(
            alertActions.error(
              i18next.t('services.user.loginIncorrectPasswdMail'),
            ),
          )
          dispatch(failureToken(token.error))
        } else {
          dispatch(successLogin())
          dispatch(successToken(token))
          initNotifications()
          dispatch(getUserInfo(true))
        }
      },
      (error) => {
        dispatch(alertActions.error('Error Login', error))
        dispatch(failureToken(error))
      },
    )

    function requestLogin() {
      return { type: userConstants.LOGIN_REQUEST }
    }
    function successLogin() {
      return { type: userConstants.LOGIN_SUCCESS }
    }
    function requestToken() {
      return { type: userConstants.TOKEN_REQUEST }
    }
    function successToken(token) {
      return { type: userConstants.TOKEN_SUCCESS, token }
    }
    function failureToken(error) {
      return { type: userConstants.TOKEN_FAILURE, error }
    }
  }
}

function totp(email, password, code) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(requestLogin())
      var locale = findGetParameter('sharing_locale')
      localStorage.setItem('sharing_locale', locale)
      dispatch(requestToken)
      loginService.token(email, password, code).then(
        (token) => {
          if (token) {
            if (token.hasOwnProperty('error')) {
              dispatch(
                alertActions.error(
                  i18next.t('services.user.loginIncorrectPasswdMail'),
                ),
              )
              dispatch(failureToken(token.error))
              reject(token.error)
            } else if (token.hasOwnProperty('code')) {
              dispatch(failureToken(token))
              reject(token)
            } else {
              dispatch(successLogin())
              dispatch(successToken(token))
              initNotifications()
              dispatch(getUserInfo(true))
              resolve(token)
            }
          } else {
            dispatch(successLogin())
            dispatch(successToken(token))
            initNotifications()
            dispatch(getUserInfo(true))
            resolve(token)
          }
        },
        (error) => {
          dispatch(alertActions.error('Error Login', error))
          dispatch(failureToken(error))
          reject(error)
        },
      )
    })

  function requestLogin() {
    return { type: userConstants.LOGIN_REQUEST }
  }
  function successLogin() {
    return { type: userConstants.LOGIN_SUCCESS }
  }
  function requestToken() {
    return { type: userConstants.TOKEN_REQUEST }
  }
  function successToken(token) {
    return { type: userConstants.TOKEN_SUCCESS, token }
  }
  function failureToken(error) {
    return { type: userConstants.TOKEN_FAILURE, error }
  }
}

function findGetParameter(parameterName) {
  var result = null,
    tmp = []
  var items = window.location.search.substr(1).split('&')
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split('=')
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
  }
  return result
}

function refreshToken(token) {
  return (dispatch) => {
    dispatch(request(token))
    loginService.refreshToken(token).then(
      (token) => {
        dispatch(success(token.access_token))
      },
      (error) => {
        dispatch(failure(error.toString()))
      },
    )
  }

  function request(token) {
    return { type: userConstants.REFRESH_REQUEST, token }
  }

  function success(token) {
    return { type: userConstants.REFRESH_SUCCESS, token }
  }

  function failure(error) {
    return { type: userConstants.REFRESH_FAILURE, error }
  }
}

function getUserInfo(redirect) {
  return (dispatch) => {
    dispatch(requestUserInfo())
    loginService.userInfo().then(
      (user) => {
        dispatch(successUserInfo(user))
        if (user.length === 1) {
          dispatch(selectRole(user[0]))
          if (user[0].role === rolesConstants.OPERATOR_MANAGER) {
            dispatch(getOperatorData(user[0].sharingOperatorId))
            // getOperatorData(user[0].sharingOperatorId);
          }
        } else {
          const allowaccesRolesDetected = allowAccessRole(user)
          if (allowaccesRolesDetected.length != user.length) {
            dispatch(selectRole(allowaccesRolesDetected[0], redirect))
          }
        }

        if (isUserWithRole(rolesConstants.CAU_USER)) {
          history.push('/scooters/locations')
          return
        }

        if (redirect) history.push('/home')
      },
      (error) => {},
    )

    // function getOperatorData(id) {
    //   if (id) {
    //     dispatch(requestOperatorData());
    //     loginService.getOperatorData(id).then(
    //       (user) => {
    //         dispatch(successOperatorInfo(user));
    //         !user.canOperate
    //           ? history.push("/FormCompleteData")
    //           : history.push("/home");
    //       },
    //       (error) => {}
    //     );
    //   } else {
    //     history.push("/home");
    //   }
    // }

    function requestOperatorData() {
      return { type: operatorConstant.REQUEST_OPERATOR_INFO }
    }

    function requestUserInfo(token) {
      return { type: userConstants.INFO_REQUEST, token }
    }
    function successUserInfo(user) {
      return { type: userConstants.INFO_SUCCESS, user }
    }
    function successOperatorInfo(user) {
      return { type: operatorConstant.SUCCESS_OPERATOR_INFO, user }
    }
  }
}

function getOperatorData(id) {
  return (dispatch) => {
    if (id) {
      dispatch(requestOperatorData())
      loginService.getOperatorData(id).then(
        (user) => {
          dispatch(uiActions.setOpenDrawer(user.canOperate))
          dispatch(successOperatorInfo(user))
          !user.canOperate
            ? history.push('/FormCompleteData')
            : history.push('/home')
        },
        (error) => {},
      )
    } else {
      history.push('/home')
    }

    function requestOperatorData() {
      return { type: operatorConstant.REQUEST_OPERATOR_INFO }
    }

    function successOperatorInfo(user) {
      return { type: operatorConstant.SUCCESS_OPERATOR_INFO, user }
    }
  }
}

function logout() {
  return (dispatch) => {
    dispatch(request())

    dispatch(scooterActions.clearState())

    userService.unSubscribeToTopic()
    loginService.logout()
    history.push('/login')
  }

  function request() {
    return { type: userConstants.LOGOUT }
  }
}

function subscribeToTopic(token) {
  return (dispatch) => {
    loginService.setWithExpiry('token_notifications', token, 999999999999)
    userService.subscribeToTopic(token).then(
      (data) => {
        //
      },
      (error) => {
        //console.log('error unsubscribeToTopic', error)
      },
    )
  }
}
