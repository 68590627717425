import { operatorConstant } from '../../constants/operator_constant'
import { techniciansConstant } from '../../constants/technicians_constant'
import { technicianService } from '../../services/technicianService'
import { alertActions } from './alert_actions'

import i18next from 'i18next'

export const techniciansActions = {
  insert,
  update,
  getAll,
  remove,
}

function remove(body) {
  const { operatorId, technicianId } = body
  delete body.operatorId

  return (dispatch) => {
    technicianService.remove(technicianId).then(
      (results) => {
        dispatch(
          alertActions.success(i18next.t('services.technician.removeSuccess')),
        )
        dispatch(getAll(operatorId))
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.technician.removeSuccess')}: ${error}`,
          ),
        )
      },
    )
  }
}
function getAll(operatorId = null) {
  return (dispatch) => {
    dispatch(request())
    technicianService.getAll(operatorId).then(
      (results) => {
        dispatch(success(results))
        // dispatch(alertActions.success("Técnico creado correctamente"));
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.technician.removeSuccess')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: techniciansConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: techniciansConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: techniciansConstant.GET_ALL_FAILURE, error }
  }
}

function update(body) {
  const operatorId = body.operatorId
  delete body.operatorId

  return (dispatch) => {
    technicianService.update(body).then(
      (results) => {
        dispatch(
          alertActions.success(i18next.t('services.technician.updateSuccess')),
        )
        dispatch(getAll(operatorId))
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.technician.updateSuccess')}: ${error}`,
          ),
        )
      },
    )
  }
}

function insert(body) {
  const operatorId = body.sharingOperatorId
  return (dispatch) => {
    dispatch(request())
    technicianService.insert(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(getAll(operatorId))
        dispatch(
          alertActions.success(i18next.t('services.technician.insertSuccess')),
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.technician.insertSuccess')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: operatorConstant.INSERT_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.INSERT_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.INSERT_FAILURE, error }
  }
}
