import i18next from 'i18next'
import moment from 'moment'
import { scooterConstants } from '../../constants/scooter_constants'
import { history } from '../../helpers/history'
import { scooterService } from '../../services/scooterService'
import { alertActions } from './alert_actions'

import {
  isCauUser,
  isManager,
  isManagerReadOnly,
  isSacUser,
} from '../../helpers/roles'
import { operatorActions } from './operator_action'

export const scooterActions = {
  getAll,
  getAllByOperatorId,
  filter,
  getId,
  clearResults,
  getDynamics,
  getDynamicsById,
  getTripsByScooterId,
  getTripDetails,
  getAllMapByOperatorId,
  assignToOperate,
  getAllDynamicByOperator,
  getAllDynamics,
  getDataById,
  getMissingDynamic,
  setHelmetIncluded,
  setToOperate,
  getParamsConfig,
  setParamsConfig,
  updateParamsConfig,
  updateRestricted,
  saveCenterMap,
  cleanTripsView,
  heatMapOfLoggedOperator,
  heatMapOfSelectedOperator,
  cleanHeatMapData,
  clearTripsData,
  getTrips,
  clearState,
}

function getTrips(body) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getTrips(body).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.scooters.getAllError')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_TRIPS_REQUEST }
  }

  function success(results) {
    return {
      type: scooterConstants.GET_TRIPS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: scooterConstants.GET_TRIPS_FAILURE,
      error,
    }
  }
}

function clearTripsData() {
  return {
    type: scooterConstants.CLEAN_TRIP_DATA,
  }
}

function cleanHeatMapData() {
  return {
    type: scooterConstants.CLEAN_HEAT_MAP_DATA,
  }
}

function heatMapOfSelectedOperator(body) {
  return (dispatch) => {
    dispatch(request())
    scooterService.heatMapOfSelectedOperator(body).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.scooters.heatMapError')),
        )
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_HEAT_MAP_OF_SELECTED_OPERATOR_REQUEST }
  }

  function success(results) {
    return {
      type: scooterConstants.GET_HEAT_MAP_OF_SELECTED_OPERATOR_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: scooterConstants.GET_HEAT_MAP_OF_SELECTED_OPERATOR_FAILURE,
      error,
    }
  }
}

function heatMapOfLoggedOperator(body) {
  return (dispatch) => {
    dispatch(request())
    scooterService.heatMapOfLoggedOperator(body).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error())
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_HEAT_MAP_OF_SELECTED_OPERATOR_REQUEST }
  }

  function success(results) {
    return {
      type: scooterConstants.GET_HEAT_MAP_OF_SELECTED_OPERATOR_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: scooterConstants.GET_HEAT_MAP_OF_SELECTED_OPERATOR_FAILURE,
      error,
    }
  }
}

function cleanTripsView() {
  return { type: scooterConstants.CLEEN_TRIPS_VIEW_MAP }
}

function saveCenterMap(body) {
  return (dispatch) => {
    dispatch(success(body))
  }

  function success(body) {
    return { type: scooterConstants.SAVE_CENTER_MAP, body }
  }
}

function updateRestricted(body) {
  return (dispatch) => {
    scooterService.updateRestricted(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.scooters.updateRestrictedSuccess'),
          ),
        )
        //dispatch get new data getId(?)
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t(
              'services.scooters.updateRestrictedSuccess',
            )}: ${error}`,
          ),
        )
      },
    )
  }
}

function updateParamsConfig(body) {
  return (dispatch) => {
    scooterService.updateParamsConfig(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.scooters.updateParamsConfigSuccess'),
          ),
        )
        dispatch(getParamsConfig())
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t(
              'services.scooters.updateParamsConfigSuccess',
            )}: ${error}`,
          ),
        )
      },
    )
  }
}

function setParamsConfig(body) {
  return (dispatch) => {
    scooterService.setParamsConfig(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.scooters.updateParamsConfigSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t(
              'services.scooters.updateParamsConfigSuccess',
            )}: ${error}`,
          ),
        )
      },
    )
  }
}

function getParamsConfig() {
  return (dispatch) => {
    dispatch(request())
    scooterService.getParamsConfig().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error('')
        // );
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_PARAMS_CONFIG_REQUEST }
  }

  function success(results) {
    return { type: scooterConstants.GET_PARAMS_CONFIG_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.GET_PARAMS_CONFIG_FAILURE, error }
  }
}

function setToOperate(body) {
  const operatorId = body.sharingOperatorId
  return (dispatch) => {
    scooterService.setToOperate(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.scooters.updateRestrictedSuccess'),
          ),
        )
        dispatch(getAll(operatorId))
        dispatch(getAllDynamicByOperator(operatorId, false))
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.scooters.updateRestrictedError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function setHelmetIncluded(body) {
  return (dispatch) => {
    scooterService.setHelmetIncluded(body).then(
      (results) => {
        alertActions.error(
          i18next.t('services.scooters.updateRestrictedSuccess'),
        )
        dispatch(getAll(body.sharingOperatorId))
        dispatch(getAllDynamicByOperator(body.sharingOperatorId, false))
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.scooters.updateRestrictedError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function getMissingDynamic(operatorId) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getMissingDynamic(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.scooters.getAllError')))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_MISSING_DYNAMICS_REQUEST }
  }

  function success(results) {
    return { type: scooterConstants.GET_MISSING_DYNAMICS_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.GET_MISSING_DYNAMICS_FAILURE, error }
  }
}

function getDataById(scooterId) {
  return (dispatch) => {
    if (scooterId) {
      dispatch(request())
      scooterService.getDataById(scooterId).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(
            alertActions.error(i18next.t('services.scooters.getAllError')),
          )
        },
      )
    }
  }

  function request() {
    return { type: scooterConstants.GET_DATA_SCOOTER_BY_ID_REQUEST }
  }

  function success(results) {
    return { type: scooterConstants.GET_DATA_SCOOTER_BY_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.GET_DATA_SCOOTER_BY_ID_FAILURE, error }
  }
}

function getAllByOperatorId(operatorId) {
  return (dispatch) => {
    if (operatorId) {
      dispatch(request())
      scooterService.getAllByOperatorId(operatorId).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(
            alertActions.error(i18next.t('services.scooters.getAllError')),
          )
        },
      )
    }
  }

  function request() {
    return { type: scooterConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: scooterConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.GETALL_FAILURE, error }
  }
}

function getAll(operatorId) {
  return (dispatch) => {
    dispatch(request())
    if (
      isManager() ||
      isSacUser() ||
      isCauUser() ||
      (!isManager() && operatorId)
    ) {
      scooterService.getAll(operatorId).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(
            alertActions.error(i18next.t('services.scooters.getAllError')),
          )
        },
      )
    }
  }

  function request() {
    return { type: scooterConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: scooterConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.GETALL_FAILURE, error }
  }
}

function getDynamicsById(idScooter) {
  return (dispatch) => {
    if (idScooter) {
      dispatch(request())
      scooterService.getDynamicsById(idScooter).then(
        (dynamic) => {
          dispatch(success(dynamic))
        },
        (error) => {
          dispatch(failure(error))
        },
      )
    }
  }

  function request() {
    return { type: scooterConstants.GET_DYNAMIC_REQUEST }
  }

  function success(dynamic) {
    return { type: scooterConstants.GET_DYNAMIC_SUCCESS, dynamic }
  }

  function failure(error) {
    return { type: scooterConstants.GET_DYNAMIC_FAILURE, error }
  }
}

function getAllMapByOperatorId(operatorId) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getAllMapByOperatorId(operatorId).then(
      (dynamics) => {
        dispatch(success(dynamics))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_REQUEST }
  }

  function success(dynamics) {
    return {
      type: scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_SUCCESS,
      dynamics,
    }
  }

  function failure(error) {
    return { type: scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_FAILURE, error }
  }
}

function getAllDynamics() {
  return (dispatch) => {
    dispatch(request())
    scooterService.getAllDynamics().then(
      (dynamics) => {
        dispatch(success(dynamics))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GETALL_DYNAMIC_REQUEST }
  }

  function success(dynamics) {
    return { type: scooterConstants.GETALL_DYNAMIC_SUCCESS, dynamics }
  }

  function failure(error) {
    return { type: scooterConstants.GETALL_DYNAMIC_FAILURE, error }
  }
}

function getDynamics() {
  return (dispatch) => {
    dispatch(request())
    scooterService.getDynamics().then(
      (dynamics) => {
        dispatch(success(dynamics))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.GETALL_DYNAMIC_REQUEST }
  }

  function success(dynamics) {
    return { type: scooterConstants.GETALL_DYNAMIC_SUCCESS, dynamics }
  }

  function failure(error) {
    return { type: scooterConstants.GETALL_DYNAMIC_FAILURE, error }
  }
}
function getAllDynamicByOperator(operatorId, onlyVehiclesWithDynamic = true) {
  return (dispatch) => {
    if (operatorId) {
      dispatch(request())
      scooterService
        .getAllDynamicByOperator(operatorId, onlyVehiclesWithDynamic)
        .then(
          (dynamics) => {
            dispatch(success(dynamics))
          },
          (error) => {
            dispatch(failure(error))
          },
        )
    }
  }

  function request() {
    return { type: scooterConstants.GETALL_DYNAMIC_REQUEST }
  }

  function success(dynamics) {
    return { type: scooterConstants.GETALL_DYNAMIC_SUCCESS, dynamics }
  }

  function failure(error) {
    return { type: scooterConstants.GETALL_DYNAMIC_FAILURE, error }
  }
}

function getTripsByScooterId(idScooter, since = null, until = null) {
  return (dispatch) => {
    if (idScooter) {
      dispatch(request())
      scooterService.getTripsByScooterId(idScooter, since, until).then(
        (trips) => {
          dispatch(success(trips))
        },
        (error) => {
          dispatch(failure(error))
        },
      )
    }
  }

  function request() {
    return { type: scooterConstants.TRIPS_REQUEST }
  }

  function success(trips) {
    return { type: scooterConstants.TRIPS_SUCCESS, trips }
  }

  function failure(error) {
    return { type: scooterConstants.TRIPS_FAILURE, error }
  }
}

function getTripDetails(idScooter, IdTrip) {
  return (dispatch) => {
    dispatch(request())
    scooterService.getTripDetails(idScooter, IdTrip).then(
      (tripDetail) => {
        dispatch(success(tripDetail))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: scooterConstants.TRIPS_DETAIL_REQUEST }
  }

  function success(tripDetail) {
    return { type: scooterConstants.TRIPS_DETAIL_SUCCESS, tripDetail }
  }

  function failure(error) {
    return { type: scooterConstants.TRIPS_DETAIL_FAILURE, error }
  }
}

function filter(field, value) {
  return (dispatch) => {
    dispatch(request(field, value))
    const data = { field, value }
    scooterService.filter(data).then(
      (results) => {
        results.forEach((element) => {
          element.manufactureDate = moment(element.manufactureDate).format(
            'DD/MM/YYYY',
          )
        })
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request(field, value) {
    return { type: scooterConstants.FILTER_REQUEST, field, value }
  }

  function success(results) {
    return { type: scooterConstants.FILTER_SUCCESS, results }
  }

  function failure(error) {
    return { type: scooterConstants.FILTER_FAILURE, error }
  }
}

function assignToOperate(body, isCloseTicket = false) {
  const operatorId = body.sharingOperatorId || ''
  return (dispatch) => {
    scooterService.assignToOperate(body).then(
      (scooter) => {
        body.vehicleIds.length === 1 &&
          dispatch(getId(body.vehicleIds[0], false))
        if (isManager() || isManagerReadOnly()) {
          dispatch(getAll())
          dispatch(operatorActions.getAll())
        } else {
          dispatch(getAll(operatorId))
        }
        isCloseTicket ||
          dispatch(
            alertActions.success(
              i18next.t('services.scooters.assignToOperateSuccess'),
            ),
          )
      },
      (error) => {
        isCloseTicket ||
          dispatch(
            alertActions.error(
              `${i18next.t(
                'services.scooters.assignToOperateError',
              )}: ${error}`,
            ),
          )
      },
    )
  }
}

function getId(id, push = true) {
  return (dispatch) => {
    if (id) {
      dispatch(request(id))
      scooterService.getById(id).then(
        (scooter) => {
          dispatch(success(scooter))
          push && history.push('/scooters/' + id)
        },
        (error) => {
          dispatch(failure(error))
        },
      )
    }
  }

  function request(id) {
    return { type: scooterConstants.DETAILS_REQUEST, id }
  }

  function success(scooter) {
    return { type: scooterConstants.DETAILS_SUCCESS, scooter }
  }

  function failure(error) {
    return { type: scooterConstants.DETAILS_FAILURE, error }
  }
}

function clearResults() {
  return (dispatch) => {
    dispatch(clear())
  }

  function clear() {
    return { type: scooterConstants.CLEAR_RESULTS }
  }
}

function clearState() {
  return (dispatch) => {
    dispatch(clear())
  }
  function clear() {
    return { type: scooterConstants.CLEAR_STATE }
  }
}
