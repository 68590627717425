import { rolesConstants } from './roles'

export const checkRelationRoleAndLinks = (authenticationReducer, link) => {
  let role = authenticationReducer.user.role
  role = Array.isArray(role) ? role[0] : role

  const roleLinkList = [
    {
      link: '/validations',
      role: [rolesConstants.MANAGER, rolesConstants.MANAGER_READONLY],
    },
    {
      link: '/services',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
        rolesConstants.SAC_USER,
        rolesConstants.CAU_USER,
      ],
    },
    {
      link: '/monthlyDetails',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/operatorsUsers',
      role: [rolesConstants.OPERATOR_MANAGER],
    },
    {
      link: '/rates',
      role: [rolesConstants.OPERATOR_MANAGER],
    },
    {
      link: '/usersMenu',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/technicians',
      role: [rolesConstants.OPERATOR_MANAGER],
    },
    {
      link: '/home',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
        rolesConstants.SAC_USER,
        //rolesConstants.TECHNICIAN,
        //rolesConstants.OPERATOR,
      ],
    },
    {
      link: '/login',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
        rolesConstants.SAC_USER,
      ],
    },
    {
      link: '/users',
      role: [rolesConstants.MANAGER, rolesConstants.MANAGER_READONLY],
    },
    {
      link: '/scooters/locations',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.TECHNICIAN,
        rolesConstants.OPERATOR,
        rolesConstants.MANAGER_READONLY,
        rolesConstants.SAC_USER,
        rolesConstants.CAU_USER,
      ],
    },
    {
      link: '/scooters/:id',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/scooters',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.TECHNICIAN,
        rolesConstants.MANAGER_READONLY,
        //rolesConstants.OPERATOR,
      ],
    },
    {
      link: '/customers',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
        rolesConstants.SAC_USER,
        rolesConstants.CAU_USER,
      ],
    },
    {
      link: '/files/create',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/files/:id',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/files',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/upgrades/create/:id',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/upgrades',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/firmwares/create',
      role: [rolesConstants.MANAGER, rolesConstants.MANAGER_READONLY],
    },
    {
      link: '/firmwares/edit/:id',
      role: [rolesConstants.MANAGER, rolesConstants.MANAGER_READONLY],
    },
    {
      link: '/firmwares/:id',
      role: [rolesConstants.MANAGER, rolesConstants.MANAGER_READONLY],
    },
    {
      link: '/firmwares',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/operators',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/operator/profile',
      role: [rolesConstants.OPERATOR_MANAGER],
    },
    {
      link: '/operator/register',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/operator/scooterList',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
        rolesConstants.SAC_USER,
        rolesConstants.CAU_USER,
      ],
    },
    {
      link: '/operator/validate',
      role: [
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
        rolesConstants.SAC_USER,
        rolesConstants.CAU_USER,
      ],
    },
    {
      link: '/operators/list',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/operators/requestScooter',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/operators/services',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/services',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/bills',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/trips',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/analytics',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
      ],
    },
    {
      link: '/incidents',
      role: [
        rolesConstants.OPERATOR_MANAGER,
        rolesConstants.MANAGER,
        rolesConstants.TECHNICIAN,
        rolesConstants.MANAGER_READONLY,
        rolesConstants.SAC_USER,
        //rolesConstants.OPERATOR,
      ],
    },
    {
      link: '/bonus',
      role: [
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
        rolesConstants.SAC_USER,
      ],
    },
    {
      link: '/payments',
      role: [
        rolesConstants.MANAGER,
        rolesConstants.MANAGER_READONLY,
        rolesConstants.SAC_USER,
      ], //rolesConstants.OPERATOR_MANAGER,
    },
    {
      link: '/help',
      role: [rolesConstants.OPERATOR_MANAGER],
    },
    {
      link: '/UpdateDataTechnician',
      role: [rolesConstants.TECHNICIAN],
    },
    {
      link: '/UpdateDataOperatorUser',
      role: [rolesConstants.OPERATOR],
    },
    {
      link: '/cau',
      role: [rolesConstants.MANAGER, rolesConstants.MANAGER_READONLY],
    },
    {
      link: '/sac',
      role: [rolesConstants.MANAGER, rolesConstants.MANAGER_READONLY],
    },
  ]

  const result = roleLinkList
    .map((rl) => {
      if (rl.link === link) {
        return rl.role.filter((r) => r === role)
      }
    })
    .filter((r) => r != undefined)

  //   return true;

  return result && result[0].length === 1
}
