import { Container } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { sacActions } from '../../redux/actions/sac_actions'
import CustomMaterialTable from '../shared/customMaterialTable'
import ModalConfirm from '../shared/modalconfirm'
import { useManageSacUsers } from './_hooks/useManageSacUsers'
import CreateSAC from './_modals/create'
import UpdateSAC from './_modals/update'

const SacUsers = () => {
  const { t } = useTranslation()
  const [isActionsModalOpened, setIsActionsModalOpened] = useState({
    create: false,
    update: false,
    delete: false,
  })
  const [selectedUserId, setSelectedUserId] = useState('')

  const handleClose = () => {
    setIsActionsModalOpened({
      create: false,
      update: false,
      delete: false,
    })
  }

  const dispatch = useDispatch()
  const { users, loading, creating, updating } = useManageSacUsers()

  return (
    <Container maxWidth="xl">
      <CustomMaterialTable
        title={t('sac.table.title')}
        columns={[
          { title: t('sac.table.columns.name'), field: 'fullName' },
          { title: t('sac.table.columns.email'), field: 'email' },
          {
            title: t('sac.table.columns.document'),
            field: 'identificationDocumentCode',
          },
          { title: t('sac.table.columns.phone'), field: 'phoneNumber' },
        ]}
        actions={[
          {
            icon: 'add',
            tooltip: t('sac.table.actions.create'),
            isFreeAction: true,
            onClick: () =>
              setIsActionsModalOpened((data) => ({ ...data, create: true })),
          },
          {
            icon: 'edit',
            tooltip: t('sac.table.actions.edit'),
            onClick: (_, user) => {
              dispatch(sacActions.getById(user.id))
              setIsActionsModalOpened((data) => ({ ...data, update: true }))
            },
          },
          {
            icon: 'delete',
            tooltip: t('sac.table.actions.delete'),
            onClick: (_, user) => {
              setIsActionsModalOpened((data) => ({ ...data, delete: true }))
              setSelectedUserId(user.id)
            },
          },
        ]}
        data={users}
        loading={loading}
      />
      <CreateSAC
        open={isActionsModalOpened.create}
        key="create"
        onClose={handleClose}
        loading={creating}
      />
      <UpdateSAC
        open={isActionsModalOpened.update}
        key="update"
        onClose={handleClose}
        onSubmit={(user) => dispatch(sacActions.update(user, handleClose))}
        loading={updating}
      />
      <ModalConfirm
        isOpen={isActionsModalOpened.delete}
        onClose={() =>
          setIsActionsModalOpened((data) => ({ ...data, delete: false }))
        }
        title={t('sac.modals.titleDelete')}
        body={t('sac.modals.bodyDelete')}
        onConfirm={() => dispatch(sacActions.remove(selectedUserId))}
      />
    </Container>
  )
}

export default SacUsers
