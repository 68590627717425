import { operatorConstant } from '../../constants/operator_constant'
import { tariffsConstant } from '../../constants/tariffs_constant'

const initialState = {
  loadingResults: true,
  results: [],
  resultsByCustomers: [],
  dataOperatorLogged: {
    id: '',
    areas: [],
  },
  selectedOperator: {
    id: '',
    areas: [],
  },
  discounts: [],
  customersAffectedByDiscount: [],
  childs: [],
  parent: [],
  mapStateLoading: false,
  insertPending: false,
  insertFailure: false,
  insertSuccess: false,
  userPendingToCreateExist: false,
  operatorUsers: [],
  discountsCurrentOperator: [],
  discountsLoggedOperator: [],
  genericDiscountLoggedOperator: {},
  currentDiscountOfLoggedOperatorForCustomer: [],
  vehicleHomologationTypes: null,
}

export function operatorReducer(state = initialState, action) {
  switch (action.type) {
    case operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        currentDiscountOfLoggedOperatorForCustomer: action.results,
      }
    case operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        loadingResults: false,
        currentDiscountOfLoggedOperatorForCustomer: [],
        error: action.error,
      }

    case operatorConstant.GET_GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_SUCCESS:
      // const { results } = action || {
      //     results: [{ percent: 0, enable: false, promoText: '' }],
      // };
      // const updatedGenericDiscount = {
      //     percent: results[0] && results[0].percent,
      //     enable: results[0] && results[0].enable,
      //     promoText: results[0] && results[0].promoText,
      // };
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        genericDiscountLoggedOperator: action.results,
      }
    case operatorConstant.GET_GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        genericDiscountLoggedOperator: {},
        error: action.error,
      }

    case operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        discountsLoggedOperator: action.results,
      }
    case operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        discountsLoggedOperator: [],
        error: action.error,
      }

    case operatorConstant.GET_CURRENT_DISCOUNTS_OF_OPERATORS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_CURRENT_DISCOUNTS_OF_OPERATORS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        discountsCurrentOperator: action.results,
      }
    case operatorConstant.GET_CURRENT_DISCOUNTS_OF_OPERATORS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        discountsCurrentOperator: [],
        error: action.error,
      }

    case operatorConstant.GET_ALL_OPERATOR_USER_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_ALL_OPERATOR_USER_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        operatorUsers: action.results,
      }
    case operatorConstant.GET_ALL_OPERATOR_USER_FAILURE:
      return {
        ...state,
        loadingResults: false,
        operatorUsers: [],
        error: action.error,
      }

    case operatorConstant.GET_USER_DATA_BY_MAIL_SUCCESS:
      return {
        ...state,
        userPendingToCreateExist: action.results,
      }

    case operatorConstant.GET_ALL_BY_CUSTOMERS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_ALL_BY_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        resultsByCustomers: action.results,
      }
    case operatorConstant.GET_ALL_BY_CUSTOMERS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        resultsByCustomers: [],
        error: action.error,
      }
    case operatorConstant.INSERT_REQUEST:
      return {
        ...state,
        insertPending: true,
        insertFailure: false,
        insertSuccess: false,
      }
    case operatorConstant.INSERT_FAILURE:
      return {
        ...state,
        insertFailure: true,
        insertPending: false,
      }
    case operatorConstant.INSERT_SUCCESS:
      return {
        ...state,
        insertSuccess: true,
        insertPending: false,
      }

    case operatorConstant.UPDATE_RESTRICTED_SUCCESS:
      return {
        ...state,
        dataOperatorLogged: action.results,
        mapStateLoading: false,
      }

    case operatorConstant.UPDATE_RESTRICTED_REQUEST:
      return {
        ...state,
        mapStateLoading: true,
      }

    case operatorConstant.GET_PARENT_SUCCESS:
      return {
        ...state,
        parent: action.results,
      }

    case operatorConstant.GET_ALL_CHILDS_REQUEST:
      return {
        ...state,
      }
    case operatorConstant.GET_ALL_CHILDS_SUCCESS:
      return {
        ...state,
        childs: action.results,
      }
    case operatorConstant.GET_ALL_CHILDS_FAILURE:
      return {
        ...state,
        childs: [],
        error: action.error,
      }
    case operatorConstant.GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
      }

    case operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_REQUEST:
      return {
        ...state,
      }
    case operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_SUCCESS:
      return {
        ...state,
        customersAffectedByDiscount: action.results,
      }
    case operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_FAILURE:
      return {
        ...state,
        customersAffectedByDiscount: [],
        error: action.error,
      }

    case operatorConstant.GET_ALL_DISCOUNTS_REQUEST:
      return {
        ...state,
      }
    case operatorConstant.GET_ALL_DISCOUNTS_SUCCESS:
      return {
        ...state,
        discounts: action.results,
      }
    case operatorConstant.GET_ALL_DISCOUNTS_FAILURE:
      return {
        ...state,
        discounts: [],
        error: action.error,
      }
    case operatorConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case operatorConstant.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case operatorConstant.GET_ALL_TO_VALIDATE_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_ALL_TO_VALIDATE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case operatorConstant.GET_ALL_TO_VALIDATE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case operatorConstant.GET_ALL_VALIDATED_OPERATORS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case operatorConstant.GET_ALL_VALIDATED_OPERATORS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case operatorConstant.GET_ALL_VALIDATED_OPERATORS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case operatorConstant.SUCCESS_OPERATOR_INFO:
      return {
        ...state,
        dataOperatorLogged: action.user,
        loadingResults: false,
      }
    case operatorConstant.OPERATOR_INFO_SUCCESS:
      return {
        ...state,
        selectedOperator: action.user,
        loadingResult: false,
      }
    case operatorConstant.REQUEST_OPERATOR_INFO:
      return {
        ...state,
        loadingResults: true,
      }
    case tariffsConstant.REMOVE_DISCOUNTS_BY_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case tariffsConstant.REMOVE_DISCOUNTS_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        discountsLoggedOperator: state.discountsLoggedOperator.filter(
          (x) => x.id != action.id,
        ),
      }
    case tariffsConstant.REMOVE_DISCOUNTS_BY_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
      }
    case operatorConstant.GET_VEHICLE_HOMOLOGATION_TYPES_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case operatorConstant.GET_VEHICLE_HOMOLOGATION_TYPES_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        vehicleHomologationTypes: action.results,
      }
    case operatorConstant.GET_VEHICLE_HOMOLOGATION_TYPES_FAILURE:
      return {
        ...state,
        loadingResults: false,
        vehicleHomologationTypes: null,
        error: action.error,
      }
    default:
      return state
  }
}
