export const sacConstants = {
  GET_SAC_USERS_REQUEST: 'GET_SAC_USERS_REQUEST',
  GET_SAC_USERS_SUCCESS: 'GET_SAC_USERS_SUCCESS',
  GET_SAC_USERS_FAILURE: 'GET_SAC_USERS_FAILURE',

  SAC_CREATE_USER_REQUEST: 'SAC_CREATE_USER_REQUEST',
  SAC_CREATE_USER_SUCCESS: 'SAC_CREATE_USER_SUCCESS',
  SAC_CREATE_USER_FAILURE: 'SAC_CREATE_USER_FAILURE',

  SAC_UPDATE_USER_REQUEST: 'SAC_UPDATE_USER_REQUEST',
  SAC_UPDATE_USER_SUCCESS: 'SAC_UPDATE_USER_SUCCESS',
  SAC_UPDATE_USER_FAILURE: 'SAC_UPDATE_USER_FAILURE',

  GET_SAC_USER_BY_ID_REQUEST: 'GET_SAC_USER_BY_ID_REQUEST',
  GET_SAC_USER_BY_ID_SUCCESS: 'GET_SAC_USER_BY_ID_SUCCESS',
  GET_SAC_USER_BY_ID_FAILURE: 'GET_SAC_USER_BY_ID_FAILURE',

  SAC_CLEAR_USER_DATA: 'SAC_CLEAR_USER_DATA',
}
