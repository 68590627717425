import { Button, Dialog, DialogContent, Slide } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DocumentZoom = ({
  open,
  onClose,
  onEnter = () => {},
  imageUrl,
  rotateDegDocuments,
}) => {
  const { t } = useTranslation()
  const handleEnter = () => onEnter()

  const { selected } = rotateDegDocuments

  const [rotateDeg, setRotateDeg] = useState(rotateDegDocuments[selected])

  useEffect(() => {
    setRotateDeg(rotateDegDocuments[selected])
  }, [rotateDegDocuments])

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      fullWidth
      maxWidth="md"
      onEnter={handleEnter}
      onClose={onClose}
    >
      <DialogContent>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <TransformWrapper
            initialScale={1}
            minScale={1}
            maxScale={7}
            limitToBounds
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <div style={{ position: 'absolute', top: 0, zIndex: 999 }}>
                  <Button
                    mode="contained"
                    style={{
                      backgroundColor: '#e41622',
                      color: 'white',
                      margin: 4,
                    }}
                    onClick={() => zoomIn()}
                  >
                    +
                  </Button>
                  <Button
                    mode="contained"
                    style={{
                      backgroundColor: '#e41622',
                      color: 'white',
                      margin: 4,
                    }}
                    onClick={() => zoomOut()}
                  >
                    -
                  </Button>
                  <Button
                    mode="contained"
                    style={{
                      backgroundColor: '#e41622',
                      color: 'white',
                      margin: 4,
                    }}
                    onClick={() => resetTransform()}
                  >
                    x
                  </Button>
                </div>
                <TransformComponent wrapperStyle={{ overflow: 'visible' }}>
                  <img
                    style={{
                      transform: `rotate(${rotateDeg}deg)`,
                      maxHeight: '85vh',
                    }}
                    alt={t('customers.documents.altDniFront')}
                    src={imageUrl}
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DocumentZoom
