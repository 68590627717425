import { techniciansConstant } from '../../constants/technicians_constant'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
}

export function technicianReducer(state = initialState, action) {
  switch (action.type) {
    case techniciansConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case techniciansConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case techniciansConstant.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    default:
      return state
  }
}
