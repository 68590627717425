export const firmwareConstants = {
  FILTER_REQUEST: 'FIRMWARE_FILTER_REQUEST',
  FILTER_SUCCESS: 'FIRMWARE_FILTER_SUCCESS',
  FILTER_FAILURE: 'FIRMWARE_FILTER_FAILURE',

  GETALL_REQUEST: 'FIRMWARE_GETALL_REQUEST',
  GETALL_SUCCESS: 'FIRMWARE_GETALL_SUCCESS',
  GETALL_FAILURE: 'FIRMWARE_GETALL_FAILURE',

  DETAILS_REQUEST: 'FIRMWARE_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'FIRMWARE_DETAILS_SUCCESS',
  DETAILS_FAILURE: 'FIRMWARE_DETAILS_FAILURE',

  GETCOMPATIBLE_REQUEST: 'FIRMWARE_GETCOMPATIBLE_REQUEST',
  GETCOMPATIBLE_SUCCESS: 'FIRMWARE_GETCOMPATIBLE_SUCCESS',
  GETCOMPATIBLE_FAILURE: 'FIRMWARE_GETCOMPATIBLE_FAILURE',

  CLEAR_RESULTS: 'FIRMWARE_CLEAR_RESULTS',

  ADD_REQUEST: 'FIRMWARE_ADD_REQUEST',
  ADD_SUCCESS: 'FIRMWARE_ADD_SUCCESS',
  ADD_FAILURE: 'FIRMWARE_ADD_FAILURE',

  EDIT_REQUEST: 'FIRMWARE_EDIT_REQUEST',
  EDIT_SUCCESS: 'FIRMWARE_EDIT_SUCCESS',
  EDIT_FAILURE: 'FIRMWARE_EDIT_FAILURE',

  DELETE_REQUEST: 'FIRMWARE_DELETE_REQUEST',
  DELETE_SUCCESS: 'FIRMWARE_DELETE_SUCCESS',
  DELETE_FAILURE: 'FIRMWARE_DELETE_FAILURE',
}
