import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  buttonSectionValidateModal: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  incidentSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeModalButton: {
    float: 'right',
  },
  formDontValidate: {
    marginTop: '5%',
  },
  commentBoxDontValidate: {
    width: '100%',
  },
  buttonSubmitCommentDontValidate: {
    marginTop: '2%',
    float: 'right',
  },
}))

export default useStyles
