import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isManager } from '../../../helpers/roles'
import { formatToUtc } from '../../shared/formatDate'

const AssignManualy = ({ isOpen, onClose, data, onConfirm }) => {
  const { t } = useTranslation()
  const [customerEmail, setcustomerEmail] = useState('')

  const [validatedSince, setvalidatedSince] = useState(null)
  const [validatedUntil, setvalidatedUntil] = useState(null)

  const handleClose = () => {
    onClose()
  }
  const handleConfirm = () => {
    onClose()
    onConfirm(
      customerEmail,
      formatToUtc(validatedSince),
      formatToUtc(validatedUntil),
    )
  }
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('customers.modals.assignManualy.dialogTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('customers.modals.assignManualy.dialogContentText')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={t('customers.modals.assignManualy.emailLabel')}
            type="email"
            fullWidth
            value={customerEmail}
            onChange={(e) => setcustomerEmail(e.target.value)}
          />
          {!isManager() && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('customers.modals.assignManualy.sinceLabel')}
                  type="datetime-local"
                  value={validatedSince}
                  onChange={(e) => setvalidatedSince(e.target.value)}
                  // defaultValue="2017-05-24T10:30"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('customers.modals.assignManualy.untilLabel')}
                  type="datetime-local"
                  value={validatedUntil}
                  onChange={(e) => setvalidatedUntil(e.target.value)}
                  // defaultValue="2017-05-24T10:30"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('customers.modals.assignManualy.cancelBtn')}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {t('customers.modals.assignManualy.ConfirmBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default AssignManualy
