import { makeStyles } from '@material-ui/core'

const INPUT_LABEL_FONT_SIZE = '1.1rem'
const INPUT_FIELD_FONT_SIZE = '0.9rem'
const FONT_WEIGHT = 700

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'grey',
    zIndex: 999,
    display: 'flex',
    height: '100vh',
  },
  modalContainer: {
    padding: 16,
    overflow: 'auto',
    backgroundColor: 'white',
  },
  headerButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: INPUT_LABEL_FONT_SIZE,
    fontWeight: FONT_WEIGHT,
    whiteSpace: 'nowrap',
    height: 20,
  },
  input: {
    fontSize: INPUT_FIELD_FONT_SIZE,
  },
}))

export default useStyles
