export const fileConstants = {
  FILTER_REQUEST: 'FILE_FILTER_REQUEST',
  FILTER_SUCCESS: 'FILE_FILTER_SUCCESS',
  FILTER_FAILURE: 'FILE_FILTER_FAILURE',

  GETALL_REQUEST: 'FILE_GETALL_REQUEST',
  GETALL_SUCCESS: 'FILE_GETALL_SUCCESS',
  GETALL_FAILURE: 'FILE_GETALL_FAILURE',

  FIRMWARES_VERSION_REQUEST: 'FILE_FIRMWARES_VERSION_REQUEST',
  FIRMWARES_VERSION_SUCCESS: 'FILE_FIRMWARES_VERSION_SUCCESS',
  FIRMWARES_VERSION_FAILURE: 'FILE_FIRMWARES_VERSION_FAILURE',

  DETAILS_REQUEST: 'FILE_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'FILE_DETAILS_SUCCESS',
  DETAILS_FAILURE: 'FILE_DETAILS_FAILURE',

  CLEAR_RESULTS: 'FILE_CLEAR_RESULTS',

  ADD_REQUEST: 'FILE_ADD_REQUEST',
  ADD_SUCCESS: 'FILE_ADD_SUCCESS',
  ADD_FAILURE: 'FILE_ADD_FAILURE',

  EDIT_REQUEST: 'FILE_EDIT_REQUEST',
  EDIT_SUCCESS: 'FILE_EDIT_SUCCESS',
  EDIT_FAILURE: 'FILE_EDIT_FAILURE',

  DELETE_REQUEST: 'FILE_DELETE_REQUEST',
  DELETE_SUCCESS: 'FILE_DELETE_SUCCESS',
  DELETE_FAILURE: 'FILE_DELETE_FAILURE',
}
