import { ThemeProvider } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import Root from './Root'
import theme from './components/theme'
import { onMessageListener } from './firebase'
import i18n from './i18n'
import store from './redux/store'

const notistackRef = React.createRef()
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key)
}

export default class App extends React.Component {
  componentDidMount() {
    onMessageListener()
      .then((payload) => {
        console.log('payload onMessageListener => ', payload)
        // TODO: ADD ACTIONS IF YOU WANT.
      })
      .catch((err) => console.log('failed: ', err))
  }

  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              preventDuplicate
              ref={notistackRef}
              maxSnack={5}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              action={(key) => <CloseIcon onClick={onClickDismiss(key)} />}
            >
              <Root />
            </SnackbarProvider>
          </ThemeProvider>
        </Provider>
      </I18nextProvider>
    )
  }
}
