import { Button, Grid } from '@material-ui/core'
import i18next from 'i18next'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import ValidationsList from './list'
import ShiftDaysValidation from './shiftDays'

const ValidationsView = (props) => {
  //if (!props.shiftDays) console.log("!props.shiftDays")
  const [shiftDay, setShiftDay] = useState(
    !props.shiftDays
      ? [
          {
            weekDays: [],
            earliestStart: '',
            latestArrival: '',
          },
        ]
      : props.shiftDays,
  )

  const [errors, setErrors] = useState(
    !props.shiftDays
      ? {
          shiftDay: [
            {
              earliestStart: { result: true, message: '' },
              latestArrival: { result: true, message: '' },
              weekDays: { result: true, message: '' },
            },
          ],
        }
      : {
          shiftDay: props.shiftDays.map((item) => {
            return {
              earliestStart: { result: true, message: '' },
              latestArrival: { result: true, message: '' },
              weekDays: { result: true, message: '' },
            }
          }),
        },
  )

  const addShiftDay = () => {
    errors.shiftDay.push({
      earliestStart: { result: true, message: '' },
      latestArrival: { result: true, message: '' },
      weekDays: { result: true, message: '' },
    })
    setErrors({ ...errors })
    shiftDay.push({
      weekDays: [],
      earliestStart: '',
      latestArrival: '',
    })
    setShiftDay([...shiftDay])
  }
  const removeShiftDay = (index) => {
    setShiftDay(shiftDay.filter((_, i) => i !== index))
    errors.shiftDay = errors.shiftDay.filter((_, i) => i !== index)
    setErrors({ ...errors })
  }

  const setShiftDays = (event, index) => {
    let { name, value } = event.target
    var date = new Date()
    var array = value.split(':')
    date.setHours(array[0], array[1])
    array = name.split('_')
    let updatedShiftDays = shiftDay
    updatedShiftDays[index][array[0]] = value
    errors.shiftDay[index][array[0]].message = ''
    setShiftDay([...updatedShiftDays])
  }

  const handleShiftDaysUpdates = (days, option = null, index) => {
    let updatedShiftDays = shiftDay
    if (option) {
      switch (true) {
        case option === '1':
          updatedShiftDays[index].weekDays = [5, 6]
          break
        case option === '2':
          updatedShiftDays[index].weekDays = [0, 1, 2, 3, 4, 5, 6]
          break
        case option === '3':
          updatedShiftDays[index].weekDays = [0, 1, 2, 3, 4]
          break
        default:
          updatedShiftDays[index].weekDays = days.map((d) =>
            d.hasOwnProperty('key') ? parseInt(d.key) : d,
          )
      }
    } else {
      updatedShiftDays[index].weekDays = days.map((d) =>
        d.hasOwnProperty('key') ? parseInt(d.key) : d,
      )
    }
    errors.shiftDay[index].weekDays.message = ''
    setShiftDay([...updatedShiftDays])
  }

  return (
    <>
      <h2 style={{ padding: 15 }}>
        Configure el horario de atención para las validaciones
      </h2>
      <Grid container spacing={3} style={{ padding: 15 }}>
        {shiftDay.map((item, index) => (
          <ShiftDaysValidation
            item={item}
            key={index}
            index={index}
            length={shiftDay.length}
            errors={errors}
            setErrors={setErrors}
            setShiftDay={setShiftDays}
            add={() => addShiftDay()}
            remove={() => removeShiftDay(index)}
            customerReducer={props.customerReducer}
            checkUpdates={(days, option) =>
              handleShiftDaysUpdates(days, option, index)
            }
          />
        ))}
        <Grid item md={4}></Grid>
        <Grid item md={3}>
          <Button
            onClick={() => props.setCalendar(shiftDay)}
            variant="contained"
            color="primary"
            size="medium"
            fullWidth
          >
            {i18next.t('validations.shiftDay.buttonSave')}
          </Button>
        </Grid>
        <Grid item md={4}></Grid>

        <Grid item md={11}>
          {!props.customerReducer.loadingCustomerRequests && (
            <ValidationsList
              data={props.customerReducer.customerRequestsList}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default compose(withTranslation('common'))(ValidationsView)
