import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Item from './item'

const ReportDetailsView = ({ scooterReducer, servicesReducer }) => {
  const { t } = useTranslation()
  return (
    // <div>
    //   {
    //     scooterReducer.hasOwnProperty('trips') && (
    //       scooterReducer.trips.length > 0 ?
    //         scooterReducer.trips.map(t => {
    //           return <Item data={t}/>
    //         }) :
    //         <p>No hay Detalles a mostrar</p>
    //     )
    //   }
    // </div>
    <div>
      {servicesReducer.trips.length > 0 ? (
        servicesReducer.trips.map((t) => {
          return <Item data={t} />
        })
      ) : (
        <p></p> //{t("reports.sections.details.page.noDetailsToDisplay")}
      )}
    </div>
  )
}

function mapState(state) {
  const { scooterReducer, operatorReducer, servicesReducer } = state
  return { scooterReducer, operatorReducer, servicesReducer }
}

const actionCreators = {
  // getDynamicsById: scooterActions.getDynamicsById,
  // getTripsByScooterId: scooterActions.getTripsByScooterId
}

export default connect(mapState, null)(ReportDetailsView)
