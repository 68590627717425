import { handleCall } from './handleResponse'

export const paymentsService = {
  getAll,
  refundPayment,
}

function getAll(filter) {
  const params =
    filter && filter.since && filter.until
      ? `?since=${filter.since}&until=${filter.until}`
      : ''
  return handleCall(`/sharing/v1/PaymentHistorics/GetFiltered${params}`, {
    method: 'GET',
    body: null,
  })
}
function refundPayment(idPayment) {
  return handleCall(`/sharing/v1/paymentHistorics/${idPayment}/Refund`, {
    method: 'POST',
    body: null,
  })
}
