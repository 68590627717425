import { bonusConstants } from '../../constants/bonus_constants'
import { bonusService } from '../../services/bonusService'
import { alertActions } from './alert_actions'

import i18next from 'i18next'

export const bonusActions = {
  getAll,
  insert,
  update,
  getBonusDataFromBonusHistoricId,
}
function getBonusDataFromBonusHistoricId(id) {
  return (dispatch) => {
    dispatch(request())
    bonusService.getBonusDataFromBonusHistoricId(id).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: bonusConstants.GET_DATA_FROM_HISTORIC_REQUEST }
  }

  function success(results) {
    return { type: bonusConstants.GET_DATA_FROM_HISTORIC_SUCCESS, results }
  }

  function failure(error) {
    return { type: bonusConstants.GET_DATA_FROM_HISTORIC_FAILURE, error }
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    bonusService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.bonus.getAllError')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: bonusConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: bonusConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: bonusConstants.GETALL_FAILURE, error }
  }
}

function update(body) {
  return (dispatch) => {
    dispatch(request())
    bonusService.update(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(i18next.t('services.bonus.updateSuccess')),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.bonus.updateError')} : ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: bonusConstants.UPDATE_REQUEST }
  }

  function success(results) {
    return { type: bonusConstants.UPDATE_SUCCESS, results }
  }

  function failure(error) {
    return { type: bonusConstants.UPDATE_FAILURE, error }
  }
}

function insert(body) {
  return (dispatch) => {
    dispatch(request())
    bonusService.insert(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(i18next.t('services.bonus.insertSuccess')),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.bonus.insertError')} : ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: bonusConstants.INSERT_REQUEST }
  }

  function success(results) {
    return { type: bonusConstants.INSERT_SUCCESS, results }
  }

  function failure(error) {
    return { type: bonusConstants.INSERT_FAILURE, error }
  }
}
