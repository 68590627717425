import React from 'react'
import Dashboard from '../dashboard/index'
import HomeView from './page'
import PageManager from './pageManager'

import { isUserWithRole, rolesConstants } from '../../helpers/roles'

const Home = () => {
  return (
    <Dashboard
      component={
        // <DashboardHome />
        isUserWithRole([rolesConstants.MANAGER, rolesConstants.SAC_USER]) ? (
          <PageManager />
        ) : (
          <HomeView />
        )
      }
    />
  )
}

export default Home
