import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { customerActions } from '../../../redux/actions/customers_actions'
import { operatorActions } from '../../../redux/actions/operator_action'

import CustomMaterialTable from '../../shared/customMaterialTable'

import { history } from '../../../helpers/history'

import { useTranslation } from 'react-i18next'
import AssignCustomerConfirmation from './confirmAssigncustomerDialog'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  contentContainer: {
    padding: '2%',
  },
  rowSelected: {
    background: '#e41622',
  },
  btnSection: {
    marginRight: '1%',
  },
  btnAndDatePicker: {
    margin: '2% 0%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  datePickerSection: {
    marginLeft: '1%',
  },
  firstTextField: {
    marginRight: '30px',
  },
}))

const AssigntCustomerToOperator = ({
  getAllPrivateOperators,
  assignCustomersToOperator,
  operatorReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    getAllPrivateOperators()
  }, [])

  const [operatorSelector, setoperatorSelector] = useState()
  const [
    isAssignCustomerConfirmationDialogOpen,
    setisAssignCustomerConfirmationDialogOpen,
  ] = useState(false)

  const handleAssignCustomerToOperator = (event, rowData) => {
    setisAssignCustomerConfirmationDialogOpen(true)
    setoperatorSelector(rowData)
  }

  const handleConfirmAssignCustomerToOperator = (
    validatedSince,
    validatedUntil,
  ) => {
    const customerEmail = window.location.href.split('assignToOperator/')[1]
    let body = {
      sharingOperatorId: operatorSelector.id,
      customerKey: customerEmail,
      canOperateStart: validatedSince,
      canOperateEnd: validatedUntil,
    }
    assignCustomersToOperator(body)
    history.goBack()
  }

  const unassignCustomer = () => {}

  return (
    <div className={classes.contentContainer}>
      <CustomMaterialTable
        title={t('customers.assignToOperator.table.tableTittle')}
        columns={[
          {
            title: t('customers.assignToOperator.table.columns.name'),
            field: 'name',
          },
          {
            title: t('customers.assignToOperator.table.columns.cif'),
            field: 'cif',
          },
          {
            title: t('customers.assignToOperator.table.columns.email'),
            field: 'managerEmail',
          },
          {
            title: t('customers.assignToOperator.table.columns.canOperate'),
            field: 'canOperate',
            addlookup: true,
          },
          {
            title: t('customers.assignToOperator.table.columns.isPrivate'),
            field: 'isPrivate',
            addlookup: true,
          },
        ]}
        data={operatorReducer.results}
        actions={[
          (rowData) => ({
            icon: 'assignment_turned_in', //assignment_late
            tooltip: t('customers.assignToOperator.table.actions.assign'),
            onClick: (event, rowData) =>
              handleAssignCustomerToOperator(event, rowData),
          }),
        ]}
      />
      <AssignCustomerConfirmation
        isOpen={isAssignCustomerConfirmationDialogOpen}
        onClose={() => setisAssignCustomerConfirmationDialogOpen(false)}
        onConfirm={handleConfirmAssignCustomerToOperator}
      />
    </div>
  )
}
function mapState(state) {
  const { operatorReducer } = state
  return { operatorReducer }
}

const actionCreators = {
  getAllPrivateOperators: operatorActions.getAllPrivateOperators,
  assignCustomersToOperator: customerActions.assignCustomersToOperator,
}

export default connect(mapState, actionCreators)(AssigntCustomerToOperator)
