import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cauActions } from '../../../redux/actions/cau_actions'

export const useManageCauUsers = () => {
  const dispatch = useDispatch()
  const { users, loadingUsers, loadingCreate, loadingUpdate } = useSelector(
    (state) => state.cauReducer,
  )

  useEffect(() => {
    dispatch(cauActions.getAll())
  }, [])

  return {
    users,
    loading: loadingUsers,
    updating: loadingUpdate,
    creating: loadingCreate,
  }
}
