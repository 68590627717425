import React, { Fragment, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { isManager } from '../../helpers/roles'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { operatorActions } from '../../redux/actions/operator_action'
import { techniciansActions } from '../../redux/actions/technicians_action'

import NotificationsManagement from '../notifications'
import FormNewTechnician from './new/formNewTechnician'

import ModalConfirm from '../shared/modalconfirm'

import CustomMaterialTable from '../shared/customMaterialTable'
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const TechniciansList = ({
  getAll,
  getAllOperators,
  technicianReducer,
  operatorReducer,
  update,
  remove,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [selectedRow, setselectedRow] = useState({})
  const [
    isModalConfirmRemoveTechnicianOpen,
    setisModalConfirmRemoveTechnicianOpen,
  ] = useState(false)
  const [isNotificationsManagementOpen, setisNotificationsManagementOpen] =
    useState(false)
  const [isNewTechnicianFromOpen, setisNewTechnicianFromOpen] = useState(false)
  useEffect(() => {
    getAll(!isManager() && operatorReducer.dataOperatorLogged.id)
    isManager() && getAllOperators()
  }, [])

  const handleActionRemoveTechnician = (rowData) => {
    setselectedRow(rowData)
    setisModalConfirmRemoveTechnicianOpen(true)
  }
  const handleGetNameOfOperator = (rowData) => {
    const operatorFilter = operatorReducer.results.filter(
      (o) => o.id === (rowData.sharingOperatorId || ''),
    )
    return operatorFilter.length > 0 ? operatorFilter[0].name : ''
  }

  const handleUpdateTechnician = (newData, oldData) => {
    const { firstName, lastName, mobilePhone, id } = newData
    const body = {
      firstName,
      mobilePhone: mobilePhone.includes('+34')
        ? mobilePhone
        : `+34${mobilePhone}`,
      lastName,
      technicianId: id,
      operatorId: operatorReducer.dataOperatorLogged.id,
    }
    update(body)
  }

  const handleConfirmRemoveTechnician = () => {
    const removeBody = {
      operatorId: operatorReducer.dataOperatorLogged.id,
      technicianId: selectedRow.id,
    }
    remove(removeBody)
  }

  return (
    <Fragment>
      <CustomMaterialTable
        title={t('technicians.page.table.title')}
        data={technicianReducer.results}
        isLoading={technicianReducer.loadingResults}
        saveFilters={true}
        columns={[
          {
            title: t('technicians.page.table.columns.name'),
            field: 'firstName',
            editable: 'onUpdate',
          },
          {
            title: t('technicians.page.table.columns.lastName'),
            field: 'lastName',
            editable: 'onUpdate',
          },
          {
            title: t('technicians.page.table.columns.mobilePhone'),
            field: 'mobilePhone',
            editable: 'onUpdate',
          },
          {
            title: t('technicians.page.table.columns.email'),
            field: 'email',
            editable: 'never',
          },
          {
            title: t('technicians.page.table.columns.operator'),
            render: (rowData) => handleGetNameOfOperator(rowData),
            customFilterAndSearch: (term, rowData) =>
              handleGetNameOfOperator(rowData).includes(term),
            editable: 'never',
            hidden: !isManager(),
          },
        ]}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              handleUpdateTechnician(newData, oldData)
              resolve()
            }),
        }}
        actions={[
          {
            icon: 'add',
            tooltip: t('technicians.page.table.action.add'),
            isFreeAction: true,
            onClick: (event) => setisNewTechnicianFromOpen(true),
          },
          {
            icon: 'add_alert',
            tooltip: t('customers.page.actions.addNotification'),
            isFreeAction: true,
            onClick: (event) => setisNotificationsManagementOpen(true),
          },
          {
            icon: 'delete',
            tooltip: t('customers.page.actions.remove'),
            onClick: (event, rowData) => handleActionRemoveTechnician(rowData),
          },
        ]}
      />
      <NotificationsManagement
        isOpen={isNotificationsManagementOpen}
        onClose={() => setisNotificationsManagementOpen(false)}
        usersData={technicianReducer.results}
        usersType={'Technician'}
      />
      <FormNewTechnician
        isOpen={isNewTechnicianFromOpen}
        onClose={() => setisNewTechnicianFromOpen(false)}
        operatorReducer={operatorReducer}
      />
      <ModalConfirm
        isOpen={isModalConfirmRemoveTechnicianOpen}
        onClose={() => setisModalConfirmRemoveTechnicianOpen(false)}
        onConfirm={handleConfirmRemoveTechnician}
        title={`${t('technicians.page.ModalConfirmRemoveTechnician.title')} (${
          selectedRow.username
        })`}
        inputAvaiable={false}
      />
    </Fragment>
  )
}

function mapState(state) {
  const { technicianReducer, operatorReducer } = state
  return { technicianReducer, operatorReducer }
}

const actionCreators = {
  getAll: techniciansActions.getAll,
  update: techniciansActions.update,
  getAllOperators: operatorActions.getAll,
  remove: techniciansActions.remove,
}

export default connect(mapState, actionCreators)(TechniciansList)
