import cat from './default.cat.json'
import en from './default.en.json'
import es from './default.json'

import alert_dialog_cat from '../../components/alert-dialog/locales/default.cat.json'
import alert_dialog_en from '../../components/alert-dialog/locales/default.en.json'
import alert_dialog_es from '../../components/alert-dialog/locales/default.json'
import bonus_cat from '../../components/bonus/locales/default.cat.json'
import bonus_en from '../../components/bonus/locales/default.en.json'
import bonus_es from '../../components/bonus/locales/default.json'
import customers_cat from '../../components/customers/locales/default.cat.json'
import customers_en from '../../components/customers/locales/default.en.json'
import customers_es from '../../components/customers/locales/default.json'
import dashboard_cat from '../../components/dashboard/locales/default.cat.json'
import dashboard_en from '../../components/dashboard/locales/default.en.json'
import dashboard_es from '../../components/dashboard/locales/default.json'
import help_cat from '../../components/help/locales/default.cat.json'
import help_en from '../../components/help/locales/default.en.json'
import help_es from '../../components/help/locales/default.json'
import home_cat from '../../components/home/locales/default.cat.json'
import home_en from '../../components/home/locales/default.en.json'
import home_es from '../../components/home/locales/default.json'
import incidents_cat from '../../components/incidents/locales/default.cat.json'
import incidents_en from '../../components/incidents/locales/default.en.json'
import incidents_es from '../../components/incidents/locales/default.json'
import login_cat from '../../components/login/locales/default.cat.json'
import login_en from '../../components/login/locales/default.en.json'
import login_es from '../../components/login/locales/default.json'
import monthlyDetails_cat from '../../components/monthlyDetails/locales/default.cat.json'
import monthlyDetails_en from '../../components/monthlyDetails/locales/default.en.json'
import monthlyDetails_es from '../../components/monthlyDetails/locales/default.json'
import notifications_cat from '../../components/notifications/locales/default.cat.json'
import notifications_en from '../../components/notifications/locales/default.en.json'
import notifications_es from '../../components/notifications/locales/default.json'
import operator_cat from '../../components/operator/locales/default.cat.json'
import operator_en from '../../components/operator/locales/default.en.json'
import operator_es from '../../components/operator/locales/default.json'
import operatorUser_cat from '../../components/operatorUser/locales/default.cat.json'
import operatorUser_en from '../../components/operatorUser/locales/default.en.json'
import operatorUser_es from '../../components/operatorUser/locales/default.json'
import payments_cat from '../../components/payments/locales/default.cat.json'
import payments_en from '../../components/payments/locales/default.en.json'
import payments_es from '../../components/payments/locales/default.json'
import reports_cat from '../../components/reports/locales/default.cat.json'
import reports_en from '../../components/reports/locales/default.en.json'
import reports_es from '../../components/reports/locales/default.json'
import scooters_cat from '../../components/scooters/locales/default.cat.json'
import scooters_en from '../../components/scooters/locales/default.en.json'
import scooters_es from '../../components/scooters/locales/default.json'
import servicesPage_cat from '../../components/services/locales/default.cat.json'
import servicesPage_en from '../../components/services/locales/default.en.json'
import servicesPage_es from '../../components/services/locales/default.json'
import technicians_cat from '../../components/technicians/locales/default.cat.json'
import technicians_en from '../../components/technicians/locales/default.en.json'
import technicians_es from '../../components/technicians/locales/default.json'
import constansts_cat from '../../constants/locales/default.cat.json'
import constansts_en from '../../constants/locales/default.en.json'
import constansts_es from '../../constants/locales/default.json'
import services_cat from '../../services/locales/default.cat.json'
import services_en from '../../services/locales/default.en.json'
import services_es from '../../services/locales/default.json'

import shared_cat from '../../components/shared/locales/default.cat.json'
import shared_en from '../../components/shared/locales/default.en.json'
import shared_es from '../../components/shared/locales/default.json'

import validations_cat from '../../components/validations/locales/default.cat.json'
import validations_en from '../../components/validations/locales/default.en.json'
import validations_es from '../../components/validations/locales/default.json'

import cau_cat from '../../components/cauUsers/locales/default.cat.json'
import cau_en from '../../components/cauUsers/locales/default.en.json'
import cau_es from '../../components/cauUsers/locales/default.json'

import sac_cat from '../../components/sacUsers/locales/default.cat.json'
import sac_en from '../../components/sacUsers/locales/default.en.json'
import sac_es from '../../components/sacUsers/locales/default.json'

es['es']['alert-dialog'] = alert_dialog_es['es']
en['en']['alert-dialog'] = alert_dialog_en['en']
cat['cat']['alert-dialog'] = alert_dialog_cat['cat']
es['es'].dashboard = dashboard_es['es']
en['en'].dashboard = dashboard_en['en']
cat['cat'].dashboard = dashboard_cat['cat']
es['es'].customers = customers_es['es']
en['en'].customers = customers_en['en']
cat['cat'].customers = customers_cat['cat']
es['es'].scooters = scooters_es['es']
en['en'].scooters = scooters_en['en']
cat['cat'].scooters = scooters_cat['cat']
es['es'].material_table = constansts_es['es']
en['en'].material_table = constansts_en['en']
cat['cat'].material_table = constansts_cat['cat']
es['es'].validations = constansts_es['es'].validations
en['en'].validations = constansts_en['en'].validations
cat['cat'].validations = constansts_cat['cat'].validations
es['es'].services = services_es['es'].services
en['en'].services = services_en['en'].services
cat['cat'].services = services_cat['cat'].services
es['es'].incidents = incidents_es['es']
en['en'].incidents = incidents_en['en']
cat['cat'].incidents = incidents_cat['cat']
es['es'].operator = operator_es['es']
en['en'].operator = operator_en['en']
cat['cat'].operator = operator_cat['cat']
es['es'].reports = reports_es['es']
en['en'].reports = reports_en['en']
cat['cat'].reports = reports_cat['cat']
es['es'].bonus = bonus_es['es']
en['en'].bonus = bonus_en['en']
cat['cat'].bonus = bonus_cat['cat']
es['es'].login = login_es['es']
en['en'].login = login_en['en']
cat['cat'].login = login_cat['cat']
es['es'].home = home_es['es']
en['en'].home = home_en['en']
cat['cat'].home = home_cat['cat']
es['es'].payments = payments_es['es']
en['en'].payments = payments_en['en']
cat['cat'].payments = payments_cat['cat']
es['es'].technicians = technicians_es['es']
en['en'].technicians = technicians_en['en']
cat['cat'].technicians = technicians_cat['cat']
es['es'].operatorUser = operatorUser_es['es']
en['en'].operatorUser = operatorUser_en['en']
cat['cat'].operatorUser = operatorUser_cat['cat']
es['es'].notifications = notifications_es['es']
en['en'].notifications = notifications_en['en']
cat['cat'].notifications = notifications_cat['cat']
es['es'].monthlyDetails = monthlyDetails_es['es']
en['en'].monthlyDetails = monthlyDetails_en['en']
cat['cat'].monthlyDetails = monthlyDetails_cat['cat']
es['es'].servicesPage = servicesPage_es['es']
en['en'].servicesPage = servicesPage_en['en']
cat['cat'].servicesPage = servicesPage_cat['cat']
es['es'].help = help_es['es']
en['en'].help = help_en['en']
cat['cat'].help = help_cat['cat']

es['es'].shared = shared_es['es']
en['en'].shared = shared_en['en']
cat['cat'].shared = shared_cat['cat']

es['es'].validations = validations_es['es']
en['en'].validations = validations_en['en']
cat['cat'].validations = validations_cat['cat']

es['es'].cau = cau_es['es']
en['en'].cau = cau_en['en']
cat['cat'].cau = cau_cat['cat']

es['es'].sac = sac_es['es']
en['en'].sac = sac_en['en']
cat['cat'].sac = sac_cat['cat']

export { cat, en, es }
