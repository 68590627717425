import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dateFilter: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '2%',
    // justifyContent: 'space-between'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: 200,
  },
  getAllButton: {
    display: 'flex',
    marginLeft: '2%',
  },
  containerFilter: {
    padding: '1%',
  },
}))

export default useStyles
