import moment from 'moment'
import React, { Fragment, useState } from 'react'
import TimezoneSelect from 'react-timezone-select'
import useStyles from './styles'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import MapIcon from '@material-ui/icons/Map'
import PhoneInput from 'react-phone-input-2'

import { SketchPicker } from 'react-color'

import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { operatorActions } from '../../../../redux/actions/operator_action'

import DialogConfirmAction from '../../../shared/modalConfirmAction'

import { validations } from '../../../../helpers/validation'
import { alertActions } from '../../../../redux/actions/alert_actions'
import GeofenceDialog from '../dialogs/geofence'

const PersonalData = ({ operatorReducer, updateRestricted, data }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    name,
    cif,
    iban,
    colour1,
    colour2,
    isPrivate,
    id,
    contactPhone,
    areas,
    circles,
    chargeTypes,
    startTimeExclusion,
    endTimeExclusion,
    isFree,
    link,
    extraInfo,
    timeZone,
  } = data || {}
  const [fleetType, setFleetType] = React.useState('EUR')

  const [selectedTimezone, setSelectedTimezone] = useState({
    value: timeZone ?? 'Europe/Brussels',
  })

  const [isGeofenceDialogOpen, setIsGeofenceDialogOpen] = useState(false)
  const [free, setFree] = useState(isFree)
  const [linkValue, setLinkValue] = useState(link)
  const [extraInfoValue, setExtraInfoValue] = useState(extraInfo)
  const [contactPhoneState, setContactPhoneState] = useState(contactPhone)
  const [logoState, setLogoState] = useState()
  const [primaryColor, setPrimaryColor] = useState(colour1 || '')
  const [secondaryColor, setSecondaryColor] = useState(colour2 || '')
  const [startTimeExcl, setStartTimeExcl] = useState(startTimeExclusion || '')
  const [endTimeExcl, setEndTimeExcl] = useState(endTimeExclusion || '')
  const [fileBinary, setFileBinary] = useState(colour2)

  const dispatch = useDispatch()

  const [errors, setErrors] = useState({
    phone: { result: false, message: '' },
    link: { result: false, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors

    newErrors.phone = validations.validatePhone(
      contactPhoneState.charAt(0) === '+'
        ? contactPhoneState
        : `+${contactPhoneState}`,
    )

    newErrors.link =
      linkValue && !validations.validateLink(linkValue)
        ? {
            result: false,
            message: t('operator.profile.personalData.invalidLink'),
          }
        : { result: true, message: '' }

    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    console.log('errors', errors)

    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })

    if (!valid) dispatch(alertActions.error(t('validations.invalidForm')))

    return valid
  }

  const handleFleetType = () => {
    if (isPrivate && free)
      return t(
        'operator.profile.personalData.fleetTypeOptions.privateFleetWithoutPayment',
      )
    if (isPrivate && !free)
      return t(
        'operator.profile.personalData.fleetTypeOptions.privateFleetWithPayment',
      )
    if (!isPrivate && !free)
      return t(
        'operator.profile.personalData.fleetTypeOptions.publicFleetWithPayment',
      )
    return ''
  }

  const [isDialogConfirmActionOpen, setisDialogConfirmActionOpen] =
    useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setisDialogConfirmActionOpen(true)
  }

  const handleChangeAreas = (body) => {
    const [bodyCircle, bodyAreas] = body
    const operatorData = operatorReducer.hasOwnProperty('dataOperatorLogged')
      ? operatorReducer.dataOperatorLogged
      : {}
    const bodyPersonalData = {
      ...operatorData,
      areas: bodyAreas,
      circles: bodyCircle,
    }
    updateRestricted(bodyPersonalData)
  }
  const handleLogoOnChange = (e) => {
    var files = e.target.files
    var file = files[0]

    if (files && file) {
      var reader = new FileReader()

      reader.onload = function (readerEvt) {
        var binaryString = readerEvt.target.result
        setLogoState(btoa(binaryString))
      }
      reader.readAsBinaryString(file)
    }
  }

  const handleConfirmAction = () => {
    validateForm()

    if (isFormValid()) {
      const startTime = moment(startTimeExcl, 'h')
      const endTime = moment(endTimeExcl, 'h')
      const dataOperatorLoggedUpdated = {
        id: id,
        contactPhone:
          contactPhoneState.charAt(0) === '+'
            ? contactPhoneState
            : `+${contactPhoneState}`,
        iban: iban,
        logoBinary: logoState,
        colour1: primaryColor,
        colour2: secondaryColor,
        areas: areas,
        circles: circles,
        startTimeExclusion: startTimeExcl,
        endTimeExclusion: endTimeExcl,
        timeExclusionEndsNextDay: endTime.isBefore(startTime),
        chargeTypes: free ? null : ['Minutes'], // chargeTypes,
        timezone: selectedTimezone?.value ?? null,
        isFree: free,
        link: linkValue,
        extraInfo: extraInfoValue,
      }
      if (selectedTimezone === null) {
        alert('error')
        return
      }
      updateRestricted(dataOperatorLoggedUpdated)
    }
  }

  return (
    <Fragment>
      <Container component="main">
        <div className={classes.paper}>
          <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography className={classes.title}>
                  {' '}
                  {t('operator.profile.personalData.operatorData')}{' '}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  // variant='standard'
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  label={t('operator.profile.personalData.name')}
                  value={name}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <PhoneInput
                  onChange={(phone, phoneOptions) => {
                    setContactPhoneState(phone, phoneOptions.countryCode)
                  }}
                  value={contactPhoneState}
                  // countryCodeEditable={false}
                  country="es"
                  preferredCountries={['es', 'fr']}
                  inputStyle={{
                    width: '100%',
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottomColor: 'rgba(0, 0, 0, 0.42)',
                    borderBottomWidth: 1,
                    borderRadius: 0,
                    fontFamily: 'Montserrat',
                    fontSize: '1.000714rem',
                    fontWeight: 400,
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}
                  buttonStyle={{
                    border: 0,
                    borderWidth: 1,
                    backgroundColor: 'inherit',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}>
                {errors.phone.message && (
                  <p style={{ color: 'red', margin: 0 }}>
                    {' '}
                    {errors.phone.message}{' '}
                  </p>
                )}
              </Grid>

              <Grid item md={12}>
                <TextField
                  fullWidth
                  label={t('operator.profile.personalData.link')}
                  value={linkValue}
                  onChange={(e) => setLinkValue(e.target.value)}
                  error={errors.link?.message}
                />
              </Grid>
              {!errors.link?.result && (
                <Grid item xs={12} md={6}>
                  {errors.link.message && (
                    <p style={{ color: 'red', margin: 0 }}>
                      {' '}
                      {errors.link.message}{' '}
                    </p>
                  )}
                </Grid>
              )}

              <Grid item md={12}>
                <TextField
                  label={t('operator.profile.personalData.extraInfo')}
                  value={extraInfoValue}
                  onChange={(e) => setExtraInfoValue(e.target.value)}
                  rows={5}
                  variant="outlined"
                  fullWidth
                  multiline
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  // variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  label={t('operator.profile.personalData.nif')}
                  value={cif}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  // variant="outlined"
                  helperText={t(
                    'operator.profile.personalData.fieldCantBeModified',
                  )}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  label={t('operator.profile.personalData.cc')}
                  value={iban}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  // variant='standard'
                  fullWidth
                  label={t('operator.profile.personalData.isFree')}
                  InputLabelProps={{ shrink: true }}
                  value={free}
                  select
                  onChange={(e) => setFree(e.target.value)}
                >
                  {[
                    {
                      value: true,
                      label: t('operator.profile.personalData.yes'),
                    },
                    {
                      value: false,
                      label: t('operator.profile.personalData.no'),
                    },
                  ].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  // select
                  fullWidth
                  label={t('operator.profile.personalData.fleetType')}
                  value={handleFleetType()}
                  // onChange={handleChange}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  // variant='standard'
                  fullWidth
                  label={'Logo'}
                  type="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleLogoOnChange(e)}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item sm={2}>
                <p>{t('login.completeDataOperator.primaryColorLabel')}</p>
              </Grid>
              <Grid
                item
                sm={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SketchPicker
                  className={classes.colorPicker}
                  color={primaryColor || ''}
                  onChange={(color) => setPrimaryColor(color.hex)}
                />
              </Grid>
              <Grid item sm={2}>
                <p>{t('login.completeDataOperator.secondaryColorLabel')}</p>
              </Grid>
              <Grid
                item
                sm={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SketchPicker
                  color={secondaryColor || ''}
                  onChange={(color) => setSecondaryColor(color.hex)}
                />
              </Grid>
              <Grid sm={12} style={{ marginTop: 15, marginBottom: 15 }}>
                <Typography className={classes.title}>
                  {' '}
                  {t('operator.profile.personalData.schedule')}{' '}
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                md={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TextField
                  label={t('operator.profile.personalData.startTimeExclusion')}
                  value={startTimeExcl}
                  variant="outlined"
                  fullWidth
                  type={'time'}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setStartTimeExcl(e.target.value)}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TextField
                  label={t('operator.profile.personalData.endTimeExclusion')}
                  value={endTimeExcl}
                  variant="outlined"
                  fullWidth
                  type={'time'}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setEndTimeExcl(e.target.value)}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <TimezoneSelect
                  styles={{
                    control: (base) => ({ ...base, height: '55px' }),
                  }}
                  value={
                    selectedTimezone?.value
                      ? selectedTimezone.value
                      : selectedTimezone
                  }
                  onChange={setSelectedTimezone}
                />
              </Grid>
            </Grid>
            <div className={classes.mapBtn}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {t('operator.profile.personalData.updateBtn')}
              </Button>
              <Tooltip title="Geofence" placement="top" arrow>
                <Fab
                  color="primary"
                  onClick={() => setIsGeofenceDialogOpen(true)}
                >
                  <MapIcon />
                </Fab>
              </Tooltip>
            </div>
          </form>
        </div>
      </Container>
      <GeofenceDialog
        isOpen={isGeofenceDialogOpen}
        onClose={() => setIsGeofenceDialogOpen(false)}
        onChangeArea={handleChangeAreas}
        operatorReducer={operatorReducer}
      />
      <DialogConfirmAction
        isOpen={isDialogConfirmActionOpen}
        onClose={() => setisDialogConfirmActionOpen(false)}
        onConfirm={handleConfirmAction}
        addNote={false}
        data={{
          header: t('operator.profile.personalData.confirmAction.header'),
          body: t('operator.profile.personalData.confirmAction.body'),
        }}
      />
    </Fragment>
  )
}
function mapState(state) {
  const { operatorReducer } = state
  return { operatorReducer }
}

const actionCreators = {
  updateRestricted: operatorActions.updateRestricted,
}

export default connect(mapState, actionCreators)(PersonalData)
