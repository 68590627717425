import React, { useEffect, useRef, useState } from 'react'

import { Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AddIcon from '@material-ui/icons/AddBox'

import { connect } from 'react-redux'
import { customerActions } from '../../../../../redux/actions/customers_actions'
import { operatorActions } from '../../../../../redux/actions/operator_action'

import { useTranslation } from 'react-i18next'

import { alertActions } from '../../../../../redux/actions/alert_actions'
import { tariffsActions } from '../../../../../redux/actions/tariffs_action'
import store from '../../../../../redux/store'
import { operatorService } from '../../../../../services/operatorService'
import CustomMaterialTable from '../../../../shared/customMaterialTable'

import { PERCENTAGE_REGEX } from '../../../../../constants/regex'
import { validations } from '../../../../../helpers/validation'

const useStyles = makeStyles((theme) => ({
  inputSection: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  customerSection: {
    marginTop: '5%',
    // padding: '0% 2%'
  },
}))

const NewDiscount = ({
  isOpen,
  onClose,
  data,
  getAllByOperatorId,
  operatorReducer,
  customerReducer,
  createDiscount,
  applyOperatorDiscount,
  isPrivatedOperator,
  preSelectCustomer,
  getAssignableCustomersToDiscount,
  addCustomerEmail,
  getAll,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const contentRef = useRef()

  const [editable, setEditable] = useState()

  const [validatedSince, setvalidatedSince] = useState('')
  const [validatedUntil, setvalidatedUntil] = useState('')
  const [promoText, setPromoText] = useState('')
  const [promoCode, setPromoCode] = useState(null)
  const [percent, setpercent] = useState('')
  const [name, setname] = useState('')

  const [applyToAllCustomers, setapplyToAllCustomers] = useState(false)
  const [selectedCustomers, setselectedCustomers] = useState([])

  const [displayList, setdisplayList] = useState([])

  const [errors, setErrors] = useState({
    name: { result: false, message: '' },
    percent: { result: false, message: '' },
    validatedSince: { result: false, message: '' },
    validatedUntil: { result: false, message: '' },
  })

  const handleClose = () => {
    setvalidatedSince('')
    setvalidatedUntil('')
    setPromoText('')
    setPromoCode(null)
    setpercent('')
    setname('')

    setErrors({
      name: { result: false, message: '' },
      percent: { result: false, message: '' },
      validatedSince: { result: false, message: '' },
      validatedUntil: { result: false, message: '' },
    })

    onClose()
  }

  useEffect(() => {
    if (preSelectCustomer) {
      const updatedDisplayList = customerReducer.results_assignable.map((c) => {
        return {
          ...c,
          tableData: { checked: c.id === preSelectCustomer.customerId },
        }
      })

      setselectedCustomers(
        updatedDisplayList.filter((u) => u.tableData.checked),
      )

      setdisplayList(updatedDisplayList)
    } else {
      setdisplayList(customerReducer.results_assignable)
    }
  }, [customerReducer.results_assignable])

  const handleOpen = () => {
    //getAllByOperatorId(operatorReducer.dataOperatorLogged.id)
    getAssignableCustomersToDiscount()
  }

  const handleSelectedCustomers = (selected) => {
    setselectedCustomers(selected)
  }

  const handleConfirmCreateDiscount = () => {
    validateForm()
    if (isFormValid()) {
      const bodyCreateDiscount = {
        name: name,
        applySince: validatedSince,
        applyUntil: validatedUntil,
        percent: parseFloat(percent),
        customerEmails: selectedCustomers.map((c) => {
          return c.customerEmail
        }),
        promoText: promoText,
        enable: true,
        preSelectCustomer,
        promotionalCode: promoCode,
      }
      createDiscount(bodyCreateDiscount, () => handleClose())
    } else {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const validateForm = () => {
    let newErrors = errors

    newErrors.name = validations.required(name)
    newErrors.percent = validations.validatePercentage(String(percent))
    newErrors.validatedSince = validations.required(validatedSince)
    newErrors.validatedUntil = validations.required(validatedUntil)

    if (validatedSince && validatedUntil) {
      newErrors.validatedUntil = validations.compareDate(
        validatedSince,
        validatedUntil,
      )
    }

    console.log('newErrors =>', newErrors)

    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  const tableRef = useRef()

  const checkEmailExists = (email) => {
    let finded = false
    for (let index = 0; index < displayList.length; index++) {
      const element = displayList[index]
      if (element.customerEmail == email) {
        finded = true
      }
    }
    return finded
  }

  const handleAddRow = () => {
    tableRef.current.state.showAddRow = true
    setEditable({
      onRowAdd: (newData) =>
        operatorService
          .GetAssignableCustomerByEmail(newData.customerEmail)
          .then(
            (result) => {
              const emailExists = checkEmailExists(newData.customerEmail)
              if (emailExists == false) {
                newData.tableData = { checked: true }
                setdisplayList([...displayList, newData])
                addCustomerEmail(newData)
              } else {
                store.dispatch(
                  alertActions.error(
                    t('services.rates.customerEmailRepeatInList'),
                  ),
                )
              }
            },
            (error) => {
              store.dispatch(
                alertActions.error(t('services.rates.customerEmailRepeat')),
              )
            },
          ),
    })
  }

  const Wrapper = () => {
    //console.log("isPrivateOperator =>" + isPrivatedOperator)

    return isPrivatedOperator ? (
      <></>
    ) : (
      <React.Fragment>
        <Tooltip
          title={t('operator.profile.editDiscounts.table.actions.addClient')}
        >
          <IconButton onClick={handleAddRow}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        maxWidth="100%"
        onEnter={handleOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('operator.profile.newDiscount.dialogTitle')}
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <DialogContentText>
            {t('operator.profile.newDiscount.dialogContentText')}
          </DialogContentText>
          <Grid container spacing={2} className={classes.inputSection}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label={t('operator.profile.newDiscount.name')}
                value={name}
                error={errors.name.message.length === 0 ? false : true}
                helperText={errors.name.message}
                onChange={(e) => {
                  setname(e.target.value)
                  errors.name.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label={t('operator.profile.newDiscount.sincelabel')}
                // type="datetime-local"
                type="date"
                value={validatedSince}
                onChange={(e) => {
                  setvalidatedSince(e.target.value)
                  errors.validatedSince.message = ''
                  setErrors(errors)
                }}
                error={
                  errors.validatedSince.message.length === 0 ? false : true
                }
                helperText={errors.validatedSince.message}
                // defaultValue="2017-05-24T10:30"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label={t('operator.profile.newDiscount.untillabel')}
                type="date"
                value={validatedUntil}
                onChange={(e) => {
                  setvalidatedUntil(e.target.value)
                  errors.validatedUntil.message = ''
                  setErrors(errors)
                }}
                error={
                  errors.validatedUntil.message.length === 0 ? false : true
                }
                helperText={errors.validatedUntil.message}
                // defaultValue="2017-05-24T10:30"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label={t('operator.profile.newDiscount.percentlabel')}
                type="text"
                error={errors.percent.message.length === 0 ? false : true}
                helperText={errors.percent.message}
                value={percent}
                onChange={(e) => {
                  const { value } = e.target
                  if (PERCENTAGE_REGEX.test(value) || !value) setpercent(value)
                  errors.percent.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label={t('operator.profile.newDiscount.promoText')}
                value={promoText}
                // error={ errors.promoText.message.length === 0 ? false : true }
                // helperText={ errors.promoText.message }
                onChange={(e) => {
                  setPromoText(e.target.value)
                  // errors.promoText.message = ""
                  // setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label={t('operator.profile.newDiscount.promoCode')}
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value ? e.target.value : null)
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.customerSection}>
            <CustomMaterialTable
              buttons={<Wrapper />}
              saveFilters
              selection={true}
              tableRef={tableRef}
              onSelectionChange={(rows) => handleSelectedCustomers(rows)}
              title={t('operator.profile.editDiscounts.table.title')}
              columns={[
                // {
                //   title: t(
                //     "operator.profile.editDiscounts.table.columns.fullName"
                //   ),
                //   field: "fullName",
                // },
                {
                  title: t(
                    'operator.profile.editDiscounts.table.columns.email',
                  ),
                  field: 'customerEmail',
                },
                // {
                //   title: t(
                //     "operator.profile.editDiscounts.table.columns.mobilePhone"
                //   ),
                //   field: "mobilePhone",
                // },
              ]}
              editable={
                !isPrivatedOperator
                  ? {
                      onRowAdd: (newData) =>
                        operatorService
                          .GetAssignableCustomerByEmail(newData.customerEmail)
                          .then(
                            (result) => {
                              const emailExists = checkEmailExists(
                                newData.customerEmail,
                              )
                              if (emailExists == false) {
                                newData.tableData = { checked: true }
                                setdisplayList([...displayList, newData])
                                addCustomerEmail(newData)
                              } else {
                                store.dispatch(
                                  alertActions.error(
                                    t(
                                      'services.rates.customerEmailRepeatInList',
                                    ),
                                  ),
                                )
                              }
                            },
                            (error) => {
                              store.dispatch(
                                alertActions.error(
                                  t('services.rates.customerEmailRepeat'),
                                ),
                              )
                            },
                          ),
                    }
                  : {}
              }
              data={displayList}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('operator.profile.newDiscount.cancelBtn')}
          </Button>
          <Button
            onClick={handleConfirmCreateDiscount}
            color="primary"
            autoFocus
          >
            {t('operator.profile.newDiscount.confirmBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { operatorReducer, customerReducer } = state
  return { operatorReducer, customerReducer }
}

const actionCreators = {
  getAllByOperatorId: customerActions.getAllByOperatorId,
  applyOperatorDiscount: customerActions.applyOperatorDiscount,
  createDiscount: operatorActions.createDiscount,
  getAssignableCustomersToDiscount:
    operatorActions.getAssignableCustomersToDiscount,
  getAll: customerActions.getAll,
  addCustomerEmail: tariffsActions.addCustomerEmail,
}

export default connect(mapState, actionCreators)(NewDiscount)
