import { userConstants } from '../../constants/user_constants'
const initialState = {
  userMailExist: false,
  userMailRequestPending: false,
}

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.MAIL_EXIST_REQUEST:
      return {
        ...state,
        userMailRequestPending: true,
      }
    case userConstants.MAIL_EXIST_SUCCESS:
      return {
        ...state,
        userMailRequestPending: false,
        userMailExist: true,
      }
    case userConstants.MAIL_EXIST_FAILURE:
      return {
        ...state,
        userMailRequestPending: false,
        userMailExist: false,
      }

    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.users,
      }
    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map((user) =>
          user.id === action.id ? { ...user, deleting: true } : user,
        ),
      }
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        ...state,
        items: state.items.filter((user) => user.id !== action.id),
      }
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map((user) => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error }
          }

          return user
        }),
      }
    default:
      return state
  }
}
