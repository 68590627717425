import { paymentsConstant } from '../../constants/payments_constant'
import { paymentsService } from '../../services/paymentsService'

import i18next from 'i18next'
import { alertActions } from './alert_actions'

export const paymentsActions = {
  getAll,
  refundPayment,
}

function getAll(start, end) {
  return (dispatch) => {
    dispatch(request())
    paymentsService.getAll(start, end).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.payments.getallError')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: paymentsConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: paymentsConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: paymentsConstant.GET_ALL_FAILURE, error }
  }
}

function refundPayment(idPayment) {
  return (dispatch) => {
    dispatch(request())
    paymentsService.refundPayment(idPayment).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(i18next.t('services.payments.refundSuccess')),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.payments.refundError')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: paymentsConstant.GET_REFUND_PAYMENT_REQUEST }
  }

  function success(results) {
    return { type: paymentsConstant.GET_REFUND_PAYMENT_SUCCESS, results }
  }

  function failure(error) {
    return { type: paymentsConstant.GET_REFUND_PAYMENT_FAILURE, error }
  }
}
