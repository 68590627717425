import i18next from 'i18next'
import moment from 'moment'
import { customerConstants } from '../../constants/customer_constants'
import { customerService } from '../../services/customersServices'
import { servicesService } from '../../services/servicesService'
import { alertActions } from './alert_actions'

import { isManager, isManagerReadOnly } from '../../helpers/roles'

import { operatorActions } from './operator_action'

export const customerActions = {
  getUserByServiceId,
  getAll,
  getPendingToValidate,
  filter,
  getId,
  clearResults,
  getById,
  insert,
  assignCustomersToOperator,
  validate,
  getAllByOperatorId,
  customersByOperators,
  customersByOperatorsById,
  applyOperatorDiscount,
  unassignCustomersOperator,
  customersByPrivateOperatorId,
  updateAssignOperatorDates,
  updateForAdmin,
  getAllCountries,
  getWeekDays,
  setCalendar,
  setCalendar,
  getCalendar,
  getCustomerValidationRequests,
  validateAndUpdate,
  openValidationsModal,
  closeValidationsModal,
}

function getUserByServiceId(serviceId) {
  return (dispatch) => {
    dispatch(request())
    servicesService.getById(serviceId).then(
      (results) => {
        const { customerId } = results
        customerService.getById(customerId).then(
          (results) => {
            dispatch(success(results))
          },
          (error) => {
            dispatch(failure(error))
          },
        )
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: customerConstants.GET_CUSTOMER_BY_SERVICE_ID_REQUEST,
      // type: customerConstants.GET_CUSTOMERS_BY_PRIVATE_OPERATOR_ID_REQUEST,
    }
  }

  function success(results) {
    return {
      type: customerConstants.GET_CUSTOMER_BY_SERVICE_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: customerConstants.GET_CUSTOMER_BY_SERVICE_ID_FAILURE,
      error,
    }
  }
}

function updateAssignOperatorDates(body) {
  const operatorId = body.operatorId
  delete body.operatorId
  return (dispatch) => {
    customerService.updateAssignOperatorDates(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.customers.updateAssignOperatorDatesSuccess'),
          ),
        )

        if (isManager() || isManagerReadOnly()) {
          dispatch(getAll())
          dispatch(customersByOperators())
        } else {
          dispatch(getAllByOperatorId(operatorId))
          dispatch(customersByOperatorsById(operatorId))
        }
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t(
              'services.customers.updateAssignOperatorDatesError',
            )} : ${error}`,
          ),
        )
      },
    )
  }
}

function customersByPrivateOperatorId(operatorId) {
  return (dispatch) => {
    if (operatorId) {
      dispatch(request())
      customerService.customersByPrivateOperatorId(operatorId).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
        },
      )
    }
  }

  function request() {
    return {
      type: customerConstants.GETALL_REQUEST,
      // type: customerConstants.GET_CUSTOMERS_BY_PRIVATE_OPERATOR_ID_REQUEST,
    }
  }

  function success(results) {
    return {
      type: customerConstants.GETALL_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: customerConstants.GET_BY_ID_FAILURE,
      error,
    }
  }
}

function applyOperatorDiscount(body) {
  const discountId = body.discountId
  delete body.discountId
  return (dispatch) => {
    customerService.applyOperatorDiscount(body).then(
      (results) => {
        discountId &&
          dispatch(operatorActions.getAffectedCustomersByDiscount(discountId))
        dispatch(
          alertActions.success(
            i18next.t('services.customers.applyOperatorDiscountSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t(
              'services.customers.applyOperatorDiscountError',
            )} : ${error}`,
          ),
        )
      },
    )
  }
}

function customersByOperatorsById(operatorId) {
  return (dispatch) => {
    if (operatorId) {
      dispatch(request())
      customerService.customersByOperatorsById(operatorId).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          // dispatch(
          //   alertActions.error(
          //     "Ha ocurrido un error al recoger los clientes. Por favor reintente. Message:" +
          //       error
          //   )
          // );
        },
      )
    }
  }

  function request() {
    return {
      type: customerConstants.GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_REQUEST,
    }
  }

  function success(results) {
    return {
      type: customerConstants.GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: customerConstants.GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_FAILURE,
      error,
    }
  }
}

function customersByOperators() {
  return (dispatch) => {
    dispatch(request())
    customerService.customersByOperators().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "Ha ocurrido un error al recoger los clientes. Por favor reintente. Message:" +
        //       error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_CUSTOMERS_BY_OPERATORS_REQUEST }
  }

  function success(results) {
    return {
      type: customerConstants.GET_CUSTOMERS_BY_OPERATORS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: customerConstants.GET_CUSTOMERS_BY_OPERATORS_FAILURE,
      error,
    }
  }
}

function getAllByOperatorId(operatorId) {
  return (dispatch) => {
    dispatch(request())
    customerService.getAllByOperatorId(operatorId).then(
      (results) => {
        results.forEach((element) => {
          element.birthday = moment(element.birthday).format('DD/MM/YYYY')
        })
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "Ha ocurrido un error al recoger los clientes. Por favor reintente. Message:" +
        //       error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: customerConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GETALL_FAILURE, error }
  }
}

function validate(body) {
  const canOperate = body.validating
  delete body.validating
  return (dispatch) => {
    customerService.validate(body).then(
      (customer) => {
        canOperate
          ? dispatch(
              alertActions.success(
                i18next.t('services.customers.validateSuccess'),
              ),
            )
          : dispatch(
              alertActions.success(
                i18next.t('services.customers.validateSuccess2'),
              ),
            )
        dispatch(getPendingToValidate())
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.customers.validateError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function validateAndUpdate(email, body) {
  const { from, operatorId = '' } = body

  return (dispatch) => {
    customerService.validateAndUpdate(email, body).then(
      (success) => {
        dispatch(
          alertActions.success(i18next.t('services.customers.validateSuccess')),
        )

        dispatch(
          from == 'Customers'
            ? getAllByOperatorId(operatorId)
            : getPendingToValidate(),
        )
      },
      (error) => {
        dispatch(
          alertActions.error('Ha ocurrido un error al validar y actualizar'),
        )
      },
    )
  }
}

function unassignCustomersOperator(body) {
  const operatorId = body.sharingOperatorId
  let customerId
  delete body.sharingOperatorId
  if (body.hasOwnProperty('customerId')) {
    customerId = body.customerId
  }
  return (dispatch) => {
    customerService.unassignCustomersOperator(body).then(
      (customer) => {
        customerId && dispatch(operatorActions.getByCustomers(customerId))
        if (isManager() || isManagerReadOnly()) {
          dispatch(getAll())
          dispatch(customersByOperators())
          // dispatch(operatorActions.getAllOperators());
        } else {
          dispatch(getAllByOperatorId(operatorId))
          dispatch(customersByOperatorsById(operatorId))
          // customersByPrivateOperatorId(operatorId);
        }
        dispatch(
          alertActions.success(
            i18next.t('services.customers.unassignCustomersOperatorSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t(
              'services.customers.unassignCustomersOperatorError',
            )}: ${error}`,
          ),
        )
      },
    )
  }
}

function assignCustomersToOperator(body) {
  const operatorId = body.sharingOperatorId
  let customerId = null

  if (body.hasOwnProperty('customerId')) {
    customerId = body.customerId
  }

  return (dispatch) => {
    customerService.assignCustomersToOperator(body).then(
      (customer) => {
        customerId && dispatch(operatorActions.getByCustomers(customerId))
        if (isManager() || isManagerReadOnly()) {
          dispatch(customersByOperators())
        } else {
          dispatch(getAllByOperatorId(operatorId))
          dispatch(customersByOperatorsById(operatorId))
        }
        dispatch(
          alertActions.success(
            i18next.t('services.customers.assignCustomersToOperatorSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t(
              'services.customers.assignCustomersToOperatorSuccess',
            )}: ${error}`,
          ),
        )
      },
    )
  }
}

function insert(body) {
  return (dispatch) => {
    customerService.insert(body).then(
      (customer) => {
        dispatch(
          alertActions.success(i18next.t('services.customers.insertSuccess')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.customers.insertError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function getById(customerId) {
  return (dispatch) => {
    dispatch(request())
    customerService.getById(customerId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "Ha ocurrido un error al recoger clientes. Por favor reintente. Message:" +
        //       error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_BY_ID_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GET_BY_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GET_BY_ID_FAILURE, error }
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    customerService.getAll().then(
      (results) => {
        results.forEach((element) => {
          element.birthday = moment(element.birthday).format('DD/MM/YYYY')
        })
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.customers.getAllError')),
        )
      },
    )
  }

  function request() {
    return { type: customerConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GETALL_FAILURE, error }
  }
}

function getPendingToValidate() {
  return (dispatch) => {
    dispatch(request())
    customerService.getPendingToValidate().then(
      (results) => {
        results.forEach((element) => {
          element.birthday = moment(element.birthday).format('DD/MM/YYYY')
        })
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.customers.getAllError')),
        )
      },
    )
  }

  function request() {
    return { type: customerConstants.GETALL_PENDING_VALIDATE_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GETALL_PENDING_VALIDATE_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GETALL_PENDING_VALIDATE_FAILURE, error }
  }
}

function filter(field, value) {
  return (dispatch) => {
    dispatch(request(field, value))
    const data = { field, value }
    customerService.filter(data).then(
      (results) => {
        results.forEach((element) => {
          element.birthday = moment(element.birthday).format('DD/MM/YYYY')
        })
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request(field, value) {
    return { type: customerConstants.FILTER_REQUEST, field, value }
  }

  function success(results) {
    return { type: customerConstants.FILTER_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.FILTER_FAILURE, error }
  }
}

function getId(id) {
  return (dispatch) => {
    dispatch(request(id))
    dispatch(success({ customer: {} }))
  }

  function request(id) {
    return { type: customerConstants.DETAILS_REQUEST, id }
  }

  function success(result) {
    return { type: customerConstants.DETAILS_SUCCESS, result }
  }

  function failure(error) {
    return { type: customerConstants.DETAILS_FAILURE, error }
  }
}

function clearResults() {
  return (dispatch) => {
    dispatch(clear())
  }

  function clear() {
    return { type: customerConstants.CLEAR_RESULTS }
  }
}

function updateForAdmin(body) {
  const { email, from, userData, operatorId } = body

  return (dispatch) => {
    dispatch(request())
    customerService.updateForAdmin(email, userData).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(
            i18next.t('services.customers.updateForAdminSuccess'),
          ),
        )
        dispatch(
          from === 'Customers'
            ? getAllByOperatorId(operatorId)
            : getPendingToValidate(),
        )
      },
      (error) => {
        let output = `${i18next.t('services.customers.updateError')}: `

        if (error.errors) {
          for (const [key, value] of Object.entries(error?.errors)) {
            output += `${value} `
          }
        } else {
          output += ` ${error}`
        }

        dispatch(alertActions.error(output))
      },
    )
  }

  function failure(error) {
    return { type: customerConstants.UPDATE_FAILURE, error }
  }

  function success(result) {
    return { type: customerConstants.UPDATE_SUCCESS, result }
  }

  function request(id) {
    return { type: customerConstants.UPDATE_REQUEST, id }
  }
}

function getAllCountries() {
  return (dispatch) => {
    dispatch(request())
    customerService.getAllCountries().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_ALL_COUNTRIES_REQUEST }
  }

  function success(countries) {
    return { type: customerConstants.GET_ALL_COUNTRIES_SUCCESS, countries }
  }

  function failure(error) {
    return { type: customerConstants.GET_ALL_COUNTRIES_FAILURE, error }
  }
}

function getWeekDays() {
  return (dispatch) => {
    dispatch(request())
    customerService.getWeekDays().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_WEEK_DAYS_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GET_WEEK_DAYS_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GET_WEEK_DAYS_FAILURE, error }
  }
}

function setCalendar(body) {
  //console.log("body action => ", body)
  let args = body
  let body_transformed = []
  if (Array.isArray(body)) {
    body.forEach((shifday) => {
      const startTime = moment(shifday.earliestStart, 'h')
      const endTime = moment(shifday.latestArrival, 'h')
      shifday = { ...shifday, endsNextDay: endTime.isBefore(startTime) }
      body_transformed.push(shifday)
    })
    args = body_transformed
  }
  //console.log("body action transformed => ", body_transformed)
  return (dispatch) => {
    customerService.setCalendar(args).then(
      (response) => {
        //console.log("response setCalendar => ", response)
        dispatch(
          alertActions.success(i18next.t('validations.shiftDay.labels.insert')),
        )
        dispatch(getCalendar())
      },
      (error) => {
        //console.log("error setCalendar => ", error)
        dispatch(
          alertActions.error(
            `${i18next.t('validations.shiftDay.labels.error')}`,
          ),
        )
      },
    )
  }
}

function getCalendar() {
  return (dispatch) => {
    dispatch(request())
    customerService.getCalendar().then(
      (results) => {
        //console.log("results => ", results.shiftDays)
        dispatch(success(results.shiftDays))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_CALENDAR_VALIDATIONS_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GET_CALENDAR_VALIDATIONS_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GET_CALENDAR_VALIDATIONS_FAILURE, error }
  }
}

function getCustomerValidationRequests() {
  return (dispatch) => {
    dispatch(request())
    customerService.getCustomerValidationRequests().then(
      (results) => {
        //console.log("results => ", results)
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_CUSTOMER_VALIDATION_REQUESTS_REQUEST }
  }

  function success(results) {
    return {
      type: customerConstants.GET_CUSTOMER_VALIDATION_REQUESTS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: customerConstants.GET_CUSTOMER_VALIDATION_REQUESTS_FAILURE,
      error,
    }
  }
}

function openValidationsModal() {
  return (dispatch) =>
    dispatch({ type: customerConstants.DOCUMENTS_MODAL_OPEN })
}

function closeValidationsModal() {
  return (dispatch) =>
    dispatch({ type: customerConstants.DOCUMENTS_MODAL_CLOSE })
}
