export const servicesConstants = {
  GETALL_REQUEST: 'SERVICES_GETALL_REQUEST',
  GETALL_SUCCESS: 'SERVICES_GETALL_SUCCESS',
  GETALL_FAILURE: 'SERVICES_GETALL_FAILURE',

  GET_ALL_BY_OPERATOR_ID_REQUEST: 'SERVICES_GET_ALL_BY_OPERATOR_ID_REQUEST',
  GET_ALL_BY_OPERATOR_ID_SUCCESS: 'SERVICES_GET_ALL_BY_OPERATOR_ID_SUCCESS',
  GET_ALL_BY_OPERATOR_ID_FAILURE: 'SERVICES_GET_ALL_BY_OPERATOR_ID_FAILURE',

  GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_REQUEST:
    'SERVICES_GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_REQUEST',
  GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_SUCCESS:
    'SERVICES_GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_SUCCESS',
  GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_FAILURE:
    'SERVICES_GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_FAILURE',

  GET_OPERATOR_TOTALS_BY_CUSTOMERS_REQUEST:
    'SERVICES_GET_OPERATOR_TOTALS_BY_CUSTOMERS_REQUEST',
  GET_OPERATOR_TOTALS_BY_CUSTOMERS_SUCCESS:
    'SERVICES_GET_OPERATOR_TOTALS_BY_CUSTOMERS_SUCCESS',
  GET_OPERATOR_TOTALS_BY_CUSTOMERS_FAILURE:
    'SERVICES_GET_OPERATOR_TOTALS_BY_CUSTOMERS_FAILURE',

  GET_TOTALS_BY_CUSTOMER_ID_REQUEST:
    'SERVICES_GET_TOTALS_BY_CUSTOMER_ID_REQUEST',
  GET_TOTALS_BY_CUSTOMER_ID_SUCCESS:
    'SERVICES_GET_TOTALS_BY_CUSTOMER_ID_SUCCESS',
  GET_TOTALS_BY_CUSTOMER_ID_FAILURE:
    'SERVICES_GET_TOTALS_BY_CUSTOMER_ID_FAILURE',

  GET_ALL_TRIPS_BY_SERVICE_ID_REQUEST:
    'SERVICES_GET_ALL_TRIPS_BY_SERVICE_ID_REQUEST',
  GET_ALL_TRIPS_BY_SERVICE_ID_SUCCESS:
    'SERVICES_GET_ALL_TRIPS_BY_SERVICE_ID_SUCCESS',
  GET_ALL_TRIPS_BY_SERVICE_ID_FAILURE:
    'SERVICES_GET_ALL_TRIPS_BY_SERVICE_ID_FAILURE',

  GET_MONTHLY_SUMMARY_REQUEST: 'SERVICES_GET_MONTHLY_SUMMARY_REQUEST',
  GET_MONTHLY_SUMMARY_SUCCESS: 'SERVICES_GET_MONTHLY_SUMMARY_SUCCESS',
  GET_MONTHLY_SUMMARY_FAILURE: 'SERVICES_GET_MONTHLY_SUMMARY_FAILURE',

  GET_REPORT_REQUEST: 'SERVICES_GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'SERVICES_GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'SERVICES_GET_REPORT_FAILURE',

  REFUND_REQUEST: 'SERVICES_REFUND_REQUEST',
  REFUND_SUCCESS: 'SERVICES_REFUND_SUCCESS',
  REFUND_FAILURE: 'SERVICES_REFUND_FAILURE',

  CANCEL_PAYMENT_REQUEST: 'SERVICES_CANCEL_PAYMENT_REQUEST',
  CANCEL_PAYMENT_SUCCESS: 'SERVICES_CANCEL_PAYMENT_SUCCESS',
  CANCEL_PAYMENT_FAILURE: 'SERVICES_CANCEL_PAYMENT_FAILURE',
}
