import { handleCall } from './handleResponse'

export const scooterService = {
  getAll,
  getById,
  insert,
  update,
  _delete,
  filter,
  getDynamics,
  getDynamicsById,
  getTripsByScooterId,
  getTripDetails,
  getAllMapByOperatorId,
  assignToOperate,
  getAllDynamicByOperator,
  getAllDynamics,
  getDataById,
  getMissingDynamic,
  setHelmetIncluded,
  setToOperate,
  getParamsConfig,
  setParamsConfig,
  updateParamsConfig,
  getAllByOperatorId,
  updateRestricted,
  heatMapOfLoggedOperator,
  heatMapOfSelectedOperator,
  getTrips,
}

function getTrips(body) {
  const { since, until } = body || { since: null, until: null }
  const filter = since && until ? `?since=${since}&until=${until}` : ''
  return handleCall(`/vehicles/v1/Vehicles/trips${filter}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // const filter = since && until ? `?since=${since}&until=${until}` : "";

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/trips${filter}`,
  //   requestOptions
  // ).then(handleResponse);
}

function heatMapOfSelectedOperator(body) {
  const { since, until, operatorId } = body

  return handleCall(
    `/vehicles/v1/Vehicles/GetHeatMap?since=${since}&until=${until}&sharingOperatorId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/GetHeatMap?since=${since}&until=${until}&sharingOperatorId=${operatorId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function heatMapOfLoggedOperator(body) {
  const { since, until } = body

  return handleCall(
    `/vehicles/v1/Vehicles/GetHeatMapOfLoggedOperator?since=${since}&until=${until}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/GetHeatMapOfLoggedOperator?since=${since}&until=${until}`,
  //   requestOptions
  // ).then(handleResponse);
}

function updateRestricted(body) {
  const scooterId = body.scooterId
  delete body.scooterId

  return handleCall(`/vehicles/v1/Vehicles/${scooterId}/UpdateRestricted`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/${scooterId}/UpdateRestricted`,
  //   requestOptions
  // ).then(handleResponse);
}

function updateParamsConfig(body) {
  const paramsConfigId = body.id
  delete body.id

  return handleCall(`/sharing/v1/ParamsConfig/${paramsConfigId}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/ParamsConfig/${paramsConfigId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function setParamsConfig(body) {
  return handleCall(`/sharing/v1/ParamsConfig`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "POST",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/ParamsConfig`,
  //   requestOptions
  // ).then(handleResponse);
}

function getParamsConfig() {
  return handleCall(`/sharing/v1/ParamsConfig`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/ParamsConfig`,
  //   requestOptions
  // ).then(handleResponse);
}

function setToOperate(body) {
  return handleCall(`/vehicles/v1/Vehicles/SelectToOperate`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/SelectToOperate`,
  //   requestOptions
  // ).then(handleResponse);
}

function setHelmetIncluded(body) {
  return handleCall(`/vehicles/v1/Vehicles/HelmetIncluded`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/HelmetIncluded`,
  //   requestOptions
  // ).then(handleResponse);
}

function getMissingDynamic(operatorId) {
  const byOperator = operatorId ? `ByOperator/${operatorId}` : ''
  return handleCall(`/vehicles/v1/Vehicles/GetMissingDynamic${byOperator}`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // const byOperator = operatorId ? `ByOperator/${operatorId}` : "";

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/GetMissingDynamic${byOperator}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getDataById(scooterId) {
  return handleCall(`/vehicles/v1/Vehicles/${scooterId}`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/Vehicles/" + scooterId,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllDynamics() {
  return handleCall(`/vehicles/v1/Vehicles/GetAllDynamic`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/Vehicles/GetAllDynamic",
  //   requestOptions
  // ).then(handleResponse);
}

function assignToOperate(body) {
  return handleCall(`/vehicles/v1/Vehicles/CanOperate`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // const requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/Vehicles/CanOperate",
  //   requestOptions
  // ).then(handleResponse);
}

function getAllDynamicByOperator(operatorId, onlyVehiclesWithDynamic) {
  const params = !onlyVehiclesWithDynamic
    ? `?onlyVehiclesWithDynamic=false`
    : ''
  return handleCall(
    `/vehicles/v1/Vehicles/GetAllDynamicByOperator/${operatorId}${params}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // const params = !onlyVehiclesWithDynamic
  //   ? `?onlyVehiclesWithDynamic=false`
  //   : "";

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/GetAllDynamicByOperator/${operatorId}${params}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllMapByOperatorId(operatorId) {
  return handleCall(`/vehicles/v1/Vehicles/GetAllMapByOperator/${operatorId}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/vehicles/v1/Vehicles/GetAllMapByOperator/${operatorId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllByOperatorId(operatorId) {
  return handleCall(
    `/vehicles/v1/vehicles?field=Sharing.SharingOperatorId&value=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/vehicles?field=Sharing.SharingOperatorId&value=" +
  //     operatorId,
  //   requestOptions
  // ).then(handleResponse);
}
function getAll(operatorId) {
  const params = operatorId
    ? `?field=Sharing.SharingOperatorId&value=${operatorId}`
    : ''
  return handleCall(`/vehicles/v1/vehicles${params}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // const params = operatorId
  //   ? `?field=Sharing.SharingOperatorId&value=${operatorId}`
  //   : "";
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles" + params,
  //   requestOptions
  // ).then(handleResponse);
}

function getById(id) {
  return handleCall(`/vehicles/v1/vehicles/${id}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles/" + id,
  //   requestOptions
  // ).then(handleResponse);
}

function insert(upgrade) {
  return handleCall(`/vehicles/v1/vehicles`, {
    method: 'POST',
    body: JSON.stringify(upgrade),
  })
  // const requestOptions = {
  //   method: "POST",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  //   body: JSON.stringify(upgrade),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles",
  //   requestOptions
  // ).then(handleResponse);
}

function update(upgrade) {
  const id = JSON.stringify(upgrade).id
  return handleCall(`/vehicles/v1/vehicles/${id}`, {
    method: 'PUT',
    body: JSON.stringify(upgrade),
  })
  // const requestOptions = {
  //   method: "PUT",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  //   body: JSON.stringify(upgrade),
  // };
  // const id = JSON.stringify(upgrade).id;
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles/" + id,
  //   requestOptions
  // ).then(handleResponse);
}

function filter(data) {
  const querystring = data.field + '&value=' + data.value
  return handleCall(`/vehicles/v1/vehicles/?field=${querystring}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // const querystring = data.field + "&value=" + data.value;
  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/vehicles/?field=" +
  //     querystring,
  //   requestOptions
  // ).then(handleResponse);
}

function _delete(id) {
  return handleCall(`/vehicles/v1/vehicles/${id}`, {
    method: 'DELETE',
    body: null,
  })
  // const requestOptions = {
  //   method: "DELETE",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles/" + id,
  //   requestOptions
  // ).then(handleResponse);
}

function getDynamicsById(idScooter) {
  return handleCall(`/vehicles/v1/vehicles/${idScooter}/dynamic`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/vehicles/" +
  //     idScooter +
  //     "/dynamic",
  //   requestOptions
  // ).then(handleResponse);
}

function getTripsByScooterId(idScooter, since, until) {
  const filter = since && until ? `?since=${since}&until=${until}` : ''
  return handleCall(`/vehicles/v1/vehicles/${idScooter}/trips${filter}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // const filter = since && until ? `?since=${since}&until=${until}` : "";
  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/vehicles/" +
  //     idScooter +
  //     "/trips" +
  //     filter,
  //   requestOptions
  // ).then(handleResponse);
}

// function getTripsByScooterId(idScooter) {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader(),
//   };
//   return fetch(
//     stringConstants.URL_ApiGateway +
//       "/vehicles/v1/vehicles/" +
//       idScooter +
//       "/trips",
//     requestOptions
//   ).then(handleResponse);
// }

function getTripDetails(idScooter, idTrip) {
  return handleCall(`/vehicles/v1/vehicles/${idScooter}/trips/${idTrip}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };
  // return fetch(
  //   stringConstants.URL_ApiGateway +
  //     "/vehicles/v1/vehicles/" +
  //     idScooter +
  //     "/trips/" +
  //     idTrip,
  //   requestOptions
  // ).then(handleResponse);
}

function getDynamics() {
  return handleCall(`/vehicles/v1/vehicles/dynamics`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/vehicles/v1/vehicles/dynamics",
  //   requestOptions
  // ).then(handleResponse);
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     if (response.status === 204 || response.status === 404) {
//       return [];
//     }
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       if (response.status === 401) {
//         var refreshToken = loginService.getWithExpiry("refresh_token");
//         loginService.refreshToken(refreshToken).then((token) => {
//           loginService.setWithExpiry(
//             "token",
//             token.access_token,
//             token.expires_in
//           );
//         });
//       }
//       const error = (data && data.message) || text;
//       return Promise.reject(error);
//     }

//     // check if the token is close to expire
//     var date = new Date();
//     var now_utc = Date.UTC(
//       date.getUTCFullYear(),
//       date.getUTCMonth(),
//       date.getUTCDate(),
//       date.getUTCHours(),
//       date.getUTCMinutes(),
//       date.getUTCSeconds()
//     );
//     var now = new Date(now_utc);
//     now.setMinutes(now.getMinutes() + 20);
//     var token_expire = new Date(loginService.getWithExpiry("token_dateExpiry"));
//     // if in 10 minutes expire, renew the token
//     if (now > token_expire) {
//       refreshToken = loginService.getWithExpiry("refresh_token");
//       loginService.refreshToken(refreshToken).then((token) => {
//         loginService.setWithExpiry(
//           "token",
//           token.access_token,
//           token.expires_in
//         );
//       });
//     }
//     return data;
//   });
// }
