import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  groupButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '10%',
  },
}))

const ConfirmCloseTicket = ({ onConfirm, isOpen, onClose, data }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isBlockScooterChecked, setisBlockScooterChecked] = useState(false)
  const [closeTicketComment, setcloseTicketComment] = useState('')

  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              {t('incidents.confirmCloseTicket.title')}
            </h2>
            <p id="transition-modal-description">
              {t('incidents.confirmCloseTicket.description')}
            </p>
            <FormControlLabel
              className={classes.switchBlockScooterAsOperator}
              control={
                <Switch
                  checked={!isBlockScooterChecked}
                  onChange={() => {
                    setisBlockScooterChecked((prev) => !prev)
                  }}
                  color="primary"
                />
              }
              label={t('incidents.confirmCloseTicket.unlockScooter')}
            />
            <TextField
              label={t('incidents.confirmCloseTicket.labelCloseTicketComment')}
              fullWidth
              value={closeTicketComment}
              onChange={(e) => setcloseTicketComment(e.target.value)}
            />
            <div className={classes.groupButtons}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                {t('incidents.confirmCloseTicket.cancelBtn')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  onConfirm(closeTicketComment, isBlockScooterChecked)
                }
              >
                {t('incidents.confirmCloseTicket.confirmBtn')}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default ConfirmCloseTicket
