import { isOperatorManager } from '../helpers/roles'
import store from '../redux/store'

export const checkCanOperate = () => {
  const { authenticationReducer, operatorReducer } = store.getState()

  if (authenticationReducer.hasOwnProperty('user')) {
    if (isOperatorManager()) {
      return operatorReducer.dataOperatorLogged.canOperate
    } else {
      return true
    }
  }
}

store.subscribe(checkCanOperate)

export const displayList = (operatorList) => {
  return Array.isArray(operatorList) ? operatorList : [operatorList]
}
