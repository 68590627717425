import React, { useState } from 'react'
import useStyles from './styles'

import TextField from '@material-ui/core/TextField'
import { customerActions } from '../../../redux/actions/customers_actions'

import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'

import { history } from '../../../helpers/history'

const UserNewView = ({ insert }) => {
  const classes = useStyles()

  const [username, setusername] = useState()
  const [password, setpassword] = useState()
  const [email, setemail] = useState()
  const [mobilePhone, setmobilePhone] = useState()
  const [nif, setnif] = useState()
  const [firstName, setfirstName] = useState()
  const [lastName, setlastName] = useState()
  const [birthday, setbirthday] = useState()
  const [gender, setgender] = useState()
  const [country, setcountry] = useState()
  const [city, setcity] = useState()
  const [address, setaddress] = useState()
  const [postalCode, setpostalCode] = useState()

  const handleSubmit = () => {
    const body = {
      username,
      password,
      email,
      mobilePhone: mobilePhone.includes('+34')
        ? mobilePhone
        : `+34${mobilePhone}`,
      nif,
      firstName,
      lastName,
      birthday: `${birthday}T00:00:00.00Z`,
      gender,
      country,
      city,
      address,
      postalCode,
    }
    insert(body)
    history.goBack()
  }

  return (
    <div className={classes.mainContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label={'username'}
            autoFocus
            value={username}
            onChange={(e) => setusername(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label={'password'}
            type="password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label={'email'}
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label={'mobilePhone'}
            value={mobilePhone}
            onChange={(e) => setmobilePhone(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label={'nif'}
            value={nif}
            onChange={(e) => setnif(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label={'firstName'}
            value={firstName}
            onChange={(e) => setfirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label={'lastName'}
            value={lastName}
            onChange={(e) => setlastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="date"
            label="Birthday"
            type="date"
            // defaultValue="2017-05-24"
            value={birthday}
            onChange={(e) => setbirthday(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label={'gender'}
            value={gender}
            onChange={(e) => setgender(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label={'country'}
            value={country}
            onChange={(e) => setcountry(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label={'city'}
            value={city}
            onChange={(e) => setcity(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label={'address'}
            value={address}
            onChange={(e) => setaddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label={'postalCode'}
            value={postalCode}
            onChange={(e) => setpostalCode(e.target.value)}
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          className={classes.submitButton}
        >
          create
        </Button>
      </Grid>
    </div>
  )
}

const actionCreators = {
  insert: customerActions.insert,
}

export default connect(null, actionCreators)(UserNewView)
