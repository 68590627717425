import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { customerActions } from '../../redux/actions/customers_actions'
import Dashboard from '../dashboard'
import CustomersView from './page'

class Customers extends React.Component {
  componentDidMount() {
    this.props.clearTable()
  }

  render() {
    return <Dashboard component={<CustomersView {...this.props} />} />
  }
}

function mapState(state) {
  const { customerReducer, authenticationReducer } = state
  return { customerReducer, authenticationReducer }
}

const actionCreators = {
  filter: customerActions.filter,
  getAll: customerActions.getAll,
  getById: customerActions.getId,
  clearTable: customerActions.clearResults,
  getAllByOperatorId: customerActions.getAllByOperatorId,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Customers)
