import { Dialog, DialogContent, Grid, Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { authHeader } from '../../../helpers/authHeader'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    margin: '0 auto',
  },
  img: {
    width: '70%',
    padding: 15,
    //display: 'none'
  },
}))

const OldDocuments = ({ open, onClose, selectedRow, dataDocumentation, t }) => {
  const [dniFront, setdniFront] = useState('')
  const [dniBack, setdniBack] = useState('')
  const [drivingLicenceFront, setdrivingLicenceFront] = useState('')
  const [drivingLicenceBack, setdrivingLicenceBack] = useState('')

  function fetchWithAuthentication(url) {
    const headers = new Headers()
    const headersAuth = authHeader()
    headers.set('Authorization', headersAuth.Authorization)
    return fetch(url, { headers })
  }

  function _arrayBufferToBase64(buffer) {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  async function displayProtectedImage(imageId, imageUrl) {
    const imageElement = document.getElementById(imageId)
    // Fetch the image.
    const response = await fetchWithAuthentication(imageUrl)

    // Convert the data to Base64 and build a data URL.
    const binaryData = await response.arrayBuffer()
    const base64 = _arrayBufferToBase64(binaryData)
    const dataUrl = `data:image/png;base64,${base64}`

    // Update the source of the image.
    if (imageElement) {
      imageElement.src = dataUrl
      imageElement.style.display = 'inline'
    }
  }

  useEffect(() => {
    setdniFront('')
    setdrivingLicenceFront('')
    setdniBack('')
    setdrivingLicenceBack('')
    dataDocumentation
      .filter((x) => x.isBackup)
      .map((d) => {
        const { id, customerId, code, fileBinary, isBackup } = d
        switch (code) {
          case 'DniFront':
            setdniFront(fileBinary)
            break
          case 'DrivingLicenseFront':
            setdrivingLicenceFront(fileBinary)
            break
          case 'DniBack':
            setdniBack(fileBinary)
            break
          case 'DrivingLicenseBack':
            setdrivingLicenceBack(fileBinary)
            break
          default:
            break
        }
      })
  }, [dataDocumentation])

  const handleEnter = () => {
    /* displayProtectedImage('OldPhotoDNIFront', 'https://firebasestorage.googleapis.com/v0/b/altech-silence-pro.appspot.com/o/uXFtEgcVymeBeqDmhFkJgb3Uqiz2%2FID2.jpg?alt=media')
        displayProtectedImage('OldPhotoDNIBack', 'https://firebasestorage.googleapis.com/v0/b/altech-silence-pro.appspot.com/o/uXFtEgcVymeBeqDmhFkJgb3Uqiz2%2FID2.jpg?alt=media')
        displayProtectedImage('OldPhotoDriveLicenseFront', 'https://firebasestorage.googleapis.com/v0/b/altech-silence-pro.appspot.com/o/uXFtEgcVymeBeqDmhFkJgb3Uqiz2%2FID2.jpg?alt=media')
        displayProtectedImage('OldPhotoDriveLicenseBack', 'https://firebasestorage.googleapis.com/v0/b/altech-silence-pro.appspot.com/o/uXFtEgcVymeBeqDmhFkJgb3Uqiz2%2FID2.jpg?alt=media') */
  }

  const classes = useStyles()

  return (
    <Dialog
      TransitionComponent={Transition}
      onEnter={handleEnter}
      open={open}
      onClose={onClose}
    >
      <DialogContent style={{ alignContent: 'center' }}>
        <div style={{ padding: '2%' }}>
          <Grid container className={classes.alignCenter}>
            <Grid item md={6} className={classes.alignCenter}>
              {/* <img
                                className={classes.img}
                                id="OldPhotoDNIFront"
                                alt="idCard front image"
                            /> */}
              {dniFront ? (
                <img
                  className={classes.img}
                  alt={t('customers.documents.altDniFront')}
                  src={`data:image/jpeg;base64,${dniFront}`}
                />
              ) : (
                <p>{t('customers.documents.errorDni.front')}</p>
              )}
            </Grid>
            <Grid item md={6} className={classes.alignCenter}>
              {/*  <img
                                className={classes.img}
                                id="OldPhotoDNIBack"
                                alt="idCard front image"
                            /> */}
              {dniBack ? (
                <img
                  className={classes.img}
                  alt={t('customers.documents.altDniBack')}
                  src={`data:image/jpeg;base64,${dniBack}`}
                />
              ) : (
                <p>{t('customers.documents.errorDni.back')}</p>
              )}
            </Grid>
            <Grid item md={6} className={classes.alignCenter}>
              {/* <img
                                className={classes.img}
                                id="OldPhotoDriveLicenseFront"
                                alt="idCard front image"
                            /> */}
              {drivingLicenceFront ? (
                <img
                  className={classes.img}
                  alt={t('customers.documents.altDrivingLicenceFront')}
                  src={`data:image/jpeg;base64,${drivingLicenceFront}`}
                />
              ) : (
                <p>{t('customers.documents.errordrivingLicense.front')}</p>
              )}
            </Grid>
            <Grid item md={6} className={classes.alignCenter}>
              {/* <img
                                className={classes.img}
                                id="OldPhotoDriveLicenseBack"
                                alt="idCard front image"
                            /> */}
              {drivingLicenceBack ? (
                <img
                  className={classes.img}
                  alt={t('customers.documents.altDrivingLicenceBack')}
                  src={`data:image/jpeg;base64,${drivingLicenceBack}`}
                />
              ) : (
                <p>{t('customers.documents.errordrivingLicense.back')}</p>
              )}
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default OldDocuments
