export const handleTrueFalsyTermSwitch = (term, value) => {
  if (term.length > 1) return true
  switch (term[0]) {
    case '0':
      return value
    case '1':
      return !value
    default:
      return true
  }
}
