import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/messaging'
import stringConstants from './constants/strings'
import { userActions } from './redux/actions/user_actions'
import store from './redux/store'

firebase.initializeApp(stringConstants.firebaseConfig)

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

export const onMessageListener = () =>
  new Promise((resolve) => {
    try {
      messaging.onMessage((payload) => {
        console.log(' onMessageListener payload', payload)
        //store.dispatch(chatActions.setNewMessageFlag(true, payload));
        return resolve(payload)
      })
    } catch (error) {
      console.log('onMessageListener error: ', error)
    }
  })

export const initNotifications = () => {
  try {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register(
          window.location.origin +
            `/${process.env.REACT_APP_ENV}FirebaseConfig/firebase-messaging-sw.js`,
        )
        .then(function (registration) {
          //firebase.messaging().useServiceWorker(registration); //deprecated
          firebase
            .messaging()
            .getToken({
              vapidKey: stringConstants.vapidKey,
              serviceWorkerRegistration: registration,
            })
            .then((token) => {
              console.log('current firebase messagins token =>', token)

              //SUBSCRIBE TO TOPIC
              store.dispatch(userActions.subscribeToTopic(token))
            })
            .catch((error) => {
              console.log('error => ', error)
            })
        })
        .catch(function (err) {
          console.log('Service worker registration failed, error:', err)
        })
    }
  } catch (error) {
    console.log('initNotifications error: ', error)
  }
}
