import { customerConstants } from '../../constants/customer_constants'
import { operatorConstant } from '../../constants/operator_constant'
import { tariffsConstant } from '../../constants/tariffs_constant'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
  pendingValidateResults: [],
  results_assignable: [],
  selected: null,
  countries: [],
  customersByOperator: [],
  customerByService: {},
  weekDays: [],
  calendarValidation: null,
  loadingCalendar: false,
  loadingCustomerRequests: false,
  customerRequestsList: [],
  validationModalOpened: false,
}

export function customerReducer(state = initialState, action) {
  switch (action.type) {
    case customerConstants.GET_CUSTOMER_BY_SERVICE_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
        customerByService: {},
      }
    case customerConstants.GET_CUSTOMER_BY_SERVICE_ID_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        customerByService: action.results,
      }
    case customerConstants.GET_CUSTOMER_BY_SERVICE_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        customerByService: {},
        error: action.error,
      }

    case customerConstants.GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case customerConstants.GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        customersByOperator: action.results,
      }
    case customerConstants.GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        customersByOperator: [],
        error: action.error,
      }
    case customerConstants.GET_CUSTOMERS_BY_OPERATORS_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case customerConstants.GET_CUSTOMERS_BY_OPERATORS_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        customersByOperator: action.results,
      }
    case customerConstants.GET_CUSTOMERS_BY_OPERATORS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        customersByOperator: [],
        error: action.error,
      }
    case customerConstants.FILTER_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case customerConstants.FILTER_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        results: action.results,
      }
    case customerConstants.FILTER_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case customerConstants.GETALL_REQUEST:
      return {
        ...state,
        loadingResultsAll: true,
        showResults: false,
        results: [],
      }
    case customerConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResultsAll: false,
        showResults: true,
        results: action.results,
      }
    case customerConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResultsAll: false,
        results: [],
        error: action.error,
      }
    case customerConstants.GETALL_PENDING_VALIDATE_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
        pendingValidateResults: [],
      }
    case customerConstants.GETALL_PENDING_VALIDATE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        pendingValidateResults: action.results,
      }
    case customerConstants.GETALL_PENDING_VALIDATE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        pendingValidateResults: [],
        error: action.error,
      }
    case customerConstants.GET_BY_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
        selected: null,
      }
    case customerConstants.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        selected: action.results,
      }
    case customerConstants.GET_BY_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        selected: {},
        error: action.error,
      }
    case customerConstants.DETAILS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case customerConstants.DETAILS_SUCCESS:
      return {
        ...state,
        customer: action.detail,
        loadingResults: false,
        showResults: true,
      }
    case customerConstants.DETAILS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case customerConstants.CLEAR_RESULTS:
      return {
        ...state,
        results: [],
        showResults: false,
        loadingResults: false,
      }
    case customerConstants.GET_ALL_COUNTRIES_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case customerConstants.GET_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        countries: action.countries,
      }
    case customerConstants.GET_ALL_COUNTRIES_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case operatorConstant.GET_ASSIGNABLE_CUSTOMERS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
        results_assignable: [],
      }
    case operatorConstant.GET_ASSIGNABLE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results_assignable: action.results,
      }
    case operatorConstant.GET_ASSIGNABLE_CUSTOMERS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results_assignable: [],
      }
    case tariffsConstant.ADD_CUSTOMER_EMAIL:
      action.email.discountAssigned = true
      return {
        ...state,
        results: [...state.results, action.email],
      }
    case customerConstants.GET_WEEK_DAYS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        weekDays: [],
      }
    case customerConstants.GET_WEEK_DAYS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        weekDays: action.results,
      }
    case customerConstants.GET_WEEK_DAYS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        weekDays: [],
      }
    case customerConstants.GET_CALENDAR_VALIDATIONS_REQUEST:
      return {
        ...state,
        loadingCalendar: true,
        calendarValidation: null,
      }
    case customerConstants.GET_CALENDAR_VALIDATIONS_SUCCESS:
      return {
        ...state,
        loadingCalendar: false,
        calendarValidation: action.results,
      }
    case customerConstants.GET_CALENDAR_VALIDATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingCalendar: false,
        calendarValidation: null,
      }
    case customerConstants.GET_CUSTOMER_VALIDATION_REQUESTS_REQUEST:
      return {
        ...state,
        loadingCustomerRequests: true,
        customerRequestsList: [],
        error: null,
      }
    case customerConstants.GET_CUSTOMER_VALIDATION_REQUESTS_SUCCESS:
      return {
        ...state,
        loadingCustomerRequests: false,
        customerRequestsList: action.results,
        error: null,
      }
    case customerConstants.GET_CUSTOMER_VALIDATION_REQUESTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingCustomerRequests: false,
        customerRequestsList: [],
        error: action.error,
      }
    case customerConstants.DOCUMENTS_MODAL_OPEN:
      return {
        ...state,
        validationModalOpened: true,
      }
    case customerConstants.DOCUMENTS_MODAL_CLOSE:
      return {
        ...state,
        validationModalOpened: false,
      }
    default:
      return state
  }
}
