import React, { useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslation } from 'react-i18next'

import moment from 'moment'
import CustomMaterialTable from '../../shared/customMaterialTable'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  dialogContainer: {
    padding: '2%',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dateFilter: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '2%',
    // justifyContent: 'space-between'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: 200,
  },
  getAllButton: {
    display: 'flex',
    marginLeft: '2%',
  },
  containerFilter: {
    padding: '1%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CustomerMovements = ({
  isOpen,
  onClose,
  customerData,
  servicesReducer,
  onSearch,
}) => {
  const { customerData: customer } = customerData
  const { t } = useTranslation()
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  const [since, setsince] = useState()
  const [until, setuntil] = useState()

  const handleClickSearchBetweenDates = () => {
    onSearch(customerData.customerId, since, until)
  }

  const handleClickSearchAll = () => {
    onSearch(customerData.customerId, null, null)
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('customers.modals.customerMovements.dialogTitle')}{' '}
              {customer && customer.customerFullName}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.dialogContainer}>
          <div className={classes.containerFilter}>
            <div className={classes.dateFilter}>
              <TextField
                id="date"
                label={t('incidents.page.since')}
                type="date"
                value={since}
                onChange={(e) => setsince(e.target.value)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date"
                label={t('incidents.page.until')}
                type="date"
                value={until}
                onChange={(e) => setuntil(e.target.value)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickSearchBetweenDates}
              >
                {t('incidents.page.search')}
              </Button>
              <div className={classes.getAllButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickSearchAll}
                >
                  {t('incidents.page.searchAllBtn')}
                </Button>
              </div>
            </div>
          </div>
          <CustomMaterialTable
            title={t('customers.modals.customerMovements.table.title')}
            addSummaryRow={true}
            columns={[
              {
                title: t(
                  'customers.modals.customerMovements.table.columns.originAddress',
                ),
                field: 'originAddress',
              },
              {
                title: t(
                  'customers.modals.customerMovements.table.columns.distanceKms',
                ),
                field: 'distanceKms',
              },
              {
                title: t(
                  'customers.modals.customerMovements.table.columns.tripStartTimestamp',
                ),
                field: 'tripStartTimestamp',
                render: (rowData) =>
                  rowData.tripStartTimestamp
                    ? moment(rowData.tripStartTimestamp).format(
                        'DD/MM/YYYY HH:mm',
                      )
                    : '',
              },
              {
                title: t(
                  'customers.modals.customerMovements.table.columns.tripEndTimestamp',
                ),
                field: 'tripEndTimestamp',
                render: (rowData) =>
                  rowData.tripEndTimestamp
                    ? moment(rowData.tripEndTimestamp).format(
                        'DD/MM/YYYY HH:mm',
                      )
                    : '',
              },
              // {
              //   title: t("customers.modals.customerMovements.table.columns.reserveStartTimestamp"),
              //   field: "reserveStartTimestamp",
              // },
              // {
              //   title: t("customers.modals.customerMovements.table.columns.reserveCancelTimestamp"),
              //   field: "reserveCancelTimestamp",
              // },
              {
                title: t(
                  'customers.modals.customerMovements.table.columns.drivenMinutes',
                ),
                field: 'drivenMinutes',
              },
              {
                title: t(
                  'customers.modals.customerMovements.table.columns.pausedMinutes',
                ),
                field: 'pausedMinutes',
              },
              {
                title: t(
                  'customers.modals.customerMovements.table.columns.price',
                ),
                field: 'price',
              },
              {
                title: t(
                  'customers.modals.customerMovements.table.columns.paymentStatus',
                ),
                field: 'paymentStatus',
              },
              {
                title: t(
                  'customers.modals.customerMovements.table.columns.sharingOperatorName',
                ),
                field: 'sharingOperatorName',
              },
              // { title: "vehicleId", field: "vehicleId" },
            ]}
            data={servicesReducer.results}
          />
        </div>
      </Dialog>
    </div>
  )
}
export default CustomerMovements
