import React, { Fragment, useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'
import BatteryCharging90Icon from '@material-ui/icons/BatteryCharging90'

import { connect } from 'react-redux'
import { operatorActions } from '../../redux/actions/operator_action'
import { scooterActions } from '../../redux/actions/scooter_actions'
import { servicesActions } from '../../redux/actions/services_actions'
import { ticketsActions } from '../../redux/actions/tickets_action'

import {
  determineVehicleStatus,
  filterVehicleStatus,
} from '../../helpers/vehicle'
import { formatDateToDisplay } from '../shared/formatDate'

import ScooterTrips from '../scooters/dialogs/scooterTrips'
import DetailsContainer from './items/details'

import { useTranslation } from 'react-i18next'
import {
  isCauUser,
  isManager,
  isManagerReadOnly,
  isOperatorManager,
  isSacUser,
  isTechnician,
} from '../../helpers/roles'
import LastTrip from '../scooters/dialogs/lastTrip'

import ActiveTicketsFromSelectedScooter from '../incidents/scooterManagment/dialogs/activeTicketsFromSelectedScooter'
import CreateNewTicket from '../incidents/scooterManagment/dialogs/createNewTicket'
import DialogConfirmAction from '../shared/modalConfirmAction'

import IncidentsDetails from '../incidents//details'

import { handleTrueFalsyTermSwitch } from '../../helpers/filters'
import { history } from '../../helpers/history'
import CustomMaterialTable from '../shared/customMaterialTable'

const HomeView = ({
  getAllScooters,
  getTripsByScooterId,
  getAllDynamics,
  getAllDynamicByOperator,
  authenticationReducer,
  scooterReducer,
  getAllScootersByOperatorId,
  getTripDetails,
  operatorReducer,
  getCustomerByServiceId,
  getId,
  customerReducer,
  createTicket,
  assignToOperate,
  getAllOpenByVehicle,
  ticketsReducer,
  getDynamicsById,
  endTrip,
  getOperatorInfo,
}) => {
  const { t } = useTranslation()
  const [isLastTripModalOpen, setisLastTripModalOpen] = useState(false)
  const [isTripsListModalOpen, setisTripsListModalOpen] = useState(false)
  const [selectedVehicle, setselectedVehicle] = useState(null)

  const [detailSelectedTicketFromScooter, setdetailSelectedTicketFromScooter] =
    useState({})
  const [
    isDialogDetailsTicketSelectedOpen,
    setisDialogDetailsTicketSelectedOpen,
  ] = useState(false)
  const [isDialogNewTicketOpen, setisDialogNewTicketOpen] = useState(false)
  const [
    isDialogConfirmClearActiveServices,
    setisDialogConfirmClearActiveServices,
  ] = useState(false)
  const [
    isDialogActiveTicketsFromSelectedScooterOpen,
    setisDialogActiveTicketsFromSelectedScooterOpen,
  ] = useState(false)
  const [loading, setLoading] = useState(false)

  const updateDataOperatorLogged = false

  const fetchData = () => {
    if (isManager() || isManagerReadOnly() || isSacUser() || isCauUser()) {
      getAllScooters()
      getAllDynamics()
    } else {
      const operatorId = authenticationReducer.user.sharingOperatorId
      if (operatorId) {
        getAllDynamicByOperator(operatorId, false)
        getAllScootersByOperatorId(operatorId)
      }
    }
  }
  useEffect(() => {
    fetchData()
    setLoading(true)
  }, [])

  useEffect(() => {
    authenticationReducer.user.role != null && fetchData()
  }, [authenticationReducer.user])

  useEffect(() => {
    if (isTechnician() && !isOperatorManager())
      history.push('scooters/locations')
  })

  const handleClickScooterAcction = (rowData) => {
    const { sharing, scooterId } = rowData || { sharing: {}, scooterId: null }
    const scooterData = scooterReducer.results.filter(
      (d) => d.id === scooterId,
    )[0]

    getAllOpenByVehicle(scooterId)
    getOperatorInfo(sharing.sharingOperatorId, updateDataOperatorLogged)

    if (sharing.activeServiceId) getCustomerByServiceId(sharing.activeServiceId)
    getId(scooterId, false)
    getDynamicsById(scooterId)
    setselectedVehicle({ ...scooterData, dynamicData: rowData })
  }

  useEffect(() => {
    if (
      !scooterReducer.loadingResults &&
      !scooterReducer.loadingDynamics &&
      scooterReducer.showResults
    ) {
      setLoading(false)
    }
  }, [
    scooterReducer.loadingResults,
    scooterReducer.loadingDynamics,
    scooterReducer.showResults,
  ])

  const handleGetLastTrip = () => {
    setisLastTripModalOpen(true)
    getTripsByScooterId(selectedVehicle.id)
  }
  const vehicleStatus = (rowData) => {
    const { scutum } = rowData
    const { isVehicleOn } = scutum || { isVehicleOn: false }
    if (isVehicleOn) return 'ON'
    if (!isVehicleOn) return 'OFF'
  }

  const vehicleService = (rowData) => {
    const { sharing } = rowData
    const { outOfService, activeServiceId } = sharing || {
      outOfService: null,
      activeServiceId: null,
    }
    if (outOfService) return 'OUT'
    if (activeServiceId) return 'ON'
    if (!activeServiceId) return 'OFF'
  }

  const vehicleStatusFilter = (term, rowData) => {
    const { scutum } = rowData
    const { isVehicleOn } = scutum || { isVehicleOn: false }

    switch (term[0]) {
      case '0':
        return !isVehicleOn
      case '1':
        return isVehicleOn
      default:
        return true
    }
  }

  const vehicleServiceFilter = (term, rowData) => {
    const { sharing } = rowData
    const { outOfService, activeServiceId } = sharing || {
      outOfService: null,
      activeServiceId: null,
    }

    switch (term[0]) {
      case '0':
        return !activeServiceId
      case '1':
        return activeServiceId
      case '2':
        return outOfService
      default:
        return true
    }
  }

  const handleConfirmClearActiveServices = () => {
    const { sharing } = selectedVehicle
    const operatorId = !isManager()
      ? operatorReducer.dataOperatorLogged.id
      : null
    endTrip(sharing && sharing.activeServiceId, operatorId)
  }

  const handleConfirmCreateNewTicket = (
    description,
    newComment,
    isBlockScooterChecked,
  ) => {
    const { id, sharing } = selectedVehicle

    const body = {
      sharingOperatorId: isManager()
        ? ''
        : sharing && sharing.sharingOperatorId,
      vehicleId: id,
      serviceId: sharing && sharing.activeServiceId,
      title: description,
      openNoteText: newComment,
      vehicleCannotOperate: isBlockScooterChecked,
      technicianId: null,
    }

    createTicket(body)
  }

  const handleLockScooter = (rowData) => {
    const { scooter } = scooterReducer
    const { sharing } = scooter || {
      outOfService: null,
      sharingOperatorId: null,
    }

    const body = {
      sharingOperatorId: sharing.sharingOperatorId,
      vehicleIds: [rowData.id],
      outOfService: !sharing.outOfService,
    }
    assignToOperate(body)
  }

  const handleTicketSelectedFromScooter = (value) => {
    setdetailSelectedTicketFromScooter({
      ...value,
      scooterData: selectedVehicle,
    })
    setisDialogDetailsTicketSelectedOpen(true)
  }

  const handleCreateTicket = (selected) => {
    setselectedVehicle(selected)
    setisDialogNewTicketOpen(true)
  }

  const handleCreateTicketBody = ({
    description,
    newComment,
    isBlockScooterChecked,
    selectedRow,
  }) => {
    const { id, sharing } = selectedRow

    const body = {
      sharingOperatorId: isManager()
        ? ''
        : sharing && sharing.sharingOperatorId,
      vehicleId: id,
      serviceId: sharing && sharing.activeServiceId,
      title: description,
      openNoteText: newComment,
      vehicleCannotOperate: isBlockScooterChecked,
      technicianId: null,
    }
    createTicket(body)
  }

  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item md={9} xs={12} sm={9} lg={9}>
          <CustomMaterialTable
            saveFilters={true}
            isLoading={loading}
            // isLoading={scooterReducer.loadingResults}
            addSummaryRow={true}
            autoclickSelected={true}
            onRowClick={(rowData) => handleClickScooterAcction(rowData)}
            rowClickAvaiable={true}
            title={t('scooters.page.title')}
            data={scooterReducer.dynamics}
            columns={[
              {
                title: t('home.page.table.model'),
                field: 'model',
              },
              {
                title: t('home.page.table.state'),
                render: (rowData) => vehicleStatus(rowData),
                customSort: (a, b) =>
                  vehicleStatus(a).localeCompare(vehicleStatus(b)),
                lookup: { 1: 'ON', 0: 'OFF' },
                customFilterAndSearch: (term, rowData) =>
                  vehicleStatusFilter(term, rowData),
              },
              {
                title: t('home.page.table.status'),
                lookup: {
                  0: t('home.page.table.reserveActive'),
                  1: t('home.page.table.activeService'),
                  2: t('home.page.table.free'),
                },
                customFilterAndSearch: (term, rowData) =>
                  filterVehicleStatus(term, rowData),
                render: (rowData) => determineVehicleStatus(rowData),
              },
              {
                title: 'Service',
                render: (rowData) => vehicleService(rowData),
                lookup: { 1: 'ON', 0: 'OFF', 2: 'OUT' },
                customSort: (a, b) =>
                  vehicleService(a).localeCompare(vehicleService(b)),
                customFilterAndSearch: (term, rowData) =>
                  vehicleServiceFilter(term, rowData),
              },
              {
                title: t('home.page.table.view'),
                render: (rowData) =>
                  rowData.currentVisibleToClients
                    ? t('home.items.scooterDetails.outOfServiceValueTrue')
                    : t('home.items.scooterDetails.outOfServiceValueFalse'),
                lookup: { 0: t('shared.yes'), 1: t('shared.no') },
                customFilterAndSearch: (term, rowData) =>
                  handleTrueFalsyTermSwitch(
                    term,
                    rowData.currentVisibleToClients,
                  ),
              },
              {
                title: t('home.page.table.battery'),
                field: 'scutum.batterySoc',
                addCustomFilterComponentNumberCells: 'scutum.batterySoc',
                // render: (rowData) => `${rowData.scutum && rowData.scutum.batterySoc}%`,
                render: (rowData) => (
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <span>
                      {rowData.scutum &&
                        rowData.scutum.batterySoc.toString() + '%'}
                    </span>
                    {rowData.scutum && rowData.scutum.isBatteryCharging && (
                      <BatteryCharging90Icon style={{ color: 'green' }} />
                    )}
                  </div>
                ),
              },
              {
                title: t('home.page.table.odo') + '(m)',
                field: 'scutum.totalOdometer',
                addCustomFilterComponentNumberCells: 'scutum.totalOdometer',
              },
              {
                title: t('home.page.table.plate'),
                field: 'plate',
              },
              {
                title: t('home.page.table.currentUser'),
                field: 'currentCustomerFullName',
              },
              {
                title: t('home.page.table.operatorName'),
                field: 'sharingOperatorName',
                hidden: !(isManager() || isCauUser() || isSacUser()),
              },
              {
                title: t('home.page.table.latestService'),
                render: (rowData) =>
                  formatDateToDisplay(rowData.latestServiceTimestamp, true),
              },
              {
                title: t('home.page.table.lastConnection'),
                render: (rowData) =>
                  formatDateToDisplay(rowData.lastConnectionTimestamp, true),
              },
            ]}
          />
        </Grid>
        <Grid item md={3} xs={12} sm={3} lg={3}>
          <DetailsContainer
            scooter={scooterReducer.scooter}
            getId={getId}
            selectedVehicle={selectedVehicle}
            onOpenTripsList={() => {
              setisTripsListModalOpen(true)
            }}
            onOpenLastTrip={handleGetLastTrip}
            customerReducer={customerReducer}
            onCreateTicket={handleCreateTicket}
            onCleanService={() => setisDialogConfirmClearActiveServices(true)}
            onBlockScooter={() => handleLockScooter(selectedVehicle)}
            onShowIncidents={() =>
              setisDialogActiveTicketsFromSelectedScooterOpen(true)
            }
            operatorReducer={operatorReducer}
          />
        </Grid>
        <ScooterTrips
          scooterId={selectedVehicle && selectedVehicle.id}
          isOpen={isTripsListModalOpen}
          onClose={() => {
            setisTripsListModalOpen(false)
          }}
          data={selectedVehicle}
          initialLastTrips={true}
        />
        <LastTrip
          isOpen={isLastTripModalOpen}
          onClose={() => {
            setisLastTripModalOpen(false)
          }}
          getTripDetails={getTripDetails}
          selectedVehicle={selectedVehicle}
          scooterReducer={scooterReducer}
        />
      </Grid>

      <CreateNewTicket
        isOpen={isDialogNewTicketOpen}
        onClose={() => setisDialogNewTicketOpen(false)}
        onConfirm={handleConfirmCreateNewTicket}
        preSelectedScooter={selectedVehicle}
        onCreate={handleCreateTicketBody}
      />
      <DialogConfirmAction
        isOpen={isDialogConfirmClearActiveServices}
        onClose={() => setisDialogConfirmClearActiveServices(false)}
        addNote={false}
        onConfirm={handleConfirmClearActiveServices}
        data={{
          header: t('incidents.scooterManagement.confirmActionDialog.header'),
          body: t('incidents.scooterManagement.confirmActionDialog.body'),
        }}
      />
      <ActiveTicketsFromSelectedScooter
        isOpen={isDialogActiveTicketsFromSelectedScooterOpen}
        onClose={() => setisDialogActiveTicketsFromSelectedScooterOpen(false)}
        data={ticketsReducer.results} //ticketsReducer.results
        onSelectTicket={handleTicketSelectedFromScooter}
      />

      <IncidentsDetails
        isOpen={isDialogDetailsTicketSelectedOpen}
        onClose={() => setisDialogDetailsTicketSelectedOpen(false)}
        data={detailSelectedTicketFromScooter}
      />
    </Fragment>
  )
}

function mapState(state) {
  const {
    authenticationReducer,
    scooterReducer,
    operatorReducer,
    customerReducer,
    ticketsReducer,
  } = state
  return {
    authenticationReducer,
    scooterReducer,
    operatorReducer,
    customerReducer,
    ticketsReducer,
  }
}

const actionCreators = {
  getAllScooters: scooterActions.getAll,
  getAllScootersByOperatorId: scooterActions.getAllByOperatorId,
  getAllDynamics: scooterActions.getAllDynamics,
  getTripsByScooterId: scooterActions.getTripsByScooterId,
  getAllDynamicByOperator: scooterActions.getAllDynamicByOperator,
  getTripDetails: scooterActions.getTripDetails,
  getId: scooterActions.getId,
  getOperatorInfo: operatorActions.getOperatorInfo,
  getCustomerByServiceId: servicesActions.getCustomerByServiceId,
  cleanService: servicesActions.cleanService,
  createTicket: ticketsActions.createTicket,
  assignToOperate: scooterActions.assignToOperate,
  getAllOpenByVehicle: ticketsActions.getAllOpenByVehicle,
  getDynamicsById: scooterActions.getDynamicsById,
  endTrip: servicesActions.endTrip,
}

export default connect(mapState, actionCreators)(HomeView)
