import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { useActionsModal } from '../../../hooks/useActionsModal'
import { sacActions } from '../../../redux/actions/sac_actions'
import PasswordField from '../../shared/PasswordField'

const DEFAULT_USER = {
  firstName: '',
  lastName: '',
  identificationDocumentCode: '',
  email: '',
  password: '',
  confirmPassword: '',
  phoneNumber: '',
}

const CreateSAC = ({ open, onClose, loading }) => {
  const errorKeys = [
    'firstName',
    'lastName',
    'email',
    'password',
    'confirmPassword',
    'phoneNumber',
  ]

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    data,
    onChange,
    clearData,
    errors,
    updateErrors,
    isFormValid,
    isError,
    clearErrors,
  } = useActionsModal(DEFAULT_USER, errorKeys)

  useEffect(() => {
    return () => clearData()
  }, [])

  const handleSubmit = () => {
    validateForm()
    if (isFormValid()) dispatch(sacActions.create(data, handleClose))
  }

  const handleClose = () => {
    clearData()
    clearErrors()
    onClose()
  }

  const validateForm = () => {
    let _errors = errors

    errors.firstName = validations.required(data.firstName)
    errors.lastName = validations.required(data.lastName)
    errors.email = validations.required(data.email)
    errors.password = validations.required(data.password)
    errors.confirmPassword = validations.required(data.confirmPassword)
    errors.phoneNumber = data.phoneNumber
      ? validations.validatePrefix(data.phoneNumber)
      : { result: true, message: '' }

    if (!(data.password === data.confirmPassword)) {
      errors.password = {
        result: false,
        message: t('dashboard.changePassword.passwordsDontMatch'),
      }
      errors.confirmPassword = {
        result: false,
        message: t('dashboard.changePassword.passwordsDontMatch'),
      }
    }

    updateErrors({ ..._errors })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('sac.modals.titleCreate')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t('sac.modals.firstName')}
              name="firstName"
              value={data.firstName}
              error={isError(errors.firstName)}
              helperText={errors.firstName.message}
              onChange={(e) => onChange(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t('sac.modals.lastName')}
              name="lastName"
              value={data.lastName}
              error={isError(errors.lastName)}
              helperText={errors.lastName.message}
              onChange={(e) => onChange(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label={t('sac.modals.email')}
              name="email"
              value={data.email}
              error={isError(errors.email)}
              helperText={errors.email.message}
              onChange={(e) => onChange(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordField
              label={t('sac.modals.password')}
              name="password"
              value={data.password}
              error={isError(errors.password)}
              helperText={errors.password.message}
              onChange={(e) => onChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordField
              label={t('sac.modals.confirmPassword')}
              name="confirmPassword"
              value={data.confirmPassword}
              error={isError(errors.confirmPassword)}
              helperText={errors.confirmPassword.message}
              onChange={(e) => onChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t('sac.modals.phoneNumber')}
              name="phoneNumber"
              placeholder="+35659999999"
              value={data.phoneNumber}
              error={isError(errors.phoneNumber)}
              helperText={errors.phoneNumber.message}
              onChange={(e) => onChange(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t('sac.modals.documentNumber')}
              name="identificationDocumentCode"
              value={data.identificationDocumentCode}
              onChange={(e) => onChange(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={handleSubmit}
          style={{ backgroundColor: 'red', color: 'white' }}
          disabled={loading}
        >
          {!loading ? (
            t('sac.modals.create')
          ) : (
            <CircularProgress color="white" size={24} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateSAC
