import { servicesConstants } from '../../constants/services_constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
  trips: [],
  totalsByCustomers: [],
  operatorTotalsByCustomers: [],
  totalsByOperator: [],
  customerTotalsByOperator: [],
  monthlySummary: [],
}

export function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case servicesConstants.GET_MONTHLY_SUMMARY_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
        monthlySummary: [],
      }
    case servicesConstants.GET_MONTHLY_SUMMARY_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        monthlySummary: action.results,
      }
    case servicesConstants.GET_MONTHLY_SUMMARY_FAILURE:
      return {
        ...state,
        loadingResults: false,
        monthlySummary: [],
        error: action.error,
      }

    case servicesConstants.GET_OPERATOR_TOTALS_BY_CUSTOMERS_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
        operatorTotalsByCustomers: [],
      }
    case servicesConstants.GET_OPERATOR_TOTALS_BY_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        operatorTotalsByCustomers: action.results,
      }
    case servicesConstants.GET_OPERATOR_TOTALS_BY_CUSTOMERS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        operatorTotalsByCustomers: [],
        error: action.error,
      }

    case servicesConstants.GET_TOTALS_BY_CUSTOMER_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
        totalsByCustomers: [],
      }
    case servicesConstants.GET_TOTALS_BY_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        totalsByCustomers: action.results,
      }
    case servicesConstants.GET_TOTALS_BY_CUSTOMER_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        totalsByCustomers: [],
        error: action.error,
      }

    case servicesConstants.GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
        customerTotalsByOperator: [],
      }
    case servicesConstants.GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        customerTotalsByOperator: action.results,
      }
    case servicesConstants.GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        customerTotalsByOperator: [],
        error: action.error,
      }

    case servicesConstants.GET_ALL_TRIPS_BY_SERVICE_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case servicesConstants.GET_ALL_TRIPS_BY_SERVICE_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        trips: action.results,
      }
    case servicesConstants.GET_ALL_TRIPS_BY_SERVICE_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        trips: [],
        error: action.error,
      }
    case servicesConstants.GETALL_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case servicesConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case servicesConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case servicesConstants.GET_ALL_BY_OPERATOR_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case servicesConstants.GET_ALL_BY_OPERATOR_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case servicesConstants.GET_ALL_BY_OPERATOR_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case servicesConstants.GET_REPORT_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case servicesConstants.GET_REPORT_SUCCESS:
      return {
        ...state,
        loadingResults: false,
      }
    case servicesConstants.GET_REPORT_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    default:
      return state
  }
}
