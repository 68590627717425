import { operatorUserConstant } from '../../constants/operatorUser_constant'
import { operatorUserService } from '../../services/operatorUserService'
import { alertActions } from './alert_actions'

import i18next from 'i18next'

export const operatorUserActions = {
  getAll,
  update,
  remove,
}

function remove(body) {
  const { operatorId, opUserId } = body
  delete body.operatorId
  return (dispatch) => {
    operatorUserService.remove(opUserId).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.operatorUser.removeSuccess'),
          ),
        )
        dispatch(getAll(operatorId))
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.operatorUser.removeError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function getAll(operatorId) {
  return (dispatch) => {
    dispatch(request())
    operatorUserService.getAll(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "Ha ocurrido un error al recoger los . Por favor reintente. Message:" +
        //       error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: operatorUserConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: operatorUserConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorUserConstant.GET_ALL_FAILURE, error }
  }
}

function update(body) {
  const operatorId = body.operatorId
  delete body.operatorId
  return (dispatch) => {
    dispatch(request())
    operatorUserService.update(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(
            i18next.t('services.operatorUser.updateSuccess'),
          ),
        )
        dispatch(getAll(operatorId))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.operatorUser.updateError')}: 
              ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: operatorUserConstant.UPDATE_REQUEST }
  }

  function success(results) {
    return { type: operatorUserConstant.UPDATE_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorUserConstant.UPDATE_FAILURE, error }
  }
}
