export const scooterConstants = {
  CLEEN_TRIPS_VIEW_MAP: 'SCOOTERS_CLEEN_TRIPS_VIEW_MAP',

  SAVE_CENTER_MAP: 'SCOOTERS_SAVE_CENTER_MAP',

  FILTER_REQUEST: 'SCOOTERS_FILTER_REQUEST',
  FILTER_SUCCESS: 'SCOOTERS_FILTER_SUCCESS',
  FILTER_FAILURE: 'SCOOTERS_FILTER_FAILURE',

  GET_DATA_SCOOTER_BY_ID_REQUEST: 'SCOOTERS_GET_DATA_SCOOTER_BY_ID_REQUEST',
  GET_DATA_SCOOTER_BY_ID_SUCCESS: 'SCOOTERS_GET_DATA_SCOOTER_BY_ID_SUCCESS',
  GET_DATA_SCOOTER_BY_ID_FAILURE: 'SCOOTERS_GET_DATA_SCOOTER_BY_ID_FAILURE',

  GETALL_REQUEST: 'SCOOTERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'SCOOTERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'SCOOTERS_GETALL_FAILURE',

  DETAILS_REQUEST: 'SCOOTERS_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'SCOOTERS_DETAILS_SUCCESS',
  DETAILS_FAILURE: 'SCOOTERS_DETAILS_FAILURE',

  TRIPS_REQUEST: 'SCOOTERS_TRIPS_REQUEST',
  TRIPS_SUCCESS: 'SCOOTERS_TRIPS_SUCCESS',
  TRIPS_FAILURE: 'SCOOTERS_TRIPS_FAILURE',

  TRIPS_DETAIL_REQUEST: 'SCOOTERS_TRIPS_DETAIL_REQUEST',
  TRIPS_DETAIL_SUCCESS: 'SCOOTERS_TRIPS_DETAIL_SUCCESS',
  TRIPS_DETAIL_FAILURE: 'SCOOTERS_TRIPS_DETAIL_FAILURE',

  GETALL_DYNAMIC_REQUEST: 'SCOOTERS_GETALL_DYNAMIC_REQUEST',
  GETALL_DYNAMIC_SUCCESS: 'SCOOTERS_GETALL_DYNAMIC_SUCCESS',
  GETALL_DYNAMIC_FAILURE: 'SCOOTERS_GETALL_DYNAMIC_FAILURE',

  GET_DYNAMIC_REQUEST: 'SCOOTERS_GET_DYNAMIC_REQUEST',
  GET_DYNAMIC_SUCCESS: 'SCOOTERS_GET_DYNAMIC_SUCCESS',
  GET_DYNAMIC_FAILURE: 'SCOOTERS_GET_DYNAMIC_FAILURE',

  GET_ALL_MAP_BY_OPERATOR_ID_REQUEST:
    'SCOOTERS_GET_ALL_MAP_BY_OPERATOR_ID_REQUEST',
  GET_ALL_MAP_BY_OPERATOR_ID_SUCCESS:
    'SCOOTERS_GET_ALL_MAP_BY_OPERATOR_ID_SUCCESS',
  GET_ALL_MAP_BY_OPERATOR_ID_FAILURE:
    'SCOOTERS_GET_ALL_MAP_BY_OPERATOR_ID_FAILURE',

  CLEAR_RESULTS: 'SCOOTERS_CLEAR_RESULTS',

  DELETE_REQUEST: 'SCOOTERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'SCOOTERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'SCOOTERS_DELETE_FAILURE',

  GET_MISSING_DYNAMICS_REQUEST: 'SCOOTERS_GET_MISSING_DYNAMICS_REQUEST',
  GET_MISSING_DYNAMICS_SUCCESS: 'SCOOTERS_GET_MISSING_DYNAMICS_SUCCESS',
  GET_MISSING_DYNAMICS_FAILURE: 'SCOOTERS_GET_MISSING_DYNAMICS_FAILURE',

  GET_PARAMS_CONFIG_REQUEST: 'SCOOTERS_GET_PARAMS_CONFIG_REQUEST',
  GET_PARAMS_CONFIG_SUCCESS: 'SCOOTERS_GET_PARAMS_CONFIG_SUCCESS',
  GET_PARAMS_CONFIG_FAILURE: 'SCOOTERS_GET_PARAMS_CONFIG_FAILURE',

  GET_HEAT_MAP_OF_LOGGED_OPERATOR_REQUEST:
    'SCOOTERS_GET_HEAT_MAP_OF_LOGGED_OPERATOR_REQUEST',
  GET_HEAT_MAP_OF_LOGGED_OPERATOR_SUCCESS:
    'SCOOTERS_GET_HEAT_MAP_OF_LOGGED_OPERATOR_SUCCESS',
  GET_HEAT_MAP_OF_LOGGED_OPERATOR_FAILURE:
    'SCOOTERS_GET_HEAT_MAP_OF_LOGGED_OPERATOR_FAILURE',

  GET_HEAT_MAP_OF_SELECTED_OPERATOR_REQUEST:
    'SCOOTERS_GET_HEAT_MAP_OF_SELECTED_OPERATOR_REQUEST',
  GET_HEAT_MAP_OF_SELECTED_OPERATOR_SUCCESS:
    'SCOOTERS_GET_HEAT_MAP_OF_SELECTED_OPERATOR_SUCCESS',
  GET_HEAT_MAP_OF_SELECTED_OPERATOR_FAILURE:
    'SCOOTERS_GET_HEAT_MAP_OF_SELECTED_OPERATOR_FAILURE',

  CLEAN_HEAT_MAP_DATA: 'SCOOTERS_CLEAN_HEAT_MAP_DATA',

  CLEAN_TRIP_DATA: 'SCOOTERS_CLEAN_TRIP_DATA',

  GET_TRIPS_REQUEST: 'SCOOTERS_GET_TRIPS_REQUEST',
  GET_TRIPS_SUCCESS: 'SCOOTERS_GET_TRIPS_SUCCESS',
  GET_TRIPS_FAILURE: 'SCOOTERS_GET_TRIPS_FAILURE',

  CLEAR_STATE: 'SCOOTERS_CLEAR_STATE',
}
