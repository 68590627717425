import {
  Button,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import i18next from 'i18next'
import React from 'react'
import { isManager } from '../../helpers/roles'

class SearchItem extends React.Component {
  constructor(props) {
    super()
    this.state = {
      op: '',
      year: '',
      month: '',
      errors: {
        op: {
          result: true,
          message: '',
        },
      },
    }
    this.onChangeOp = this.onChangeOp.bind(this)
    this.onClickButton = this.onClickButton.bind(this)
  }

  onChangeOp = (event, newValue) => {
    let errors = this.state.errors
    errors.op.message = ''
    this.setState({
      op: newValue ? newValue : '',
    })
  }

  componentDidMount() {
    this.props.getOperators()
  }

  onClickButton() {
    const opUser = isManager()
      ? this.state.op.id
      : this.props.authenticationReducer.user.sharingOperatorId
    this.props.getReportPDF(opUser, this.state.year, this.state.month)
  }

  render() {
    const year = new Date().getFullYear()
    const { t } = this.props

    const classes = this.useStyles

    const MONTH_DATA = [
      {
        value: 1,
        month: i18next.t('monthlyDetails.search.monthList.january'),
      },
      {
        value: 2,
        month: i18next.t('monthlyDetails.search.monthList.february'),
      },
      {
        value: 3,
        month: i18next.t('monthlyDetails.search.monthList.march'),
      },
      {
        value: 4,
        month: i18next.t('monthlyDetails.search.monthList.april'),
      },
      {
        value: 5,
        month: i18next.t('monthlyDetails.search.monthList.may'),
      },
      {
        value: 6,
        month: i18next.t('monthlyDetails.search.monthList.june'),
      },
      {
        value: 7,
        month: i18next.t('monthlyDetails.search.monthList.july'),
      },
      {
        value: 8,
        month: i18next.t('monthlyDetails.search.monthList.august'),
      },
      {
        value: 9,
        month: i18next.t('monthlyDetails.search.monthList.september'),
      },
      {
        value: 10,
        month: i18next.t('monthlyDetails.search.monthList.october'),
      },
      {
        value: 11,
        month: i18next.t('monthlyDetails.search.monthList.november'),
      },
      {
        value: 12,
        month: i18next.t('monthlyDetails.search.monthList.december'),
      },
    ]

    //console.log(MONTH_DATA)

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item md={1} implementation="css" smDown component={Hidden} />
          {isManager() ? (
            <Grid item md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={this.state.op || ''}
                  onChange={this.onChangeOp}
                  name={'operator'}
                  options={this.props.operatorReducer.results}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant={'outlined'}
                      label={i18next.t('monthlyDetails.search.op')}
                      helperText={this.state.errors.op.message}
                      error={
                        this.state.errors.op.message.length === 0 ? false : true
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
          ) : null}
          <Grid item md={1}>
            <FormControl fullWidth>
              <InputLabel id="month">
                {i18next.t('monthlyDetails.search.month')}
              </InputLabel>
              <Select
                id="month"
                value={this.state.month}
                onChange={(event) =>
                  this.setState({ month: event.target.value })
                }
              >
                {MONTH_DATA.map((item) => {
                  return (
                    <MenuItem value={item.value} key={Math.random()}>
                      {item.month}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={1}>
            <FormControl fullWidth>
              <InputLabel id="year">
                {i18next.t('monthlyDetails.search.year')}
              </InputLabel>
              <Select
                id="year"
                value={this.state.year}
                onChange={(event) =>
                  this.setState({ year: event.target.value })
                }
              >
                {[0, 1, 2, 3, 4].map((item) => {
                  return (
                    <MenuItem value={year - item} key={Math.random()}>
                      {year - item}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={1}>
            <FormControl>
              <Button
                style={{ marginTop: '12%' }}
                variant="contained"
                onClick={this.onClickButton}
                disabled={
                  (isManager() && this.state.op == '') ||
                  this.state.year == '' ||
                  this.state.month == '' ||
                  this.props.servicesReducer.loadingResults
                }
                color="primary"
              >
                {i18next.t('monthlyDetails.search.download')}
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default SearchItem
