import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  DropDownSections: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  warningNote: {
    color: 'red',
    fontSize: 'larger',
  },
  sectionAccordionText: {
    fontSize: 'larger',
  },
  sectionAccordionIcons: {
    alignSelf: 'center',
  },
  commentSection: {
    marginBottom: '5%',
  },
  hideSection: {
    display: 'none',
  },
  x: {
    width: '100%',
  },
}))

export default useStyles
