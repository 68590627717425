import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'

const ChangePassword = ({ data, onConfirm }) => {
  const { t } = useTranslation()

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('')

  return (
    <div>
      <Grid
        container
        spacing={1}
        style={{ justifyContent: 'center', display: 'flex' }}
      >
        <Grid item xs={6} sm={6}>
          <TextField
            label={t('dashboard.changePassword.actualPassword')}
            type="password"
            autoFocus
            fullWidth
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={6}></Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            label={t('dashboard.changePassword.newPassword')}
            type="password"
            autoFocus
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            label={t('dashboard.changePassword.repeatNewPassword')}
            type="password"
            fullWidth
            value={newPasswordRepeat}
            onChange={(e) => setNewPasswordRepeat(e.target.value)}
            error={newPassword != newPasswordRepeat}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onConfirm(oldPassword, newPassword)}
            disabled={
              oldPassword === newPassword ||
              newPassword != newPasswordRepeat ||
              oldPassword.length === 0 ||
              newPassword.length === 0 ||
              newPasswordRepeat.length === 0
            }
          >
            {t('dashboard.changePassword.update')}
          </Button>
        </Grid>
      </Grid>
      {newPassword != newPasswordRepeat && newPassword != '' && (
        <p style={{ color: 'red' }}>
          {t('dashboard.changePassword.passwordsDontMatch')}
        </p>
      )}
      {newPassword === oldPassword && newPassword != '' && (
        <p style={{ color: 'red' }}>
          {t('dashboard.changePassword.samePasswords')}
        </p>
      )}
    </div>
  )
}

export default ChangePassword
