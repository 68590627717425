import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const OperatorsList = ({ isOpen, onClose, onConfirm, data }) => {
  const { t } = useTranslation()
  const [operator, setoperator] = useState()
  const [validatedSince, setvalidatedSince] = useState(null)
  const [validatedUntil, setvalidatedUntil] = useState(null)

  const handleClose = () => {
    onClose()
    setvalidatedSince(null)
    setvalidatedUntil(null)
  }

  const handleConfirm = () => {
    onConfirm(operator, validatedSince, validatedUntil)
    setvalidatedSince(null)
    setvalidatedUntil(null)
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('customers.modals.operatorsList.dialogTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('customers.modals.operatorsList.dialogContent')}
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('customers.modals.operatorsList.sinceLabel')}
                type="datetime-local"
                value={validatedSince}
                onChange={(e) => setvalidatedSince(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('customers.modals.operatorsList.untilLabel')}
                type="datetime-local"
                value={validatedUntil}
                onChange={(e) => setvalidatedUntil(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Autocomplete
            multiple
            options={data}
            onChange={(e, value) => setoperator(value)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(
                  'customers.modals.operatorsList.autoCompleteTextFieldLabel',
                )}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('customers.modals.operatorsList.cancelBtn')}
          </Button>
          <Button disabled={!operator} onClick={handleConfirm} color="primary">
            {t('customers.modals.operatorsList.ConfirmBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default OperatorsList
