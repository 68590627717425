import MaterialTable, { MTableToolbar } from 'material-table'
import React from 'react'
import { withTranslation } from 'react-i18next'

class MaterialTableExtend extends React.Component {
  render() {
    const { t } = this.props
    const translations = t('material_table.tableStrings', {
      returnObjects: true,
    })
    return (
      <MaterialTable
        {...this.props}
        components={{
          Toolbar: (props) => (
            <React.Fragment>
              <MTableToolbar {...props} />
              {this.props.buttons}
            </React.Fragment>
          ),
        }}
        localization={translations}
        options={{
          padding: 'dense',
          filtering: true,
          exportButton: true,
          selection: this.props.selection ? true : false,
          actionsColumnIndex: this.props.actionsColumnIndex,
          pageSizeOptions: [10, 20, 100],
          pageSize: 10,
          rowStyle: (rowData) => {
            if (rowData.tableData.checked) {
              return { backgroundColor: 'rgb(228, 229, 229)' }
            }
            return {}
          },
        }}
      />
    )
  }
}

export default withTranslation('common')(MaterialTableExtend)
