import { handleCall } from './handleResponse'

export const operatorService = {
  getAll,
  insert,
  updateRestricted,
  update,
  insertOperatorUsers,
  validateOperator,
  getAllToValidate,
  getAllPrivateOperators,
  getallValidatedOperators,
  getAllDiscounts,
  createDiscount,
  updateDiscount,
  getAffectedCustomersByDiscount,
  getDiscountsByCustomerId,
  setGenericDiscount,
  updatePersonalizedDiscountOfLoggedOperator,
  getAllChilds,
  getWithNoParent,
  getParent,
  getByCustomers,
  getUserDataByEmail,
  updateOperatorUsers,
  getOperatorUses,
  getAllDiscountsOfLoggedOperator,
  getCurrentDiscountsOfOperator,
  getCurrentDiscountsOfLoggedOperatorForCustomer,
  getCurrentDiscountsOfOperatorForCustomer,
  getAssignableCustomersToDiscount,
  GetAssignableCustomerByEmail,
  getVehicleHomologationTypes,
}

function getCurrentDiscountsOfOperatorForCustomer(body) {
  const { operatorId, customerId } = body
  return handleCall(
    `/operators/v1/Discounts/GetCurrentDiscountsOfOperatorForCustomer?sharingOperatorId=${operatorId}&customerId=${customerId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getAssignableCustomersToDiscount(discountId) {
  return handleCall(
    discountId
      ? `/operators/v1/Discounts/GetAssignableCustomers?discountId=${discountId}`
      : '/operators/v1/Discounts/GetAssignableCustomers',
    {
      method: 'GET',
      body: null,
    },
  )
}

function GetAssignableCustomerByEmail(email) {
  return handleCall(
    `/operators/v1/Discounts/GetAssignableCustomerByEmail?email=${email}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getCurrentDiscountsOfLoggedOperatorForCustomer(customerId) {
  return handleCall(
    `/operators/v1/Discounts/GetCurrentDiscountsOfLoggedOperatorForCustomer?customerId=${customerId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Discounts/GetCurrentDiscountsOfLoggedOperatorForCustomer?customerId=${customerId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getCurrentDiscountsOfOperator(operatorId) {
  return handleCall(
    `/operators/v1/Discounts/GetCurrentDiscountsOfOperator?sharingOperatorId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Discounts/GetCurrentDiscountsOfOperator?sharingOperatorId=${operatorId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllDiscountsOfLoggedOperator() {
  return handleCall(`/operators/v1/Discounts/GetAllDiscountsOfLoggedOperator`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Discounts/GetAllDiscountsOfLoggedOperator`,
  //   requestOptions
  // ).then(handleResponse);
}

function updatePersonalizedDiscountOfLoggedOperator(body) {
  return handleCall(
    `/operators/v1/Discounts/UpdatePersonalizedDiscountOfLoggedOperator`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
    },
  )
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Discounts/UpdatePersonalizedDiscountOfLoggedOperator`,
  //   requestOptions
  // ).then(handleResponse);
}

function setGenericDiscount(body) {
  return handleCall(
    `/operators/v1/Discounts/UpdateGenericDiscountOfLoggedOperator`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
    },
  )
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Discounts/UpdateGenericDiscountOfLoggedOperator`,
  //   requestOptions
  // ).then(handleResponse);
}

function getOperatorUses() {
  return handleCall(`/operators/v1/OperatorUsers`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/OperatorUsers`,
  //   requestOptions
  // ).then(handleResponse);
}

function updateOperatorUsers(body) {
  const operatorId = body.operatorId
  delete body.operatorId

  return handleCall(`/operators/v1/OperatorUsers/${operatorId}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/OperatorUsers/${operatorId}/Update`,
  //   requestOptions
  // ).then(handleResponse);
}

function getUserDataByEmail(email) {
  return handleCall(
    `/operators/v1/Operators/GetUserDataByEmail?email=${email}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Operators/GetUserDataByEmail?email=${email}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getByCustomers(customerId) {
  return handleCall(
    `/operators/v1/Operators/GetAllPrivatesByCustomer?customerId=${customerId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Operators/GetAllPrivatesByCustomer?customerId=${customerId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getParent(operatorId) {
  return handleCall(
    `/operators/v1/Operators/GetParent?SharingOperatorId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Operators/GetParent?SharingOperatorId=${operatorId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getWithNoParent() {
  return handleCall(`/operators/v1/Operators/GetWithNoParent`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   stringConstants.URL_ApiGateway + "/operators/v1/Operators/GetWithNoParent",
  //   requestOptions
  // ).then(handleResponse);
}

function getAllChilds(operatorId) {
  return handleCall(
    `/operators/v1/Operators/GetAllChilds?SharingOperatorId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Operators/GetAllChilds?SharingOperatorId=${operatorId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getDiscountsByCustomerId(customerId) {
  return handleCall(
    `/operators/v1/Discounts/GetByCustomer?CustomerId=${customerId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Discounts/GetByCustomer?CustomerId=${customerId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function updateDiscount(body) {
  const { discountId } = body
  delete body.discountId
  return handleCall(
    `/operators/v1/Discounts/${discountId}/UpdateCustomDiscountOfLoggedOperator`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
    },
  )
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Discounts/${discountId}/UpdateCustomDiscountOfLoggedOperator`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAffectedCustomersByDiscount(discountId) {
  return handleCall(
    `/operators/v1/Discounts/${discountId}/GetAffectedCustomers`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Discounts/${discountId}/GetAffectedCustomers`,
  //   requestOptions
  // ).then(handleResponse);
}

function createDiscount(body) {
  return handleCall(
    `/operators/v1/Discounts/NewCustomDiscountOfLoggedOperator`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
  )
  // var requestOptions = {
  //   method: "POSt",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Discounts/NewCustomDiscountOfLoggedOperator`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllDiscounts(operatorId) {
  const filter = operatorId
    ? '?field=SharingOperatorId&value=' + operatorId
    : ''
  return handleCall(`/operators/v1/Discounts${filter}`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // const filter = operatorId
  //   ? "?field=SharingOperatorId&value=" + operatorId
  //   : "";
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Discounts${filter}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getallValidatedOperators() {
  return handleCall(`/operators/v1/operators?field=CanOperate&value=true`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/operators?field=CanOperate&value=true`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllPrivateOperators() {
  return handleCall(`/operators/v1/Operators?field=IsPrivate&value=true`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Operators?field=IsPrivate&value=true`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllToValidate() {
  return handleCall(`/operators/v1/Operators?field=CanOperate&value=false`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Operators?field=CanOperate&value=false`,
  //   requestOptions
  // ).then(handleResponse);
}

function validateOperator(body) {
  const sharingOperatorId = body.sharingOperatorId
  delete body.sharingOperatorId

  return handleCall(`/operators/v1/Operators/${sharingOperatorId}/Validate`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Operators/${sharingOperatorId}/Validate`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAll(operatorId) {
  const filter = operatorId ? `/${operatorId}` : ''
  return handleCall(`/operators/v1/Operators${filter}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // const filter = operatorId ? `/${operatorId}` : "";
  // return fetch(
  //   stringConstants.URL_ApiGateway + "/operators/v1/Operators" + filter,
  //   requestOptions
  // ).then(handleResponse);
}

function insert(body) {
  return handleCall(`/operators/v1/Operators/New`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "POST",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Operators/New`,
  //   requestOptions
  // ).then(handleResponse);
}
function insertOperatorUsers(body) {
  return handleCall(`/operators/v1/OperatorUsers/New`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "POST",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/OperatorUsers/New`,
  //   requestOptions
  // ).then(handleResponse);
}

function update(body) {
  const operatorId = body.id
  delete body.id

  return handleCall(`/operators/v1/Operators/${operatorId}/update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Operators/${operatorId}/update`,
  //   requestOptions
  // ).then(handleResponse);
}

function updateRestricted(body) {
  const operatorId = body.id

  delete body.id

  return handleCall(`/operators/v1/Operators/${operatorId}/UpdateRestricted`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // let requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/Operators/${operatorId}/UpdateRestricted`,
  //   requestOptions
  // ).then(handleResponse);
}

function getVehicleHomologationTypes() {
  return handleCall(`/vehicles/v1/Vehicles/VehicleHomologationTypes`, {
    method: 'GET',
    body: null,
  })
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     if (response.status === 204 || response.status === 404) {
//       return [];
//     }
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       if (response.status === 401) {
//         var refreshToken = loginService.getWithExpiry("refresh_token");
//         loginService.refreshToken(refreshToken).then((token) => {
//           loginService.setWithExpiry(
//             "token",
//             token.access_token,
//             token.expires_in
//           );
//         });
//       }
//       const error = (data && data.message) || text;
//       return Promise.reject(error);
//     }

//     // check if the token is close to expire
//     var date = new Date();
//     var now_utc = Date.UTC(
//       date.getUTCFullYear(),
//       date.getUTCMonth(),
//       date.getUTCDate(),
//       date.getUTCHours(),
//       date.getUTCMinutes(),
//       date.getUTCSeconds()
//     );
//     var now = new Date(now_utc);
//     now.setMinutes(now.getMinutes() + 20);
//     var token_expire = new Date(loginService.getWithExpiry("token_dateExpiry"));
//     // if in 10 minutes expire, renew the token
//     if (now > token_expire) {
//       refreshToken = loginService.getWithExpiry("refresh_token");
//       loginService.refreshToken(refreshToken).then((token) => {
//         loginService.setWithExpiry(
//           "token",
//           token.access_token,
//           token.expires_in
//         );
//       });
//     }
//     return data;
//   });
// }
