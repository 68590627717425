import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { cat, en, es } from './locales'

const options = {
  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  debug: process.env.NODE_ENV === 'development' ? true : false,

  // lng: 'en',

  resources: {
    es: {
      common: es.es,
    },
    en: {
      common: en.en,
    },
    cat: {
      common: cat.cat,
    },
  },

  fallbackLng: 'es',

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },
}

i18n.use(LanguageDetector).init(options)

let language = localStorage.getItem('lang')

i18n.changeLanguage(language || 'en', (err, t) => {
  if (err) return console.log('something went wrong loading', err)
})

export default i18n
