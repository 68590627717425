import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'
import useStyles from './styles'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { handleTrueFalsyTermSwitch } from '../../helpers/filters'
import { scooterActions } from '../../redux/actions/scooter_actions'
import { servicesActions } from '../../redux/actions/services_actions'
import { ticketsActions } from '../../redux/actions/tickets_action'

import { isManager, isSacUser } from '../../helpers/roles'

import IncidentsDetails from './details'

import { formatDateToDisplay } from '../shared/formatDate'

import Spinner from '../shared/Spinner'

import DialogConfirmAction from '../shared/modalConfirmAction'

import CreateNewTicket from './scooterManagment/dialogs/createNewTicket'

import CustomMaterialTable from '../shared/customMaterialTable'

const TicketsList = ({
  operatorReducer,
  ticketsReducer,
  getAllByOperatorId,
  getAll,
  getVehiclesDataById,
  scooterReducer,
  getAllScooters,
  getAllByDates,
  assignToOperate,
  cleanService,
  createTicket,
  endTrip,
}) => {
  ///hacer el filtro por fecha, mostrar datos acordes
  const classes = useStyles()

  const { t } = useTranslation()

  const [isDialogNewTicketOpen, setisDialogNewTicketOpen] = useState(false)

  const [displayedList, setdisplayedList] = useState(
    ticketsReducer.results.filter((t) => t.isClosed === false),
  )
  const [
    isDialogConfirmClearActiveServices,
    setisDialogConfirmClearActiveServices,
  ] = useState(false)
  const [dataScooterSelected, setdataScooterSelected] = useState()

  const [since, setsince] = useState()
  const [until, setuntil] = useState()

  const [isDialogTicketDetailsOpen, setisDialogTicketDetailsOpen] =
    useState(false)
  const [selectedDataToDisplayDetails, setselectedDataToDisplayDetails] =
    useState({})

  const handleClickAction = (event, rowData) => {
    setisDialogTicketDetailsOpen(true)
    setselectedDataToDisplayDetails(rowData)
  }

  const handleClickSearchBetweenDates = () => {
    isManager() || isSacUser()
      ? getAllByDates(since, until)
      : getAllByOperatorId(null, since, until)
  }

  const handleClickSearchAll = () => {
    isManager() || isSacUser() ? getAll() : getAllByOperatorId(null, null, null)
  }

  const handleSearchScooters = () => {
    isManager() ? getAllScooters() : getAllScooters(null, null, null)
  }
  useEffect(() => {
    handleSearchScooters()
    handleClickSearchAll()
  }, [])

  useEffect(() => {
    handleSearchScooters()
    mountDisplayList()
  }, [ticketsReducer.results])

  useEffect(() => {
    mountDisplayList()
  }, [scooterReducer.results])

  const mountDisplayList = () => {
    let updatedDisplayList = []
    ticketsReducer.results.map((t) => {
      scooterReducer.results.filter((s) => {
        t.vehicleId === s.id &&
          updatedDisplayList.push({ ...t, scooterData: s })
      })
    })

    setdisplayedList(updatedDisplayList.reverse())
  }

  const handleLockScooter = (rowData) => {
    const { scooterData, vehicleId } = rowData || {
      sharing: { outOfService: null, sharingOperatorId: null },
    }

    const outOfServiceValue = scooterData.sharing.outOfService
    const operatorId = scooterData.sharing.sharingOperatorId
    const body = {
      sharingOperatorId: operatorId,
      vehicleIds: [vehicleId],
      outOfService: !outOfServiceValue,
    }
    // console.log("rowData", rowData);
    assignToOperate(body)
  }

  const handleActionClearActiveServices = (event, rowData) => {
    setdataScooterSelected(rowData)
    setisDialogConfirmClearActiveServices(true)
  }
  const handleConfirmClearActiveServices = () => {
    const { sharing } = dataScooterSelected
    const operatorId = !isManager()
      ? operatorReducer.dataOperatorLogged.id
      : null
    // cleanService(sharing && sharing.activeServiceId, operatorId);
    endTrip(sharing && sharing.activeServiceId, operatorId)
  }

  const handleConfirmCreateNewTicket = ({
    description,
    newComment,
    isBlockScooterChecked,
    selectedRow,
  }) => {
    const { id, sharing } = selectedRow

    const body = {
      sharingOperatorId: isManager()
        ? ''
        : sharing && sharing.sharingOperatorId,
      vehicleId: id,
      serviceId: sharing && sharing.activeServiceId,
      title: description,
      openNoteText: newComment,
      vehicleCannotOperate: isBlockScooterChecked,
      technicianId: null,
    }

    createTicket(body)
  }
  const handleActionCreateTicket = () => {
    setisDialogNewTicketOpen(true)
  }

  return (
    <div>
      <div className={classes.containerFilter}>
        <div className={classes.dateFilter}>
          <TextField
            id="date"
            label={t('incidents.page.since')}
            type="date"
            value={since}
            onChange={(e) => setsince(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date"
            label={t('incidents.page.until')}
            type="date"
            value={until}
            onChange={(e) => setuntil(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!since || !until}
            onClick={handleClickSearchBetweenDates}
          >
            {t('incidents.page.search')}
          </Button>
          <div className={classes.getAllButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickSearchAll}
            >
              {t('incidents.page.searchAllBtn')}
            </Button>
          </div>
        </div>
      </div>
      {ticketsReducer.loadingResults ? (
        <div style={{ 'margin-top': '50%' }}>
          <Spinner loading={ticketsReducer.loadingResults} />
        </div>
      ) : (
        <CustomMaterialTable
          data={displayedList}
          saveFilters={true}
          isLoading={ticketsReducer.loadingResults}
          columns={[
            { title: 'Id', field: 'id' },
            { title: t('incidents.page.table.columns.title'), field: 'title' },
            {
              title: t('incidents.page.table.columns.created'),
              field: 'created',
              render: (rowData) => formatDateToDisplay(rowData.created),
            },
            {
              title: t('incidents.page.table.columns.closed'),
              field: 'closeTimestamp',
              render: (rowData) => formatDateToDisplay(rowData.closeTimestamp),
            },
            // { title: ('createUser'), field: 'createUser'},
            {
              title: t('incidents.page.table.columns.finalized'),
              field: 'isClosed',
              lookup: { 0: t('shared.yes'), 1: t('shared.no') },
              customFilterAndSearch: (term, rowData) =>
                handleTrueFalsyTermSwitch(term, rowData.isClosed),
              render: (rowData) =>
                rowData.isClosed
                  ? t('customers.page.columns.yes')
                  : t('customers.page.columns.no'),
            },
            {
              title: t('incidents.page.table.columns.plate'),
              field: 'scooterData.plate',
            },
            {
              title: t('incidents.page.table.columns.imei'),
              field: 'scooterData.imei',
            },
            {
              title: t('incidents.page.table.columns.model'),
              field: 'scooterData.model',
            },
            {
              title: t('incidents.page.table.columns.outOfService'),
              field: 'scooterData.sharing.outOfService',
              lookup: { 0: t('shared.yes'), 1: t('shared.no') },
              customFilterAndSearch: (term, rowData) =>
                handleTrueFalsyTermSwitch(
                  term,
                  rowData.scooterData.sharing?.outOfService,
                ),
              render: (rowData) =>
                rowData.scooterData && rowData.scooterData.sharing?.outOfService
                  ? t('customers.page.columns.yes')
                  : t('customers.page.columns.no'),
            },
            {
              title: t('incidents.page.table.columns.operatorName'),
              field: 'sharingOperatorName',
              hidden: !(isManager() || isSacUser()),
            },
            {
              title: t('incidents.page.table.columns.createUser'),
              field: 'createUserFullName',
            },
          ]}
          title={t('incidents.page.table.title')}
          actions={[
            {
              icon: 'add',
              tooltip: 'Añadir',
              isFreeAction: true,
              onClick: () => handleActionCreateTicket(),
            },
            {
              icon: 'view_list',
              tooltip: t('incidents.page.table.actions.details'),
              onClick: (event, rowData) => handleClickAction(event, rowData),
            },

            (rowData) => ({
              icon: 'cleaning_services',
              tooltip: t(
                'incidents.scooterManagement.table.actions.cleanActiveServices',
              ),
              onClick: (event, rowData) =>
                handleActionClearActiveServices(event, rowData),
              disabled: (rowData) => {
                const { scooterData } = rowData || {
                  sharing: { activeServiceId: null },
                }
                return !scooterData.sharing.activeServiceId
              },
            }),

            (rowData) => ({
              icon: `lock${
                rowData &&
                rowData.scooterData &&
                rowData.scooterData.sharing &&
                rowData.scooterData.sharing.outOfService &&
                '_open'
              }`,
              tooltip:
                rowData &&
                rowData.scooterData &&
                rowData.scooterData.sharing &&
                rowData.scooterData.sharing.outOfService
                  ? t(
                      'incidents.scooterManagement.table.actions.unblockScooter',
                    )
                  : t('incidents.scooterManagement.table.actions.blockScooter'),
              onClick: (event, rowData) => handleLockScooter(rowData),
            }),
          ]}
        />
      )}

      <DialogConfirmAction
        isOpen={isDialogConfirmClearActiveServices}
        onClose={() => setisDialogConfirmClearActiveServices(false)}
        addNote={false}
        onConfirm={handleConfirmClearActiveServices}
        data={{
          header: t('incidents.scooterManagement.confirmActionDialog.header'),
          body: t('incidents.scooterManagement.confirmActionDialog.body'),
        }}
      />
      <IncidentsDetails
        isOpen={isDialogTicketDetailsOpen}
        onClose={() => setisDialogTicketDetailsOpen(false)}
        data={selectedDataToDisplayDetails}
      />
      <CreateNewTicket
        isOpen={isDialogNewTicketOpen}
        onClose={() => setisDialogNewTicketOpen(false)}
        onCreate={handleConfirmCreateNewTicket}
        scooterReducer={scooterReducer}
      />
    </div>
  )
}

function mapState(state) {
  const { operatorReducer, ticketsReducer, scooterReducer } = state
  return { operatorReducer, ticketsReducer, scooterReducer }
}

const actionCreators = {
  getAllByOperatorId: ticketsActions.getAllByOperatorId,
  addNote: ticketsActions.addNote,
  getAll: ticketsActions.getAll,
  getVehiclesDataById: scooterActions.getDataById,
  getAllScooters: scooterActions.getAll,
  getAllByDates: ticketsActions.getAllByDates,
  cleanService: servicesActions.cleanService,
  assignToOperate: scooterActions.assignToOperate,
  createTicket: ticketsActions.createTicket,
  endTrip: servicesActions.endTrip,
}

export default connect(mapState, actionCreators)(TicketsList)
