import { ticketsConstant } from '../../constants/tickets_constant'
import { ticketsService } from '../../services/ticketsService'

import { isManager, isManagerReadOnly } from '../../helpers/roles'
import { alertActions } from './alert_actions'
import { operatorActions } from './operator_action'
import { scooterActions } from './scooter_actions'

import i18next from 'i18next'

import store from '../store'

export const ticketsActions = {
  getAllByOperatorId,
  addNote,
  getById,
  closeTicket,
  getAll,
  getAllOpenByVehicle,
  createTicket,
  getAllByDates,
}

function getAllByDates(since, until) {
  return (dispatch) => {
    dispatch(request())
    ticketsService.getAllByDates(since, until).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.ticket.selectRoleLoginError')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: ticketsConstant.GET_ALL_FAILURE }
  }

  function success(results) {
    return { type: ticketsConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: ticketsConstant.GET_ALL_FAILURE, error }
  }
}

function createTicket(body) {
  const sharingOperatorId = body.sharingOperatorId
  delete body.sharingOperatorId
  return (dispatch) => {
    dispatch(request())
    ticketsService.createTicket(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(
            i18next.t('services.ticket.createTicketSuccess'),
          ),
        )

        if (isManager() || isManagerReadOnly()) {
          dispatch(getAll())
          dispatch(scooterActions.getAll())
          dispatch(operatorActions.getAll())
        } else {
          dispatch(scooterActions.getAll(sharingOperatorId, null, null))
          dispatch(getAllByOperatorId(sharingOperatorId, null, null))
        }

        // isManager()
        //   ? dispatch(getAll())
        //   : dispatch(getAllByOperatorId(sharingOperatorId, null, null));
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.ticket.createTicketSuccess')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: ticketsConstant.CREATE_NEW_TICKET_REQUEST }
  }

  function success(results) {
    return { type: ticketsConstant.CREATE_NEW_TICKET_SUCCESS, results }
  }

  function failure(error) {
    return { type: ticketsConstant.CREATE_NEW_TICKET_FAILURE, error }
  }
}

function getAllOpenByVehicle(vehicleId) {
  return (dispatch) => {
    if (vehicleId) {
      dispatch(request())
      ticketsService.getAllOpenByVehicle(vehicleId).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(
            alertActions.error(
              `${i18next.t(
                'services.ticket.getAllOpenByVehicleError',
              )}: ${error}`,
            ),
          )
        },
      )
    }
  }

  function request() {
    return { type: ticketsConstant.GET_ALL_OPEN_BY_VEHICLE_ID_REQUEST }
  }

  function success(results) {
    return {
      type: ticketsConstant.GET_ALL_OPEN_BY_VEHICLE_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return { type: ticketsConstant.GET_ALL_OPEN_BY_VEHICLE_ID_FAILURE, error }
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    ticketsService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.ticket.getAllError')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: ticketsConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: ticketsConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: ticketsConstant.GET_ALL_FAILURE, error }
  }
}

function getAllByOperatorId(operatorId, since = null, until = null) {
  return (dispatch) => {
    const { authenticationReducer } = store.getState()
    const operatorIdFromAuthReducer =
      authenticationReducer.user.sharingOperatorId

    if (operatorId || operatorIdFromAuthReducer) {
      dispatch(request())
      ticketsService
        .getAllByOperatorId(
          operatorId || operatorIdFromAuthReducer,
          since,
          until,
        )
        .then(
          (results) => {
            dispatch(success(results))
          },
          (error) => {
            dispatch(failure(error))
            dispatch(
              alertActions.error(
                `${i18next.t('services.ticket.getAllError')}: ${error}`,
              ),
            )
          },
        )
    }
  }

  function request() {
    return { type: ticketsConstant.GET_ALL_BY_OPERATOR_ID_REQUEST }
  }

  function success(results) {
    return { type: ticketsConstant.GET_ALL_BY_OPERATOR_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: ticketsConstant.GET_ALL_BY_OPERATOR_ID_FAILURE, error }
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request())
    ticketsService.getById(id).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.ticket.getAllError')}: ${error}`,
          ),
        )
      },
    )
  }
  function request() {
    return { type: ticketsConstant.GET_BY_ID_REQUEST }
  }

  function success(results) {
    return { type: ticketsConstant.GET_BY_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: ticketsConstant.GET_BY_ID_FAILURE, error }
  }
}

function addNote(body) {
  const ticketId = body.ticketId
  return (dispatch) => {
    ticketsService.addNote(body).then(() => {
      dispatch(getById(ticketId))
      dispatch(
        alertActions.success(i18next.t('services.ticket.addNoteSuccess')),
      )
    })
  }
}

// function closeTicket(body) {
//   const bodyVehicleCanOperate = body.bodyVehicleCanOperate;
//   delete body.bodyVehicleCanOperate;

//   return (dispatch) => {
//     ticketsService.closeTicket(body).then(() => {
//       dispatch(alertActions.success("Ticket cerrado correctamente"));
//       dispatch(scooterActions.assignToOperate(bodyVehicleCanOperate, true));
//     });
//   };
// }
function closeTicket(body) {
  const bodyVehicleCanOperate = body.bodyVehicleCanOperate
  delete body.bodyVehicleCanOperate

  return (dispatch) => {
    ticketsService.closeTicket(body).then(
      (results) => {
        dispatch(
          alertActions.success(i18next.t('services.ticket.closeTicketSuccess')),
        )
        dispatch(scooterActions.assignToOperate(bodyVehicleCanOperate, true))
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.ticket.closeTicketSuccess')}: ${error}`,
          ),
        )
      },
    )
  }
}
