import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  activeTrip: {
    background: '#76a900',
  },
  group: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  streetDirectionsGroup: {
    marginBottom: '2%',
  },
  tripDetails: {
    padding: '0% 7%',
  },
  divider: {
    margin: '5% 0%',
  },
}))

export default useStyles
