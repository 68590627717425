import { ticketsConstant } from '../../constants/tickets_constant'

const initialState = {
  loadingResults: false,
  results: [],
  selectedDetails: [],
}

export function ticketsReducer(state = initialState, action) {
  switch (action.type) {
    case ticketsConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case ticketsConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case ticketsConstant.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }

    case ticketsConstant.GET_ALL_BY_OPERATOR_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case ticketsConstant.GET_ALL_BY_OPERATOR_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case ticketsConstant.GET_ALL_BY_OPERATOR_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }

    case ticketsConstant.GET_ALL_OPEN_BY_VEHICLE_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case ticketsConstant.GET_ALL_OPEN_BY_VEHICLE_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case ticketsConstant.GET_ALL_OPEN_BY_VEHICLE_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }

    case ticketsConstant.GET_BY_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case ticketsConstant.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        selectedDetails: action.results,
      }
    case ticketsConstant.GET_BY_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    default:
      return state
  }
}
