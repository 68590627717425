import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Analytics from './components/analytics'
import Bill from './components/bills'
import BillsDetails from './components/bills/details'
import Bonus from './components/bonus'
import CauUsers from './components/cauUsers'
import Customers from './components/customers'
import AssigntCustomerToOperator from './components/customers/assignToOperator'
import Help from './components/help'
import Home from './components/home'
import Incidents from './components/incidents'
import IncidentDetails from './components/incidents/details'
import Login from './components/login'
import FormCompleteData from './components/login/completeDataOperator'
import MultifactorCode from './components/login/multifactorCode'
import MultifactorQR from './components/login/multifactorQr'
import MonthlyDetails from './components/monthlyDetails'
import List from './components/operator/list/list'
import Profile from './components/operator/profile/profile'
import RequestScooter from './components/operator/requestScooter'
import Validate from './components/operator/validate/validate'
import OperatorUser from './components/operatorUser'
import Payments from './components/payments'
import Rates from './components/rates'
import Report from './components/reports'
import ReportDetails from './components/reports/sections/usage/details'
import SacUsers from './components/sacUsers'
import Scooters from './components/scooters'
import ScooterDetails from './components/scooters/details'
import ScootersLocation from './components/scooters/location'
import Services from './components/services'
import Technicians from './components/technicians'
import UserList from './components/users'
import UserNew from './components/users/new'
import OperatorUserUpdate from './components/users/update/indexOperatorUserUpdate'
import TechnicianUpdate from './components/users/update/indexTechnicianUpdate'
import Validations from './components/validations'
import { history } from './helpers/history'
import { userActions } from './redux/actions/user_actions'

const Root = ({ authenticationReducer, checkToken }) => {
  const { auth } = authenticationReducer

  useEffect(() => {
    if (
      ![
        '/subscriptions/confirmation',
        '/multifactor',
        '/multifactor-code',
      ].includes(window.location.pathname)
    ) {
      checkToken()
    }
  }, [auth])

  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute path="/home" component={Home} auth={auth}></PrivateRoute>
        <Route path="/login" component={Login}></Route>
        <Route path="/multifactor" component={MultifactorQR}></Route>
        <Route path="/multifactor-code" component={MultifactorCode}></Route>
        <PrivateRoute
          path="/FormCompleteData"
          component={FormCompleteData}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/users/create"
          component={UserNew}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/users"
          component={UserList}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/scooters/locations"
          component={ScootersLocation}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/scooters/:id"
          component={ScooterDetails}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/scooters"
          component={Scooters}
          auth={auth}
        ></PrivateRoute>
        {/* <PrivateRoute
                    path="/customers/documentation/:id"
                    component={CustomerDocumentation}
                    auth={auth}
                ></PrivateRoute> */}
        <PrivateRoute
          path="/customers/assignToOperator/:id"
          component={AssigntCustomerToOperator}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/customers"
          component={Customers}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/operator/profile"
          component={Profile}
          auth={auth}
        ></PrivateRoute>
        {/* <PrivateRoute
          path="/operator/register"
          component={Register}
          auth={auth}
        ></PrivateRoute> */}
        <PrivateRoute
          path="/operator/scooterList"
          component={Scooters}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/operator/scooterList/:id"
          component={Scooters}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/operators/validate"
          component={Validate}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/operators/list"
          component={List}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/operators/requestScooter"
          component={RequestScooter}
          auth={auth}
        ></PrivateRoute>
        {/* <PrivateRoute
          path="/services"
          component={ServicesList}
          auth={auth}
        ></PrivateRoute> */}
        <PrivateRoute
          path="/bills/:id"
          component={BillsDetails}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute path="/bills" component={Bill} auth={auth}></PrivateRoute>
        <PrivateRoute
          path="/reports/details/:id"
          component={ReportDetails}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/trips"
          component={Report}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/analytics"
          component={Analytics}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/incidents/details/:id"
          component={IncidentDetails}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/incidents"
          component={Incidents}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/bonus"
          component={Bonus}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/payments"
          component={Payments}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/UpdateDataTechnician"
          component={TechnicianUpdate}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/UpdateDataOperatorUser"
          component={OperatorUserUpdate}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/technicians"
          component={Technicians}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/rates"
          component={Rates}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/operatorsUsers"
          component={OperatorUser}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/monthlyDetails"
          component={MonthlyDetails}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/services"
          component={Services}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/validations"
          component={Validations}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/cau"
          component={CauUsers}
          history={history}
          auth={auth}
        />
        <PrivateRoute
          path="/sac"
          component={SacUsers}
          history={history}
          auth={auth}
        />
        <PrivateRoute path="/help" component={Help} auth={auth}></PrivateRoute>
        <Route path="/" render={() => <Redirect to="/home" />} />
      </Switch>
    </Router>
  )
}

function mapState(state) {
  const { authenticationReducer } = state
  return { authenticationReducer }
}

const actionCreators = {
  checkToken: userActions.checkToken,
}

export default connect(mapState, actionCreators)(Root)
