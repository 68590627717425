import React from 'react'
import { connect } from 'react-redux'
import Dashboard from '../dashboard'
import UserView from './page'

class UserList extends React.Component {
  render() {
    return <Dashboard component={<UserView />} />
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  }
}

export default connect(mapStateToProps)(UserList)
