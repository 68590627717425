import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  requestBtn: {
    marginTop: '2%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputSection: {
    width: '70%',
    margin: 'auto',
  },
}))

export default useStyles
