import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import { formatDateToInsertIntoComponent } from '../../shared/formatDate'
const EditCustomerAssignationRange = ({ isOpen, onClose, data, onConfirm }) => {
  const { t } = useTranslation()
  const [since, setsince] = useState('')
  const [until, setuntil] = useState('')

  useEffect(() => {
    if (data) {
      const { canOperateEnd, canOperateStart } = data
      setsince(formatDateToInsertIntoComponent(canOperateStart))
      if (moment(data.canOperateEnd).format('DD/MM/YYYY') != '31/12/9999')
        setuntil(formatDateToInsertIntoComponent(canOperateEnd))
    }
  }, [data])

  const handleClose = () => {
    onClose()
  }

  const handleConfirm = () => {
    const body = { start: since, end: until }
    onConfirm(body)
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('customers.modals.editRangeDates.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('customers.modals.editRangeDates.description')}
          </DialogContentText>
          <Grid container component="main" spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label={t('customers.modals.editRangeDates.labelStart')}
                type="date"
                value={since}
                onChange={(e) => setsince(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label={t('customers.modals.editRangeDates.labelEnd')}
                type="date"
                value={until}
                onChange={(e) => setuntil(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('customers.modals.editRangeDates.closeBtn')}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {t('customers.modals.editRangeDates.confirmBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default EditCustomerAssignationRange
