import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mapBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  colorPicker: {
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
  },
}))

export default useStyles
