import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { operatorActions } from '../../../redux/actions/operator_action'
import ModalOperatorValidate from '../modal/modalOperatorValidate'

import DetailsOperator from '../details'
import EditOperators from './modal'

import DialogConfirmAction from '../../shared/modalConfirmAction'
import FormNewOperatorManager from './new/formNewOperatorManager'

import { useTranslation } from 'react-i18next'

import { isUserWithRole, rolesConstants } from '../../../helpers/roles'
import CustomMaterialTable from '../../shared/customMaterialTable'

const ValidateView = ({
  getAllToValidate,
  operatorReducer,
  getAll,
  validateOperator,
  getVehicleHomologationTypes,
}) => {
  const { t } = useTranslation()
  const [dataOperatorSelected, setdataOperatorSelected] = useState([])
  const [isModalOpen, setisModalOpen] = useState(false)
  const [isDialogConfirmOpen, setisDialogConfirmOpen] = useState(false)

  const [isDialogNewOperatorManagerOpen, setisDialogNewOperatorManagerOpen] =
    useState(false)
  const [isDialogOperatorDetailsOpen, setisDialogOperatorDetailsOpen] =
    useState(false)
  const [isDialogOperatorEditOpen, setisDialogOperatorEditOpen] =
    useState(false)

  const handleClickAction = (event, rowData) => {
    setdataOperatorSelected(rowData)

    rowData.canOperate ? setisDialogConfirmOpen(true) : setisModalOpen(true)
  }

  useEffect(() => {
    getVehicleHomologationTypes()
    getAll()
  }, [])

  const handleConfirmInvalidateOperator = (comment) => {
    const body = {
      sharingOperatorId: dataOperatorSelected.id,
      canOperate: false,
      cannotOperateReason: comment,
    }
    validateOperator(body)
  }

  const handleClickOpenOperatorDetails = (event, rowData) => {
    setdataOperatorSelected(rowData)
    setisDialogOperatorDetailsOpen(true)
  }
  const handleClickOpenEditOperator = (event, rowData) => {
    setdataOperatorSelected(rowData)
    setisDialogOperatorEditOpen(true)
  }

  const displayList = Array.isArray(operatorReducer.results)
    ? operatorReducer.results
    : [operatorReducer.results]

  let margin = 0
  const looparentId = (rowData) => {
    if (rowData.parentId) {
      margin += 10
      const parent = displayList.filter((o) => o.id === rowData.parentId)
      parent.length > 0 && looparentId(parent[0])
    }
  }

  const setPaddingCells = (rowData) => {
    margin = 0
    looparentId(rowData)

    return { marginLeft: `${margin}%` }
  }

  const findTypeOfOperator = (rowData) => {
    const { isPrivate, isFree } = rowData

    if (isPrivate && isFree) return 'Fleet'
    if (isPrivate && !isFree) return 'Share Fleet'
    if (!isPrivate && !isFree) return 'Public Fleet'
    if (!isPrivate && isFree) return '...'
  }

  return (
    <div className={'mainContainer'}>
      {operatorReducer.results && (
        <CustomMaterialTable
          data={displayList}
          isLoading={operatorReducer.loadingResults}
          saveFilters={true}
          columns={[
            {
              title: t('operator.validate.name'),
              field: 'name',
            },
            {
              title: t('operator.validate.exclusionTime'),
              field: 'inExclusionTime',
              render: (rowData) =>
                rowData.inExclusionTime
                  ? t('operator.profile.personalData.yes')
                  : t('operator.profile.personalData.no'),
            },
            { title: t('operator.validate.primaryColor'), field: 'colour1' },
            { title: t('operator.validate.secondaryColor'), field: 'colour2' },
            { title: t('operator.validate.iban'), field: 'iban' },
            { title: t('operator.validate.email'), field: 'managerEmail' },
            {
              title: t('operator.validate.canOperate'),
              field: 'canOperate',
              addlookup: true,
            },
            {
              title: t('operator.validate.type'),
              field: '',
              render: (rowData) => findTypeOfOperator(rowData),
              customFilterAndSearch: (term, rowData) =>
                findTypeOfOperator(rowData).includes(term),
            },
          ]}
          title={t('operator.validate.titleTableResults')}
          actions={[
            {
              icon: 'add',
              tooltip: t('Add operator manager'),
              isFreeAction: true,
              hidden: isUserWithRole([
                rolesConstants.SAC_USER,
                rolesConstants.CAU_USER,
              ]),
              onClick: (event) => setisDialogNewOperatorManagerOpen(true),
            },
            (rowData) => ({
              icon: `${
                !rowData.canOperate ? 'check' : 'remove'
              }_circle_outline`,
              tooltip: !rowData.canOperate
                ? t('operator.validate.actions.enableOperator')
                : t('operator.validate.actions.unableOperator'),
              onClick: (event, rowData) => handleClickAction(event, rowData),
              iconProps: {
                style: { color: rowData.canOperate ? 'black' : 'red' },
              },
              hidden: isUserWithRole([
                rolesConstants.SAC_USER,
                rolesConstants.CAU_USER,
              ]),
            }),
            (rowData) => ({
              icon: `info`,
              tooltip: t('operator.validate.actions.operatorDetails'),
              onClick: (event, rowData) =>
                handleClickOpenOperatorDetails(event, rowData),
              hidden: !rowData.canOperate,
            }),
            (rowData) => ({
              icon: `edit`,
              tooltip: t('operator.validate.actions.editOperatorData'),
              onClick: (event, rowData) =>
                handleClickOpenEditOperator(event, rowData),
              hidden:
                !rowData.canOperate ||
                isUserWithRole([
                  rolesConstants.SAC_USER,
                  rolesConstants.CAU_USER,
                ]),
            }),
          ]}
          parentChildData={(row, rows) =>
            rows.find((a) => a.id === row.parentId)
          }
        />
      )}
      <ModalOperatorValidate
        data={dataOperatorSelected}
        isOpen={isModalOpen}
        onClose={() => {
          setisModalOpen(false)
        }}
      />

      <DialogConfirmAction
        hasNote={true}
        isOpen={isDialogConfirmOpen}
        onClose={() => setisDialogConfirmOpen(false)}
        onConfirm={handleConfirmInvalidateOperator}
        data={{
          header: t('operator.validate.confirmAction.header'),
          body: t('operator.validate.confirmAction.body'),
        }}
      />

      <DetailsOperator
        isOpen={isDialogOperatorDetailsOpen}
        onClose={() => setisDialogOperatorDetailsOpen(false)}
        data={dataOperatorSelected}
      />
      <EditOperators
        isOpen={isDialogOperatorEditOpen}
        onClose={() => setisDialogOperatorEditOpen(false)}
        data={dataOperatorSelected}
      />
      <FormNewOperatorManager
        isOpen={isDialogNewOperatorManagerOpen}
        onClose={() => setisDialogNewOperatorManagerOpen(false)}
      />
    </div>
  )
}

function mapState(state) {
  const { operatorReducer } = state
  return { operatorReducer }
}

const actionCreators = {
  getAllToValidate: operatorActions.getAllToValidate,
  getAll: operatorActions.getAll,
  validateOperator: operatorActions.validateOperator,
  getVehicleHomologationTypes: operatorActions.getVehicleHomologationTypes,
}

export default connect(mapState, actionCreators)(ValidateView)
