import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  endRow: {
    marginBottom: 15,
  },
  title: {
    padding: 25,
  },
  label: {
    paddingBottom: 5,
  },
  row: {},
  vehicleDataHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowContainer: {
    alignItems: 'center',
  },
  gridMainContainer: {
    padding: '0% 3%',
  },
  dividerPadding: {
    padding: '2% 0%',
  },
}))

export default useStyles
