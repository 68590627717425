import NewReleasesIcon from '@material-ui/icons/NewReleases'
import React, { useEffect, useState } from 'react'

import { connect, useDispatch } from 'react-redux'

import { customerActions } from '../../../redux/actions/customers_actions'
import { documentsActions } from '../../../redux/actions/documents_action'

import { useTranslation } from 'react-i18next'

import CustomerDocumentationDialog from '../../customers/documentation/index'

import { Container, Tooltip } from '@material-ui/core'
import moment from 'moment'
import CustomMaterialTable from '../../shared/customMaterialTable/index'

const ClientPendingValidate = ({
  getPendingToValidate,
  getDrivingLicenses,
  customerReducer,
  documentsReducer,
  getIdDocTypes,
  getAllCountries,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    getPendingToValidate()
    getDrivingLicenses()
    getIdDocTypes()
    getAllCountries()

    return () => dispatch(customerActions.closeValidationsModal())
  }, [])

  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [isValidateDialogOpen, setisValidateDialogOpen] = useState(false)

  const handleOpenValidateDocuments = (event, rowData) => {
    setSelectedCustomer(rowData)
    dispatch(customerActions.openValidationsModal())
  }

  const handleClose = () => {
    setisValidateDialogOpen(false)
    getPendingToValidate()
  }
  return (
    <Container maxWidth="xl">
      <CustomMaterialTable
        title={t('customers.page.titleValidateList')}
        saveFilters={true}
        isLoading={customerReducer.loadingResults}
        tab={'operatorsValidate'}
        columns={[
          {
            title: t('customers.page.columns.dateRegister'),
            field: 'created',
            render: (rowData) => {
              return rowData.created
                ? moment(rowData.created).format('DD/MM/YYYY HH:mm')
                : ''
            },
          },
          {
            title: t('customers.page.columns.firstName'),
            field: 'firstName',
            render: (item) =>
              item.documentsUploaded ? (
                <React.Fragment>
                  <Tooltip arrow title={t('home.pageManager.hasDocuments')}>
                    <NewReleasesIcon
                      color="primary"
                      style={{
                        fontSize: 17,
                        marginRight: 2,
                      }}
                    />
                  </Tooltip>
                  {item.firstName}
                </React.Fragment>
              ) : (
                item.firstName
              ),
          },
          {
            title: t('customers.page.columns.lastName'),
            field: 'lastName',
          },
          {
            title: t('customers.page.columns.email'),
            field: 'email',
          },
          {
            title: t('customers.page.columns.mobilePhone'),
            field: 'mobilePhone',
          },
          {
            title: t('customers.page.columns.city'),
            field: 'city',
          },
          {
            title: t('customers.page.columns.motive'),
            field: 'cannotOperateReason',
          },
        ]}
        data={customerReducer.pendingValidateResults.filter(
          (c) => !c.documentsVerified,
        )}
        actions={[
          (rowData) => ({
            icon: 'attach_file',
            tooltip: t('customers.page.actions.validateDocuments'),
            onClick: (event, rowData) => {
              handleOpenValidateDocuments(event, rowData)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            },
            iconProps: {
              style: {
                color: 'red',
              },
            },
          }),
        ]}
      />
      {customerReducer.validationModalOpened && (
        <CustomerDocumentationDialog
          isOpen={customerReducer.validationModalOpened}
          customerId={selectedCustomer.id}
          from="Validate"
          onClose={() => dispatch(customerActions.closeValidationsModal())}
        />
      )}
    </Container>
  )
}

function mapState(state) {
  const { customerReducer, documentsReducer } = state
  return { customerReducer, documentsReducer }
}

const actionCreators = {
  getPendingToValidate: customerActions.getPendingToValidate,
  getDrivingLicenses: documentsActions.getDrivingLicenses,
  getIdDocTypes: documentsActions.getIdDocTypes,
  getAllCountries: customerActions.getAllCountries,
}

export default connect(mapState, actionCreators)(ClientPendingValidate)
