import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'

import { useTranslation } from 'react-i18next'
import { operatorActions } from '../../../redux/actions/operator_action'
import { scooterActions } from '../../../redux/actions/scooter_actions'
import { tariffsActions } from '../../../redux/actions/tariffs_action'

import { connect } from 'react-redux'

import MapsGeofence from '../../shared/geofence/mapsGeofence'
import OperatorDataToValidate from './dataToValidateInsideModalOperatorValidate/operatorDataToValidate'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import DialogConfirmAction from '../../shared/modalConfirmAction'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  bodyContainer: {
    padding: '3%',
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentSection: {
    padding: '2%',
  },
  formDontValidate: {
    width: '60%',
    margin: 'auto',
  },
  commentBoxDontValidate: {
    width: '100%',
  },
  buttonSubmitCommentDontValidate: {
    // marginTop: '2%',
    // float: "right"
  },
  operatorAreasSection: {
    padding: '8px 16px 16px',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ModalOperatorValidate = ({
  data,
  isOpen,
  onClose,
  validateOperator,
  getAllScootersByOperatorId,
  scooterReducer,
  getByOperatorId,

  fetchPendingValidate = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }
  useEffect(() => {
    if (data.hasOwnProperty('id')) {
      getAllScootersByOperatorId(data.id)
      getByOperatorId(data.id)
    }
  }, [data])

  const [validateChecks, setvalidateChecks] = useState({
    section1: false,
    section2: false,
  })
  const [allCheckboxAreTrue, setallCheckboxAreTrue] = useState(false)
  const [isFormDontValidatedOpen, setisFormDontValidatedOpen] = useState(false)

  useEffect(() => {
    if (validateChecks.section1 && validateChecks.section2) {
      setallCheckboxAreTrue(true)
    } else {
      setallCheckboxAreTrue(false)
    }
  }, [validateChecks])
  const handlecheckboxClick = (e) => {
    e.stopPropagation()
    setvalidateChecks({ ...validateChecks, [e.target.name]: e.target.checked })
  }

  const handleSubmitValidate = () => {
    const body = {
      sharingOperatorId: data.id,
      canOperate: true,
      cannotOperateReason: '',
    }
    validateOperator(body, fetchPendingValidate)
    setallCheckboxAreTrue(false)
    onClose()
  }
  const handleConfirmDontValidateOperator = (comment) => {
    const body = {
      sharingOperatorId: data.id,
      canOperate: false,
      cannotOperateReason: comment,
    }

    validateOperator(body)
    onClose()
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('operator.modalOperatorValidate.toolbar.validateText')}:{' '}
              {data.name}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => setisFormDontValidatedOpen((prev) => !prev)}
            >
              {t('operator.modalOperatorValidate.toolbar.invalidatebtn')}
            </Button>
            <Button
              autoFocus
              color="inherit"
              onClick={handleSubmitValidate}
              disabled={!allCheckboxAreTrue}
            >
              {t('operator.modalOperatorValidate.toolbar.validatebtn')}
            </Button>
          </Toolbar>
        </AppBar>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <FormControlLabel
              aria-label="Acknowledge"
              name="section1"
              onClick={(e) => handlecheckboxClick(e)}
              control={<Checkbox />}
              label={t(
                'operator.modalOperatorValidate.acordionText.operatorData',
              )}
            />
          </AccordionSummary>
          <AccordionDetails>
            <OperatorDataToValidate data={data ? data : {}} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions2-content"
            id="additional-actions2-header"
          >
            <FormControlLabel
              aria-label="Acknowledge"
              name="section2"
              onClick={(e) => handlecheckboxClick(e)}
              control={<Checkbox />}
              label={t(
                'operator.modalOperatorValidate.acordionText.operatorAreas',
              )}
            />
          </AccordionSummary>
          <div className={classes.operatorAreasSection}>
            <MapsGeofence
              toolsAvaiable={false}
              data={data}
              displayOnly={true}
            />
          </div>
        </Accordion>
      </Dialog>
      <DialogConfirmAction
        hasNote={true}
        isOpen={isFormDontValidatedOpen}
        onClose={() => setisFormDontValidatedOpen(false)}
        onConfirm={handleConfirmDontValidateOperator}
        data={{
          header: t('operator.modalOperatorValidate.confirmAction.header'),
          body: t('operator.modalOperatorValidate.confirmAction.body'),
        }}
      />
    </div>
  )
}
function mapState(state) {
  const { operatorReducer, scooterReducer, tariffsReducer } = state
  return { operatorReducer, scooterReducer, tariffsReducer }
}

const actionCreators = {
  validateOperator: operatorActions.validateOperator,
  getAllScootersByOperatorId: scooterActions.getAll,
  getByOperatorId: tariffsActions.getByOperatorId,
}

export default connect(mapState, actionCreators)(ModalOperatorValidate)
