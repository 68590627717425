import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { scooterActions } from '../../../../redux/actions/scooter_actions'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const ScootersToValidate = ({
  getAll,
  operatorId,
  scooterReducer,
  displayOnly = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    getAll(operatorId)
  }, [])

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              {t('operator.scooterToValidate.plate')}
            </TableCell>
            <TableCell align="center">
              {t('operator.scooterToValidate.model')}
            </TableCell>
            <TableCell align="center">
              {t('operator.scooterToValidate.helmetIncluded')}
            </TableCell>
            <TableCell align="center">
              {t('operator.scooterToValidate.imei')}
            </TableCell>
            <TableCell align="center">
              {t('operator.scooterToValidate.vin')}
            </TableCell>
            <TableCell align="center">
              {t('operator.scooterToValidate.outOfService')}
            </TableCell>
            <TableCell align="center">
              {t('operator.scooterToValidate.bastidor')}
            </TableCell>
            {/* { displayOnly && <TableCell align="center">Más Información</TableCell> } */}
          </TableRow>
        </TableHead>
        <TableBody>
          {scooterReducer.results.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="center">{row.plate}</TableCell>
              <TableCell align="center">{row.model}</TableCell>
              <TableCell align="center">
                {row.sharing && row.sharing.isHelmetIncluded
                  ? t('operator.scooterToValidate.isHelmetIncludedTrue')
                  : t('operator.scooterToValidate.isHelmetIncludeFalse')}
              </TableCell>
              <TableCell align="center">{row.imei}</TableCell>
              <TableCell align="center">{row.frameNo}</TableCell>
              <TableCell align="center">
                {row.sharing && row.sharing.outOfService
                  ? t('operator.scooterToValidate.isoutOfServiceTrue')
                  : t('operator.scooterToValidate.isoutOfServiceFalse')}
              </TableCell>
              <TableCell align="center">{row.bastidorNum}</TableCell>
              {/* { displayOnly && <TableCell align="center">
                      <IconButton aria-label="Más Información" className={classes.margin} onClick={()=>history.push(`/operator/scooterList/${row.id}`)}>
                        <InfoIcon />
                      </IconButton>
                    </TableCell> } */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

const actionCreators = {
  getAll: scooterActions.getAll,
}

export default connect(mapState, actionCreators)(ScootersToValidate)
