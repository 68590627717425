import React from 'react'
import Dashboard from '../dashboard'
import TechniciansView from './page'

class Technicians extends React.Component {
  render() {
    return <Dashboard component={<TechniciansView />} />
  }
}

export default Technicians
