import i18next from 'i18next'
import { parsePhoneNumber } from 'libphonenumber-js'
import moment from 'moment'

export const validations = {
  required,
  minLength,
  maxLength,
  alMostOne,
  compareDate,
  checkExtensionFile,
  validateEmail,
  validateLink,
  validatePromoCode,
  validatePercentage,
  validatePhone,
  expiredDate,
  validateHigher,
  validateHigherInt,
  validatePrefix,
}

let EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

let LINK_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%.\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%\+.~#?&\/=]*)$/

let PROMOCODE_REGEX = /^[0-9]{4,12}$/

let IN_RANGE_FROM_1_TO_100_REGEX = /^[1-9][0-9]?$|^100$/

function validateEmail(email) {
  const regex = EMAIL_REGEX
  let result = regex.test(String(email.trim()).toLowerCase())
  return result
    ? { result, message: '' }
    : { result, message: i18next.t('validations.register.email') }
}

function required(value = '', callback = null, message = null) {
  if (value !== null && value.length === 0) {
    return {
      result: false,
      message: i18next.t(message || 'validations.required'),
    }
  } else {
    if (callback) {
      return callback
    }
    return { result: true, message: '' }
  }
}

function checkExtensionFile(fileName, extension, callback = null) {
  var ext = fileName.split('.')
  if (extension.toLowerCase() !== ext[ext.length - 1].toLowerCase()) {
    return {
      result: false,
      message: i18next.t('validations.checkExtensionFile'),
    }
  } else {
    if (callback) {
      return callback
    }
    return { result: true, message: '' }
  }
}

function compareDate(date1, date2) {
  var d1 = new Date(date1)
  var d2 = new Date(date2)
  if (d2.getTime() <= d1.getTime()) {
    return { result: false, message: i18next.t('validations.comparateDate') }
  } else {
    return { result: true, message: '' }
  }
}

function alMostOne(array) {
  var result = false
  var message = i18next.t('validations.alMostOne')
  array = array.filter(function (el) {
    return el !== ''
  })
  if (array.length === 0) {
    return { result, message }
  } else {
    return { result: true, message: '' }
  }
}

function minLength(value, fieldName, min) {
  if (value.length < min) {
    return {
      result: false,
      message: 'El minimo para ' + fieldName + ' es ' + min,
    }
  } else {
    return { result: true, message: '' }
  }
}

function maxLength(value, fieldName, max) {
  if (value.length > max) {
    return {
      result: false,
      message: 'El maximo para ' + fieldName + ' es ' + max,
    }
  } else {
    return { result: true, message: '' }
  }
}

function validatePromoCode(value) {
  const regex = PROMOCODE_REGEX
  let result = !(value === '' || regex.test(value))

  return result
    ? { result: false, message: i18next.t('validations.promoCode') }
    : { result: true, message: '' }
}

function validateHigher(from = 0, value = null) {
  let result = !value || value >= 0
  return result
    ? { result: true, message: '' }
    : { result: false, message: 'Numero invalido' }
}

function validateHigherInt(from = 0, value = null) {
  console.log(
    'test =>',
    !value || String(value).includes('.') || String(value).includes(','),
  )
  if (String(value).includes('.') || String(value).includes(','))
    return { result: false, message: i18next.t('validations.validInt') }
  let result = value >= 0 || !value
  return result
    ? { result: true, message: '' }
    : { result: false, message: i18next.t('validations.validInt') }
}

function validatePercentage(value) {
  return IN_RANGE_FROM_1_TO_100_REGEX.test(value)
    ? { result: true, message: '' }
    : { result: false, message: i18next.t('validations.inRange') }
}

function validatePhone(phoneNumber = '', countryCode = '') {
  let output = {
    result: false,
    message: i18next.t('validations.invalidPhone'),
  }

  try {
    const pn = parsePhoneNumber(phoneNumber, countryCode)
    output = pn.isValid()
      ? { result: true, message: '' }
      : { result: false, message: i18next.t('validations.invalidPhone') }
  } catch {
    return output
  }
  return output
}

function validateLink(value) {
  return LINK_REGEX.test(value.trim())
}

function expiredDate(date, message) {
  let validation = { result: true, message: '' }
  if (moment(date).isBefore(moment()) || !date || date === 'Invalid date') {
    validation = { result: false, message: i18next.t(message) }
  }
  return validation
}

function validatePrefix(phone = '') {
  return phone.charAt(0).includes('+')
    ? { result: true, message: '' }
    : { result: false, message: i18next.t('validations.invalidPrefix') }
}
