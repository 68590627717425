import { Grid, IconButton, TextField } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import i18next from 'i18next'
import React from 'react'

const RatesByHours = ({
  item,
  errors,
  displayOnly,
  setRateDay,
  setErrors,
  index,
  length,
  add,
  isFree,
  remove,
}) => {
  const validateValue = (value) => (isNaN(value) ? '' : value)

  console.log('errors =>', errors)

  return (
    <React.Fragment>
      <Grid item xs={2}>
        <TextField
          label={i18next.t('operator.profile.rates.byHour.hours')}
          fullWidth
          value={validateValue(item.hours)}
          InputProps={{ readOnly: displayOnly }}
          error={errors.rateHour[index]?.hours.message === '' ? false : true}
          helperText={errors.rateHour[index]?.hours.message}
          name={'hours_' + index}
          onChange={(e) => {
            setRateDay(e, index)
            // errors.rateHour[index].hours.message = ""
            setErrors(errors)
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={i18next.t('operator.profile.rates.byHour.pricePerHour')}
          fullWidth
          value={validateValue(item.pricePerHour)}
          InputProps={{ readOnly: displayOnly }}
          type="number"
          error={
            errors.rateHour[index]?.pricePerHour.message.length === 0
              ? false
              : true
          }
          helperText={errors.rateHour[index]?.pricePerHour.message}
          name={'pricePerHour_' + index}
          onChange={(e) => {
            setRateDay(e, index)
            // errors.rateHour[index]?.pricePerHour.message = ""
            // setErrors(errors)
          }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isFree}
        />
      </Grid>
      <Grid
        item
        xs={1}
        sm={1}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!displayOnly &&
          (index + 1 == length ? (
            <React.Fragment>
              {length > 1 && (
                <IconButton onClick={() => remove(index)}>
                  <Remove />
                </IconButton>
              )}
              <IconButton onClick={() => add()}>
                <Add />
              </IconButton>
            </React.Fragment>
          ) : (
            <>
              <IconButton onClick={() => remove(index)}>
                <Remove />
              </IconButton>
            </>
          ))}
      </Grid>
      <Grid item xs={2} sm={2}></Grid>
    </React.Fragment>
  )
}

export default RatesByHours
