import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sacActions } from '../../../redux/actions/sac_actions'

export const useManageSacUsers = () => {
  const dispatch = useDispatch()
  const { users, loadingUsers, loadingCreate, loadingUpdate } = useSelector(
    (state) => state.sacReducer,
  )

  useEffect(() => {
    dispatch(sacActions.getAll())
  }, [])

  return {
    users,
    loading: loadingUsers,
    updating: loadingUpdate,
    creating: loadingCreate,
  }
}
