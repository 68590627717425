import React from 'react'
import Dashboard from '../dashboard'
import OperatorUserView from './page'

class OperatorUser extends React.Component {
  render() {
    return <Dashboard component={<OperatorUserView />} />
  }
}

export default OperatorUser
