import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { customerActions } from '../../redux/actions/customers_actions'
import Dashboard from '../dashboard'
import Spinner from '../shared/Spinner'
import ValidationsView from './page'

const Validations = ({
  getWeekDays,
  customerReducer,
  setCalendar,
  getCalendar,
  getCustomerValidationRequests,
}) => {
  useEffect(() => {
    getCalendar()
    getCustomerValidationRequests()
    getWeekDays()
  }, [])

  return (
    <Dashboard
      component={
        !customerReducer.loadingResults && !customerReducer.loadingCalendar ? (
          <ValidationsView
            customerReducer={customerReducer}
            setCalendar={setCalendar}
            shiftDays={customerReducer.calendarValidation}
          />
        ) : (
          <Spinner
            loading={
              customerReducer.loadingResults || customerReducer.loadingCalendar
            }
          />
        )
      }
    />
  )
}

function mapState(state) {
  const { customerReducer } = state
  return { customerReducer }
}

const actionCreators = {
  getWeekDays: customerActions.getWeekDays,
  setCalendar: customerActions.setCalendar,
  getCalendar: customerActions.getCalendar,
  getCustomerValidationRequests: customerActions.getCustomerValidationRequests,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Validations)
