import i18next from 'i18next'
import { cauConstants } from '../../constants/cau_constants'
import { cauService } from '../../services/cauService'
import { alertActions } from './alert_actions'

export const cauActions = {
  getAll,
  create,
  update,
  remove,
  getById,
  clearUserData,
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    cauService.getAll().then(
      (users) => {
        dispatch(success(users))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: cauConstants.GET_CAU_USERS_REQUEST }
  }

  function success(users) {
    return { type: cauConstants.GET_CAU_USERS_SUCCESS, users }
  }

  function failure(error) {
    return { type: cauConstants.GET_CAU_USERS_FAILURE, error }
  }
}

function create(user, onSuccess) {
  return (dispatch) => {
    dispatch(request())
    cauService.create(user).then(
      (response) => {
        dispatch(success())
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.userGenerics.createSuccess'),
          ),
        )
        onSuccess()
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.userGenerics.createError')),
        )
      },
    )
  }

  function request() {
    return { type: cauConstants.CAU_CREATE_USER_REQUEST }
  }

  function failure(error) {
    return { type: cauConstants.CAU_CREATE_USER_FAILURE, error }
  }

  function success() {
    return { type: cauConstants.CAU_CREATE_USER_SUCCESS }
  }
}

function update(user, onSuccess) {
  const { id } = user
  delete user.id

  return (dispatch) => {
    dispatch(request())
    cauService.update(id, user).then(
      (response) => {
        dispatch(success())
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.userGenerics.updateSuccess'),
          ),
        )
        onSuccess()
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.userGenerics.updateError')),
        )
      },
    )
  }

  function request() {
    return { type: cauConstants.CAU_UPDATE_USER_REQUEST }
  }

  function failure(error) {
    return { type: cauConstants.CAU_UPDATE_USER_FAILURE, error }
  }

  function success() {
    return { type: cauConstants.CAU_UPDATE_USER_SUCCESS }
  }
}

function remove(id) {
  return (dispatch) => {
    cauService.remove(id).then(
      (_) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.userGenerics.deleteSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.userGenerics.deleteError')),
        )
      },
    )
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request())
    cauService.getById(id).then(
      (user) => {
        dispatch(success(user))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: cauConstants.GET_CAU_USER_BY_ID_REQUEST }
  }

  function success(user) {
    return { type: cauConstants.GET_CAU_USER_BY_ID_SUCCESS, user }
  }

  function failure(error) {
    return { type: cauConstants.GET_CAU_USER_BY_ID_FAILURE, error }
  }
}

function clearUserData() {
  return (dispatch) => dispatch({ type: cauConstants.CLEAR_USER_DATA })
}
