import React from 'react'
import Dashboard from '../../dashboard'
import Page from './technician'

class TechnicianUpdate extends React.Component {
  render() {
    return <Dashboard component={<Page />} />
  }
}

export default TechnicianUpdate
