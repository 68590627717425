import React from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import Dashboard from '../dashboard'
import SacUsersPage from './page'

const SacUsers = (props) => {
  return <Dashboard component={<SacUsersPage {...props} />} />
}

export default compose(withTranslation('common'))(SacUsers)
