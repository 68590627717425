import React, { useEffect, useState } from 'react'
import useStyles from './styles'

import ModalCreateBonus from './modal/ModalCreateBonus'
import ModalManagerBonus from './modal/ModalManagerBonus'

import { connect } from 'react-redux'
import { bonusActions } from '../../redux/actions/bonus_actions'

import { useTranslation } from 'react-i18next'

import { formatDateToDisplay } from '../shared/formatDate'

import { isManager, isManagerReadOnly } from '../../helpers/roles'
import CustomMaterialTable from '../shared/customMaterialTable'

const BonusView = ({ bonusReducer, getAll }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    getAll()
  }, [])

  const [dataOperatorSelected, setdataOperatorSelected] = useState([])
  const [isModalOperatorOpen, setisModalOperatorOpen] = useState(false)
  const [isModalCreateBonusOpen, setisModalCreateBonusOpen] = useState(false)

  const handleClickAction = (event, rowData) => {
    setdataOperatorSelected(rowData)
    setisModalOperatorOpen(true)
  }

  return (
    <div>
      <CustomMaterialTable
        saveFilters={true}
        isLoading={bonusReducer.loadingResults}
        addSummaryRow={true}
        data={bonusReducer.results}
        columns={[
          { title: t('bonus.page.name'), field: 'name' },
          { title: t('bonus.page.price'), field: 'price' },
          {
            title: t('bonus.page.validatedSince'),
            field: 'validSince',
            render: (rowData) => formatDateToDisplay(rowData.validSince),
          },
          {
            title: t('bonus.page.validatedUntil'),
            field: 'validUntil',
            render: (rowData) => formatDateToDisplay(rowData.validUntil),
          },
          { title: t('bonus.page.offer'), field: 'ofert' },
          { title: t('bonus.page.textLabel'), field: 'text' },
          // { title: t("bonus.page.minutes"), field: "minutes" },
        ]}
        title={t('bonus.page.titleTableResults')}
        actions={[
          {
            icon: 'view_list',
            tooltip: t('bonus.page.tooltipActionText'),
            onClick: (event, rowData) => handleClickAction(event, rowData),
            hidden: !isManager() && !isManagerReadOnly(),
          },
          {
            icon: 'add',
            isFreeAction: true,
            tooltip: 'add',
            hidden: !isManager(),
            onClick: (event, rowData) => setisModalCreateBonusOpen(true),
          },
        ]}
        // components={{
        //   Toolbar: (props) => (
        //     <div>
        //       <MTableToolbar {...props} />
        //       <div
        //         style={{
        //           padding: "0px 10px",
        //           display: "flex",
        //           justifyContent: "flex-end",
        //         }}
        //       >
        //         <Button
        //           variant="contained"
        //           color="primary"
        //           onClick={() => setisModalCreateBonusOpen(true)}
        //         >
        //           {t("bonus.page.createBtn")}
        //         </Button>
        //       </div>
        //     </div>
        //   ),
        // }}
      />
      <ModalCreateBonus
        isOpen={isModalCreateBonusOpen}
        onClose={() => {
          setisModalCreateBonusOpen(false)
        }}
      />
      <ModalManagerBonus
        data={dataOperatorSelected}
        isOpen={isModalOperatorOpen}
        onClose={() => {
          setisModalOperatorOpen(false)
        }}
      />
    </div>
  )
}

function mapState(state) {
  const { bonusReducer } = state
  return { bonusReducer }
}

const actionCreators = {
  getAll: bonusActions.getAll,
}

export default connect(mapState, actionCreators)(BonusView)
