import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { blue } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useTranslation } from 'react-i18next'
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  typogrStyle: {
    padding: '4%',
  },
})

const ActiveTicketsFromSelectedScooter = ({
  isOpen,
  onClose,
  data,
  onSelectTicket,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleListItemClick = (value) => {
    onClose()
    onSelectTicket(value)
  }

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
      <Typography className={classes.typogrStyle}>
        {t('incidents.dialogs.ticketsList.title')}
      </Typography>

      {data.length > 0 ? (
        <List>
          {data.map((d) => (
            <ListItem button onClick={() => handleListItemClick(d)} key={d.id}>
              <ListItemText primary={d.title} />
            </ListItem>
          ))}
        </List>
      ) : (
        <p className={classes.typogrStyle}>No hay tickets</p>
      )}
    </Dialog>
  )
}

export default ActiveTicketsFromSelectedScooter
