import { notificationsConstant } from '../../constants/notifications_constant'
import { notificationsService } from '../../services/notificationsService'

import { alertActions } from './alert_actions'

import i18next from 'i18next'
import { isManager } from '../../helpers/roles'

export const notificationsActions = {
  insert,
  getAll,
  update,
}

function update(body) {
  const operatorId = body.sharingOperatorId
  return (dispatch) => {
    notificationsService.update(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.notifications.updateSuccess'),
          ),
        )
        dispatch(getAll(!isManager() && operatorId))
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.notifications.updateError')),
        )
      },
    )
  }
}

function insert(body) {
  const operatorId = body.sharingOperatorId
  return (dispatch) => {
    notificationsService.insert(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.notifications.insertSuccess'),
          ),
        )
        dispatch(getAll(!isManager() && operatorId))
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.notifications.insertError')),
        )
      },
    )
  }
}

function getAll(operatorId = null) {
  return (dispatch) => {
    dispatch(request())
    notificationsService.getAll(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: notificationsConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: notificationsConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: notificationsConstant.GET_ALL_FAILURE, error }
  }
}
