import { ListItem } from '@material-ui/core'
import React from 'react'

const CustomDivider = () => {
  return (
    <ListItem>
      <div
        style={{ background: ' #e41622', height: '1.5px', width: '100%' }}
      ></div>
    </ListItem>
  )
}

export default CustomDivider
