import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { operatorActions } from '../../../redux/actions/operator_action'
import ModalOperator from '../modal/modalOperator'
import TableResults from '../tableResults/tableResults'

const ProfileView = ({ getAll, operatorReducer }) => {
  const { t } = useTranslation()

  const [dataOperatorSelected, setdataOperatorSelected] = useState([])
  const [isModalOpen, setisModalOpen] = useState(false)

  const handleClickAction = (event, rowData) => {
    setdataOperatorSelected(rowData)
    setisModalOpen(true)
  }

  useEffect(() => {
    getAll()
  }, [])

  return (
    <div className={'mainContainer'}>
      <TableResults
        data={operatorReducer.results}
        columns={[
          { title: t('operator.list.name'), field: 'name' },
          { title: t('operator.list.nif'), field: 'nif' },
          { title: t('operator.list.cc'), field: 'cc' },
          { title: t('operator.list.logo'), field: 'logo' },
          { title: t('operator.list.operatorType'), field: 'operatorType' },
          { title: t('operator.list.tariff'), field: 'rates' },
        ]}
        title={t('operator.list.titleTableResults')}
        actionsColumnIndex={7}
        actions={[
          {
            icon: 'view_list',
            tooltip: t('operator.list.textActionTooltip'),
            onClick: (event, rowData) => handleClickAction(event, rowData),
          },
        ]}
      />
      <ModalOperator
        data={dataOperatorSelected}
        isOpen={isModalOpen}
        onClose={() => {
          setisModalOpen(false)
        }}
      />
    </div>
  )
}

function mapState(state) {
  const { operatorReducer } = state
  return { operatorReducer }
}

const actionCreators = {
  getAll: operatorActions.getAll,
}

export default connect(mapState, actionCreators)(ProfileView)
