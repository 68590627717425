import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import MapDetailsTrips from './map'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    // alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  columnEdit: {
    flexBasis: '66.66%',
    padding: '1%',
  },
  descriptionData: {},
  descrptionHeader: {
    padding: '0px 16px',
  },
}))

const ReportDetailsView = ({ data }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  // const {
  //   co2Saving,
  //   distance,
  //   endBattery,
  //   endTimestamp,
  //   fromDescription,
  //   id,
  //   kml,
  //   points,
  //   reports,
  //   speedAvg,
  //   speedMax,
  //   startBattery,
  //   startTimestamp,
  //   toDescription,
  // } = data
  const {
    id,
    startTimestamp,
    endTimestamp,
    startBattery,
    endBattery,
    distance,
    speedMax,
    speedAvg,
    co2Saving,
    fromDescription,
    toDescription,
    points,
    reports,
  } = data

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>
              {t('reports.sections.details.items.index.trip')}{' '}
              {`${startTimestamp.split('T')[0]}`}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              {t('reports.sections.details.items.index.selectDestination')}
            </Typography>
          </div>
        </AccordionSummary>
        <div className={classes.descrptionHeader}>
          <p>{`${t(
            'reports.sections.details.items.index.from',
          )} ${fromDescription}`}</p>
          <p>{`${t(
            'reports.sections.details.items.index.to',
          )} ${toDescription}`}</p>
        </div>
        <AccordionDetails className={classes.details}>
          <div className={classes.columnEdit}>
            <MapDetailsTrips points={points} />
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <p>{`${t(
              'reports.sections.details.items.index.startBattery',
            )}: ${startBattery}%`}</p>
            <p>{`${t(
              'reports.sections.details.items.index.endBattery',
            )}: ${endBattery}%`}</p>
            <p>{`${t('reports.sections.details.items.index.co2Saving')}: ${
              Math.round(co2Saving * 100) / 100
            }%`}</p>
            <p>{`${t(
              'reports.sections.details.items.index.speedAvg',
            )}: ${speedAvg}km/h`}</p>
            <p>{`${t(
              'reports.sections.details.items.index.speedMax',
            )}: ${speedMax}km/h`}</p>
            <p>{`${t(
              'reports.sections.details.items.index.distance',
            )}: ${distance}km`}</p>
          </div>
        </AccordionDetails>
        <Divider />
      </Accordion>
    </div>
  )
}

function mapState(state) {
  const { scooterReducer, operatorReducer } = state
  return { scooterReducer, operatorReducer }
}

export default connect(mapState, null)(ReportDetailsView)
