import { Tooltip, Typography } from '@material-ui/core'
import React from 'react'

export const displayWithTooltip = (column) => {
  if (!column) return

  return column.length >= 15 ? (
    <Tooltip title={column}>
      <Typography variant="inherit">
        {column.substring(0, 15) + '...'}
      </Typography>
    </Tooltip>
  ) : (
    column
  )
}
