import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import i18next from 'i18next'
import React, { useState } from 'react'
import { validations } from '../../../helpers/validation'

const ResetPassword = ({ resetPassword, open, onClose }) => {
  const [email, setEmail] = useState('')

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={() => {
        onClose()
        setEmail('')
      }}
    >
      <DialogTitle>
        {i18next.t('login.index.forgottenPasswordTitle')}
      </DialogTitle>
      <DialogContent>
        <TextField
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={email == '' || !validations.validateEmail(email).result}
          onClick={() => resetPassword(email, onClose)}
        >
          {i18next.t('login.index.forgottenPasswordButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResetPassword
