import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  '.MuiToolbar-gutters': {
    paddingLeft: 0,
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.6,
    fontFamily: 'Montserrat !important',
  },
  mainContainer: {
    overflow: 'hidden',
    padding: '2%',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listSection: {
    right: 0,
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submit: {
    marginTop: '2%',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  btnCreateDiscount: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    margin: '2% 0%',
  },
}))

export default useStyles
