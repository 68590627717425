import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { scooterActions } from '../../../redux/actions/scooter_actions'
import Dashboard from '../../dashboard'
import Spinner from '../../shared/Spinner'
import DetailsViewScooter from './page'

class ScooterDetails extends React.Component {
  componentDidMount() {
    const { idScooter } = this.props
    this.props.getTrips(idScooter)
    this.props.getDynamyc(idScooter)
  }

  componentWillUnmount() {
    this.props.cleanTripsView()
  }

  render() {
    const { scooterReducer } = this.props
    return (
      <Dashboard
        component={
          scooterReducer.trips != null && scooterReducer.dynamic != null ? (
            <DetailsViewScooter {...this.props} />
          ) : (
            <Spinner loading={scooterReducer.loadingResults} />
          )
        }
      />
    )
  }
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

const actionCreators = {
  getTrips: scooterActions.getTripsByScooterId,
  getTripDetail: scooterActions.getTripDetails,
  getDynamyc: scooterActions.getDynamicsById,
  updateRestricted: scooterActions.updateRestricted,
  cleanTripsView: scooterActions.cleanTripsView,
}

function Hook(props) {
  const { id } = useParams()
  return <ScooterDetails {...props} idScooter={id} />
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Hook)
