import React, { Fragment, useState } from 'react'
import useStyles from './styles'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

import { operatorActions } from '../../../redux/actions/operator_action'
import { scooterActions } from '../../../redux/actions/scooter_actions'
import { tariffsActions } from '../../../redux/actions/tariffs_action'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { useTranslation } from 'react-i18next'

import { SketchPicker } from 'react-color'

import Rates from '../../operator/profile/rates/rates'

import AssignScooterSharing from '../..//operator/assignScooterSharing'
import MapsGeofence from '../../shared/geofence/mapsGeofence'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { Divider } from '@material-ui/core'
import PhoneInput from 'react-phone-input-2'
import { validations } from '../../../helpers/validation'

const CompleteDataOperatorView = ({
  operatorReducer,
  updateRestricted,
  setTariffs,
  tariffsReducer,
  assignToOperate,
  setToOperate,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    cif,
    colour1,
    colour2,
    contactPhone,
    id,
    isPrivate,
    isStationBase,
    name,
    cannotOperateReason,
    isFree,
  } = operatorReducer.dataOperatorLogged || {}
  const [free, setFree] = useState(isFree ?? false)
  const [logo, setLogo] = useState()
  const [primaryColor, setPrimaryColor] = useState(colour1 || '')
  const [secondaryColor, setSecondaryColor] = useState(colour2 || '')
  const [operatorType, setOperatorType] = useState(
    isPrivate
      ? t('login.completeDataOperator.private')
      : t('login.completeDataOperator.public'),
  )
  const [operatorBase, setOperatorBase] = useState(
    isStationBase
      ? t('login.completeDataOperator.stationBase')
      : t('login.completeDataOperator.freeFloating'),
  )

  const [contactPhoneState, setContactPhoneState] = useState(contactPhone)

  const [isRateSectionOpen, setIsRateSectionOpen] = useState(false)
  const [isGeofenceSectionOpen, setIsGeofenceSectionOpen] = useState(false)
  const [isSelectScooterSectionOpen, setIsSelectScooterSectionOpen] =
    useState(false)

  const [ratesValues, setRatesValues] = useState({})
  const [areasBody, setAreasBody] = useState([])
  const [circlesBody, setCirclesBody] = useState([])
  const [scootersSelectedBody, setScootersSelectedBody] = useState([])
  const [scootersAvaiableBody, setScootersAvaiableBody] = useState([])

  // TODO: Finish errors for all inputs
  const [errors, setErrors] = useState({
    phone: { result: false, message: '' },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    validateForm()

    if (isFormValid()) {
      const {
        currency,
        mode,
        pricePerMode,
        pricePerModePause,
        rateHours,
        rateDays,
        maxPricePerDay,
        freePauseMinutes,
      } = ratesValues
      const operatorId = operatorReducer.dataOperatorLogged.id
      const { areas, circles } = operatorReducer.dataOperatorLogged

      const bodyPersonalData = {
        id: id,
        contactPhone:
          contactPhoneState.charAt(0) === '+'
            ? contactPhoneState
            : `+${contactPhoneState}`,
        iban: cif,
        logoBinary: logo,
        colour1: primaryColor,
        colour2: secondaryColor,
        isFree: free,
        areas: areasBody.length === 0 ? areas : areasBody,
        circles: circlesBody.length === 0 ? circles : circlesBody,
      }

      let updatedTariffs = {
        currency: currency,
        billingUnit: mode,
        freeFractionMinutes: 0,
        priceMinute: mode === 'minute' ? parseFloat(pricePerMode) : 0,
        pricePauseMinute: mode === 'minute' ? parseFloat(pricePerModePause) : 0,
        priceHour: mode === 'hour' ? parseFloat(pricePerMode) : 0,
        priceHours: mode === 'hour' ? rateHours : null,
        priceDays: mode === 'days' ? rateDays : null,
        maxPricePerDay: mode === 'minute' ? parseFloat(maxPricePerDay) : 0,
        freePauseMinutes: mode === 'minute' ? parseInt(freePauseMinutes) : 0,
      }

      const bodySelectedSharing = {
        sharingOperatorId: operatorId,
        vehicleIds: scootersSelectedBody.map((s) => {
          return s.id
        }),
        selectToOperate: true,
      }
      const bodyavaiableSharing = {
        sharingOperatorId: operatorId,
        vehicleIds: scootersAvaiableBody.map((s) => {
          return s.id
        }),
        selectToOperate: false,
      }

      setTariffs(updatedTariffs)
      setToOperate(bodySelectedSharing)
      setToOperate(bodyavaiableSharing)
      updateRestricted(bodyPersonalData)
    }
  }

  const handleChangeAreas = (body) => {
    const [bodyCircle, bodyAreas] = body
    setAreasBody(bodyAreas)
    setCirclesBody(bodyCircle)
  }
  const handleRatesChangesValue = (values) => {
    console.log('rates values', values)
    setRatesValues(values)
  }

  const handleSelectScootersAssign = (
    dataScooterSelected,
    dataScooterAvaiable,
  ) => {
    setScootersSelectedBody(dataScooterSelected)
    setScootersAvaiableBody(dataScooterAvaiable)
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const validateForm = () => {
    let newErrors = errors
    newErrors.phone = validations.validatePhone(
      contactPhoneState.charAt(0) === '+'
        ? contactPhoneState
        : `+${contactPhoneState}`,
    )
    setErrors({ ...newErrors })
  }

  const pendingValidate = true
  return (
    <Fragment>
      {pendingValidate && (
        <div className={classes.commentSection}>
          <p className={classes.warningNote}>
            {t('login.completeDataOperator.notes.warning')}
          </p>
          {cannotOperateReason && (
            <p>
              {' '}
              {t('login.completeDataOperator.notes.commentSection')}{' '}
              {cannotOperateReason}
            </p>
          )}
        </div>
      )}

      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {t('login.completeDataOperator.headerText')}
          </Typography>
          <Typography variant="subtitle1">
            {t('login.completeDataOperator.subtitleText')}
          </Typography>
          <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  // variant='standard'

                  fullWidth
                  label={t('login.completeDataOperator.logoLabel')}
                  type="file"
                  value={logo}
                  onChange={(e) => setLogo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // variant='standard'
                  fullWidth
                  label={t('login.completeDataOperator.nameLabel')}
                  value={name}
                  helperText={t('login.completeDataOperator.helperText')}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // variant="outlined"
                  fullWidth
                  label={t('login.completeDataOperator.cifLabel')}
                  value={cif}
                  InputProps={{
                    readOnly: true,
                  }}
                  helperText={t('login.completeDataOperator.helperText')}
                />
              </Grid>

              <Grid item sm={2}>
                <p>{t('login.completeDataOperator.primaryColorLabel')}</p>
              </Grid>
              <Grid
                item
                sm={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SketchPicker
                  color={primaryColor || ''}
                  onChange={(color) => setPrimaryColor(color && color.hex)}
                />
              </Grid>

              <Grid item sm={2}>
                <p>{t('login.completeDataOperator.secondaryColorLabel')}</p>
              </Grid>
              <Grid
                item
                sm={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SketchPicker
                  color={secondaryColor || ''}
                  onChange={(color) => setSecondaryColor(color && color.hex)}
                />
              </Grid>

              <Grid item xs={12}>
                <PhoneInput
                  onChange={(phone, country) => setContactPhoneState(phone)}
                  country={'es'}
                  value={contactPhoneState}
                  preferredCountries={['es', 'fr']}
                  inputStyle={{
                    width: '100%',
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottomColor: 'rgba(0, 0, 0, 0.42)',
                    borderBottomWidth: 1,
                    borderRadius: 0,
                    fontFamily: 'Montserrat',
                    fontSize: '1.000714rem',
                    fontWeight: 400,
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}
                  buttonStyle={{
                    border: 0,
                    borderWidth: 1,
                    backgroundColor: 'inherit',
                  }}
                />
              </Grid>
              {errors.phone.message && (
                <Grid item xs={12}>
                  <p style={{ color: 'red', margin: 0 }}>
                    {' '}
                    {errors.phone.message}{' '}
                  </p>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  // variant="outlined"
                  helperText={t('login.completeDataOperator.helperText')}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  label={t('login.completeDataOperator.operatorType')}
                  type="string"
                  value={operatorType}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  // variant="outlined"
                  helperText={t('login.completeDataOperator.helperText')}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  label={t('login.completeDataOperator.operatorBase')}
                  type="string"
                  value={operatorBase}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t('operator.profile.personalData.isFree')}
                  </InputLabel>
                  <Select
                    value={free}
                    onChange={(e) => setFree(e.target.value)}
                  >
                    <MenuItem value={true}>
                      {t('operator.profile.personalData.yes')}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t('operator.profile.personalData.no')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div
                  onClick={() => setIsRateSectionOpen((prev) => !prev)}
                  className={classes.DropDownSections}
                >
                  <p className={classes.sectionAccordionText}>
                    {t('login.completeDataOperator.tariffs')}
                  </p>
                  {isRateSectionOpen ? (
                    <KeyboardArrowUpIcon
                      className={classes.sectionAccordionIcons}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      className={classes.sectionAccordionIcons}
                    />
                  )}
                </div>
                {!isRateSectionOpen && <Divider />}
              </Grid>
              <Grid
                item
                xs={12}
                className={!isRateSectionOpen ? classes.hideSection : ''}
              >
                <Rates
                  isUpdatingData={false}
                  onChangesValues={(values) => handleRatesChangesValue(values)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div
                onClick={() => setIsGeofenceSectionOpen((prev) => !prev)}
                className={classes.DropDownSections}
              >
                <p className={classes.sectionAccordionText}>
                  {t('login.completeDataOperator.area')}
                </p>
                {isGeofenceSectionOpen ? (
                  <KeyboardArrowUpIcon
                    className={classes.sectionAccordionIcons}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    className={classes.sectionAccordionIcons}
                  />
                )}
              </div>
              {!isGeofenceSectionOpen && <Divider />}
            </Grid>
            <Grid
              item
              xs={12}
              className={!isGeofenceSectionOpen ? classes.hideSection : ''}
            >
              <MapsGeofence
                onAccept={(body) => handleChangeAreas(body)}
                data={operatorReducer.dataOperatorLogged}
                mapStateLoading={operatorReducer.mapStateLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <div
                onClick={() => setIsSelectScooterSectionOpen((prev) => !prev)}
                className={classes.DropDownSections}
              >
                <p className={classes.sectionAccordionText}>
                  {t('login.completeDataOperator.assignScooterSectionTitle')}
                </p>
                {isSelectScooterSectionOpen ? (
                  <KeyboardArrowUpIcon
                    className={classes.sectionAccordionIcons}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    className={classes.sectionAccordionIcons}
                  />
                )}
              </div>
              {!isSelectScooterSectionOpen && <Divider />}
            </Grid>
            <Grid
              item
              xs={12}
              className={!isSelectScooterSectionOpen ? classes.hideSection : ''}
            >
              <AssignScooterSharing
                isUpdatingSection={false}
                onSelectedChanges={(dataScooterSelected, dataScooterAvaiable) =>
                  handleSelectScootersAssign(
                    dataScooterSelected,
                    dataScooterAvaiable,
                  )
                }
              />
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t('login.completeDataOperator.buttonSubmitText')}
            </Button>
          </form>
        </div>
      </Container>
    </Fragment>
  )
}

function mapState(state) {
  const { servicesReducer, scooterReducer, operatorReducer, tariffsReducer } =
    state
  return { servicesReducer, scooterReducer, operatorReducer, tariffsReducer }
}

const actionCreators = {
  updateRestricted: operatorActions.updateRestricted,

  setTariffs: tariffsActions.setTariffs,
  assignToOperate: scooterActions.assignToOperate,
  setToOperate: scooterActions.setToOperate,
}

export default connect(mapState, actionCreators)(CompleteDataOperatorView)
