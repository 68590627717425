import { Avatar, Grid, ListItemAvatar, Paper } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import ExploreIcon from '@material-ui/icons/Explore'
import React, { useEffect, useRef, useState } from 'react'

import moment from 'moment'

import { useTranslation } from 'react-i18next'

import { formatDateToDisplay } from '../../shared/formatDate'

import { GoogleApiWrapper, Map, Polyline } from 'google-maps-react'

import stringConstants from '../../../constants/strings'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '50%',
    padding: '1%',
    marginBottom: '2%',
  },
  containerBody: {
    padding: '1%',
  },
  dialogContainer: {
    padding: '2%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DetailsPayment = ({
  isOpen,
  onClose,
  servicesReducer,
  google,
  selectedPayment,
}) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const [selectedTripDetails, setselectedTripDetails] = useState(null)
  const [selectedTripPoints, setselectedTripPoints] = useState([])
  const [bounds, setBounds] = useState(null)

  const mapRef = useRef()

  const { trips } = servicesReducer

  useEffect(() => {
    trips[0] && setselectedTripDetails(trips[0])
  }, [trips])

  const handleClose = () => {
    onClose()
  }

  const defaultProps = {
    center: {
      lat: 41.390205,
      lng: 2.154007,
    },
    zoom: 11,
  }

  const selectItem = (item) => {
    setselectedTripDetails(item)
    const updatedPoints = []
    item.points.map((p) => {
      updatedPoints.push({ lat: p.lat, lng: p.lon })
    })
    setselectedTripPoints(updatedPoints)
    var updatedBounds = new google.maps.LatLngBounds()
    updatedPoints.forEach(function (n) {
      updatedBounds.extend(n)
    })
    setBounds(updatedBounds)
  }

  const trimString = (string, length) => {
    var trimmedString = string
      ? string.length > length
        ? string.substring(0, length - 3) + '...'
        : string
      : 'undefined'
    return trimmedString
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Payment details trip
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.dialogContainer}>
          <Grid container>
            <Grid item md={6}>
              <Paper style={{ maxHeight: 563, overflow: 'auto' }}>
                <List>
                  {trips.map((item) => (
                    <ListItem
                      button
                      key={item.id}
                      onClick={() => selectItem(item)}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <ExploreIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        secondary={
                          t('scooters.details.trips.page.detailTrip.from') +
                          trimString(item.fromDescription, 25) +
                          t('scooters.details.trips.page.detailTrip.to') +
                          trimString(item.toDescription, 25)
                        }
                        primary={
                          moment(item.startTimestamp).format(
                            'DD/MM/YYYY HH:mm',
                          ) +
                          ' ' +
                          item.distance +
                          'm'
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
            <Grid item md={6}>
              {selectedTripDetails && (
                <Grid container spacing={0}>
                  <Grid item md={12}>
                    <strong>
                      {t('scooters.details.trips.page.detailTrip.title')}
                    </strong>
                  </Grid>
                  <Grid item md={6}>
                    <span>
                      {t('scooters.details.trips.page.detailTrip.from')}
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>{selectedTripDetails.fromDescription}</span>
                  </Grid>

                  <Grid item md={6}>
                    <span>
                      {t('scooters.details.trips.page.detailTrip.to')}
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>{selectedTripDetails.toDescription}</span>
                  </Grid>

                  <Grid item md={6}>
                    <span>
                      {t('scooters.details.trips.page.detailTrip.distance')}
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>{selectedTripDetails.distance}m</span>
                  </Grid>

                  <Grid item md={6}>
                    <span>
                      {t('scooters.details.trips.page.detailTrip.coSave')}
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>
                      {(
                        Math.round(selectedTripDetails.distance * 100) / 100
                      ).toFixed(2)}
                    </span>
                  </Grid>

                  <Grid item md={6}>
                    <span>
                      {t('scooters.details.trips.page.detailTrip.start')}
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>
                      {formatDateToDisplay(
                        selectedTripDetails.startTimestamp,
                        true,
                      )}
                    </span>
                  </Grid>

                  <Grid item md={6}>
                    <span>
                      {t('scooters.details.trips.page.detailTrip.finish')}
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>
                      {formatDateToDisplay(
                        selectedTripDetails.endTimestamp,
                        true,
                      )}
                    </span>
                  </Grid>

                  <Grid item md={6}>
                    <span>
                      {t(
                        'scooters.details.trips.page.detailTrip.start_battery',
                      )}
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>{selectedTripDetails.startBattery}%</span>
                  </Grid>
                  <Grid item md={6}>
                    <span>
                      {' '}
                      {t(
                        'scooters.details.trips.page.detailTrip.finish_battery',
                      )}
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>{selectedTripDetails.endBattery}%</span>
                  </Grid>

                  <Grid item md={6}>
                    <span>
                      {' '}
                      {t('scooters.details.trips.page.detailTrip.speed_avg')}:
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>
                      {(
                        Math.round(selectedTripDetails.speedAvg * 100) / 100
                      ).toFixed(2)}
                      km/h
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>
                      {' '}
                      {t('scooters.details.trips.page.detailTrip.speed_max')}
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span>{selectedTripDetails.speedMax}km/h</span>
                  </Grid>
                </Grid>
              )}
              <div style={{ height: '60vh', width: '100%' }}>
                {selectedTripDetails && (
                  <Map
                    ref={(ref) => {
                      mapRef.current = ref
                    }}
                    bounds={bounds}
                    containerStyle={{
                      position: 'relative',
                      width: '100%',
                      height: '60vh',
                    }}
                    google={google}
                    zoom={58}
                    initialCenter={defaultProps.center}
                    zoom={defaultProps.zoom}
                  >
                    <Polyline
                      path={selectedTripPoints}
                      strokeColor="black" //"#97d700"
                      strokeOpacity={0.8}
                      strokeWeight={3}
                    />
                  </Map>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: stringConstants.GOOGLE_API_KEY,
  // libraries: ["drawing", "visualization"],
  libraries: ['drawing,visualization'],
})(DetailsPayment)
