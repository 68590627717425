export const operatorConstant = {
  GET_ALL_REQUEST: 'OPERATOR_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'OPERATOR_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'OPERATOR_GET_ALL_FAILURE',

  GET_ALL_BY_CUSTOMERS_REQUEST: 'OPERATOR_GET_ALL_BY_CUSTOMERS_REQUEST',
  GET_ALL_BY_CUSTOMERS_SUCCESS: 'OPERATOR_GET_ALL_BY_CUSTOMERS_SUCCESS',
  GET_ALL_BY_CUSTOMERS_FAILURE: 'OPERATOR_GET_ALL_BY_CUSTOMERS_FAILURE',

  GET_ALL_TO_VALIDATE_REQUEST: 'OPERATOR_GET_ALL_TO_VALIDATE_REQUEST',
  GET_ALL_TO_VALIDATE_SUCCESS: 'OPERATOR_GET_ALL_TO_VALIDATE_SUCCESS',
  GET_ALL_TO_VALIDATE_FAILURE: 'OPERATOR_GET_ALL_TO_VALIDATE_FAILURE',

  GET_ALL_CHILDS_REQUEST: 'OPERATOR_GET_ALL_CHILDS_REQUEST',
  GET_ALL_CHILDS_SUCCESS: 'OPERATOR_GET_ALL_CHILDS_SUCCESS',
  GET_ALL_CHILDS_FAILURE: 'OPERATOR_GET_ALL_CHILDS_FAILURE',

  GET_PARENT_REQUEST: 'OPERATOR_GET_PARENT_REQUEST',
  GET_PARENT_SUCCESS: 'OPERATOR_GET_PARENT_SUCCESS',
  GET_PARENT_FAILURE: 'OPERATOR_GET_PARENT_FAILURE',

  GET_ALL_DISCOUNTS_REQUEST: 'OPERATOR_GET_ALL_DISCOUNTS_REQUEST',
  GET_ALL_DISCOUNTS_SUCCESS: 'OPERATOR_GET_ALL_DISCOUNTS_SUCCESS',
  GET_ALL_DISCOUNTS_FAILURE: 'OPERATOR_GET_ALL_DISCOUNTS_FAILURE',

  GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_REQUEST:
    'OPERATOR_GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_REQUEST',
  GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_SUCCESS:
    'OPERATOR_GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_SUCCESS',
  GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_FAILURE:
    'OPERATOR_GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_FAILURE',

  GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_REQUEST:
    'OPERATOR_GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_REQUEST',
  GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_SUCCESS:
    'OPERATOR_GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_SUCCESS',
  GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_FAILURE:
    'OPERATOR_GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_FAILURE',

  GET_ALL_VALIDATED_OPERATORS_REQUEST:
    'OPERATOR_GET_ALL_VALIDATED_OPERATORS_REQUEST',
  GET_ALL_VALIDATED_OPERATORS_SUCCESS:
    'OPERATOR_GET_ALL_VALIDATED_OPERATORS_SUCCESS',
  GET_ALL_VALIDATED_OPERATORS_FAILURE:
    'OPERATOR_GET_ALL_VALIDATED_OPERATORS_FAILURE',

  INSERT_REQUEST: 'INSERT_REQUEST',
  INSERT_SUCCESS: 'INSERT_SUCCESS',
  INSERT_FAILURE: 'INSERT_FAILURE',

  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',

  UPDATE_RESTRICTED_REQUEST: 'UPDATE_RESTRICTED_REQUEST',
  UPDATE_RESTRICTED_SUCCESS: 'UPDATE_RESTRICTED_SUCCESS',
  UPDATE_RESTRICTED_FAILURE: 'UPDATE_RESTRICTED_FAILURE',

  SUCCESS_OPERATOR_INFO: 'SUCCESS_OPERATOR_INFO',
  REQUEST_OPERATOR_INFO: 'REQUEST_OPERATOR_INFO',
  OPERATOR_INFO_SUCCESS: 'OPERATOR_INFO_SUCCESS',

  GET_USER_DATA_BY_MAIL_REQUEST: 'GET_USER_DATA_BY_MAIL_REQUEST',
  GET_USER_DATA_BY_MAIL_SUCCESS: 'GET_USER_DATA_BY_MAIL_SUCCESS',

  GET_ALL_OPERATOR_USER_REQUEST: 'OPERATOR_USER_GET_ALL_REQUEST',
  GET_ALL_OPERATOR_USER_SUCCESS: 'OPERATOR_USER_GET_ALL_SUCCESS',
  GET_ALL_OPERATOR_USER_FAILURE: 'OPERATOR_USER_GET_ALL_FAILURE',

  GET_CURRENT_DISCOUNTS_OF_OPERATORS_REQUEST:
    'CURRENT_DISCOUNTS_OF_OPERATORS_GET_REQUEST',
  GET_CURRENT_DISCOUNTS_OF_OPERATORS_SUCCESS:
    'CURRENT_DISCOUNTS_OF_OPERATORS_GET_SUCCESS',
  GET_CURRENT_DISCOUNTS_OF_OPERATORS_FAILURE:
    'CURRENT_DISCOUNTS_OF_OPERATORS_GET_FAILURE',

  GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_REQUEST:
    'CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_GET_REQUEST',
  GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_SUCCESS:
    'CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_GET_SUCCESS',
  GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FAILURE:
    'CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_GET_FAILURE',

  GET_GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_REQUEST:
    'GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_GET_REQUEST',
  GET_GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_SUCCESS:
    'GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_GET_SUCCESS',
  GET_GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_FAILURE:
    'GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_GET_FAILURE',

  GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_REQUEST:
    'CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_GET_REQUEST',
  GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_SUCCESS:
    'CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_GET_SUCCESS',
  GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_FAILURE:
    'CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_GET_FAILURE',

  GET_ASSIGNABLE_CUSTOMERS_REQUEST: 'GET_ASSINGNABLE_CUSTOMERS_REQUEST',
  GET_ASSIGNABLE_CUSTOMERS_SUCCESS: 'GET_ASSINGNABLE_CUSTOMERS_SUCCESS',
  GET_ASSIGNABLE_CUSTOMERS_FAILURE: 'GET_ASSINGNABLE_CUSTOMERS_FAILURE',

  GET_VEHICLE_HOMOLOGATION_TYPES_REQUEST:
    'GET_VEHICLE_HOMOLOGATION_TYPES_REQUEST',
  GET_VEHICLE_HOMOLOGATION_TYPES_SUCCESS:
    'GET_VEHICLE_HOMOLOGATION_TYPES_SUCCESS',
  GET_VEHICLE_HOMOLOGATION_TYPES_FAILURE:
    'GET_VEHICLE_HOMOLOGATION_TYPES_FAILURE',
}
