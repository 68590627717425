export const ticketsConstant = {
  GET_ALL_BY_OPERATOR_ID_REQUEST: 'TICKETS_GET_ALL_BY_OPERATOR_ID_REQUEST',
  GET_ALL_BY_OPERATOR_ID_SUCCESS: 'TICKETS_GET_ALL_BY_OPERATOR_ID_SUCCESS',
  GET_ALL_BY_OPERATOR_ID_FAILURE: 'TICKETS_GET_ALL_BY_OPERATOR_ID_FAILURE',

  GET_ALL_OPEN_BY_VEHICLE_ID_REQUEST:
    'TICKETS_GET_ALL_OPEN_BY_VEHICLE_ID_REQUEST',
  GET_ALL_OPEN_BY_VEHICLE_ID_SUCCESS:
    'TICKETS_GET_ALL_OPEN_BY_VEHICLE_ID_SUCCESS',
  GET_ALL_OPEN_BY_VEHICLE_ID_FAILURE:
    'TICKETS_GET_ALL_OPEN_BY_VEHICLE_ID_FAILURE',

  GET_ALL_REQUEST: 'TICKETS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'TICKETS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'TICKETS_GET_ALL_FAILURE',

  GET_BY_ID_REQUEST: 'TICKETS_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'TICKETS_GET_BY_ID_SUCCESS',
  GET_BY_ID_FAILURE: 'TICKETS_GET_BY_ID_FAILURE',

  CREATE_NEW_TICKET_REQUEST: 'TICKETS_CREATE_NEW_TICKET_REQUEST',
  CREATE_NEW_TICKET_SUCCESS: 'TICKETS_CREATE_NEW_TICKET_SUCCESS',
  CREATE_NEW_TICKET_FAILURE: 'TICKETS_CREATE_NEW_TICKET_FAILURE',
}
