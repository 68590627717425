import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { operatorActions } from '../../../redux/actions/operator_action'
import { scooterActions } from '../../../redux/actions/scooter_actions'
import { userActions } from '../../../redux/actions/user_actions'
import DialogConfirmAction from '../../shared/modalConfirmAction'
import IncludeHelmet from './dialogs/includeHelment'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    overflow: 'hidden',
    padding: '1%',
  },
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    height: '70vh',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  customList: {
    width: '40%',
  },
  updateButton: {
    // marginTop: "5%",
  },
}))

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

const TransferList = ({
  operatorReducer,
  getAll,
  scooterReducer,
  onSelectedChanges,
  assignToOperate,
  isUpdatingSection = true,
  validateOperator,
  setHelmetIncluded,
  logout,
  selectToOperateSection,
  setToOperate,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { results } = scooterReducer
  const [checked, setChecked] = useState([])

  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const [selectedItemData, setselectedItemData] = useState({})
  const [isDialogIncludeHelmetOpen, setisDialogIncludeHelmetOpen] =
    useState(false)
  const [isDialogConfirmActionOpen, setisDialogConfirmActionOpen] =
    useState(false)

  const [dataScooterSelected, setdataScooterSelected] = useState([])
  const [dataScooterAvaiable, setdataScooterAvaiable] = useState([])

  useEffect(() => {
    let updatedScooterSelected = []
    let updatedScooterAvaiable = []
    right.map((value) => {
      updatedScooterSelected.push(
        scooterReducer.results.filter((d) => d.imei === value)[0],
      )
    })
    left.map((value) => {
      updatedScooterAvaiable.push(
        scooterReducer.results.filter((d) => d.imei === value)[0],
      )
    })

    setdataScooterSelected(updatedScooterSelected)
    setdataScooterAvaiable(updatedScooterAvaiable)
    !isUpdatingSection &&
      onSelectedChanges(updatedScooterSelected, updatedScooterAvaiable)
  }, [right, left])

  useEffect(() => {
    const canOperate = results.filter(
      (s) => s.sharing && s.sharing.outOfService,
    )
    const cantOperate = results.filter(
      (s) => s.sharing && !s.sharing.outOfService,
    )
    const selectedToOperate = results.filter(
      (s) => s.sharing && s.sharing.selectToOperate,
    )
    const notSelectedToOperate = results.filter(
      (s) => s.sharing && !s.sharing.selectToOperate,
    )

    setLeft(
      selectToOperateSection
        ? cantOperate.map((d) => d.imei)
        : notSelectedToOperate.map((d) => d.imei),
    )
    setRight(
      selectToOperateSection
        ? canOperate.map((d) => d.imei)
        : selectedToOperate.map((d) => d.imei),
    )
  }, [scooterReducer.results])

  useEffect(() => {
    getAll(operatorReducer.dataOperatorLogged.id)
  }, [])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = (items) => intersection(checked, items).length

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleUpdateSelectedScooters = () => {
    setisDialogConfirmActionOpen(true)
  }

  const handleConfirmAction = () => {
    const id = operatorReducer.dataOperatorLogged.id
    if (selectToOperateSection) {
      const bodySelected = {
        sharingOperatorId: id,
        vehicleIds: dataScooterSelected.map((s) => {
          return s.id
        }),
        outOfService: true,
      }
      const bodyavaiable = {
        sharingOperatorId: id,
        vehicleIds: dataScooterAvaiable.map((s) => {
          return s.id
        }),
        outOfService: false,
      }
      assignToOperate(bodySelected)
      assignToOperate(bodyavaiable)
    } else {
      const bodySelectedSharing = {
        sharingOperatorId: id,
        vehicleIds: dataScooterSelected.map((s) => {
          return s.id
        }),
        selectToOperate: true,
      }
      const bodyavaiableSharing = {
        sharingOperatorId: id,
        vehicleIds: dataScooterAvaiable.map((s) => {
          return s.id
        }),
        selectToOperate: false,
      }
      setToOperate(bodySelectedSharing)
      setToOperate(bodyavaiableSharing)
    }
  }

  const handleSelectItemHasHelmet = (value) => {
    setselectedItemData(value)
    setisDialogIncludeHelmetOpen(true)
  }

  const handleConfirmIncludeHelmet = (data) => {
    setisDialogIncludeHelmetOpen(false)

    const helmetIncluded = data.sharing && data.sharing.isHelmetIncluded

    const body = {
      sharingOperatorId: data.sharing.sharingOperatorId,
      vehicleIds: [data.id],
      isHelmetIncluded: !helmetIncluded,
    }

    setHelmetIncluded(body)
  }

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': t('operator.assignScooterSharing.allItemsSelected'),
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${t(
          'operator.assignScooterSharing.selected',
        )}`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const dataFromCurrentValue = results.filter(
            (d) => d.imei === value,
          )[0]
          const {
            imei,
            name,
            model,
            revision,
            frameNo,
            manufactureDate,
            plate,
            sharing,
          } = dataFromCurrentValue || {}

          const labelId = `transfer-list-all-item-${imei}-label`
          return (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    {t('operator.assignScooterSharing.headerTooltip')}
                  </Typography>
                  {`${t('operator.assignScooterSharing.imei')}: ${imei}`}
                  <br />
                  {`${'Out of Service'}: ${
                    sharing && sharing.outOfService ? 'Yes' : 'No'
                  }`}
                  <br />
                  {`${t(
                    'operator.assignScooterSharing.manufactureDate',
                  )}: ${manufactureDate}`}
                  <br />
                  {`${t('operator.assignScooterSharing.name')}: ${name}`}
                  <br />
                  {`${t('operator.assignScooterSharing.model')}: ${model}`}
                  <br />
                  {`${t(
                    'operator.assignScooterSharing.revision',
                  )}: ${revision}`}
                  <br />
                  {`${t('operator.assignScooterSharing.frameNo')}: ${frameNo}`}
                  <br />
                  {`${t('operator.assignScooterSharing.plate')}: ${plate}`}
                  <br />
                </React.Fragment>
              }
            >
              <ListItem key={imei} role="listitem" button>
                <ListItemIcon>
                  <Checkbox
                    onClick={handleToggle(value)}
                    checked={checked.indexOf(imei) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItem id={labelId}>
                  <IconButton
                    onClick={() =>
                      handleSelectItemHasHelmet(dataFromCurrentValue)
                    }
                  >
                    <SportsMotorsportsIcon
                      style={{
                        color:
                          sharing && sharing.isHelmetIncluded ? 'green' : 'red',
                        'margin-right': '5% ',
                      }}
                    />
                  </IconButton>

                  {` ${plate} (${model})`}
                </ListItem>
                {/* <ListItemText id={labelId} primary={`${plate} (${model})`} /> */}
              </ListItem>
            </HtmlTooltip>
          )
        })}
        <ListItem />
      </List>
    </Card>
  )

  return (
    <div className={classes.mainContainer}>
      {/* <p style={{ marginTop: "2%" }}>
        Desde este menú podrás decidir que motos están
        {selectToOperateSection ? " Operativas" : " disponibles para Sharing"}
      </p> */}
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item className={classes.customList}>
          {customList(t('operator.assignScooterSharing.avaiable'), left)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label={t('operator.assignScooterSharing.moveSelectedRight')}
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label={t('operator.assignScooterSharing.moveSelectedLeft')}
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.customList}>
          {customList(t('operator.assignScooterSharing.operatives'), right)}
        </Grid>
      </Grid>
      {isUpdatingSection && (
        <Button
          variant="contained"
          color="primary"
          className={classes.updateButton}
          onClick={handleUpdateSelectedScooters}
        >
          {t('operator.assignScooterSharing.update')}
        </Button>
      )}
      <DialogConfirmAction
        isOpen={isDialogConfirmActionOpen}
        onClose={() => setisDialogConfirmActionOpen(false)}
        onConfirm={handleConfirmAction}
        addNote={false}
        data={{
          header: 'Aviso Modificación Datos',
          body: 'Estás modificando las motos seleccionadas para que puedan operar en sharing. ¿Quieres continuar?',
        }}
      />
      <IncludeHelmet
        isOpen={isDialogIncludeHelmetOpen}
        onClose={() => setisDialogIncludeHelmetOpen(false)}
        data={selectedItemData}
        onConfirm={handleConfirmIncludeHelmet}
      />
    </div>
  )
}

function mapState(state) {
  const { operatorReducer, scooterReducer } = state
  return { operatorReducer, scooterReducer }
}

const actionCreators = {
  getAll: scooterActions.getAll,
  assignToOperate: scooterActions.assignToOperate,
  validateOperator: operatorActions.validateOperator,
  setHelmetIncluded: scooterActions.setHelmetIncluded,
  logout: userActions.logout,
  setToOperate: scooterActions.setToOperate,
}

export default connect(mapState, actionCreators)(TransferList)
