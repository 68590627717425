import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import { operatorActions } from '../../../redux/actions/operator_action'

import { isManager } from '../../../helpers/roles'

import { useTranslation } from 'react-i18next'
import { formatDateToDisplay } from '../../shared/formatDate'

import CustomMaterialTable from '../../shared/customMaterialTable/index'

import EditDiscount from '../../operator/profile/rates/dialogs/editDiscount'
import NewDiscount from '../../operator/profile/rates/dialogs/newDiscount'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const DiscountDetails = ({
  isOpen,
  onClose,
  data,
  getCurrentDiscountsOfLoggedOperatorForCustomer,
  operatorReducer,
  getCurrentDiscountsOfOperatorForCustomer,
}) => {
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  const [selectedDiscount, setselectedDiscount] = useState({})
  const [genericDiscount, setgenericDiscount] = useState(null)
  const [isNewDiscountDialogOpen, setisNewDiscountDialogOpen] = useState(false)
  const [isEditDiscountDialogOpen, setisEditDiscountDialogOpen] =
    useState(false)

  useEffect(() => {
    if (isOpen) {
      const { customerId, sharingOperatorId } = data
      isManager()
        ? getCurrentDiscountsOfOperatorForCustomer({
            operatorId: sharingOperatorId,
            customerId,
          })
        : getCurrentDiscountsOfLoggedOperatorForCustomer(data.customerId)
    }
  }, [data])

  useEffect(() => {
    const percentFind =
      operatorReducer.currentDiscountOfLoggedOperatorForCustomer.find(
        (i) => i.isGeneric,
      )
    setgenericDiscount(percentFind ? percentFind.percent : null)
  }, [operatorReducer.currentDiscountOfLoggedOperatorForCustomer])

  //  Acción Discounts: Hay que poder gestionar (es decir, añadir borrar y modificar) un descuento de una persona en específico desde dicha ventana,
  //  y no solo ver los listados.
  //  Aquí se ha de listar siempre el descuento personalizado del cliente (si lo hubiera).

  const handleCreateDiscount = (rowData) => {
    setisNewDiscountDialogOpen(true)
  }

  const HandleEditDiscotun = (rowData) => {
    setisEditDiscountDialogOpen(true)
    setselectedDiscount(rowData)
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="xl"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {`${t('customers.modals.DiscountDetails.dialogTitle')} (${
            data && data.customerData && data.customerData.fullName
          })`}
        </DialogTitle>
        <DialogContent dividers>
          {isManager() && (
            <Typography gutterBottom>
              {t('customers.modals.DiscountDetails.dialogContent')}
            </Typography>
          )}
          <div>
            {genericDiscount && (
              <Grid container spacing={2}>
                <Grid item md={3}>
                  {t('customers.modals.DiscountDetails.isGeneric')}
                </Grid>
                <Grid item md={3}>
                  {t('customers.modals.DiscountDetails.percentageLabel')}:{' '}
                  {genericDiscount}
                </Grid>
              </Grid>
            )}

            <CustomMaterialTable
              title={''}
              columns={[
                {
                  title: t('customers.modals.DiscountDetails.sinceLabel'),
                  field: 'applySince',
                  render: (rowData) =>
                    formatDateToDisplay(rowData.applySince, true),
                },
                {
                  title: t('customers.modals.DiscountDetails.UntilLabel'),
                  field: 'applyUntil',
                  render: (rowData) =>
                    formatDateToDisplay(rowData.applyUntil, true),
                },
                {
                  title: t('customers.modals.DiscountDetails.percentageLabel'),
                  field: 'percent',
                  render: (rowData) => rowData.percent + '%',
                },
              ]}
              data={operatorReducer.currentDiscountOfLoggedOperatorForCustomer.filter(
                (i) => !i.isGeneric,
              )}
              actions={[
                (rowData) => ({
                  icon: 'add',
                  tooltip: 'Add',
                  isFreeAction: true,
                  onClick: (event, rowData) => handleCreateDiscount(rowData),
                  hidden: isManager(),
                }),
                (rowData) => ({
                  icon: 'edit',
                  tooltip: 'edit',
                  onClick: (event, rowData) => HandleEditDiscotun(rowData),
                  hidden: isManager(),
                }),
              ]}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <NewDiscount
        isOpen={isNewDiscountDialogOpen}
        onClose={() => setisNewDiscountDialogOpen(false)}
        isPrivatedOperator={operatorReducer.dataOperatorLogged.isPrivate}
        preSelectCustomer={data}
      />

      <EditDiscount
        isOpen={isEditDiscountDialogOpen}
        onClose={() => setisEditDiscountDialogOpen(false)}
        isPrivatedOperator={operatorReducer.dataOperatorLogged.isPrivate}
        data={selectedDiscount}
        preSelectCustomer={data}
      />
    </div>
  )
}

function mapState(state) {
  const { operatorReducer, customerReducer } = state
  return { operatorReducer, customerReducer }
}

const actionCreators = {
  getCurrentDiscountsOfLoggedOperatorForCustomer:
    operatorActions.getCurrentDiscountsOfLoggedOperatorForCustomer,
  getCurrentDiscountsOfOperatorForCustomer:
    operatorActions.getCurrentDiscountsOfOperatorForCustomer,
}

export default connect(mapState, actionCreators)(DiscountDetails)
