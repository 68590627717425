import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { blue } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import React from 'react'

import { IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
})

const SelectRole = ({ isOpen, data, onSelected, onClose }) => {
  const handleSelected = (d) => {
    onSelected(d)
  }

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={isOpen}>
      <div style={{ display: 'flex' }}>
        <DialogTitle id="simple-dialog-title">Roles Detectados</DialogTitle>
        <IconButton color="primary" onClick={() => onClose()} component="span">
          <HighlightOffIcon />
        </IconButton>
      </div>
      <List>
        {data &&
          data.map((d) => {
            return (
              <ListItem
                autoFocus
                button
                key={Math.random()}
                onClick={() => handleSelected(d)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={d.role} />
              </ListItem>
            )
          })}
      </List>
    </Dialog>
  )
}

export default SelectRole
