import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: '5%',
    width: '50%',
    margin: 'auto',
  },
  submitButton: {
    marginTop: '5%',
  },
}))

export default useStyles
