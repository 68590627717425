import { paymentsConstant } from '../../constants/payments_constant'

const initialState = {
  loadingResults: false,
  results: [],
}

export function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case paymentsConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case paymentsConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case paymentsConstant.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    default:
      return state
  }
}
