import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  shadows: Array(25).fill('0.5'),
  spacing: 8,
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#e41622', //'#97D700',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: '#0066ff',
      main: '#25282A', //'#25282A',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: 'Montserrat !important',
    fontSize: 14.01,
  },
})

export default theme
