import React from 'react'
import useStyles from './styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { useTranslation } from 'react-i18next'

const OperatorDataToValidate = ({ data }) => {
  const {
    cif,
    colour1,
    colour2,
    iban,
    isPrivated,
    isStationBase,
    name,
    chargeTypes,
    isFree,
  } = data
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Container component="main">
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t(
                'operator.modal.modalScooterOperator.operatorDataToValidate.name',
              )}
              value={name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t(
                'operator.modal.modalScooterOperator.operatorDataToValidate.nif',
              )}
              value={cif}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t(
                'operator.modal.modalScooterOperator.operatorDataToValidate.cc',
              )}
              value={iban}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ display: 'flex' }}>
              <div>{t('operator.validate.primaryColor')}</div>
              <div style={{ background: colour1, width: '100%' }}>
                {colour1}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ display: 'flex' }}>
              <div>{t('operator.validate.secondaryColor')}</div>
              <div style={{ background: colour2, width: '100%' }}>
                {colour2}
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t(
                "operator.modal.modalScooterOperator.operatorDataToValidate.primaryColor"
              )}
              type="string"
              value={colour1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={t(
                "operator.modal.modalScooterOperator.operatorDataToValidate.secondaryColor"
              )}
              type="string"
              value={colour2}
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t(
                'operator.modal.modalScooterOperator.operatorDataToValidate.operatorType',
              )}
              type="string"
              value={
                isPrivated
                  ? t(
                      'operator.modal.modalScooterOperator.operatorDataToValidate.private',
                    )
                  : t(
                      'operator.modal.modalScooterOperator.operatorDataToValidate.public',
                    )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t(
                'operator.modal.modalScooterOperator.operatorDataToValidate.operatorBase',
              )}
              type="string"
              value={
                isStationBase
                  ? t(
                      'operator.modal.modalScooterOperator.operatorDataToValidate.stationBase',
                    )
                  : t(
                      'operator.modal.modalScooterOperator.operatorDataToValidate.freeFloating',
                    )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t(
                'operator.modal.modalScooterOperator.operatorDataToValidate.free',
              )}
              type="string"
              value={
                isFree
                  ? t(
                      'operator.modal.modalScooterOperator.operatorDataToValidate.yes',
                    )
                  : t(
                      'operator.modal.modalScooterOperator.operatorDataToValidate.no',
                    )
              }
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default OperatorDataToValidate
