import React, { useState } from 'react'

import useStyles from './style'

import { Card, Grid, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import moment from 'moment'

const SpecificationScooterView = (props) => {
  const classes = useStyles()

  const { t, scooterReducer, updateRestricted } = props
  const { dynamic, scooter } = scooterReducer

  const {
    analogues,
    data,
    devicePower,
    digitals,
    es4,
    gps,
    lastconnectionTimestamp,
    motoState,
    scooterId,
    scutum,
    id,
  } = dynamic || {}
  const {
    activeServiceId,
    bastidorNum,
    frameNo,
    imei,
    model,
    plate,
    sharingOperatorId,
    login,
    userId,
    name,
    revision,
    manufactureDate,
    color,
    bluetoothMAC,
  } = scooter || {}

  const [scooterName, setscooterName] = useState(name)
  const [scooterPlate, setscooterPlate] = useState(plate)

  const [editAvaiable, seteditAvaiable] = useState(false)

  const handleSave = () => {
    const body = {
      scooterId,
      plate: scooterPlate,
      name: scooterName,
    }
    updateRestricted(body)
    seteditAvaiable(false)
  }

  return (
    <Grid container spacing={3} justify="center">
      <Grid item md={5} lg={5} xs={8} sm={8}>
        <Card className={classes.card} variant="outlined">
          <div className={classes.vehicleDataHeader}>
            <h3 style={{ padding: 25 }}>
              {t('scooters.details.spec.page.vehicule.title')}
            </h3>
            <Button
              variant="contained"
              color="primary"
              onClick={editAvaiable ? handleSave : () => seteditAvaiable(true)}
            >
              {editAvaiable
                ? t('scooters.details.spec.page.vehicule.save')
                : t('scooters.details.spec.page.vehicule.edit')}
            </Button>
          </div>
          <Grid container justify="center" spacing={3} alignItems="center">
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.id')}
                value={id || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.user')}
                value={userId || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.name')}
                value={scooterName || 'n/a'}
                onChange={(e) => setscooterName(e.target.value)}
                inputProps={{ readOnly: !editAvaiable }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.model')}
                value={model || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.revision')}
                value={revision || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.dateFab')}
                value={moment(manufactureDate).format('DD/MM/YYYY') || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.matricula')}
                value={scooterPlate || 'n/a'}
                onChange={(e) => setscooterPlate(e.target.value)}
                inputProps={{ readOnly: !editAvaiable }}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.color')}
                value={color || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.vehicule.codVin')}
                value={frameNo || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item md={5} lg={5} xs={8} sm={8}>
        <Card className={classes.card} variant="outlined">
          <h3 style={{ padding: 25 }}>
            {t('scooters.details.spec.page.astra.title')}
          </h3>
          <Grid container justify="center" spacing={3} alignItems="center">
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.codEmei')}
                value={imei || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.model')}
                value={model || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.version')}
                value={login ? login.hardwareVersion || 'n/a' : 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.dirMAC')}
                value={bluetoothMAC || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.idSIM')}
                value={'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.fr_version')}
                value={login ? login.firmwareVersion || 'n/a' : 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.astra.protocolo')}
                value={login ? login.protocol || 'n/a' : 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5} className={classes.endRow}>
              <TextField
                label={t('scooters.details.spec.page.astra.lastRecept')}
                value={
                  gps
                    ? moment(gps.lastFixTimestamp).format('DD/MM/YYYY h:m')
                    : 'n/a'
                }
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={5} lg={5} xs={8} sm={8}>
        <Card className={classes.card} variant="outlined">
          <h3 style={{ padding: 25 }}>
            {t('scooters.details.spec.page.lastReception.title')}
          </h3>
          <Grid container justify="center" spacing={3} alignItems="center">
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.lat')}
                value={gps ? gps.latitude || 'n/a' : 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.long')}
                value={gps ? gps.longitude || 'n/a' : 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.speed')}
                value={gps ? gps.currentSpeed || 'n/a' : 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.speed_max')}
                value={gps ? gps.maxSpeed || 'n/a' : 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.rumb')}
                value={gps ? gps.heading || 'n/a' : 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.alt')}
                value={gps ? gps.altitude || 'n/a' : 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.total_long')}
                value={'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.total_km')}
                value={(scutum && scutum.totalOdometer) || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.mode')}
                value={(scutum && scutum.drivingMode) || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.temp_eng')}
                value={(scutum && scutum.motorTemperature) || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.temp_inv')}
                value={(scutum && scutum.inverterTemperature) || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.temp_amb')}
                value={(scutum && scutum.ambientTemperature) || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.flag_od')}
                value={'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.flag_carg')}
                value={'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.flag_reg')}
                value={'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.flag_desc')}
                value={'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.lastReception.warnings')}
                value={'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={5} lg={5} xs={8} sm={8}>
        <Card className={classes.card} variant="outlined">
          <h3 style={{ padding: 25 }}>
            {t('scooters.details.spec.page.battery.title')}
          </h3>
          <Grid container justify="center" spacing={3} alignItems="center">
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.battery.id_bat')}
                value={(scutum && scutum.batteryPackId) || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.battery.level_bat')}
                value={(scutum && scutum.batterySoc) || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.battery.temp_max')}
                value={(scutum && scutum.batteryMaxTemperature) || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.battery.state_carg')}
                value={'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.battery.volt')}
                value={(scutum && scutum.batteryVoltage) || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.battery.ten')}
                value={'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
            <Grid item md={5}>
              <TextField
                label={t('scooters.details.spec.page.battery.corriente')}
                value={(scutum && scutum.batteryCurrentDrain) || 'n/a'}
                inputProps={{ readOnly: true }}
                disabled={editAvaiable}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SpecificationScooterView
