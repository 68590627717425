import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { scooterActions } from '../../redux/actions/scooter_actions'
import Dashboard from '../dashboard'

import {
  isManager,
  isManagerReadOnly,
  isUserWithRole,
  rolesConstants,
} from '../../helpers/roles'
import { operatorActions } from '../../redux/actions/operator_action'
import HomeView from '../home/page'

import PageOperator from './pageByOperator'

const Scooter = (props) => {
  // componentDidMount() {
  //   this.props.clearTable();
  // }

  useEffect(() => {
    ;(isManager() || isManagerReadOnly()) && props.getAllOperators()
  }, [])

  return (
    <Dashboard
      component={
        isUserWithRole([
          rolesConstants.MANAGER,
          rolesConstants.MANAGER_READONLY,
          rolesConstants.SAC_USER,
          rolesConstants.CAU_USER,
        ]) ? (
          // <ScooterView {...this.props} />
          <HomeView />
        ) : (
          <PageOperator {...props} />

          // <TabSection {...props} />
        )
      }
    />
  )
}

function mapState(state) {
  const { scooterReducer, operatorReducer } = state
  return { scooterReducer, operatorReducer }
}

const actionCreators = {
  filter: scooterActions.filter,
  getAll: scooterActions.getAll,
  getById: scooterActions.getId,
  clearTable: scooterActions.clearResults,
  getAllOperators: operatorActions.getAll,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Scooter)
