import React from 'react'

import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import ClientPendingValidate from './itemsPageManager/clientsPendingValidate'
import OperatorsPendingValidate from './itemsPageManager/operatorsPendingValidate'

import { Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isManager } from '../../helpers/roles'

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    position: 'initial',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

const PageManager = () => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Container maxWidth="xl">
      <div>
        <Accordion
          square
          classes={{
            '.MuiAccordion-root': { position: 'initial' },
          }}
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{t('home.pageManager.customers')} </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ClientPendingValidate />
          </AccordionDetails>
        </Accordion>
        {isManager() && (
          <Accordion
            square
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>{t('home.pageManager.operators')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OperatorsPendingValidate />
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </Container>
  )
}

export default PageManager
