import { GoogleApiWrapper, Map, Polyline } from 'google-maps-react'
import React, { useEffect, useState } from 'react'
import stringConstants from '../../../../../../../constants/strings'

const MapDetailsTrips = ({ google, points }) => {
  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '60vh',
  }

  const [formatedPoints, setformatedPoints] = useState([])

  useEffect(() => {
    setformatedPoints(
      points.map((p) => {
        return { lat: p.lat, lng: p.lon }
      }),
    )
  }, [points])

  return (
    <Map
      containerStyle={containerStyle}
      google={google}
      zoom={17}
      initialCenter={{ lat: 41.349771, lng: 2.107064 }}
      center={formatedPoints[0]}
    >
      <Polyline
        path={formatedPoints}
        strokeColor="#e41622"
        strokeOpacity={1}
        strokeWeight={5}
      />
    </Map>
  )
}

export default GoogleApiWrapper({
  apiKey: stringConstants.GOOGLE_API_KEY,
  // libraries: ["drawing", "visualization"],
  libraries: ['drawing,visualization'],
})(MapDetailsTrips)
