import moment from 'moment'

const dobleDigit = (n) => {
  return (n < 10 ? '0' : '') + n
}

export const formatToUtc = (dateprop) => {
  var date = new Date(dateprop)
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
  return new Date(now_utc)
}
export const formatDateToDisplay = (
  date,
  includeMinutes = false,
  includeSeconds = false,
) => {
  if (date && date != '0001-01-01T00:00:00Z') {
    const dateFormated = new Date(date)

    const minutes = includeMinutes
      ? `${dobleDigit(dateFormated.getHours())}:${dobleDigit(
          dateFormated.getMinutes(),
        )}`
      : ''

    const seconds = includeSeconds ? dobleDigit(dateFormated.getSeconds()) : ''

    const finaldate = `${dobleDigit(dateFormated.getDate())}-${dobleDigit(
      dateFormated.getMonth() + 1,
    )}-${dateFormated.getFullYear()} ${minutes}${seconds}`

    const final = moment(date)
    const max = moment('2099-12-31')

    return final.isAfter(max) ? '31-12-2099' : finaldate
  } else {
    return '' //N/A
  }
}
export const formatDateToInsertIntoComponent = (
  date,
  includeMinutes = false,
) => {
  const dateFormated = new Date(date)
  const minutes = includeMinutes
    ? `T${dobleDigit(dateFormated.getUTCHours())}:${dobleDigit(
        dateFormated.getUTCMinutes(),
      )}`
    : ''

  return `${dateFormated.getUTCFullYear()}-${dobleDigit(
    dateFormated.getUTCMonth() + 1,
  )}-${dobleDigit(dateFormated.getUTCDate())}${minutes}`
}

export const getDate = (time) => {
  const dateFormated = new Date(time)

  return `${dobleDigit(dateFormated.getDate())}-${dobleDigit(
    dateFormated.getMonth() + 1,
  )}-${dateFormated.getFullYear()}`
}

export const getHour = (time) => {
  const dateFormated = new Date(time)

  return `${dobleDigit(dateFormated.getHours())}:${dobleDigit(
    dateFormated.getMinutes(),
  )}`
}

export const getTimeBetween = (unit, date1, date2) => {
  const dateFormated1 = moment(date1)
  const dateFormated2 = moment(date2)

  return dateFormated2.diff(dateFormated1, unit) //years, months, weeks, days, hours, minutes, and seconds
}
