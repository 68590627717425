import { operatorConstant } from '../../constants/operator_constant'
import { operatorService } from '../../services/operatorService'

import { alertActions } from './alert_actions'

import { loginService } from '../../services/loginService'
import { operatorUserActions } from './operatorUser_actions'

import i18next from 'i18next'

export const operatorActions = {
  getAll,
  insert,
  updateRestricted,
  update,
  getOperatorInfo,
  insertOperatorUsers,
  validateOperator,
  getAllToValidate,
  getAllPrivateOperators,
  getallValidatedOperators,
  getAllDiscounts,
  createDiscount,
  updateDiscount,
  getAffectedCustomersByDiscount,
  getDiscountsByCustomerId,
  getParent,
  // getChilds,
  getAllChilds,
  getWithNoParent,
  getByCustomers,
  getUserDataByEmail,
  updateOperatorUsers,
  getOperatorUses,
  setGenericDiscount,
  updatePersonalizedDiscountOfLoggedOperator,
  getAllDiscountsOfLoggedOperator,
  getCurrentDiscountsOfOperator,
  getGenericDiscountOfLoggedOperator,
  getCurrentDiscountsOfLoggedOperatorForCustomer,
  getCurrentDiscountsOfOperatorForCustomer,
  getAssignableCustomersToDiscount,
  getVehicleHomologationTypes,
}

function getCurrentDiscountsOfOperatorForCustomer(body) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getCurrentDiscountsOfOperatorForCustomer(body).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_REQUEST,
    }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_FAILURE,
      error,
    }
  }
}

function getAssignableCustomersToDiscount(discountId = null) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAssignableCustomersToDiscount(discountId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: operatorConstant.GET_ASSIGNABLE_CUSTOMERS_REQUEST,
    }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_ASSIGNABLE_CUSTOMERS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_ASSIGNABLE_CUSTOMERS_FAILURE,
      error,
    }
  }
}

function getCurrentDiscountsOfLoggedOperatorForCustomer(customerId) {
  return (dispatch) => {
    dispatch(request())
    operatorService
      .getCurrentDiscountsOfLoggedOperatorForCustomer(customerId)
      .then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
        },
      )
  }

  function request() {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_REQUEST,
    }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FOR_CUSTOMER_FAILURE,
      error,
    }
  }
}

function getGenericDiscountOfLoggedOperator() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAllDiscountsOfLoggedOperator().then(
      (results) => {
        dispatch(success(results.find((r) => r.isGeneric) ?? {}))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: operatorConstant.GET_GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_REQUEST,
    }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_GENERIC_DISCOUNTS_OF_LOGGED_OPERATORS_FAILURE,
      error,
    }
  }
}
function getCurrentDiscountsOfOperator(operatorId) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getCurrentDiscountsOfOperator(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_OPERATORS_REQUEST,
    }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_OPERATORS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_OPERATORS_FAILURE,
      error,
    }
  }
}

function getAllDiscountsOfLoggedOperator() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAllDiscountsOfLoggedOperator().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_REQUEST,
    }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_CURRENT_DISCOUNTS_OF_LOGGED_OPERATORS_FAILURE,
      error,
    }
  }
}

function updatePersonalizedDiscountOfLoggedOperator(body) {
  const sharingOperatorId = body.sharingOperatorId
  delete body.sharingOperatorId
  return (dispatch) => {
    operatorService.updatePersonalizedDiscountOfLoggedOperator(body).then(
      (results) => {
        dispatch(
          alertActions.success('Descuento genérico modificado correctamente'),
        )
        // dispatch(getAllDiscounts(sharingOperatorId));
        getAllDiscountsOfLoggedOperator()
      },
      (error) => {
        // dispatch(
        //   alertActions.error(
        //     "Ha ocurrido un error al actualizar. Por favor reintente. Message:" +
        //       error
        //   )
        // );
      },
    )
  }
}

function setGenericDiscount(body) {
  return (dispatch) => {
    operatorService.setGenericDiscount(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.operator.setGenericDiscountSuccess'),
          ),
        )
        dispatch(getGenericDiscountOfLoggedOperator())
      },
      (error) => {
        dispatch(alertActions.error(error))
      },
    )
  }
}

function getOperatorUses() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getOperatorUses().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            i18next.t('services.operator.getOperatorUsesError'),
          ),
        )
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_OPERATOR_USER_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_OPERATOR_USER_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_OPERATOR_USER_FAILURE, error }
  }
}

function updateOperatorUsers(body) {
  return (dispatch) => {
    operatorService.updateOperatorUsers(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.operator.updateOperatorUsersSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.operator.updateOperatorUsersError'),
          ),
        )
      },
    )
  }
}

function getUserDataByEmail(email) {
  return (dispatch) => {
    operatorService.getUserDataByEmail(email).then((results) => {
      dispatch(success(results.length > 0))
    })
  }
  function success(results) {
    return { type: operatorConstant.GET_USER_DATA_BY_MAIL_SUCCESS, results }
  }
}

function getByCustomers(customerId) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getByCustomers(customerId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all operators by customerId. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_BY_CUSTOMERS_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_BY_CUSTOMERS_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_BY_CUSTOMERS_FAILURE, error }
  }
}

function getParent(operatorId) {
  return (dispatch) => {
    operatorService.getParent(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        // dispatch(
        //   alertActions.error(
        //     "Ha ocurrido un error al recoger datos. Por favor reintente. Message:" +
        //       error
        //   )
        // );
      },
    )
  }
  function success(results) {
    return { type: operatorConstant.GET_PARENT_SUCCESS, results }
  }
}

function getWithNoParent() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getWithNoParent().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get operadores padres. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_FAILURE, error }
  }
}

function getAllChilds(operatorId) {
  return (dispatch) => {
    operatorService.getAllChilds(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        // dispatch(
        //   alertActions.error(
        //     "Ha ocurrido un error al recoger datos. Por favor reintente. Message:" +
        //       error
        //   )
        // );
      },
    )
  }
  function request() {
    return { type: operatorConstant.GET_ALL_CHILDS_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_CHILDS_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_CHILDS_FAILURE, error }
  }
}

function getDiscountsByCustomerId(customerId) {
  return (dispatch) => {
    operatorService.getDiscountsByCustomerId(customerId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        // dispatch(
        //   alertActions.error(
        //     "Ha ocurrido un error al recoger datos. Por favor reintente. Message:" +
        //       error
        //   )
        // );
      },
    )
  }
  function request() {
    return { type: operatorConstant.GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_DISCOUNTS_SUCCESS, results }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_ALL_DISCOUNTS_BY_CUSTOMER_ID_FAILURE,
      error,
    }
  }
}

function updateDiscount(body, onSuccess = () => {}) {
  const { preSelectCustomer } = body
  delete body.preSelectCustomer
  return (dispatch) => {
    operatorService.updateDiscount(body).then(
      (results) => {
        onSuccess()
        dispatch(
          alertActions.success(
            i18next.t('services.operator.updateDiscountSuccess'),
          ),
        )
        dispatch(getAllDiscountsOfLoggedOperator())
        preSelectCustomer &&
          dispatch(
            getCurrentDiscountsOfLoggedOperatorForCustomer(
              preSelectCustomer.customerId,
            ),
          )
      },
      (error) => {
        dispatch(alertActions.error(error))
      },
    )
  }
}

function getAffectedCustomersByDiscount(discountId) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAffectedCustomersByDiscount(discountId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "Ha ocurrido un error al recoger clientes. Por favor reintente. Message:" +
        //       error
        //   )
        // );
      },
    )
  }

  function request() {
    return {
      type: operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_REQUEST,
    }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_ALL_CUSTOMERS_AFFECTED_BY_DISCOUNT_FAILURE,
      error,
    }
  }
}

function createDiscount(body, onSuccess = () => {}) {
  const { preSelectCustomer } = body
  delete body.preSelectCustomer
  return (dispatch) => {
    operatorService.createDiscount(body).then(
      (results) => {
        onSuccess()

        dispatch(
          alertActions.success(
            i18next.t('services.operator.createDiscountSuccess'),
          ),
        )
        dispatch(getAllDiscountsOfLoggedOperator())

        preSelectCustomer &&
          dispatch(
            getCurrentDiscountsOfLoggedOperatorForCustomer(
              preSelectCustomer.customerId,
            ),
          )
      },
      (error) => {
        dispatch(alertActions.error(error))
      },
    )
  }
}

function getAllDiscounts(operatorId) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAllDiscounts(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all VALIDATED operators action. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_DISCOUNTS_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_DISCOUNTS_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_DISCOUNTS_FAILURE, error }
  }
}

function getallValidatedOperators() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getallValidatedOperators().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all VALIDATED operators action. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_VALIDATED_OPERATORS_REQUEST }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_ALL_VALIDATED_OPERATORS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_ALL_VALIDATED_OPERATORS_FAILURE,
      error,
    }
  }
}

function getAllPrivateOperators() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAllPrivateOperators().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all private operator action. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_FAILURE, error }
  }
}

function getAllToValidate() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAllToValidate().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all operator to validate action. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_TO_VALIDATE_FAILURE, error }
  }
}

function validateOperator(body, fetchPendingValidate = false) {
  return (dispatch) => {
    operatorService.validateOperator(body).then((results) => {
      let message = ''
      message = body.canOperate
        ? i18next.t('services.operator.validateOperatorSuccess')
        : i18next.t('services.operator.validateOperatorSuccess1')
      dispatch(alertActions.success(message))
      dispatch(fetchPendingValidate ? getAllToValidate() : getAll())
    })
  }
}

function getAll(operatorId) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAll(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.operator.getAllError')))
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_FAILURE, error }
  }
}

function getOperatorInfo(id, dataOperatorLogged = true) {
  return (dispatch) => {
    dispatch(requestOperatorInfo())
    loginService.getOperatorData(id).then((user) => {
      if (dataOperatorLogged) {
        dispatch(successOperatorInfo(user))
      } else {
        dispatch(operatorInfo(user))
      }
    })

    function operatorInfo(user) {
      return { type: operatorConstant.OPERATOR_INFO_SUCCESS, user }
    }

    function successOperatorInfo(user) {
      return { type: operatorConstant.SUCCESS_OPERATOR_INFO, user }
    }
    function requestOperatorInfo() {
      return { type: operatorConstant.REQUEST_OPERATOR_INFO }
    }
  }
}

function updateRestricted(body) {
  const operatorId = body.id

  return (dispatch) => {
    dispatch(request())
    operatorService.updateRestricted(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(
            i18next.t('services.operator.updateRestrictedSuccess'),
          ),
        )
        const obj = {
          sharingOperatorId: operatorId,
          canOperate: false,
          cannotOperateReason:
            'Datos del operador modificados pendientes de validar por Slience',
        }
        //dispatch(userActions.logout());
        // dispatch(getOperatorInfo(operatorId));
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            i18next.t('services.operator.updateRestrictedError'),
          ),
        )
      },
    )
  }

  function request() {
    return { type: operatorConstant.UPDATE_RESTRICTED_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.UPDATE_RESTRICTED_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.UPDATE_RESTRICTED_FAILURE, error }
  }
}

function insert(body) {
  return (dispatch) => {
    dispatch(request())
    operatorService.insert(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(getAll())
        dispatch(
          alertActions.success(i18next.t('services.operator.insertSuccess')),
        )
      },
      (error) => {
        dispatch(failure(error))
        /* dispatch(
                    alertActions.error(i18next.t("services.operator.insertError"))
                ); */
        dispatch(alertActions.error(error))
      },
    )
  }

  function request() {
    return { type: operatorConstant.INSERT_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.INSERT_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.INSERT_FAILURE, error }
  }
}

function insertOperatorUsers(body) {
  const operatorId = body.sharingOperatorId
  return (dispatch) => {
    dispatch(request())
    operatorService.insertOperatorUsers(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(operatorUserActions.getAll(operatorId))
        dispatch(
          alertActions.success(i18next.t('services.operator.insertSuccess')),
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.operator.insertSuccess')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: operatorConstant.INSERT_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.INSERT_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.INSERT_FAILURE, error }
  }
}

function update(body) {
  return (dispatch) => {
    dispatch(request())
    operatorService.update(body).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(
            i18next.t('services.operator.updateRestrictedSuccess'),
          ),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.operator.updateRestrictedError')}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: operatorConstant.UPDATE_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.UPDATE_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.UPDATE_FAILURE, error }
  }
}

function getVehicleHomologationTypes() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getVehicleHomologationTypes().then(
      (results) => {
        //console.log("results getVehicleHomologationTypes (action) => ", results)
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_VEHICLE_HOMOLOGATION_TYPES_REQUEST }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_VEHICLE_HOMOLOGATION_TYPES_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_VEHICLE_HOMOLOGATION_TYPES_FAILURE,
      error,
    }
  }
}
