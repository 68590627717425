import { fotaConstants } from '../../../constants/fota/fota_constants'

const initialState = {
  loadingResults: false,
  results: [],
}

export function fotaReducer(state = initialState, action) {
  switch (action.type) {
    case fotaConstants.FILTER_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case fotaConstants.FILTER_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case fotaConstants.FILTER_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case fotaConstants.DETAILS_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case fotaConstants.DETAILS_SUCCESS:
      return {
        ...state,
        fota: action.fota,
        loadingResults: false,
      }
    case fotaConstants.DETAILS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case fotaConstants.GETALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case fotaConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case fotaConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case fotaConstants.GETALL_PROCESSID_SUCCESS:
      return {
        ...state,
        processIds: action.result,
      }
    case fotaConstants.CANCEL_REQUEST:
      return {
        ...state,
      }
    case fotaConstants.CANCEL_SUCCESS:
      return {
        ...state,
      }
    case fotaConstants.CANCEL_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case fotaConstants.CLEAR_RESULTS:
      return {
        ...state,
        results: [],
        loadingResults: false,
      }
    case fotaConstants.GET_MASTER_DATA_SUCCESS:
      return {
        ...state,
        status: action.result.fotaStatusValues,
      }
    case fotaConstants.ADD_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case fotaConstants.ADD_SUCCESS:
      return {
        ...state,
        loadingResults: false,
      }
    case fotaConstants.ADD_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    default:
      return state
  }
}
