import React, { Fragment, useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { operatorActions } from '../../../redux/actions/operator_action'

import ModalOperatorValidate from '../../operator/modal/modalOperatorValidate'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../../shared/customMaterialTable/index'
const OperatorsPendingValidate = ({
  validateOperator,
  getAllToValidate,
  operatorReducer,
}) => {
  const { t } = useTranslation()
  const [dataOperatorSelected, setdataOperatorSelected] = useState([])
  const [isModalOpen, setisModalOpen] = useState(false)

  useEffect(() => {
    getAllToValidate()
  }, [])

  const handleClickAction = (event, rowData) => {
    setdataOperatorSelected(rowData)
    setisModalOpen(true)
  }

  return (
    <Fragment>
      <CustomMaterialTable
        data={operatorReducer.results}
        isLoading={operatorReducer.loadingResults}
        saveFilters={true}
        tab={'customersValidate'}
        columns={[
          {
            title: t('operator.validate.name'),
            field: 'name',
          },
          { title: t('operator.validate.email'), field: 'managerEmail' },
          { title: t('operator.validate.contactPhone'), field: 'contactPhone' },
          { title: t('operator.validate.nif'), field: 'cif' },
        ]}
        title={t('operator.validate.titleValidateTable')}
        actions={[
          (rowData) => ({
            icon: `check_circle_outline`,
            tooltip: t('operator.validate.actions.enableOperator'),
            onClick: (event, rowData) => handleClickAction(event, rowData),
            iconProps: {
              style: {
                color: 'red',
              },
            },
          }),
        ]}
      />
      <ModalOperatorValidate
        fetchPendingValidate={true}
        data={dataOperatorSelected}
        isOpen={isModalOpen}
        onClose={() => {
          setisModalOpen(false)
        }}
      />
    </Fragment>
  )
}

function mapState(state) {
  const { operatorReducer } = state
  return { operatorReducer }
}

const actionCreators = {
  getAllToValidate: operatorActions.getAllToValidate,
  validateOperator: operatorActions.validateOperator,
}

export default connect(mapState, actionCreators)(OperatorsPendingValidate)
