import { upgradeConstants } from '../../../constants/fota/upgrade_constants'

const initialState = {
  showResults: true,
  loadingResults: false,
  results: [],
  firmwaresSelected: [],
  firmwares: [],
}

export function upgradeReducer(state = initialState, action) {
  switch (action.type) {
    case upgradeConstants.FILTER_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case upgradeConstants.FILTER_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case upgradeConstants.FILTER_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case upgradeConstants.DETAILS_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case upgradeConstants.DETAILS_SUCCESS:
      return {
        ...state,
        upgrade: action.upgrade,
        loadingResults: false,
      }
    case upgradeConstants.DETAILS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case upgradeConstants.GETALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case upgradeConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case upgradeConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case upgradeConstants.DELETE_REQUEST:
      return {
        ...state,
      }
    case upgradeConstants.DELETE_SUCCESS:
      return {
        ...state,
        results: state.results.filter((x) => x.id !== action.id),
      }
    default:
      return state
  }
}
