import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const AssignCustomerConfirmation = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation()
  const [validatedSince, setvalidatedSince] = useState('')
  const [validatedUntil, setvalidatedUntil] = useState('')

  const handleClose = () => {
    onClose()
  }

  const handleConfirm = () => {
    onClose()
    onConfirm(validatedSince, validatedUntil)
  }
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('customers.assignCustomerConfirmation.assignManualy')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('customers.assignCustomerConfirmation.textDateRange')}
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('customers.assignCustomerConfirmation.lableSince')}
                type="datetime-local"
                value={validatedSince}
                onChange={(e) => setvalidatedSince(e.target.value)}
                // defaultValue="2017-05-24T10:30"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('customers.assignCustomerConfirmation.labelUntil')}
                type="datetime-local"
                value={validatedUntil}
                onChange={(e) => setvalidatedUntil(e.target.value)}
                // defaultValue="2017-05-24T10:30"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('customers.assignCustomerConfirmation.cancelBtn')}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {t('customers.assignCustomerConfirmation.confirmBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default AssignCustomerConfirmation
