import React, { Fragment, useEffect, useState } from 'react'
import useStyles from './styles'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { connect } from 'react-redux'
import { operatorActions } from '../../../../redux/actions/operator_action'

import CircularProgress from '@material-ui/core/CircularProgress'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { validations } from '../../../../helpers/validation'

const RegisterOperatorManager = ({
  insert,
  operatorReducer,
  getAll,
  userReducer,
  getUserDataByEmail,
  getVehicleHomologationTypes,
}) => {
  const vehicleHomologationTypes =
    operatorReducer.vehicleHomologationTypes || null
  const classes = useStyles()
  const { t } = useTranslation()
  const { insertPending, insertFailure, insertSuccess } = operatorReducer
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const [cif, setCif] = useState('')
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [username, setUsername] = useState('')
  const [vehicleType, setVehicleType] = useState('')
  const [operatorType, setOperatorType] = useState(false)
  const [parent, setParent] = useState(null)
  const [repeatPassword, setRepeatPassword] = useState('')
  const [helpertextrepeatPassword, setHelpertextRepeatPassword] =
    useState(false)
  const [costByVehicle, setCostByVehicle] = useState()
  const [serviceCommissionPercent, setServiceCommissionPercent] = useState()
  const [minimumAge, setMinimumAge] = useState(18)
  const [free, setFree] = useState(false)
  const [errors, setErrors] = useState({
    phone: { result: false, message: '' },
  })

  useEffect(() => {
    setHelpertextRepeatPassword(password === repeatPassword)
  }, [repeatPassword, password])

  useEffect(() => {
    if (operatorReducer.vehicleHomologationTypes == null) {
      getVehicleHomologationTypes()
    }
    setVehicleType(vehicleHomologationTypes ? 1 : '') //Set default vehicle type to (Scooter (L3e-A1))
    getAll()
  }, [])

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const validateForm = () => {
    let newErrors = errors
    newErrors.phone = validations.validatePhone(
      phoneNumber.charAt(0) === '+' ? phoneNumber : `+${phoneNumber}`,
    )

    setErrors({ ...newErrors })
  }

  const handleSubmit = () => {
    validateForm()

    if (isFormValid()) {
      const body = {
        username: username,
        password: password,
        managerEmail: mail,
        contactPhone:
          phoneNumber.charAt(0) === '+' ? phoneNumber : `+${phoneNumber}`,
        cif: cif,
        name: name,
        parentId: parent ? parent.id : null,
        isPrivate: operatorType,
        costByVehicle: parseFloat(costByVehicle) || 0,
        serviceCommissionPercent: parseFloat(serviceCommissionPercent),
        vehicleTypes: [vehicleType],
        isFree: free,
        minimumAge: parseInt(minimumAge),
      }

      setPhoneNumber('')

      insert(body)
    }
  }

  useEffect(() => {
    if (insertSuccess) {
      setMail('')
      setPassword('')
      setCif('')
      setName('')
      setPhoneNumber('')
      setUsername('')
      setCostByVehicle('')
      setServiceCommissionPercent('')
      setVehicleType('')
      setMinimumAge(18)
      setErrors({
        phone: { result: false, message: '' },
      })
    }
  }, [insertSuccess])

  const autocompleteList = [
    ...(Array.isArray(operatorReducer.results)
      ? operatorReducer.results
      : [operatorReducer.results]),
  ]

  const handleCheckIfEmailExist = () => {
    getUserDataByEmail(mail)
  }

  const handleChangeMail = (email) => {
    setMail(email)
  }

  useEffect(() => {
    handleCheckIfEmailExist(mail)
  }, [mail])

  useEffect(() => {
    operatorReducer.userPendingToCreateExist && setPassword(null)
  }, [operatorReducer.userPendingToCreateExist])

  const getVehiclesTypes = (vehicleHomologationTypes) => {
    const vehiclesTypesArray =
      Object.entries(vehicleHomologationTypes).map((e) => ({
        ['key']: parseInt(e[0]),
        ['name']: e[1],
      })) ?? []
    //console.log("vehiclesTypesArray transformed => ", vehiclesTypesArray)
    if (vehiclesTypesArray.length == 0) {
      return (
        <MenuItem key={0} value={''}>
          {'No options'}
        </MenuItem>
      )
    }
    return vehiclesTypesArray.map((vehicleTypeItem) => {
      //console.log("vehicleType item => ", vehicleTypeItem)
      return (
        <MenuItem key={vehicleTypeItem.key} value={vehicleTypeItem.key}>
          {vehicleTypeItem.name}
        </MenuItem>
      )
    })
  }

  return (
    <Container component="main" maxWidth="md" style={{ overflow: 'hidden' }}>
      <div className={classes.paper}>
        {insertPending ? (
          <div style={{ 'margin-top': '10%' }}>
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  // variant="outlined"
                  required
                  fullWidth
                  label={t('operator.register.operatorManager.name')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // variant="outlined"
                  required
                  fullWidth
                  label={t('operator.register.operatorManager.username')}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  // variant='standard'
                  required
                  fullWidth
                  label={t('operator.register.operatorManager.email')}
                  value={mail}
                  onChange={(e) => handleChangeMail(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  type="password"
                  label={t('operator.register.operatorManager.password')}
                  disabled={operatorReducer.userPendingToCreateExist}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  helperText={
                    operatorReducer.userPendingToCreateExist
                      ? t(
                          'operator.register.operatorManager.helperTextPasswordField',
                        )
                      : ''
                  }
                  error={operatorReducer.userPendingToCreateExist}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  // variant='standard'
                  required
                  fullWidth
                  type="password"
                  label={t('operator.register.operatorManager.password')}
                  disabled={operatorReducer.userPendingToCreateExist}
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  helperText={
                    !helpertextrepeatPassword &&
                    !operatorReducer.userPendingToCreateExist &&
                    t(
                      'operator.register.operatorManager.helperTextRepeatPassword',
                    )
                  }
                  error={
                    !helpertextrepeatPassword &&
                    !operatorReducer.userPendingToCreateExist
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label={t('operator.register.operatorManager.cif')}
                  value={cif}
                  onChange={(e) => setCif(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <PhoneInput
                  onChange={(phone) => {
                    setPhoneNumber(phone)
                  }}
                  country={'es'}
                  value=""
                  preferredCountries={['es', 'fr']}
                  inputStyle={{
                    width: '100%',
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottomColor: 'rgba(0, 0, 0, 0.42)',
                    borderBottomWidth: 1,
                    borderRadius: 0,
                    fontFamily: 'Montserrat',
                    fontSize: '1.000714rem',
                    fontWeight: 400,
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}
                  buttonStyle={{
                    border: 0,
                    borderWidth: 1,
                    backgroundColor: 'inherit',
                  }}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid
                item
                xs={6}
                alignItems="flex-start"
                style={{ padding: 0, paddingLeft: 8, paddingRight: 8 }}
              >
                {errors.phone.message && (
                  <p style={{ margin: 0, color: 'red', fontSize: '0.75rem' }}>
                    {' '}
                    {errors.phone.message}{' '}
                  </p>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label={t('operator.register.operatorManager.costByVehicle')}
                  value={costByVehicle}
                  onChange={(e) => setCostByVehicle(e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label={t(
                    'operator.register.operatorManager.serviceCommissionPercent',
                  )}
                  value={serviceCommissionPercent}
                  onChange={(e) => setServiceCommissionPercent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t('operator.profile.personalData.isFree')}
                  </InputLabel>
                  <Select
                    value={free}
                    onChange={(e) => setFree(e.target.value)}
                  >
                    <MenuItem value={true}>
                      {t('operator.profile.personalData.yes')}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t('operator.profile.personalData.no')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* VEHICLE TYPE */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t('operator.profile.personalData.vehicleType')}
                  </InputLabel>
                  <Select
                    value={vehicleType}
                    onChange={(e) => setVehicleType(e.target.value)}
                  >
                    {getVehiclesTypes(vehicleHomologationTypes)}
                  </Select>
                </FormControl>
              </Grid>
              {/*  */}

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label={t('operator.register.operatorManager.minimumAge')}
                  value={minimumAge}
                  onChange={(e) => setMinimumAge(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t('operator.profile.personalData.operatorType')}
                  </InputLabel>
                  <Select
                    value={operatorType}
                    onChange={(e) => setOperatorType(e.target.value)}
                  >
                    <MenuItem value={true}>
                      {t('operator.profile.personalData.private')}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t('operator.profile.personalData.public')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  size="small"
                  onChange={(event, value) => setParent(value)}
                  className={classes.autocomplete}
                  options={autocompleteList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      focused
                      {...params}
                      // variant="outlined"
                      label={t(
                        'operator.register.operatorManager.labelAutocompleteOperators',
                      )}
                      placeholder={t(
                        'operator.register.operatorManager.placeHolderAutocompleteOperators',
                      )}
                      className={classes.inputFilterOperator}
                      InputLabelProps={{
                        style: { color: 'black', borderColor: 'black' },
                      }}
                      helperText={t(
                        'operator.register.operatorManager.helperTextparentSection',
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleSubmit()}
            >
              {t('operator.register.operatorManager.submitBtn')}
            </Button>
          </Fragment>
        )}
      </div>
    </Container>
  )
}

function mapState(state) {
  const { operatorReducer, userReducer } = state
  return { operatorReducer, userReducer }
}
const actionCreators = {
  insert: operatorActions.insert,
  getAll: operatorActions.getAll,
  getUserDataByEmail: operatorActions.getUserDataByEmail,
  getVehicleHomologationTypes: operatorActions.getVehicleHomologationTypes,
}

export default connect(mapState, actionCreators)(RegisterOperatorManager)
