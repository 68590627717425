import { Grid } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { GoogleApiWrapper, Map, Polyline } from 'google-maps-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import stringConstants from '../../../constants/strings'

import { formatDateToDisplay } from '../../shared/formatDate'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  containerGrid: {
    padding: '2%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const LastTrip = ({
  isOpen,
  onClose,
  selectedVehicle,
  scooterReducer,
  getTripDetails,
  google,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { tripDetail, loadingResults, trips } = scooterReducer

  const [lastTripData, setlastTripData] = useState({})
  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    // if (trips.length > 0) {
    //   let tempTripData = trips[0];
    //   let tempTrip = new Date(trips[0].startTimestamp);
    //   trips.map((t) => {
    //     const dateT = new Date(t.startTimestamp);
    //     if (dateT > tempTrip && tempTrip) {
    //       tempTrip = dateT;
    //       tempTripData = t;
    //     }
    //   });
    //   const newPoints = tempTripData.points.map((p) => {
    //     return { lat: p.lat, lng: p.lon };
    //   });
    //   getTripDetails(selectedVehicle && selectedVehicle.id, tempTripData.id);
    //   setlastTripData({ ...tempTripData, points: newPoints });
    // }
  }, [trips])

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '80vh',
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('scooters.details.trips.page.detailTrip.lastTrip')}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.containerGrid}>
          <Grid item xs={12} sm={6}>
            {Object.keys(tripDetail).length != 0 ? (
              <div className={classes.tripDetails}>
                <strong>
                  {t('scooters.details.trips.page.detailTrip.title')}
                </strong>
                <br />
                <div className={classes.streetDirectionsGroup}>
                  <p>
                    {t('scooters.details.trips.page.detailTrip.from')}
                    {tripDetail.fromDescription}
                  </p>
                  <p>
                    {t('scooters.details.trips.page.detailTrip.to')}
                    {tripDetail.toDescription}
                  </p>
                </div>
                <div className={classes.group}>
                  <p>
                    {t('scooters.details.trips.page.detailTrip.distance')}
                    {tripDetail.distance}m
                  </p>
                  <p>
                    {t('scooters.details.trips.page.detailTrip.coSave')}
                    {(Math.round(tripDetail.distance * 100) / 100).toFixed(2)}
                  </p>
                </div>
                <div className={classes.group}>
                  <p>
                    {t('scooters.details.trips.page.detailTrip.start')}
                    {formatDateToDisplay(tripDetail.startTimestamp, true)}
                  </p>
                  <p>
                    {t('scooters.details.trips.page.detailTrip.finish')}
                    {formatDateToDisplay(tripDetail.endTimestamp, true)}
                  </p>
                </div>
                <div className={classes.group}>
                  <p>
                    {t('scooters.details.trips.page.detailTrip.start_battery')}
                    {tripDetail.startBattery}%
                  </p>
                  <p>
                    {t('scooters.details.trips.page.detailTrip.finish_battery')}
                    {tripDetail.endBattery}%
                  </p>
                </div>
                <div className={classes.group}>
                  <p>
                    {t('scooters.details.trips.page.detailTrip.speed_avg')}:
                    {(Math.round(tripDetail.speedAvg * 100) / 100).toFixed(2)}
                    km/h
                  </p>
                  <p>
                    {t('scooters.details.trips.page.detailTrip.speed_max')}
                    {tripDetail.speedMax}km/h
                  </p>
                </div>
              </div>
            ) : (
              'Data not found'
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {lastTripData.points && (
              <Map
                containerStyle={containerStyle}
                google={google}
                zoom={16}
                initialCenter={{
                  lat: lastTripData.points[0].lat,
                  lng: lastTripData.points[0].lng,
                }}
              >
                <Polyline
                  path={lastTripData.points}
                  strokeColor="#0000FF"
                  strokeOpacity={1}
                  strokeWeight={2}
                />
              </Map>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: stringConstants.GOOGLE_API_KEY,
  // libraries: ["drawing", "visualization"],
  libraries: ['drawing,visualization'],
})(LastTrip)
