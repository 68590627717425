import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../redux/reducers/index'

const loggerMiddleware = createLogger()

const middleware = [thunkMiddleware]

if (process.env.NODE_ENV === 'development') middleware.push(loggerMiddleware)

const store = createStore(rootReducer, applyMiddleware(...middleware))

export default store
