import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import PersonalData from './personalData/personalData'

import { connect } from 'react-redux'

import { operatorActions } from '../../../redux/actions/operator_action'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}))

const ProfileView = ({ operatorReducer }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <PersonalData data={operatorReducer.dataOperatorLogged} />
    </div>
  )
}

function mapState(state) {
  const { scooterReducer, operatorReducer } = state
  return { scooterReducer, operatorReducer }
}

const actionCreators = {
  updateRestricted: operatorActions.updateRestricted,
}

export default connect(mapState, actionCreators)(ProfileView)
