import { handleCall } from './handleResponse'

export const customerService = {
  getAll,
  getPendingToValidate,
  getById,
  update,
  filter,
  _delete,
  insert,
  assignCustomersToOperator,
  validate,
  getAllByOperatorId,
  customersByOperators,
  customersByOperatorsById,
  applyOperatorDiscount,
  unassignCustomersOperator,
  customersByPrivateOperatorId,
  updateAssignOperatorDates,
  getAllCountries,
  editAssignCustomersToOperator,
  getWeekDays,
  setCalendar,
  getCalendar,
  getCustomerValidationRequests,
  setImageRotations,
  validateAndUpdate,
  updateForAdmin,
}

function updateAssignOperatorDates(body) {
  return handleCall(`/customers/v1/Customers/UpdateAssignOperatorDates`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}
function customersByPrivateOperatorId(operatorId) {
  return handleCall(
    `/customers/v1/Customers/GetByOperator?sharingOperatorId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function applyOperatorDiscount(body) {
  return handleCall(`/customers/v1/Customers/ApplyOperatorDiscounts`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function customersByOperatorsById(operatorId) {
  return handleCall(
    `/customers/v1/CustomerByOperators?field=SharingOperatorId&value=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function customersByOperators() {
  return handleCall(`/customers/v1/CustomerByOperators`, {
    method: 'GET',
    body: null,
  })
}

function getAllByOperatorId(operatorId) {
  return handleCall(
    `/customers/v1/Customers/GetByOperator?SharingOperatorId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function validate(body) {
  const customerId = body.customerId
  delete body.customerId

  return handleCall(`/customers/v1/Customers/${customerId}/Validate`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function unassignCustomersOperator(body) {
  return handleCall(`/customers/v1/Customers/unassignOperator`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function assignCustomersToOperator(body) {
  return handleCall(`/customers/v1/Customers/AssignOperator`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function editAssignCustomersToOperator(body) {
  return handleCall(`/customers/v1/Customers/AssignOperator`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function insert(body) {
  return handleCall(`/customers/v1/Customers/Register`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getAll() {
  return handleCall(`/customers/v1/Customers`, {
    method: 'GET',
    body: null,
  })
}

function getPendingToValidate() {
  return handleCall(`/customers/v1/customers/GetPendingToValidate`, {
    method: 'GET',
    body: null,
  })
}

function getById(id) {
  return handleCall(`/customers/v1/Customers/${id}`, {
    method: 'GET',
    body: null,
  })
}

function update(upgrade) {
  const id = JSON.stringify(upgrade).id
  return handleCall(`/customers/v1/Customers/${id}`, {
    method: 'PUT',
    body: JSON.stringify(upgrade),
  })
}

function filter(data) {
  const querystring = data.field + '&value=' + data.value
  return handleCall(`/customers/v1/customers/?field=${querystring}`, {
    method: 'GET',
    body: null,
  })
}

function _delete(id) {
  return handleCall(`/customers/v1/customers/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function updateForAdmin(email, data) {
  return handleCall(
    `/customers/v1/Customers/UpdateForAdmin?email=${encodeURIComponent(email)}`,
    {
      method: 'PUT',
      body: data,
    },
  )
}

function getAllCountries() {
  return handleCall(`/customers/v1/Customers/GetAllCountries`, {
    method: 'GET',
    body: null,
  })
}

function getWeekDays() {
  return handleCall(`/customers/v1/Schedule/GetWeekDays`, {
    method: 'GET',
    body: null,
  })
}

function setCalendar(body) {
  const shiftDates = { shiftDays: body }
  return handleCall(`/customers/v1/Schedule/SetCalendar`, {
    method: 'POST',
    body: JSON.stringify(shiftDates),
  })
}

function getCalendar() {
  return handleCall(`/customers/v1/Schedule/GetCalendar`, {
    method: 'GET',
    body: null,
  })
}

function getCustomerValidationRequests() {
  return handleCall(`/customers/v1/Documents/GetCustomerValidationRequests`, {
    method: 'GET',
    body: null,
  })
}

function setImageRotations(body) {
  const {
    customerId = '',
    nifFront = 0,
    nifBack = 0,
    licenseFront = 0,
    licenseBack = 0,
  } = body

  return handleCall(
    `/customers/v1/Documents/AddRotationToDocuments?customerId=${customerId}&nifFront=${nifFront}&nifBack=${nifBack}&licenseFront=${licenseFront}
    &licenseBack=${licenseBack}`,
    {
      method: 'PUT',
      body: null,
    },
  )
}

function validateAndUpdate(email, data) {
  return handleCall(
    `/customers/v1/Customers/ValidateAndUpdate?email=${encodeURIComponent(
      email,
    )}`,
    {
      method: 'PUT',
      body: data,
    },
  )
}
