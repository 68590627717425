import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const IncludeHelmet = ({ isOpen, onClose, data, onConfirm, allSelected }) => {
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }
  const { sharing } = data || {}

  const handleConfirm = () => {
    onConfirm(data)
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t('operator.includeHelmet.dialogTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {sharing && sharing.isHelmetIncluded
              ? t('operator.includeHelmet.dialogContentTextRemoveHelmet')
              : t('operator.includeHelmet.dialogContentTextAddHelmet')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('operator.includeHelmet.cancelBtn')}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {t('operator.includeHelmet.confirmBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default IncludeHelmet
