import { handleCall } from './handleResponse'

export const notificationsService = {
  insert,
  getAll,
  update,
}

function update(body) {
  const notificationId = body.notificationId
  delete body.notificationId
  return handleCall(`/sharing/v1/Notifications/${notificationId}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Notifications/${notificationId}/Update`,
  //   requestOptions
  // ).then(handleResponse);
}

function insert(body) {
  return handleCall(`/sharing/v1/Notifications/New`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  // var requestOptions = {
  //   method: "POST",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Notifications/New`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAll(operatorId) {
  const filter = operatorId
    ? `?field=SharingOperatorId&value=${operatorId}`
    : ''

  return handleCall(`/sharing/v1/Notifications${filter}`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // const filter = operatorId
  //   ? `?field=SharingOperatorId&value=${operatorId}`
  //   : "";

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Notifications${filter}`,
  //   requestOptions
  // ).then(handleResponse);
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     if (response.status === 204 || response.status === 404) {
//       return [];
//     }
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       if (response.status === 401) {
//         var refreshToken = loginService.getWithExpiry("refresh_token");
//         loginService.refreshToken(refreshToken).then((token) => {
//           loginService.setWithExpiry(
//             "token",
//             token.access_token,
//             token.expires_in
//           );
//         });
//       }
//       const error = (data && data.message) || text;
//       return Promise.reject(error);
//     }

//     // check if the token is close to expire
//     var date = new Date();
//     var now_utc = Date.UTC(
//       date.getUTCFullYear(),
//       date.getUTCMonth(),
//       date.getUTCDate(),
//       date.getUTCHours(),
//       date.getUTCMinutes(),
//       date.getUTCSeconds()
//     );
//     var now = new Date(now_utc);
//     now.setMinutes(now.getMinutes() + 20);
//     var token_expire = new Date(loginService.getWithExpiry("token_dateExpiry"));
//     // if in 10 minutes expire, renew the token
//     if (now > token_expire) {
//       refreshToken = loginService.getWithExpiry("refresh_token");
//       loginService.refreshToken(refreshToken).then((token) => {
//         loginService.setWithExpiry(
//           "token",
//           token.access_token,
//           token.expires_in
//         );
//       });
//     }
//     return data;
//   });
// }
