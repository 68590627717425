import {
  Circle,
  GoogleApiWrapper,
  HeatMap,
  Map,
  Marker,
  Polygon,
} from 'google-maps-react'
import moment from 'moment'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import useStyles from './styles'

import InfoWindowEx from './infowidowEx'

import { Grid } from '@material-ui/core'
import {
  isCauUser,
  isManager,
  isManagerReadOnly,
  isSacUser,
  isUserWithRole,
  rolesConstants,
} from '../../../helpers/roles'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import PolymerIcon from '@material-ui/icons/Polymer'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import Autocomplete from '@material-ui/lab/Autocomplete'
import stringConstants from '../../../constants/strings'

import { selectIconMarkerMap } from '../../shared/selectIconMap'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  calculateMapBounds,
  DEFAULT_EUROPE_COORDINATES,
  findGeofencesCenter,
} from '../../../helpers/map'
import { alertActions } from '../../../redux/actions/alert_actions'

const AppUrl = process.env.PUBLIC_URL

const MapsGeofence = ({
  operatorReducer,
  scooterReducer,
  google,
  ongetId,
  onShowOperatorDetails,
  setisConfigParamsDialogOpen,
  onOpenTripsList,
  ongetUserByServiceId,
  customerReducer,
  saveCenterMap,

  heatMapOfLoggedOperator,
  heatMapOfSelectedOperator,
  cleanHeatMapData,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const mapRef = useRef()
  const dispatch = useDispatch()

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '80vh',
  }
  const { mapPosition } = scooterReducer

  const [since, setsince] = useState()
  const [until, setuntil] = useState()
  const [operatorIdSelected, setoperatorIdSelected] = useState()

  const [bounds, setBounds] = useState(null)

  const [centerMap, setcenterMap] = useState()
  const [zoomMap, setzoomMap] = useState()

  const [polygon, setpolygon] = useState([])
  const [circles, setcircles] = useState([])

  const [showCircles, setshowCircles] = useState(false)
  const [showPolygons, setshowPolygons] = useState(false)

  const [activeMarker, setactiveMarker] = useState({})
  const [selectedPlace, setselectedPlace] = useState({})
  const [showInfoWindow, setshowInfoWindow] = useState(false)

  const [operatorSelected, setoperatorSelected] = useState(null)
  const [scooterSelected, setscooterSelected] = useState(null)
  const [circlesListDisplayed, setcirclesListDisplayed] = useState([])
  const [polygonsListDisplayed, setpolygonsListDisplayed] = useState([])
  const [scootersListDisplayed, setscootersListDisplayed] = useState([])
  const [autocompleteModeScooter, setautocompleteModeScooter] = useState(false)
  const [autocompleteList, setautocompleteList] = useState([])

  const [operatorDataFromAreaSelected, setoperatorDataFromAreaSelected] =
    useState(null)

  // useEffect(() => {
  //   const { zoom, center } = mapPosition;
  //   // setzoomMap(zoom);
  //   setcenterMap(findGeofencesCenter(polygon, circles));
  // }, []); //mapPosition

  useEffect(() => {
    fillDisplayList()
  }, [operatorSelected, scooterSelected, scooterReducer.dynamics])

  useEffect(() => {
    formatData()
  }, [operatorReducer.results, operatorReducer.dataOperatorLogged])

  useEffect(() => {
    handleAutocompleteList()
    // setoperatorSelected(null);
    // setscooterSelected(null);
  }, [
    operatorReducer.results,
    scooterReducer.dynamics,
    autocompleteModeScooter,
  ])

  useEffect(() => {
    setcenterMap(findGeofencesCenter(polygon, circles))
    if (polygon.length > 0 || circles.length > 1) {
      handleFitCoordinates()
    }
  }, [circles, polygon])

  useEffect(() => {
    // handleFitMarkers();
  }, [scootersListDisplayed])

  const fillDisplayList = () => {
    if (autocompleteModeScooter) {
      if (scooterSelected && scooterSelected.id != 'clean') {
        setscootersListDisplayed(
          scooterReducer.dynamics.filter(
            (s) => s.scooterId === scooterSelected.scooterId,
          ),
        )
      } else {
        setscootersListDisplayed(scooterReducer.dynamics)
      }
    } else {
      if (operatorSelected && operatorSelected.id != 'clean') {
        setscootersListDisplayed(
          scooterReducer.dynamics.filter(
            (s) =>
              s.sharing && s.sharing.sharingOperatorId === operatorSelected.id,
          ),
        )
        setcirclesListDisplayed(
          circles.filter((c) => c.operatorId === operatorSelected.id),
        )
        setpolygonsListDisplayed(
          polygon.filter((p) => p.operatorId === operatorSelected.id),
        )
      } else {
        setscootersListDisplayed(scooterReducer.dynamics)
        setcirclesListDisplayed(circles)
        setpolygonsListDisplayed(polygon)
      }
    }
  }

  const handleMarkerClick = (props, marker) => {
    setactiveMarker(marker)
    setselectedPlace(props.data)
    props.data.sharing &&
      props.data.sharing.activeServiceId &&
      ongetUserByServiceId(props.data.sharing.activeServiceId)
    setshowInfoWindow(true)
  }

  const handleInfoWindowClose = () => {
    setshowInfoWindow(false)
    setactiveMarker(null)
  }

  const handleMapClick = () => {
    setoperatorDataFromAreaSelected(null)
    if (showInfoWindow) {
      setactiveMarker(null)
      setshowInfoWindow(false)
    }
  }

  const vehicleStatus = (rowData) => {
    const { sharing, scutum } = rowData
    const { isVehicleOn } = scutum || { isVehicleOn: false }
    if (isVehicleOn) return 'ON'
    if (!isVehicleOn) return 'OFF'
  }

  const renderinfowindowContent = () => {
    const {
      imei,
      lastConnectionTimestamp,
      devicePower,
      activeServiceId,
      scooterId,
      sharingOperatorName,
      plate,
      outOfService,
      scutum,
      sharing,
    } = selectedPlace

    return (
      <InfoWindowEx
        marker={activeMarker}
        visible={showInfoWindow}
        onClose={handleInfoWindowClose}
        style={classes.infoWindowEx}
      >
        <div className={classes.infoWindowContent}>
          <h2>{plate}</h2>
          <Grid container>
            <Grid item md={6}>
              <span className={classes.infowindowTitlesRows}>
                {t('scooters.location.page.plate')}:
              </span>
            </Grid>
            <Grid item md={6}>
              <span>{plate}</span>
            </Grid>

            <Grid item md={6}>
              <span className={classes.infowindowTitlesRows}>
                {t('scooters.location.page.lastConnexion')}:{' '}
              </span>
            </Grid>
            <Grid item md={6}>
              <span>
                {moment(lastConnectionTimestamp).format('DD/MM/YYYY HH:mm')}
              </span>
            </Grid>

            <Grid item md={6}>
              <span className={classes.infowindowTitlesRows}>
                {t('scooters.location.page.batteryLvl')}:{' '}
              </span>
            </Grid>
            <Grid item md={6}>
              <span>{scutum && scutum.batterySoc}%</span>
            </Grid>

            <Grid item md={6}>
              <span className={classes.infowindowTitlesRows}>
                {t('scooters.location.page.on')}:{' '}
              </span>
            </Grid>
            <Grid item md={6}>
              <span>{vehicleStatus(selectedPlace)}</span>
            </Grid>

            {isManager() && (
              <Fragment>
                <Grid item md={6}>
                  <span className={classes.infowindowTitlesRows}>
                    {t('scooters.location.page.operatorName')}:{' '}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{sharingOperatorName}</span>
                </Grid>
              </Fragment>
            )}

            <Grid item md={6}>
              <span className={classes.infowindowTitlesRows}>
                {t('scooters.location.page.outOfService')}:{' '}
              </span>
            </Grid>
            <Grid item md={6}>
              <span>
                {sharing && sharing.outOfService
                  ? t('scooters.location.page.Yes')
                  : t('scooters.location.page.No')}
              </span>
            </Grid>
            <Grid item md={6}>
              <span className={classes.infowindowTitlesRows}>
                {t('scooters.location.page.currentUser')}:{' '}
              </span>
            </Grid>
            <Grid item md={6}>
              <span>
                {sharing && sharing.activeServiceId
                  ? customerReducer.customerByService.email
                  : 'N/A'}
              </span>
            </Grid>
          </Grid>
          <div className={classes.linkGroup}>
            <Link onClick={() => ongetId(scooterId)} className={classes.link}>
              {t('scooters.location.page.moreAbout')}
            </Link>

            {/* <Link
              className={classes.link}
              onClick={() => {
                onOpenTripsList(selectedPlace);
              }}
            >
              {t("scooters.location.page.lastTrip")}
            </Link> */}
          </div>
        </div>
      </InfoWindowEx>
    )
  }

  const formatData = () => {
    if (isManager() || isManagerReadOnly() || isCauUser() || isSacUser()) {
      operatorReducer.results.map((o) => {
        o.areas &&
          o.areas.map((a) => {
            setpolygon((prev) => [
              ...prev,
              {
                operatorId: o.id,
                dataOperator: o,
                id: Math.random(),
                coord: a.map((c) => {
                  return { lat: c.lat, lng: c.lon }
                }),
              },
            ])
          })
        o.circles &&
          o.circles.map((c) => {
            setcircles((prev) => [
              ...prev,
              {
                operatorId: o.id,
                dataOperator: o,
                id: Math.random(),
                lat: c.lat,
                lng: c.lon,
                rad: c.radius,
              },
            ])
          })
      })
    } else {
      const o = operatorReducer.dataOperatorLogged
      o.areas &&
        o.areas.map((a) => {
          setpolygon((prev) => [
            ...prev,
            {
              operatorId: o.id,
              dataOperator: o,
              id: Math.random(),
              coord: a.map((c) => {
                return { lat: c.lat, lng: c.lon }
              }),
            },
          ])
        })
      o.circles &&
        o.circles.map((c) => {
          setcircles((prev) => [
            ...prev,
            {
              operatorId: o.id,
              dataOperator: o,
              id: Math.random(),
              lat: c.lat,
              lng: c.lon,
              rad: c.radius,
            },
          ])
        })
    }
  }

  const handleActiveShowCircle = () => {
    fillDisplayList()
    setshowCircles((prev) => !prev)
  }
  const handleActiveShowPolygons = () => {
    fillDisplayList()
    setshowPolygons((prev) => !prev)
  }

  const handleAreaClick = (operatorData) => {
    setoperatorDataFromAreaSelected(operatorData)
  }

  const handleActiveScooterAutocompleteFilter = () => {
    setautocompleteModeScooter((prev) => !prev)
  }

  const handleAutocompleteList = () => {
    const displayListOperator = Array.isArray(operatorReducer.results)
      ? operatorReducer.results
      : [operatorReducer.results]

    const dynamicsWithNoGPSNull = scooterReducer.dynamics.filter(
      (s) => s.gps && s.plate,
    )
    const displayListScooter = Array.isArray(dynamicsWithNoGPSNull)
      ? dynamicsWithNoGPSNull
      : [dynamicsWithNoGPSNull]

    const autocompleteListOperator = [
      { name: 'Mostrar todos...', id: 'clean' },
      ...displayListOperator,
    ]
    const autocompleteListScooter = [
      { plate: 'Mostrar todos...', id: 'clean' },
      ...displayListScooter,
    ]
    setautocompleteList(
      autocompleteModeScooter
        ? autocompleteListScooter
        : autocompleteListOperator,
    )
  }

  const selectIconMarker = (scooter) => {
    return selectIconMarkerMap(scooter)
  }

  const handleFitCoordinates = () => {
    let updatedBounds = new google.maps.LatLngBounds()
    calculateMapBounds(polygon, circles).forEach((coordinate) => {
      updatedBounds.extend(coordinate)
    })
    console.log('updatedBounds =>', updatedBounds)
    setBounds(updatedBounds)
  }

  const handleFitMarkers = () => {
    const points = scootersListDisplayed
      .filter((s) => s.gps && s.gps.latitude != null && s.gps.longitude != null)
      .map((s) => {
        return { lat: s.gps.latitude, lng: s.gps.longitude }
      })
    var updatedBounds = new google.maps.LatLngBounds()
    points.forEach(function (n) {
      updatedBounds.extend(n)
    })
    setBounds(updatedBounds)
  }

  const handleSearchHeatMap = () => {
    if (!(operatorIdSelected && since && until)) {
      dispatch(alertActions.error('Debes rellenar todos los campos'))
      return
    }

    return isUserWithRole([
      rolesConstants.MANAGER,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
    ])
      ? heatMapOfSelectedOperator({
          since,
          until,
          operatorId: operatorIdSelected.id,
        })
      : heatMapOfLoggedOperator({
          since,
          until,
        })
  }

  const gradient = [
    'rgba(0, 255, 255, 0)',
    'rgba(0, 255, 255, 1)',
    'rgba(0, 191, 255, 1)',
    'rgba(0, 127, 255, 1)',
    'rgba(0, 63, 255, 1)',
    'rgba(0, 0, 255, 1)',
    'rgba(0, 0, 223, 1)',
    'rgba(0, 0, 191, 1)',
    'rgba(0, 0, 159, 1)',
    'rgba(0, 0, 127, 1)',
    'rgba(63, 0, 91, 1)',
    'rgba(127, 0, 63, 1)',
    'rgba(191, 0, 31, 1)',
    'rgba(255, 0, 0, 1)',
  ]

  return (
    <Fragment>
      <div className={classes.heatContainerFitler}>
        <span>*{t('scooters.location.heatMapFilter.helperText')}</span>
        <div className={classes.datePickerContainer}>
          <TextField
            id="date"
            label={t('scooters.dialogs.scooterTrips.since')}
            type="date"
            value={since}
            onChange={(e) => setsince(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date"
            label={t('scooters.dialogs.scooterTrips.until')}
            type="date"
            value={until}
            className={classes.textField}
            onChange={(e) => setuntil(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {isUserWithRole([
            rolesConstants.MANAGER,
            rolesConstants.SAC_USER,
            rolesConstants.CAU_USER,
          ]) && (
            <Autocomplete
              size="small"
              onChange={(event, value) => setoperatorIdSelected(value)}
              // className={classes.autocomplete}
              style={{ width: '300px', marginLeft: '1%' }}
              options={operatorReducer.results}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={'Operadores'}
                  placeholder={'Nombre Operador'}
                  className={classes.inputFilterOperator}
                  InputLabelProps={{
                    style: { color: 'black', borderColor: 'black' },
                  }}
                />
              )}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            className={classes.textField}
            onClick={() => {
              handleSearchHeatMap()
            }}
          >
            {t('scooters.dialogs.scooterTrips.search')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.textField}
            onClick={() => cleanHeatMapData()}
          >
            {t('scooters.dialogs.scooterTrips.clean')}
          </Button>
          {isManager() && (
            <div className={classes.containerBtnConfigParams}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setisConfigParamsDialogOpen(true)}
              >
                {t('scooters.location.page.scooterParams')}
              </Button>
            </div>
          )}
          {/* <IconButton color="primary" onClick={() => cleanHeatMapData()}>
          <ClearAllIcon />
        </IconButton> */}
        </div>
      </div>
      <div className={classes.mapContainer}>
        <Map
          ref={(ref) => {
            mapRef.current = ref
          }}
          bounds={bounds}
          onClick={handleMapClick}
          containerStyle={containerStyle}
          google={google}
          //   zoom={zoomMap}
          zoom={centerMap === DEFAULT_EUROPE_COORDINATES ? 5 : 10}
          center={centerMap}
          // initialCenter={centerMap}
          onCenterChanged={(props, props2) => {
            const { zoom, center } = props2
            saveCenterMap({
              zoom,
              center: {
                lat: center.lat(),
                lng: center.lng(),
              },
            })
          }}
        >
          {scooterReducer.heatMapData.length > 0 && (
            <HeatMap
              // gradient={gradient}
              positions={scooterReducer.heatMapData}
              opacity={0.8}
              radius={12}
            />
          )}

          <div>
            {isUserWithRole([
              rolesConstants.MANAGER,
              rolesConstants.SAC_USER,
              rolesConstants.CAU_USER,
            ]) && (
              <Autocomplete
                size="small"
                onChange={(event, value) =>
                  autocompleteModeScooter
                    ? setscooterSelected(value)
                    : setoperatorSelected(value)
                }
                className={classes.autocomplete}
                options={autocompleteList}
                getOptionLabel={(option) =>
                  autocompleteModeScooter ? option.plate : option.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={autocompleteModeScooter ? 'Motos' : 'Operadores'}
                    placeholder={
                      autocompleteModeScooter
                        ? 'Matrícula Moto'
                        : 'Nombre Operador'
                    }
                    className={classes.inputFilterOperator}
                    InputLabelProps={{
                      style: { color: 'black', borderColor: 'black' },
                    }}
                  />
                )}
              />
            )}
            <div className={classes.filterLayersInsideMap}>
              <Tooltip title="Mostrar Circulos" arrow>
                <Button
                  variant="contained"
                  className={[
                    isManager() ? classes.btnFilter2 : classes.btnFilter,
                    showCircles && classes.btnActive,
                  ]}
                  onClick={handleActiveShowCircle}
                >
                  <RadioButtonUncheckedIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Mostrar Polígonos" arrow>
                <Button
                  variant="contained"
                  className={[
                    isManager() ? classes.btnFilter2 : classes.btnFilter,
                    showPolygons && classes.btnActive,
                  ]}
                  onClick={handleActiveShowPolygons}
                >
                  <PolymerIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Centrar Motos" arrow>
                <Button
                  variant="contained"
                  className={
                    isManager() ? classes.btnFilter2 : classes.btnFilter
                  }
                  onClick={handleFitMarkers}
                >
                  <FilterCenterFocusIcon />
                  {/* Centrar */}
                </Button>
                {/* <IconButton
                  color="primary"
                  component="span"
                  variant="contained"
                  className={[
                    isManager() ? classes.btnFilter2 : classes.btnFilter,
                    showCircles && classes.btnActive,
                  ]}
                  onClick={handleFitMarkers}
                >
                  <FilterCenterFocusIcon />
                </IconButton> */}
              </Tooltip>
              {isManager() && (
                <Tooltip title="Modificar Buscador" arrow>
                  <Button
                    variant="contained"
                    className={[
                      isManager() ? classes.btnFilter2 : classes.btnFilter,
                      autocompleteModeScooter && classes.btnActive,
                    ]}
                    onClick={handleActiveScooterAutocompleteFilter}
                  >
                    <MotorcycleIcon />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          {operatorDataFromAreaSelected && isManager() && (
            <Card className={classes.cardInfoOperatorArea}>
              <CardContent>
                <Typography variant="h4" component="h2">
                  {t('scooters.location.page.operatorData')}
                </Typography>
                <Typography variant="body2" component="p">
                  <p>
                    {t('scooters.location.page.name')}:{' '}
                    {operatorDataFromAreaSelected.name}
                  </p>
                  <p>
                    {t('scooters.location.page.name')}:{' '}
                    {operatorDataFromAreaSelected.isPrivate
                      ? t('scooters.location.page.Yes')
                      : t('scooters.location.page.No')}
                  </p>
                  <p>
                    {t('scooters.location.page.email')}:{' '}
                    {operatorDataFromAreaSelected.managerEmail}
                  </p>
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    onShowOperatorDetails(operatorDataFromAreaSelected)
                  }
                >
                  {t('scooters.location.page.moreAbout')}
                </Button>
              </CardActions>
            </Card>
          )}
          {scootersListDisplayed.map((s) => {
            const { gps, model, imei, plate } = s
            const positionGPS = gps && {
              lat: gps.latitude,
              lng: gps.longitude,
            }

            return (
              <Marker
                data={s}
                key={imei}
                onClick={handleMarkerClick}
                position={positionGPS}
                icon={{
                  url: selectIconMarker(s),
                  anchor: new google.maps.Point(16, 16),
                  scaledSize: new google.maps.Size(32, 32),
                }}
              />
            )
          })}
          {showPolygons &&
            polygonsListDisplayed.map((o) => {
              const { operator } = o
              return (
                <Polygon
                  onClick={() => handleAreaClick(o.dataOperator)}
                  paths={o.coord}
                  strokeColor="#0000FF"
                  strokeOpacity={0.8}
                  strokeWeight={2}
                  fillColor="#0000FF"
                  fillOpacity={0.35}
                />
              )
            })}
          {showCircles &&
            circlesListDisplayed.map((c) => {
              const { dataOperator } = c
              return (
                <Circle
                  onClick={() => handleAreaClick(dataOperator)}
                  key={c.id}
                  radius={c.rad}
                  center={{ lat: c.lat, lng: c.lng }}
                  strokeColor="transparent"
                  strokeOpacity={0}
                  strokeWeight={5}
                  fillColor="#FF0000"
                  fillOpacity={0.2}
                />
              )
            })}
          {renderinfowindowContent()}
        </Map>
      </div>
    </Fragment>
  )
}

export default GoogleApiWrapper({
  apiKey: stringConstants.GOOGLE_API_KEY,
  // libraries: ["drawing", "visualization"],
  libraries: ['drawing,visualization'],
})(MapsGeofence)
