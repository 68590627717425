import i18next from 'i18next'

export const determineVehicleStatus = (data) => {
  const { currentCustomerEmail, currentServiceId, hasTripStarted } = data
  let status = ''
  if (currentCustomerEmail && currentServiceId != null && hasTripStarted) {
    status = i18next.t('home.page.table.activeService')
  } else if (
    currentCustomerEmail &&
    currentServiceId != null &&
    !hasTripStarted
  ) {
    status = i18next.t('home.page.table.reserveActive')
  } else {
    status = i18next.t('home.page.table.free')
  }
  return status
}

export const filterVehicleStatus = (term, rowData) => {
  const { currentCustomerEmail, currentServiceId, hasTripStarted } = rowData
  let found = []
  term.map((option) => {
    if (option === '0') {
      found.push(
        currentCustomerEmail && currentServiceId != null && !hasTripStarted,
      )
    } else if (option === '1') {
      found.push(
        currentCustomerEmail && currentServiceId != null && hasTripStarted,
      )
    } else if (option === '2') {
      found.push(!currentCustomerEmail && !currentServiceId && !hasTripStarted)
    }
  })

  // Case no filters selected
  if (term.length === 0) return true

  return found.includes(true)
}
