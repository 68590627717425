import { combineReducers } from 'redux'

import { alertReducer } from './alert_reducer'
import { authenticationReducer } from './authentication_reducer'
import { bonusReducer } from './bonus_reducer'
import { cauReducer } from './cau_reducer'
import { customerReducer } from './customer_reducer'
import { documentsReducer } from './documents_reducer'
import { fileReducer } from './fota/file_reducer'
import { firmwareReducer } from './fota/firmware_reducer'
import { fotaReducer } from './fota/fota_reducer'
import { upgradeReducer } from './fota/upgrade_reducer'
import { notificationsReducer } from './notifications_reducer'
import { operatorCustomerReducer } from './operatorCustomer_reducer'
import { operatorUserReducer } from './operatorUser_reducer'
import { operatorReducer } from './operator_reducer'
import { paymentsReducer } from './payments_reducer'
import { registrationReducer } from './registration_reducer'
import { sacReducer } from './sac_reducer'
import { scooterReducer } from './scooter_reducer'
import { servicesReducer } from './services_reducer'
import { tariffsReducer } from './tariffs_reducer'
import { technicianReducer } from './technician_reducer'
import { ticketsReducer } from './tickets_reducer'
import { uiReducer } from './ui_reducer'
import { userReducer } from './user_reducer'

const reducer = combineReducers({
  userReducer,
  alertReducer,
  registrationReducer,
  authenticationReducer,
  scooterReducer,
  customerReducer,
  firmwareReducer,
  upgradeReducer,
  fileReducer,
  fotaReducer,
  operatorReducer,
  bonusReducer,
  servicesReducer,
  operatorCustomerReducer,
  paymentsReducer,
  ticketsReducer,
  tariffsReducer,
  documentsReducer,
  technicianReducer,
  operatorUserReducer,
  notificationsReducer,
  uiReducer,
  cauReducer,
  sacReducer,
})

export default reducer
