import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { blue } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import React from 'react'

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
})

const MiddleSelectorDetails = ({ isOpen, onClose, onSelected, data }) => {
  const classes = useStyles()
  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <DialogTitle id="simple-dialog-title">
        Selecciona un usuario para ver sus detalles
      </DialogTitle>
      <List>
        {isOpen &&
          data.map((d) => (
            <ListItem button onClick={() => onSelected(d)} key={d.id}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={d.name} />
            </ListItem>
          ))}
      </List>
    </Dialog>
  )
}

export default MiddleSelectorDetails
