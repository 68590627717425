import GoogleMapReact from 'google-map-react'
import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import DialogConfirmAction from '../../shared/modalConfirmAction'

import { GoogleApiWrapper } from 'google-maps-react'
import stringConstants from '../../../constants/strings'
import {
  DEFAULT_EUROPE_COORDINATES,
  calculateMapBounds,
  findGeofencesCenter,
} from '../../../helpers/map'

const Map = ({
  defaultCenter,
  libraries = ['drawing'],
  children,
  zoomLevel,
  ...rest
}) => {
  const mapRef = useRef()

  console.log('defaultCenter =>', defaultCenter)

  return (
    <GoogleMapReact
      {...rest}
      bootstrapURLKeys={{
        key: stringConstants.GOOGLE_API_KEY,
        libraries,
      }}
      options={{
        styles: [],
        mapTypeId: 'roadmap',
        mapTypeControl: true,
        streetViewControl: true,
        rotateControl: true,
        scaleControl: true,
      }}
      center={defaultCenter}
      defaultZoom={8}
      zoom={zoomLevel}
      ref={mapRef}
      yesIWantToUseGoogleMapApiInternals
    >
      {children}
    </GoogleMapReact>
  )
}

const MapsGeofence = ({
  toolsAvaiable = true,
  onAccept,
  data = {},
  displayOnly = true,
  google,
  mapStateLoading,
}) => {
  const { t } = useTranslation()
  const mapInstance = useRef()
  const mapApi = useRef()

  const [circlesData, setcirclesData] = useState([])
  const [polygonData, setpolygonData] = useState([])
  const [defaultCenter, setDefaultCenter] = useState(null)
  const [zoomLevel, setZoomLevel] = useState(8)
  const [isDialogConfirmActionOpen, setisDialogConfirmActionOpen] =
    useState(false)

  useEffect(() => {
    const polygons = formatToDisplayPolygons(data.areas)
    const circles = formatToDisplayCircles(data.circles)
    setDefaultCenter(findGeofencesCenter(polygons, circles))
  }, [])

  useEffect(() => {
    console.log('current =>>', mapApi)
    console.log('current mapInstance=>>', mapInstance)
  }, [mapApi, mapInstance])

  const handleFitCoordinates = (polygons, circles) => {
    let updatedBounds = new mapApi.current.LatLngBounds()
    calculateMapBounds(polygons, circles).forEach((coordinate) => {
      updatedBounds.extend(coordinate)
    })
    // setBounds(updatedBounds);
    // return updatedBounds
  }

  const formatToDisplayPolygons = (data) => {
    let updatedList = []
    data &&
      data.map((d) => {
        updatedList.push({
          id: Math.random(),
          coord: d.map((p) => {
            return { lat: p.lat, lng: p.lon }
          }),
        })
      })
    return updatedList
  }

  const formatToDisplayCircles = (data) => {
    let updatedList = []
    data &&
      data.map((d) => {
        updatedList.push({
          id: Math.random(),
          lat: d.lat,
          lng: d.lon,
          radius: d.radius,
        })
      })
    return updatedList
  }

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds)
      })
    })
  }

  const apiHasLoaded = ({ map, maps }) => {
    mapInstance.current = map
    mapApi.current = maps

    let selectedShape

    let storePolygons = []
    let storeCircles = []

    // remove transit layers
    const transitLayer = new mapApi.current.TransitLayer()
    transitLayer.setMap(null)

    const bounds = new maps.LatLngBounds()
    const polygon = formatToDisplayPolygons(data.areas)
    const circles = formatToDisplayCircles(data.circles)

    if (polygon.length > 0 || circles.length > 1) {
      calculateMapBounds(polygon, circles).forEach((coordinate) => {
        bounds.extend(coordinate)
      })
      map.fitBounds(bounds)
    }

    const findAndUpdateData = (data, action) => {
      const selectedShapeId = selectedShape.id
      let updatedList

      switch (action) {
        case 'set_at':
        case 'insert_at':
        case 'dragend':
          updatedList = [
            ...storePolygons.filter((p) => p.id != selectedShapeId),
            data,
          ]
          storePolygons = updatedList
          setpolygonData(storePolygons)
          break
        case 'radius_changed':
        case 'center_changed':
          updatedList = [
            ...storeCircles.filter((p) => p.id != selectedShapeId),
            data,
          ]
          storeCircles = updatedList
          setcirclesData(storeCircles)
          break
        default:
          break
      }
    }

    const createPolygonWithEvents = (p) => {
      const polygon = new mapApi.current.Polygon({
        paths: p.coord,
        strokeColor: 'red',
        fillColor: '#95D6AF',
        id: `polygon-${Math.random()}`,
      })

      polygon.getPaths().forEach(function (path, index) {
        mapApi.current.event.addListener(path, 'insert_at', function (e) {
          findAndUpdateData(polygon, 'insert_at')
        })

        mapApi.current.event.addListener(path, 'set_at', function (e) {
          findAndUpdateData(polygon, 'set_at')
        })
      })

      mapApi.current.event.addListener(polygon, 'click', function (e) {
        displayOnly && setSelection(polygon)
      })

      mapApi.current.event.addListener(polygon, 'dragend', function (e) {
        findAndUpdateData(polygon, 'dragend')
      })
      storePolygons.push(polygon)
      polygon.setMap(map)
    }

    const createCirclesWithEvents = (c) => {
      const circle = new mapApi.current.Circle({
        // strokeColor: "#FF0000",
        // strokeOpacity: 0.8,
        // strokeWeight: 2,
        // fillColor: "#FF0000",
        // fillOpacity: 0.35,
        strokeColor: 'red',
        fillColor: '#95D6AF',
        map,
        id: `circle-${Math.random()}`,
        center: { lat: c.lat, lng: c.lng },
        radius: c.radius, //|| 1200,
      })

      mapApi.current.event.addListener(circle, 'click', function (e) {
        displayOnly && setSelection(circle)
      })
      mapApi.current.event.addListener(circle, 'center_changed', function (e) {
        findAndUpdateData(circle, 'center_changed')
      })
      mapApi.current.event.addListener(circle, 'radius_changed', function (e) {
        findAndUpdateData(circle, 'radius_changed')
      })
      storeCircles.push(circle)
      circle.setMap(map)
    }

    const renderShapes = () => {
      //render polygons
      formatToDisplayPolygons(data.areas).map((p) => {
        createPolygonWithEvents(p)
      })

      //render circles
      formatToDisplayCircles(data.circles).map((c) => {
        createCirclesWithEvents(c)
      })
    }
    renderShapes()

    //drawing tool options
    try {
      if (toolsAvaiable) {
        const drawingManager = new mapApi.current.drawing.DrawingManager({
          // drawingMode: mapApi.current.drawing.OverlayType.MARKER,
          drawingControl: true,
          drawingControlOptions: {
            position: mapApi.current.ControlPosition.TOP_CENTER,
            drawingModes: [
              mapApi.current.drawing.OverlayType.CIRCLE,
              mapApi.current.drawing.OverlayType.POLYGON,
              // mapApi.current.drawing.OverlayType.POLYLINE,
              // mapApi.current.drawing.OverlayType.RECTANGLE,
            ],
          },
          circleOptions: {
            fillColor: '#ffff00',
            id: `circle-${Math.random()}`,
          },
          polygonOptions: {
            fillColor: '#ffff00',
            id: `polygon-${Math.random()}`,
          },
        })
        mapApi.current.event.addListener(
          drawingManager,
          'overlaycomplete',
          function (event) {
            setSelection(event.overlay)
            if (event.type === 'polygon') {
              // createPolygonWithEvents(event.overlay)
              storePolygons.push(event.overlay)
            } else {
              // createCirclesWithEvents(event.overlay)
              storeCircles.push(event.overlay)
            }
            drawingManager.setDrawingMode(null)
          },
        )

        // console.log("mapApi", mapApi.current);
        // console.log("drawingManager", drawingManager);
        drawingManager.setMap(map)
      }
    } catch (error) {
      //console.log("error: " + error);
    }

    //custom controls drawing tools
    function clearSelection() {
      if (selectedShape) {
        if (selectedShape.type !== 'marker') {
          selectedShape.setEditable(false)
          selectedShape.setDraggable(false)
        }
        selectedShape = null
      }
    }
    function setSelection(shape) {
      if (shape.type !== 'marker') {
        clearSelection()
        shape.setEditable(true)
        shape.setDraggable(true)
      }

      selectedShape = shape
    }
    function deleteSelectedShape() {
      if (selectedShape) {
        selectedShape.setMap(null)
        if (selectedShape.id.includes('polygon')) {
          storePolygons = storePolygons.filter((p) => p.id != selectedShape.id)
        } else {
          storeCircles = storeCircles.filter((p) => p.id != selectedShape.id)
        }
      }
    }

    mapApi.current.event.addListener(map, 'click', function (e) {
      clearSelection()
    })

    const deleteShapeBtn = document.createElement('button')
    const saveShapeBtn = document.createElement('button')
    const revertShapeBtn = document.createElement('button')

    const buttonize = (btn, type) => {
      // Set CSS for the control border.
      let color, colorHover
      if (type === 'delete') {
        color = 'tomato'
        colorHover = 'red'
      } else if (type === 'save') {
        color = 'forestgreen'
        colorHover = 'green'
      } else if (type === 'reverse') {
        color = 'steelblue'
        colorHover = 'blue'
      }

      btn.type = 'button'

      btn.setAttribute(
        'style',
        `
        background-color: ${color};
        cursor: pointer;
        padding: 0px 8px;
        margin: 5px;
        margin-left: -5px;
        color:white;
        font-size: 0.9em;
        font-weight: bold;
        height:28px;
        border:none;
        outline: none;
        box-shadow: none;
      `,
      )

      if (type === 'delete') {
        btn.innerHTML = 'Eliminar'
      } else if (type === 'save') {
        btn.innerHTML = 'Confirmar'
      } else if (type === 'reverse') {
        btn.innerHTML = 'Revertir'
      }

      btn.addEventListener('mouseover', () => {
        btn.style.background = colorHover
      })
      btn.addEventListener('mouseout', () => {
        btn.style.background = color
      })
    }

    // add styles
    buttonize(deleteShapeBtn, 'delete')
    buttonize(saveShapeBtn, 'save')
    buttonize(revertShapeBtn, 'reverse')

    deleteShapeBtn.addEventListener('click', () => {
      deleteSelectedShape()
    })

    saveShapeBtn.addEventListener('click', () => {
      handleSaveEvent(storePolygons, storeCircles)
    })

    revertShapeBtn.addEventListener('click', () => {
      storePolygons.map((p) => {
        p.setMap(null)
        storePolygons = storePolygons.filter((p) => p.id != p.id)
      })
      storeCircles.map((c) => {
        c.setMap(null)
        storeCircles = storeCircles.filter((p) => p.id != c.id)
      })

      renderShapes()
    })

    if (toolsAvaiable) {
      map.controls[mapApi.current.ControlPosition.TOP_CENTER].push(
        deleteShapeBtn,
      )
      map.controls[mapApi.current.ControlPosition.TOP_CENTER].push(saveShapeBtn)
      map.controls[mapApi.current.ControlPosition.TOP_CENTER].push(
        revertShapeBtn,
      )
    }
  }

  const formatDataToSend = () => {
    let bodyCircle = []
    let bodyAreas = []
    circlesData.map((c) =>
      bodyCircle.push({ lat: c.lat, lon: c.lng, radius: c.radius }),
    )
    polygonData.map((p) =>
      bodyAreas.push(
        p.coord.map((c) => {
          return { lat: c.lat, lon: c.lng }
        }),
      ),
    )
    return [bodyCircle, bodyAreas]
  }

  const handleConfirmAction = () => {
    // console.log('handleConfirmAction')
    // console.log('circlesData',circlesData)
    // console.log('polygonData',polygonData)
    onAccept(formatDataToSend())
    setcirclesData([])
    setpolygonData([])
  }

  const handleSaveEvent = (storePolygons, storeCircles) => {
    setpolygonData(
      storePolygons.map((o) => {
        // console.log("o", o.getPath().getArray());
        // o.getPath()
        //   .getArray()
        //   .map((p) => {
        //     console.log("p lat", p.lat());
        //     console.log("p lng", p.lng());
        //   });
        return {
          id: Math.random(),
          coord: o
            .getPath()
            .getArray()
            .map((c) => {
              return { lat: c.lat(), lng: c.lng() }
            }),
        }
      }),
    )
    setcirclesData(
      storeCircles.map((c) => {
        return {
          id: Math.random(),
          lat: c.getCenter().lat(),
          lng: c.getCenter().lng(),
          radius: c.getRadius(),
        }
      }),
    )
    setisDialogConfirmActionOpen(true)
  }

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <Map
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={apiHasLoaded}
        defaultCenter={defaultCenter}
        zoomLevel={defaultCenter === DEFAULT_EUROPE_COORDINATES ? 5 : 10}
      />

      <DialogConfirmAction
        isOpen={isDialogConfirmActionOpen}
        onClose={() => setisDialogConfirmActionOpen(false)}
        onConfirm={handleConfirmAction}
        addNote={false}
        data={{
          header: t('operator.shared.geofence.confirmActions.header'),
          body: t('operator.shared.geofence.confirmActions.body'),
        }}
      />
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: stringConstants.GOOGLE_API_KEY,
  libraries: ['drawing,visualization'],
})(MapsGeofence)
