import React, { Fragment, useEffect, useState } from 'react'
import Dashboard from '../../dashboard/index'
import ScootersLocationView from './page'

import { connect } from 'react-redux'
import { customerActions } from '../../../redux/actions/customers_actions'
import { operatorActions } from '../../../redux/actions/operator_action'
import { scooterActions } from '../../../redux/actions/scooter_actions'

import Spinner from '../../shared/Spinner'

import { isUserWithRole, rolesConstants } from '../../../helpers/roles'

import DetailsOperator from '../../operator/details'

import ConfigParams from './dialogs/configParams'

import ScooterTrips from '../../scooters/dialogs/scooterTrips'

const ScootersLocation = ({
  getAllDynamics,
  getAllDynamicByOperator,
  operatorReducer,
  scooterReducer,
  getId,
  getallValidatedOperators,
  getallOperators,
  getParamsConfig,
  updateParamsConfig,
  getUserByServiceId,
  customerReducer,
  saveCenterMap,
  heatMapOfLoggedOperator,
  heatMapOfSelectedOperator,
  cleanHeatMapData,
}) => {
  useEffect(() => {
    const operatorId = operatorReducer.dataOperatorLogged.id
    isUserWithRole([
      rolesConstants.MANAGER,
      rolesConstants.MANAGER_READONLY,
      rolesConstants.CAU_USER,
      rolesConstants.SAC_USER,
    ])
      ? (() => {
          getParamsConfig()
          getAllDynamics()
          getallOperators()
        })()
      : (() => {
          getAllDynamicByOperator(operatorId)
        })()
  }, [])

  const [isTripsListModalOpen, setisTripsListModalOpen] = useState(false)
  const [showOperatorDetails, setshowOperatorDetails] = useState(false)
  const [operatorSelected, setoperatorSelected] = useState()
  const [scooterSelected, setscooterSelected] = useState()
  const [isConfigParamsDialogOpen, setisConfigParamsDialogOpen] =
    useState(false)

  const handleGetIdScooter = (scooterId) => {
    getId(scooterId)
  }

  const handleShowOperatorDetails = (operatorSelected) => {
    setoperatorSelected(operatorSelected)
    setshowOperatorDetails(true)
  }

  const handleSaveConfigParams = (params) => {
    setisConfigParamsDialogOpen(false)
    updateParamsConfig(params)
  }

  const handleopenTripsList = (scooter) => {
    const findScooter = scooterReducer.results.filter(
      (s) => s.id === scooter.scooterId,
    )[0]
    setscooterSelected(findScooter)
    setisTripsListModalOpen(true)
  }

  return (
    <Fragment>
      <Dashboard
        component={
          !scooterReducer.loadingResults ? (
            <ScootersLocationView
              operatorReducer={operatorReducer}
              scooterReducer={scooterReducer}
              ongetId={handleGetIdScooter}
              onShowOperatorDetails={handleShowOperatorDetails}
              setisConfigParamsDialogOpen={setisConfigParamsDialogOpen}
              onOpenTripsList={handleopenTripsList}
              ongetUserByServiceId={getUserByServiceId}
              customerReducer={customerReducer}
              saveCenterMap={saveCenterMap}
              heatMapOfLoggedOperator={heatMapOfLoggedOperator}
              heatMapOfSelectedOperator={heatMapOfSelectedOperator}
              cleanHeatMapData={cleanHeatMapData}
            />
          ) : (
            <Spinner loading={scooterReducer.loadingResults} />
          )
        }
      />
      {operatorSelected && (
        <DetailsOperator
          isOpen={showOperatorDetails}
          onClose={() => setshowOperatorDetails(false)}
          data={operatorSelected}
        />
      )}
      <ConfigParams
        scooterReducer={scooterReducer}
        isOpen={isConfigParamsDialogOpen}
        onSave={handleSaveConfigParams}
        onClose={() => setisConfigParamsDialogOpen(false)}
      />
      <ScooterTrips
        isOpen={isTripsListModalOpen}
        onClose={() => {
          setisTripsListModalOpen(false)
        }}
        data={scooterSelected}
      />
    </Fragment>
  )
}

function mapState(state) {
  const { operatorReducer, scooterReducer, customerReducer } = state
  return { operatorReducer, scooterReducer, customerReducer }
}

const actionCreators = {
  getAllDynamics: scooterActions.getAllDynamics,
  getAllDynamicByOperator: scooterActions.getAllDynamicByOperator,
  getId: scooterActions.getId,
  getallValidatedOperators: operatorActions.getallValidatedOperators,
  getallOperators: operatorActions.getAll,
  getParamsConfig: scooterActions.getParamsConfig,
  updateParamsConfig: scooterActions.updateParamsConfig,
  getUserByServiceId: customerActions.getUserByServiceId,
  saveCenterMap: scooterActions.saveCenterMap,
  heatMapOfLoggedOperator: scooterActions.heatMapOfLoggedOperator,
  heatMapOfSelectedOperator: scooterActions.heatMapOfSelectedOperator,
  cleanHeatMapData: scooterActions.cleanHeatMapData,
}

export default connect(mapState, actionCreators)(ScootersLocation)
