import i18next from 'i18next'
import moment from 'moment'
import React from 'react'
import { handleTrueFalsyTermSwitch } from '../../../helpers/filters'
import CustomMaterialTable from '../../shared/customMaterialTable'

const ValidationsList = ({ data }) => {
  return (
    <>
      <CustomMaterialTable
        title={i18next.t('validations.list.title')}
        data={data}
        columns={[
          {
            title: i18next.t('validations.list.columns.dateUpload'),
            field: 'registerDate',
            render: (rowData) =>
              rowData.registerDate
                ? moment(rowData.registerDate).format('DD/MM/YYYY HH:mm:ss')
                : '-',
          },
          {
            title: i18next.t('validations.list.columns.dateValidate'),
            field: 'responseDate',
            render: (rowData) =>
              rowData.responseDate
                ? moment(rowData.responseDate).format('DD/MM/YYYY HH:mm:ss')
                : '-',
          },
          {
            title: i18next.t('validations.list.columns.client'),
            field: 'customerName',
          },
          {
            title: i18next.t('validations.list.columns.delay'),
            field: 'delay',
            render: (rowData) => (rowData.delay ? Math.ceil(rowData.delay) : 0),
          },
          {
            title: i18next.t('validations.list.columns.inShift'),
            field: 'isInLaborableSchedule',
            lookup: { 0: i18next.t('shared.yes'), 1: i18next.t('shared.no') },
            customFilterAndSearch: (term, rowData) =>
              handleTrueFalsyTermSwitch(term, rowData?.isInLaborableSchedule),
            render: (rowData) =>
              rowData.isInLaborableSchedule
                ? i18next.t('validations.list.yes')
                : i18next.t('validations.list.no'),
          },
        ]}
      />
    </>
  )
}

export default ValidationsList
