import React from 'react'
import useStyles from './styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import Button from '@material-ui/core/Button'

import { useTranslation } from 'react-i18next'

const MODELSDUMMY = [
  {
    model: 'S01',
    avaiable: 10,
  },
  {
    model: 'S02',
    avaiable: 23,
  },
  {
    model: 'S03',
    avaiable: 55,
  },
]
const RequestScooter = () => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Container component="main">
      <div className={classes.inputSection}>
        {MODELSDUMMY.map((s) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('operator.requestScooter.model')}
                autoFocus
                disabled
                value={s.model}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('operator.requestScooter.quantity')}
                type="number"
              />
            </Grid>
          </Grid>
        ))}
      </div>
      <div className={classes.requestBtn}>
        <Button variant="contained" color="primary">
          {t('operator.requestScooter.submitBtn')}
        </Button>
      </div>
    </Container>
  )
}
export default RequestScooter
