import { documentsConstant } from '../../constants/documents_constant'

const initialState = {
  loadingResults: false,
  results: [],
  drivingLicenses: {},
  docTypes: [],
}

export function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case documentsConstant.GET_BY_CUSTOMERS_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
        results: [],
      }
    case documentsConstant.GET_BY_CUSTOMERS_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case documentsConstant.GET_BY_CUSTOMERS_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case documentsConstant.GET_DRIVING_LICENSES_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case documentsConstant.GET_DRIVING_LICENSES_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        drivingLicenses: action.results,
      }
    case documentsConstant.GET_BY_CUSTOMERS_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        showResults: false,
        error: action.error,
      }
    case documentsConstant.GET_ID_DOC_TYPES_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case documentsConstant.GET_ID_DOC_TYPES_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        docTypes: action.results,
      }
    case documentsConstant.GET_BY_CUSTOMERS_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        showResults: false,
        error: action.error,
      }
    default:
      return state
  }
}
