import { uiConstants } from '../../constants/ui_constant'

let initialState = {
  isDrawerOpen: true,
  filterTable: [
    {
      location: '',
      filters: [
        {
          columnId: '',
          value: '',
        },
      ],
    },
  ],
}

export function uiReducer(state = initialState, action) {
  switch (action.type) {
    case uiConstants.OPEN_DRAWER:
      return {
        ...state,
        isDrawerOpen: action.bool,
      }
    case uiConstants.SET_FILTER:
      const { columnId, value, currentLocation, tab, selectedRow } =
        action.object
      // console.log("action.object", action.object);
      let updatedFilterTable = state.filterTable

      let indexOfFoundLocation =
        updatedFilterTable &&
        updatedFilterTable.findIndex(
          (x) => x.location === currentLocation && x.tab === tab,
        )

      let foundFilter =
        updatedFilterTable[indexOfFoundLocation] &&
        updatedFilterTable[indexOfFoundLocation].filters

      let indexOfFoundFilter =
        foundFilter && foundFilter.findIndex((x) => x.columnId === columnId)

      if (indexOfFoundLocation === -1) {
        //create
        updatedFilterTable.push({
          location: currentLocation,
          tab: tab,
          selectedRow,
          filters: [
            {
              columnId: columnId,
              value: value,
            },
          ],
        })
      } else {
        //update
        updatedFilterTable[indexOfFoundLocation].selectedRow = selectedRow
        if (indexOfFoundFilter === -1) {
          //create
          updatedFilterTable[indexOfFoundLocation].filters.push({
            columnId,
            value,
          })
        } else {
          //update
          updatedFilterTable[indexOfFoundLocation].filters[indexOfFoundFilter] =
            { columnId, value }
        }
      }
      // indexOfFoundLocation === -1
      //   ? updatedFilterTable.push({
      //       location: currentLocation,
      //       tab: tab,
      //       selectedRow,
      //       filters: [
      //         {
      //           columnId: columnId,
      //           value: value,
      //         },
      //       ],
      //     })
      //   : indexOfFoundFilter === -1
      //   ? updatedFilterTable[indexOfFoundLocation].filters.push({
      //       columnId,
      //       value,
      //     })
      //   : (updatedFilterTable[indexOfFoundLocation].filters[
      //       indexOfFoundFilter
      //     ] = { columnId, value });

      return {
        ...state,
        filterTable: updatedFilterTable,
      }
    default:
      return state
  }
}
