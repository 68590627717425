import React from 'react'
import MaterialTableExtend from '../../material-table/index'

const TableResults = ({
  columns,
  data,
  title,
  actionsColumnIndex,
  actions = [],
}) => {
  return (
    <div>
      <MaterialTableExtend
        title={title}
        columns={columns}
        data={data}
        actionsColumnIndex={actionsColumnIndex}
        actions={actions}
      />
    </div>
  )
}

export default TableResults
