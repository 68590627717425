import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import React from 'react'
import useStyles from './styles'

import { useTranslation } from 'react-i18next'

const ModalOperator = ({ data, isOpen, onClose }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <div>
              <h2 id="transition-modal-title">
                {t('operator.modal.modalOperator.header')}
              </h2>
              <p id="transition-modal-description">
                {t('operator.modal.modalOperator.description')} {data.name}
              </p>
            </div>
            <div>
              <p>{data.name}</p>
              <p>{data.nif}</p>
              <p>{data.cc}</p>
              <p>{data.operatorType}</p>
              <p>{data.rates}</p>
              <p>{data.logo}</p>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default ModalOperator
