export const customerConstants = {
  FILTER_REQUEST: 'CUSTOMERS_FILTER_REQUEST',
  FILTER_SUCCESS: 'CUSTOMERS_FILTER_SUCCESS',
  FILTER_FAILURE: 'CUSTOMERS_FILTER_FAILURE',

  DETAILS_REQUEST: 'CUSTOMERS_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'CUSTOMERS_DETAILS_SUCCESS',
  DETAILS_FAILURE: 'CUSTOMERS_DETAILS_FAILURE',

  CLEAR_RESULTS: 'CUSTOMERS_CLEAR_RESULTS',

  GETALL_REQUEST: 'CUSTOMERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'CUSTOMERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'CUSTOMERS_GETALL_FAIL',

  GETALL_PENDING_VALIDATE_REQUEST: 'CUSTOMERS_GETALL_PENDING_VALIDATE_REQUEST',
  GETALL_PENDING_VALIDATE_SUCCESS: 'CUSTOMERS_GETALL_PENDING_VALIDATE_SUCCESS',
  GETALL_PENDING_VALIDATE_FAILURE: 'CUSTOMERS_GETALL_PENDING_VALIDATE_FAIL',

  GET_BY_ID_REQUEST: 'CUSTOMERS_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'CUSTOMERS_GET_BY_ID_SUCCESS',
  GET_BY_ID_FAILURE: 'CUSTOMERS_GET_BY_ID_FAIL',

  DELETE_REQUEST: 'CUSTOMERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CUSTOMERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CUSTOMERS_DELETE_FAILURE',

  UPDATE_REQUEST: 'CUSTOMERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CUSTOMERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CUSTOMERS_UPDATE_FAILURE',

  GET_CUSTOMERS_BY_OPERATORS_REQUEST:
    'CUSTOMERS_GET_CUSTOMERS_BY_OPERATORS_REQUEST',
  GET_CUSTOMERS_BY_OPERATORS_SUCCESS:
    'CUSTOMERS_GET_CUSTOMERS_BY_OPERATORS_SUCCESS',
  GET_CUSTOMERS_BY_OPERATORS_FAILURE:
    'CUSTOMERS_GET_CUSTOMERS_BY_OPERATORS_FAILURE',

  GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_REQUEST:
    'CUSTOMERS_GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_REQUEST',
  GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_SUCCESS:
    'CUSTOMERS_GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_SUCCESS',
  GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_FAILURE:
    'CUSTOMERS_GET_CUSTOMERS_BY_OPERATORS_BY_OPERATOR_ID_FAILURE',

  GET_CUSTOMERS_BY_PRIVATE_OPERATOR_ID_REQUEST:
    'CUSTOMERS_GET_CUSTOMERS_BY_PRIVATE_OPERATOR_ID_REQUEST',
  GET_CUSTOMERS_BY_PRIVATE_OPERATOR_ID_SUCCESS:
    'CUSTOMERS_GET_CUSTOMERS_BY_PRIVATE_OPERATOR_ID_SUCCESS',
  GET_CUSTOMERS_BY_PRIVATE_OPERATOR_ID_FAILURE:
    'CUSTOMERS_GET_CUSTOMERS_BY_PRIVATE_OPERATOR_ID_FAILURE',

  GET_CUSTOMER_BY_SERVICE_ID_REQUEST:
    'CUSTOMERS_GET_CUSTOMER_BY_SERVICE_ID_REQUEST',
  GET_CUSTOMER_BY_SERVICE_ID_SUCCESS:
    'CUSTOMERS_GET_CUSTOMER_BY_SERVICE_ID_SUCCESS',
  GET_CUSTOMER_BY_SERVICE_ID_FAILURE:
    'CUSTOMERS_GET_CUSTOMER_BY_SERVICE_ID_FAILURE',

  GET_ALL_COUNTRIES_REQUEST: 'CUSTOMERS_GET_ALL_COUNTRIES_REQUEST',
  GET_ALL_COUNTRIES_SUCCESS: 'CUSTOMERS_GET_ALL_COUNTRIES_SUCCESS',
  GET_ALL_COUNTRIES_FAILURE: 'CUSTOMERS_GET_ALL_COUNTRIES_FAILURE',

  GET_WEEK_DAYS_REQUEST: 'GET_WEEK_DAYS_REQUEST',
  GET_WEEK_DAYS_SUCCESS: 'GET_WEEK_DAYS_SUCCESS',
  GET_WEEK_DAYS_FAILURE: 'GET_WEEK_DAYS_FAILURE',

  GET_CALENDAR_VALIDATIONS_REQUEST: 'GET_CALENDAR_VALIDATIONS_REQUEST',
  GET_CALENDAR_VALIDATIONS_SUCCESS: 'GET_CALENDAR_VALIDATIONS_SUCCESS',
  GET_CALENDAR_VALIDATIONS_FAILURE: 'GET_CALENDAR_VALIDATIONS_FAILURE',

  GET_CUSTOMER_VALIDATION_REQUESTS_REQUEST:
    'GET_CUSTOMER_VALIDATION_REQUESTS_REQUEST',
  GET_CUSTOMER_VALIDATION_REQUESTS_SUCCESS:
    'GET_CUSTOMER_VALIDATION_REQUESTS_SUCCESS',
  GET_CUSTOMER_VALIDATION_REQUESTS_FAILURE:
    'GET_CUSTOMER_VALIDATION_REQUESTS_FAILURE',

  DOCUMENTS_MODAL_OPEN: 'DOCUMENTS_MODAL_OPEN',
  DOCUMENTS_MODAL_CLOSE: 'DOCUMENTS_MODAL_CLOSE',
}
