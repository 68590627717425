import React, { useEffect, useState } from 'react'

import { operatorActions } from '../../redux/actions/operator_action'
import { techniciansActions } from '../../redux/actions/technicians_action'

import { connect } from 'react-redux'

import DetailsOperator from '../operator/details'

import MiddleSelectorDetails from './modal/middleSelectorDetails'

import CustomMaterialTable from '../shared/customMaterialTable'

const UserView = ({
  getAllChilds,
  operatorReducer,
  technicianReducer,
  getWithNoParent,
  getAll,
  getAllTechnicians,
  getAllOperatorUsers,
}) => {
  const [
    isDialogMiddleSelectorDetailsOpen,
    setisDialogMiddleSelectorDetailsOpen,
  ] = useState(false)
  const [isDialogOperatorDetailsOpen, setisDialogOperatorDetailsOpen] =
    useState(false)
  const [selectedRow, setselectedRow] = useState([])

  useEffect(() => {
    // get all operatorsUsers, get allTechincian, tienen SharingOperatorId a modo de parentId, juntar arrays y añdir ese campo a modo de parentId
    // getWithNoParent()
    getAllOperatorUsers()
    getAllTechnicians()
    getAll()
  }, [])

  const handleClickAction = (event, rowData) => {
    setselectedRow(rowData)
    rowData.length > 1
      ? setisDialogMiddleSelectorDetailsOpen(true)
      : setisDialogOperatorDetailsOpen(true)
  }

  const handleUserSelected = (selected) => {
    setselectedRow(selected)
    setisDialogMiddleSelectorDetailsOpen(false)
    setisDialogOperatorDetailsOpen(true)
  }

  let margin = 0
  const looparentId = (rowData) => {
    if (rowData.parentId) {
      margin += 10
      const parent = operatorReducer.results.filter(
        (o) => o.id === rowData.parentId,
      )
      parent.length > 0 && looparentId(parent[0])
    }
  }

  const setPaddingCells = (rowData) => {
    margin = 0
    looparentId(rowData)

    return { marginLeft: `${margin}%` }
  }
  return (
    <div className={'mainContainer'}>
      {operatorReducer.results && (
        <CustomMaterialTable
          title="Usuarios Operadores"
          isLoading={operatorReducer.loadingResults}
          data={
            Array.isArray(operatorReducer.results)
              ? operatorReducer.results
              : [operatorReducer.results]
          }
          columns={[
            {
              title: 'Nombre',
              field: 'name',
              render: (rowData) => (
                <p style={setPaddingCells(rowData)}>{rowData.name}</p>
              ),
            },
            { title: 'Color principal', field: 'colour1' },
            { title: 'Color secundario', field: 'colour2' },
            { title: 'Iban', field: 'iban' },
            { title: 'Email', field: 'managerEmail' },
            {
              title: 'Puede operar',
              field: 'canOperate',
              addlookup: true,
            },
            {
              title: 'Es Privado',
              field: 'isPrivate',
              addlookup: true,
            },
          ]}
          actions={[
            {
              icon: 'view_list',
              tooltip: 'Detalles',
              onClick: (event, rowData) => handleClickAction(event, rowData),
            },
          ]}
          parentChildData={(row, rows) =>
            rows.find((a) => a.id === row.parentId)
          }
        />
      )}
      <DetailsOperator
        isOpen={isDialogOperatorDetailsOpen}
        onClose={() => setisDialogOperatorDetailsOpen(false)}
        data={selectedRow[0]}
      />
      <MiddleSelectorDetails
        isOpen={isDialogMiddleSelectorDetailsOpen}
        onClose={() => setisDialogMiddleSelectorDetailsOpen(false)}
        onSelected={handleUserSelected}
        data={selectedRow}
      />
    </div>
  )
}

function mapState(state) {
  const { customerReducer, operatorReducer, technicianReducer } = state
  return { customerReducer, operatorReducer, technicianReducer }
}

const actionCreators = {
  getAllChilds: operatorActions.getAllChilds,
  getWithNoParent: operatorActions.getWithNoParent,
  getAll: operatorActions.getAll,
  getAllTechnicians: techniciansActions.getAll,
  getAllOperatorUsers: operatorActions.getOperatorUses,
}

export default connect(mapState, actionCreators)(UserView)
