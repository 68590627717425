import { sacConstants } from '../../constants/sac_constants'

const initialState = {
  users: [],
  user: {},
  loadingUsers: false,
  loadingCreate: false,
  loadingUpdate: false,
  error: null,
}

export function sacReducer(state = initialState, action) {
  switch (action.type) {
    case sacConstants.GET_SAC_USERS_REQUEST:
      return { ...state, loadingUsers: true }
    case sacConstants.GET_SAC_USERS_SUCCESS:
      return { ...state, users: action.users, loadingUsers: false }
    case sacConstants.GET_SAC_USERS_FAILURE:
      return { ...state, loadingUsers: false, error: action.error }
    case sacConstants.SAC_CREATE_USER_REQUEST:
      return { ...state, loadingCreate: true }
    case sacConstants.SAC_CREATE_USER_SUCCESS:
      return { ...state, loadingCreate: false }
    case sacConstants.SAC_CREATE_USER_FAILURE:
      return { ...state, loadingCreate: false, error: action.error }
    case sacConstants.SAC_UPDATE_USER_REQUEST:
      return { ...state, loadingUpdate: true }
    case sacConstants.SAC_UPDATE_USER_SUCCESS:
      return { ...state, loadingUpdate: false }
    case sacConstants.SAC_UPDATE_USER_FAILURE:
      return { ...state, loadingUpdate: false, error: action.error }
    case sacConstants.GET_SAC_USER_BY_ID_SUCCESS:
      return { ...state, user: action.user }
    case sacConstants.SAC_CLEAR_USER_DATA:
      return { ...state, user: {} }
    default:
      return state
  }
}
