import React from 'react'
import { Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) => (auth ? <Component {...props} /> : null) //window.location.assign(stringConstants.URL_ApiGateway+'/users/v1/Login?redirect='+stringConstants.URL_React+'&locale=es')
    }
  />
)

export default PrivateRoute
