export const cauConstants = {
  GET_CAU_USERS_SUCCESS: 'GET_CAU_USERS_SUCCESS',
  GET_CAU_USERS_FAILURE: 'GET_CAU_USERS_FAILURE',
  GET_CAU_USERS_REQUEST: 'GET_CAU_USERS_REQUEST',

  CAU_CREATE_USER_REQUEST: 'CAU_CREATE_USER_REQUEST',
  CAU_CREATE_USER_SUCCESS: 'CAU_CREATE_USER_SUCCESS',
  CAU_CREATE_USER_FAILURE: 'CAU_CREATE_USER_FAILURE',

  CAU_UPDATE_USER_REQUEST: 'CAU_UPDATE_USER_REQUEST',
  CAU_UPDATE_USER_SUCCESS: 'CAU_UPDATE_USER_SUCCESS',
  CAU_UPDATE_USER_FAILURE: 'CAU_UPDATE_USER_FAILURE',

  GET_CAU_USER_BY_ID_REQUEST: 'GET_CAU_USER_BY_ID_REQUEST',
  GET_CAU_USER_BY_ID_FAILURE: 'GET_CAU_USER_BY_ID_FAILURE',
  GET_CAU_USER_BY_ID_SUCCESS: 'GET_CAU_USER_BY_ID_SUCCESS',

  CLEAR_USER_DATA: 'CAU_CLEAR_USER_DATA',
}
