import { fileConstants } from '../../../constants/fota/file_constansts'

const initialState = {
  showResults: true,
  loadingResults: false,
  firmwaresVersions: [],
  results: [],
}

export function fileReducer(state = initialState, action) {
  switch (action.type) {
    case fileConstants.FILTER_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case fileConstants.FILTER_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case fileConstants.FILTER_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case fileConstants.DETAILS_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case fileConstants.DETAILS_SUCCESS:
      return {
        ...state,
        file: action.file,
        loadingResults: false,
      }
    case fileConstants.DETAILS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case fileConstants.GETALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case fileConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        firmwaresVersions: [],
        results: action.results,
      }
    case fileConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case fileConstants.FIRMWARES_VERSION_REQUEST:
      return {
        loadingResults: true,
      }
    case fileConstants.FIRMWARES_VERSION_SUCCESS:
      return {
        loadingResults: false,
        firmwaresVersions: action.versions,
      }
    case fileConstants.FIRMWARES_VERSION_FAILURE:
      return {
        loadingResults: false,
        error: action.error,
      }
    case fileConstants.DELETE_REQUEST:
      return {
        ...state,
      }
    case fileConstants.DELETE_SUCCESS:
      return {
        ...state,
        results: state.results.filter((x) => x.id !== action.id),
      }
    case fileConstants.DELETE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case fileConstants.ADD_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case fileConstants.ADD_SUCCESS:
      return {
        ...state,
        loadingResults: false,
      }
    case fileConstants.ADD_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    default:
      return state
  }
}
