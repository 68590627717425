import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: '2%',
  },
  headerMainContainer: {
    // textAlign: 'center',
    // marginBottom: '5%'
  },
  summary: {
    width: '30%',
  },
  buttonsSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headerComentCard: {
    display: 'flex',
    justifyContent: 'space-between',
    // margin: '5% 0%'
  },
  commentCard: {
    padding: '.5% 4%',
    boxShadow: '0 0 3px black',
    borderRadius: '20px',
    marginBottom: '3%',
  },
  inputNewCommentSection: {
    display: 'flex',
    margin: '5% 0%',
  },
  dataDescriptionSection: {
    display: 'flex',
    justifyContent: 'space-between',
    // marginTop: "3%",
    // marginBottom: "5%",
  },
  commentHistorySection: {
    // overflow: 'scroll',
    // height: '500px'
    overflowY: 'scroll',
    padding: '2%',
    height: '50vh',
  },
  commentSection: {
    width: '60%',
    // border: '1px solid #000000',
    // borderRadius: '33px',
    // padding: '2%',
    // boxShadow: '0 0 6px 0px #aba7a7'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

export default useStyles
