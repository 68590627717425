import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'

import { connect } from 'react-redux'

import TripScooterView from '../details/trips/pageTrip'

import { scooterActions } from '../../../redux/actions/scooter_actions'

import { useTranslation } from 'react-i18next'
import { servicesActions } from '../../../redux/actions/services_actions'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '50%',
    padding: '1%',
    marginBottom: '2%',
  },
  containerBody: {
    padding: '1%',
    // marginBottom: "2%",
  },
  textField: {
    marginLeft: '2%',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '2%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ScooterTrip = ({
  isOpen,
  onClose,
  data,
  getTrips,
  getTripDetail,
  getDynamyc,
  scooterReducer,
  cleanTripsView,
  getScooterById,
  scooterId,
  preSelectTrip,
  getTripsByServiceId,
  clearTripsData,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleClose = () => {
    onClose()
    cleanTripsView()
  }

  const handleOnOpen = () => {
    if (preSelectTrip) {
      getScooterById(scooterId, false)
      getTripDetail(scooterId, preSelectTrip.tripId)
    }
  }

  useEffect(() => {
    return () => {
      // clearTripsData();
    }
  }, [])

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onEnter={handleOnOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('scooters.dialogs.scooterTrips.scooterTrips')} (
              {(data && data.plate) ||
                (preSelectTrip && preSelectTrip.vehiclePlate) ||
                (scooterReducer.scooter && scooterReducer.scooter.plate)}
              )
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.containerBody}>
          <TripScooterView
            idScooter={
              (data && data.id) || (preSelectTrip && preSelectTrip.vehicleId)
            }
            getTripDetail={getTripDetail}
            scooterReducer={scooterReducer}
            scooterData={preSelectTrip ? scooterReducer.scooter : data}
            preSelectTrip={preSelectTrip}
            scooter={scooterReducer.scooter}
          />
        </div>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

const actionCreators = {
  getTrips: scooterActions.getTripsByScooterId, //desde otros
  getTripDetail: scooterActions.getTripDetails,
  getDynamyc: scooterActions.getDynamicsById,
  cleanTripsView: scooterActions.cleanTripsView,
  getScooterById: scooterActions.getId,
  getTripsByServiceId: servicesActions.getTrips, //se usa desde service
  clearTripsData: scooterActions.clearTripsData,
}

export default connect(mapState, actionCreators)(ScooterTrip)
