import { loginService } from './loginService'

import { authHeader } from '../helpers/authHeader'

import stringConstants from '../constants/strings'

export const handleCall = (
  endpoint,
  options,
  urlApiGateway = stringConstants.URL_ApiGateway,
) => {
  const { method, body } = options
  let requestOptions = {
    method,
    headers: authHeader(),
    redirect: 'follow',
    body,
  }

  body === 'GET' && delete requestOptions.body

  const result = fetch(`${urlApiGateway}${endpoint}`, requestOptions).then(
    (response) => {
      if (response.status === 401) {
        return handleResponse(response, endpoint, options).then(
          (results) => {
            //
          },
          (error) => {
            return handleCall(endpoint, options)
          },
        )
      } else {
        return handleResponse(response, endpoint, options)
      }
    },
  )

  return result
}

export const handleCallDownload = (
  endpoint,
  options,
  urlApiGateway = stringConstants.URL_ApiGateway,
) => {
  const { method, body } = options
  let requestOptions = {
    method,
    headers: authHeader(),
    redirect: 'follow',
    body,
  }

  body === 'GET' && delete requestOptions.body

  const result = fetch(`${urlApiGateway}${endpoint}`, requestOptions).then(
    (response) => {
      if (response.status === 401) {
        return handleResponse(response, endpoint, options).then(
          (results) => {
            //
          },
          (error) => {
            return handleCall(endpoint, options)
          },
        )
      } else {
        if (response.ok) return response
        else {
          response.text().then((text) => {
            const data = text && JSON.parse(text)
            const error = (data && data.message) || text
            return Promise.reject(error)
          })
        }
      }
    },
  )
  return result
}

export const handleResponse = (response, endpoint, options) => {
  const responseContentType = response.headers.get('Content-Type')

  if (
    responseContentType == null ||
    responseContentType.includes('application/json')
  ) {
    return response.text().then((text) => {
      if (response.status === 204 || response.status === 404) {
        return []
      }
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401) {
          var refreshToken = loginService.getWithExpiry('sharing_refresh_token')
          loginService.refreshToken(refreshToken).then((token) => {
            const { access_token, expires_in } = token
            loginService.setWithExpiry(
              'sharing_token',
              access_token,
              expires_in,
            )
            // handleCall(endpoint, options);
          })
        }
        const error = (data && data.message) || text
        return Promise.reject(error)
      }

      // check if the token is close to expire
      var date = new Date()
      var now_utc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      )
      var now = new Date(now_utc)
      now.setMinutes(now.getMinutes() + 20)
      var token_expire = new Date(
        loginService.getWithExpiry('sharing_token_dateExpiry'),
      )
      // if in 10 minutes expire, renew the token
      if (now > token_expire) {
        refreshToken = loginService.getWithExpiry('sharing_refresh_token')
        loginService.refreshToken(refreshToken).then((token) => {
          loginService.setWithExpiry(
            'sharing_token',
            token.access_token,
            token.expires_in,
          )
        })
      }
      return data
    })
  }

  if (responseContentType.match(/image\/([a-zA-Z\+\*]+)/gm)) {
    return response.blob().then((imageBlob) => {
      const imageUrl = URL.createObjectURL(imageBlob)
      return imageUrl
    })
  }

  throw new Error(
    `Unknown response Content-Type header received! (${responseContentType})`,
  )
}
