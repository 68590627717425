export const setupUserState = (customerReducer) => {
  return {
    mobilePhone: customerReducer.selected?.mobilePhone,
    idDocCode: customerReducer.selected?.nif,
    idDocType: customerReducer.selected?.idDocType ?? 'NIF',
    drivingLicense: {
      drivingLicenseExpirationDate:
        customerReducer.selected?.drivingLicense
          ?.drivingLicenseExpirationDate ?? null,
      identityDocumentExpirationDate:
        customerReducer.selected?.drivingLicense
          ?.identityDocumentExpirationDate ?? null,
      drivingLicenseTypes:
        customerReducer.selected?.drivingLicense?.drivingLicenseTypes ?? [],
      bDrivingLicenseEmissionDate:
        customerReducer.selected?.drivingLicense?.bDrivingLicenseEmissionDate ??
        null,
    },
    firstName: customerReducer.selected?.firstName,
    lastName: customerReducer.selected?.lastName,
    birthday: customerReducer.selected?.birthday,
    country: customerReducer.countries?.find(
      (country) =>
        customerReducer.selected?.country === country.twoLetterISORegionName,
    ) || { twoLetterISORegionName: 'ES', displayName: 'España' },
    gender: customerReducer.selected?.gender,
    city: customerReducer.selected?.city,
    address: customerReducer.selected?.address,
    postalCode: customerReducer.selected?.postalCode,
    email: customerReducer.selected?.email,
  }
}
