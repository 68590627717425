import React, { useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import DataOperator from './data'

import { connect } from 'react-redux'

import DialogConfirmAction from '../../../shared/modalConfirmAction'

import { useTranslation } from 'react-i18next'
import { validations } from '../../../../helpers/validation'
import { operatorActions } from '../../../../redux/actions/operator_action'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
  },
  mainContainerContent: {
    padding: '2%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const EditOperators = ({ isOpen, onClose, data, update }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [errors, setErrors] = useState({
    phone: { result: false, message: '' },
  })

  const [updateBody, setupdateBody] = useState({})
  const [isDialogConfirmActionOpen, setisDialogConfirmActionOpen] =
    useState(false)

  const validateForm = () => {
    console.log('updateBody =>', updateBody)
    let newErrors = errors
    newErrors.phone = validations.validatePhone(updateBody.contactPhone)
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  const handleClose = () => {
    setErrors({
      phone: { result: false, message: '' },
    })

    onClose(false)
  }

  const handleSave = () => {
    setisDialogConfirmActionOpen(true)
  }

  const handleValueChanges = (body) => {
    setupdateBody(body)
  }

  const handleConfirmUpdateOperator = () => {
    validateForm()
    if (isFormValid()) update(updateBody)
  }

  return (
    <div>
      {data && (
        <Dialog
          fullScreen
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {t('operator.validate.modal.index.operatorDetails')}:{' '}
                {data.name}
              </Typography>
              <Button autoFocus color="inherit" onClick={handleSave}>
                {t('operator.validate.modal.index.saveBtn')}
              </Button>
            </Toolbar>
          </AppBar>
          <div className={classes.mainContainerContent}>
            <DataOperator
              data={data}
              onValueChanges={handleValueChanges}
              errors={errors}
              setErrors={setErrors}
            />
          </div>
        </Dialog>
      )}
      <DialogConfirmAction
        isOpen={isDialogConfirmActionOpen}
        onClose={() => setisDialogConfirmActionOpen(false)}
        onConfirm={handleConfirmUpdateOperator}
        data={{
          header: t('operator.validate.modal.index.confirmAction.header'),
          body: t('operator.validate.modal.index.confirmAction.body'),
        }}
        addNote={false}
      />
    </div>
  )
}

function mapState(state) {
  const { operatorReducer } = state
  return { operatorReducer }
}

const actionCreators = {
  update: operatorActions.update,
}

export default connect(mapState, actionCreators)(EditOperators)
