import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { scooterActions } from '../../redux/actions/scooter_actions'
import { uiActions } from '../../redux/actions/ui_actions'

import { useTranslation } from 'react-i18next'
import {
  determineVehicleStatus,
  filterVehicleStatus,
} from '../../helpers/vehicle'
import { insertToColumns } from '../shared/currentFilters'

import ModalConfirm from '../shared/modalconfirm'

import CustomMaterialTable from '../shared/customMaterialTable'
import { formatDateToDisplay } from '../shared/formatDate'
const PageOperator = ({
  scooterReducer,
  authenticationReducer,
  setFilterTable,
  getAllScootersByOperatorId,
  getAllDynamicByOperator,
  getScooterById,
  setHelmetIncluded,
  setToOperate,
}) => {
  const { t } = useTranslation()

  const [selectedRow, setselectedRow] = useState({})
  const [isDetailsDialogOpen, setisDetailsDialogOpen] = useState(false)
  const [isHelmetDialogOpen, setisHelmetDialogOpen] = useState(false)
  const [isAssignToOperateDialogOpen, setisAssignToOperateDialogOpen] =
    useState(false)

  useEffect(() => {
    const operatorId = authenticationReducer.user.sharingOperatorId
    if (operatorId) {
      getAllDynamicByOperator(operatorId, false)
      getAllScootersByOperatorId(operatorId)
    }
  }, [])

  const handleAction = (rowData, action) => {
    // rowData && getScooterById(rowData.scooterId, false);
    setselectedRow(rowData)
    switch (action) {
      case 'details':
        setisDetailsDialogOpen(true)
        getScooterById(rowData.scooterId)
        break
      case 'helmet':
        setisHelmetDialogOpen(true)
        break
      case 'assignToOperate':
        setisAssignToOperateDialogOpen(true)
        break
      default:
        break
    }
  }

  const handleSetHelmet = () => {
    const { sharing, scooterId } = selectedRow
    const body = {
      sharingOperatorId: sharing.sharingOperatorId,
      vehicleIds: [scooterId],
      isHelmetIncluded: !sharing.isHelmetIncluded,
    }

    setHelmetIncluded(body)
  }
  const handlesetToOperate = () => {
    const { sharing, scooterId } = selectedRow
    const bodySetToOperate = {
      sharingOperatorId: sharing.sharingOperatorId,
      vehicleIds: [scooterId],
      selectToOperate: !sharing.selectToOperate,
    }
    setToOperate(bodySetToOperate)
  }

  return (
    <div>
      <CustomMaterialTable
        addSummaryRow={true}
        title={t('scooters.page.title')}
        saveFilters={true}
        columns={insertToColumns([
          {
            title: t('home.page.table.model'),
            field: 'model',
          },
          {
            title: t('home.page.table.state'),
            field: 'scutum.isVehicleOn',
            addlookup: true,
          },
          {
            title: t('home.page.table.status'),
            lookup: {
              0: t('home.page.table.reserveActive'),
              1: t('home.page.table.activeService'),
              2: t('home.page.table.free'),
            },
            customFilterAndSearch: (term, rowData) =>
              filterVehicleStatus(term, rowData),
            render: (rowData) => determineVehicleStatus(rowData),
          },
          {
            title: 'Service',
            field: 'scutum.isVehicleOn',
            addlookup: true,
          },
          {
            title: t('home.page.table.battery'),
            field: 'scutum.batterySoc',
          },
          {
            title: t('home.page.table.odo'),
            field: 'scutum.totalOdometer',
          },
          {
            title: t('home.page.table.plate'),
            field: 'plate',
          },
          {
            title: t('home.page.table.currentUser'),
            field: 'currentUser',
          },
          {
            title: t('home.page.table.operatorName'),
            field: 'sharingOperatorName',
          },
          {
            title: t('home.page.table.latestService'),
            render: (rowData) =>
              formatDateToDisplay(rowData.latestServiceTimestamp, true),
          },
          {
            title: t('home.page.table.lastConnection'),
            render: (rowData) =>
              formatDateToDisplay(rowData.lastConnectionTimestamp, true),
          },
        ])}
        data={scooterReducer.dynamics}
        actions={[
          (rowData) => ({
            icon: `dehaze`,
            tooltip: t('scooters.page.actions.details'),
            onClick: (event, rowData) => handleAction(rowData, 'details'),
          }),
          (rowData) => ({
            icon: `sports_motorsports`,
            iconProps: {
              style: {
                color:
                  rowData.sharing && rowData.sharing.isHelmetIncluded
                    ? 'green'
                    : 'red',
              },
            },
            tooltip:
              rowData.sharing && rowData.sharing.isHelmetIncluded
                ? t('scooters.page.actions.removeHelmet')
                : t('scooters.page.actions.addHelmet'),
            onClick: (event, rowData) => handleAction(rowData, 'helmet'),
          }),
          (rowData) => ({
            icon: `swap_horiz`,
            iconProps: {
              style: {
                color:
                  rowData.sharing && rowData.sharing.selectToOperate
                    ? 'green'
                    : 'red',
              },
            },
            tooltip:
              rowData.sharing && rowData.sharing.selectToOperate
                ? t('scooters.page.actions.setToOperateFalse')
                : t('scooters.page.actions.setToOperateTrue'),
            onClick: (event, rowData) =>
              handleAction(rowData, 'assignToOperate'),
          }),
        ]}
      />
      <ModalConfirm
        isOpen={isHelmetDialogOpen}
        onClose={() => setisHelmetDialogOpen(false)}
        onConfirm={handleSetHelmet}
        title={
          selectedRow.sharing && selectedRow.sharing.isHelmetIncluded
            ? t('scooters.page.confirmModals.helmetTitle2')
            : t('scooters.page.confirmModals.helmetTitle1')
        }
        inputAvaiable={false}
      />
      <ModalConfirm
        isOpen={isAssignToOperateDialogOpen}
        onClose={() => setisAssignToOperateDialogOpen(false)}
        onConfirm={handlesetToOperate}
        title={
          selectedRow.sharing && selectedRow.sharing.selectToOperate
            ? t('scooters.page.confirmModals.setToOperateTitle2')
            : t('scooters.page.confirmModals.setToOperateTitle1')
        }
        inputAvaiable={false}
      />
    </div>
  )
}

function mapState(state) {
  const { scooterReducer, authenticationReducer } = state
  return { scooterReducer, authenticationReducer }
}

const actionCreators = {
  setFilterTable: uiActions.setFilterTable,
  getAllScootersByOperatorId: scooterActions.getAllByOperatorId,
  getAllDynamicByOperator: scooterActions.getAllDynamicByOperator,
  getScooterById: scooterActions.getId,
  setHelmetIncluded: scooterActions.setHelmetIncluded,
  setToOperate: scooterActions.setToOperate,
}

export default connect(mapState, actionCreators)(PageOperator)
