import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import MuiAlert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { checkCanOperate } from '../../helpers/operator'
import { alertActions } from '../../redux/actions/alert_actions'
import { uiActions } from '../../redux/actions/ui_actions'
import { userActions } from '../../redux/actions/user_actions'
import ListItems from './listItems'
import MenuUser from './menu'
import useStyles from './styles'

import PersonalData from './dialog/personalData'
import SelectRole from './dialog/selectRole'

import { isCauUser } from '../../helpers/roles'

const AppUrl = process.env.PUBLIC_URL

const Dashboardview = (props) => {
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const handleSelected = (selected) => {
    setshowDialogSelectorRoles(false)
    props.selectRole(selected, false)
  }

  const classes = props.styles
  const {
    alertReducer,
    authenticationReducer,
    operatorReducer,
    customerReducer,
  } = props
  const { t } = props

  const [showDialogSelectorRoles, setshowDialogSelectorRoles] = useState(false)
  const [isDialogPersonalDataOpen, setisDialogPersonalDataOpen] =
    useState(false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  useEffect(() => {
    setshowDialogSelectorRoles(authenticationReducer.pendingSelectUserRole)
  }, [authenticationReducer.pendingSelectUserRole])

  // useEffect(() => {
  //   checkCanOperate() ? props.handleDrawerOpen() : props.handleDrawerClose();
  // }, [operatorReducer.dataOperatorLogged]);

  const handleChangeRole = () => {
    setshowDialogSelectorRoles(true)
  }
  const handleOpenPersonalData = () => {
    setisDialogPersonalDataOpen(true)
  }

  const lastAlert =
    alertReducer.messages.length > 0
      ? alertReducer.messages[alertReducer.messages.length - 1]
      : null

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        className={clsx(classes.appBar, props.open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {checkCanOperate() && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                props.open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <ListItem
              button
              component={Link}
              to={isCauUser() ? '/scooters/locations' : '/home'}
            >
              <span>Fleet Management</span>
            </ListItem>
          </Typography>
          <MenuUser
            t={t}
            onChangeRole={handleChangeRole}
            onOpenPersonalData={handleOpenPersonalData}
            logout={props.logout}
            name={
              authenticationReducer.hasOwnProperty('user') &&
              (authenticationReducer.user.email ||
                authenticationReducer.user.role)
            }
            cssClass={classes.username}
            changeLanguage={props.changeLanguage}
          />
        </Toolbar>
      </AppBar>
      <SelectRole
        onClose={() => setshowDialogSelectorRoles(false)}
        isOpen={showDialogSelectorRoles}
        data={authenticationReducer.usersGroup}
        onSelected={handleSelected}
      />
      <PersonalData
        isOpen={isDialogPersonalDataOpen}
        onClose={() => setisDialogPersonalDataOpen(false)}
      />
      {checkCanOperate() && (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(
              classes.drawerPaper,
              !props.open && classes.drawerPaperClose,
            ),
          }}
          open={props.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={props.handleDrawerClose}>
              <ChevronLeftIcon color="primary" />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItems t={t} />
          </List>
          <Divider />
          {/* <List>{secondaryListItems}</List> */}
        </Drawer>
      )}
      {/* <Spinner /> */}
      <main
        style={{
          overflow: customerReducer.validationModalOpened ? 'hidden' : 'auto',
          flexGrow: 1,
          height: '100vh',
        }}
      >
        <div className={classes.appBarSpacer} />
        <Container
          maxWidth="xl"
          className={classes.container}
          style={{ position: 'relative' }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper elevation={0}>
                {lastAlert &&
                  enqueueSnackbar(lastAlert.message, {
                    variant: lastAlert.type,
                    autoHideDuration: 3000,
                    onClose: props.handleCloseAlert,
                  })
                    .toString()
                    .substring(0, 0)}
                {props.component}
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>{/* <Copyright /> */}</Box>
        </Container>
      </main>
    </div>
  )
}

function Hook(props) {
  const classes = useStyles()
  const { uiReducer, setOpenDrawer } = props
  const [openAlert] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }
  const handleDrawerClose = () => {
    setOpenDrawer(false)
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    props.clear()
  }

  return (
    <Dashboardview
      styles={classes}
      component={props.component}
      open={uiReducer.isDrawerOpen}
      logout={props.logout}
      alertReducer={props.alertReducer}
      authenticationReducer={props.authenticationReducer}
      openAlert={openAlert}
      {...props}
      handleDrawerOpen={handleDrawerOpen}
      handleDrawerClose={handleDrawerClose}
      handleCloseAlert={handleCloseAlert}
    />
  )
}

function mapState(state) {
  const {
    alertReducer,
    authenticationReducer,
    customerReducer,
    operatorReducer,
    uiReducer,
  } = state
  return {
    alertReducer,
    authenticationReducer,
    operatorReducer,
    uiReducer,
    customerReducer,
  }
}

const actionCreators = {
  clear: alertActions.clear,
  logout: userActions.logout,
  selectRole: userActions.selectRole,
  setOpenDrawer: uiActions.setOpenDrawer,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Hook)
