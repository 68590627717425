import React, { useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'

import { formatDateToInsertIntoComponent } from '../shared/formatDate'

import CustomMaterialTable from '../shared/customMaterialTable'

const UpdateNotification = ({
  isOpen,
  onClose,
  onConfirm,
  targetData,
  targetType, //Customer, Technician
  notificationData,
  customerColumns,
  technicianColumns,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  const [title, settitle] = useState('')
  const [msg, setmsg] = useState('')
  const [startDate, setstartDate] = useState('')
  const [endDate, setendDate] = useState('')
  const [name, setname] = useState('')
  const [selectedTargets, setselectedTargets] = useState([])

  useEffect(() => {
    const {
      body,
      id,
      scheduledEndDate,
      scheduledStartDate,
      sharingOperatorId,
      targets,
      name,
      title,
    } = notificationData

    settitle(title)
    setmsg(body)
    setstartDate(formatDateToInsertIntoComponent(scheduledStartDate))
    setendDate(formatDateToInsertIntoComponent(scheduledEndDate))
    setname(name)
    setselectedTargets(targets)
  }, [isOpen])

  const handleConfirm = () => {
    const body = {
      targetType, //Customer, Technician
      targetIds: selectedTargets,
      title, //titulo de la notificación
      body: msg,
      ScheduledStartDate: startDate,
      ScheduledEndDate: endDate,
      name, //nombre de la campaña de notificaciones (para agruparlo visualmente)
    }

    onConfirm(body)

    settitle('')
    setmsg('')
    setstartDate('')
    setendDate('')
    setname('')
    setselectedTargets('')
  }

  const handleSelectionChange = (rowData) => {
    if (targetType === 'Customer') {
      setselectedTargets(
        rowData.map((i) => {
          return i.customerId
        }),
      )
    } else {
      setselectedTargets(
        rowData.map((i) => {
          return i.id
        }),
      )
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'xl'}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('notifications.createNotification.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              {/* <TransferListUsers
                listData={targetData}
                targetType={targetType}
                notificationData={notificationData}
                onTargetChange={handleTargetChange}
              /> */}
              <CustomMaterialTable
                data={targetData} //targetType filtrar
                columns={
                  targetType === 'Customer'
                    ? customerColumns
                    : technicianColumns
                }
                title={
                  targetType === 'Customer'
                    ? t('notifications.createNotification.table.titleCustomers')
                    : t(
                        'notifications.createNotification.table.titleTechnicians',
                      )
                }
                selection={true}
                onSelectionChange={(rowData) => handleSelectionChange(rowData)}
                preSelectedRows={notificationData?.targets || []}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container alignContent={'center'}>
                <Grid item xs={12}>
                  <TextField
                    label={t('notifications.createNotification.labels.name')}
                    fullWidth
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('notifications.createNotification.labels.title')}
                    fullWidth
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t(
                      'notifications.createNotification.labels.startDate',
                    )}
                    fullWidth
                    type="date"
                    value={startDate}
                    onChange={(e) => setstartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('notifications.createNotification.labels.endDate')}
                    fullWidth
                    type="date"
                    value={endDate}
                    onChange={(e) => setendDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('notifications.createNotification.labels.msg')}
                    fullWidth
                    value={msg}
                    onChange={(e) => setmsg(e.target.value)}
                    multiline={true}
                    rowsMax={4}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('notifications.createNotification.closeBtn')}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {t('notifications.createNotification.confirmBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UpdateNotification
