import React, { useEffect, useState } from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PhoneInput from 'react-phone-input-2'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { SketchPicker } from 'react-color'
import { operatorActions } from '../../../../../redux/actions/operator_action'

const DetailsView = ({
  data,
  getAll,
  operatorReducer,
  onValueChanges,
  getVehicleHomologationTypes,
  errors,
}) => {
  const { t } = useTranslation()

  const [nameState, setNameState] = useState('')
  const [nif, setNif] = useState('')
  const [cc, setCc] = useState('')
  const [primaryColor, setPrimaryColor] = useState('')
  const [secondaryColor, setSecondaryColor] = useState('')
  const [operatorType, setOperatorType] = useState('')
  const [operatorBase, setOperatorBase] = useState('')
  const [vehicleType, setVehicleType] = useState('')
  const [isFree, setIsFree] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [parentId, setParentId] = useState('')

  const [costByVehicle, setCostByVehicle] = useState()
  const [serviceCommissionPercent, setServiceCommissionPercent] = useState()
  const [minimumAge, setMinimumAge] = useState('')

  const vehicleHomologationTypes =
    operatorReducer.vehicleHomologationTypes || null

  useEffect(() => {
    if (vehicleHomologationTypes == null) {
      getVehicleHomologationTypes()
    }
    getAll()
  }, [])

  useEffect(() => {
    if (data) {
      const {
        name,
        cif,
        iban,
        colour1,
        colour2,
        isPrivate,
        isStationBase,
        isFree,
        isHelmetIncluded,
        id,
        contactPhone,
        parentId,
        chargeTypes,
        costByVehicle,
        serviceCommissionPercent,
        vehicleHomologationTypes,
        minimumAge,
      } = data
      setNameState(name)
      setNif(cif)
      setCc(iban)
      setPrimaryColor(colour1)
      setSecondaryColor(colour2)
      setOperatorType(isPrivate ? 'private' : 'public')
      setOperatorBase(isStationBase ? 'StationBase' : 'FreeFloating')
      setIsFree(isFree)
      setContactPhone(contactPhone)
      setParentId(
        operatorReducer.results.filter((o) => {
          return o.id === parentId
        })[0],
      )
      setCostByVehicle(costByVehicle)
      setServiceCommissionPercent(serviceCommissionPercent)
      setVehicleType(vehicleHomologationTypes[0])
      setMinimumAge(minimumAge)
    }
  }, [data])

  useEffect(() => {
    let updatedData = {
      ...data,
      cif: nif,
      colour1: primaryColor,
      colour2: secondaryColor,
      contactPhone:
        contactPhone.charAt(0) === '+' ? contactPhone : `+${contactPhone}`,
      iban: cc,
      isHelmetIncluded: isFree,
      isPrivate: operatorType === 'private',
      isStationBase: operatorBase === 'StationBase',
      isFree: isFree,
      name: nameState,
      parentId: parentId ? parentId.id : null,
      costByVehicle: parseFloat(costByVehicle),
      serviceCommissionPercent: parseFloat(serviceCommissionPercent),
      vehicleHomologationTypes: [vehicleType],
      minimumAge: parseInt(minimumAge),
    }
    onValueChanges(updatedData)
  }, [
    nameState,
    nif,
    cc,
    primaryColor,
    secondaryColor,
    operatorType,
    operatorBase,
    isFree,
    contactPhone,
    parentId,
    vehicleType,
    minimumAge,
    serviceCommissionPercent,
    costByVehicle,
  ])

  const getVehiclesTypes = (vehicleHomologationTypes) => {
    const vehiclesTypesArray =
      Object.entries(vehicleHomologationTypes).map((e) => ({
        ['key']: parseInt(e[0]),
        ['name']: e[1],
      })) ?? []
    //console.log("vehiclesTypesArray transformed => ", vehiclesTypesArray)
    if (vehiclesTypesArray.length == 0) {
      return (
        <MenuItem key={0} value={''}>
          {'No options'}
        </MenuItem>
      )
    }
    return vehiclesTypesArray.map((vehicleTypeItem, index) => {
      return (
        <MenuItem key={index} value={vehicleTypeItem.key}>
          {vehicleTypeItem.name}
        </MenuItem>
      )
    })
  }

  return (
    <Container component="main">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            // variant='standard'
            required
            fullWidth
            label={t('operator.profile.personalData.name')}
            autoFocus
            value={nameState}
            onChange={(e) => setNameState(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            // variant="outlined"
            required
            fullWidth
            label={t('operator.profile.personalData.nif')}
            value={nif}
            onChange={(e) => setNif(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            // variant="outlined"
            required
            fullWidth
            label={t('operator.profile.personalData.cc')}
            value={cc}
            onChange={(e) => setCc(e.target.value)}
          />
        </Grid>
        <Grid item sm={2}>
          <p>{t('login.completeDataOperator.primaryColorLabel')}</p>
        </Grid>
        <Grid
          item
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SketchPicker
            color={primaryColor || ''}
            onChange={(color) => setPrimaryColor(color.hex)}
          />
        </Grid>

        <Grid item sm={2}>
          <p>{t('login.completeDataOperator.secondaryColorLabel')}</p>
        </Grid>
        <Grid
          item
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SketchPicker
            color={secondaryColor || ''}
            onChange={(color) => setSecondaryColor(color.hex)}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            onChange={(phone) => setContactPhone(phone)}
            value={contactPhone}
            preferredCountries={['es', 'fr']}
            inputStyle={{
              width: '100%',
              borderTop: 0,
              borderLeft: 0,
              borderRight: 0,
              borderBottomColor: 'rgba(0, 0, 0, 0.42)',
              borderBottomWidth: 1,
              borderRadius: 0,
              fontFamily: 'Montserrat',
              fontSize: '1.000714rem',
              fontWeight: 400,
              color: 'rgba(0, 0, 0, 0.87)',
            }}
            buttonStyle={{
              border: 0,
              borderWidth: 1,
              backgroundColor: 'inherit',
            }}
          />
        </Grid>
        {errors.phone.message && (
          <Grid item xs={12}>
            <p style={{ color: 'red', margin: 0 }}> {errors.phone.message} </p>
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            // variant="outlined"
            type="number"
            required
            fullWidth
            label={t('operator.register.operatorManager.costByVehicle')}
            value={costByVehicle}
            onChange={(e) => setCostByVehicle(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            // variant="outlined"
            type="number"
            required
            fullWidth
            label={t(
              'operator.register.operatorManager.serviceCommissionPercent',
            )}
            value={serviceCommissionPercent}
            onChange={(e) => setServiceCommissionPercent(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>
              {t('operator.profile.personalData.operatorType')}
            </InputLabel>
            <Select
              value={operatorType}
              onChange={(e) => setOperatorType(e.target.value)}
            >
              <MenuItem value={'private'}>
                {t('operator.profile.personalData.private')}
              </MenuItem>
              <MenuItem value={'public'}>
                {t('operator.profile.personalData.public')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>
              {t('operator.profile.personalData.operatorBase')}
            </InputLabel>
            <Select
              value={operatorBase}
              onChange={(e) => setOperatorBase(e.target.value)}
            >
              <MenuItem value={'FreeFloating'}>
                {t('operator.profile.personalData.freeFloating')}
              </MenuItem>
              <MenuItem value={'StationBase'}>
                {t('operator.profile.personalData.stationBase')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* VEHICLE TYPE */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>
              {t('operator.profile.personalData.vehicleType')}
            </InputLabel>
            <Select
              value={vehicleType}
              onChange={(e) => setVehicleType(e.target.value)}
            >
              {getVehiclesTypes(vehicleHomologationTypes)}
            </Select>
          </FormControl>
        </Grid>
        {/*  */}

        <Grid item xs={12}>
          <TextField
            // variant="outlined"
            required
            fullWidth
            label={t('operator.register.operatorManager.minimumAge')}
            value={minimumAge}
            onChange={(e) => setMinimumAge(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>{t('operator.profile.personalData.isFree')}</InputLabel>
            <Select value={isFree} onChange={(e) => setIsFree(e.target.value)}>
              <MenuItem value={true}>
                {t('operator.profile.personalData.yes')}
              </MenuItem>
              <MenuItem value={false}>
                {t('operator.profile.personalData.no')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            onChange={(event, value) => setParentId(value)}
            value={parentId}
            options={operatorReducer.results}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                // variant="outlined"
                label={t('operator.profile.personalData.operators')}
                placeholder={t('operator.profile.personalData.operatorName')}
                InputLabelProps={{
                  style: { color: 'black', borderColor: 'black' },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
function mapState(state) {
  const { operatorReducer } = state
  return { operatorReducer }
}
const actionCreators = {
  getAll: operatorActions.getAll,
  getVehicleHomologationTypes: operatorActions.getVehicleHomologationTypes,
}

export default connect(mapState, actionCreators)(DetailsView)
