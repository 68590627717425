import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { notificationsActions } from '../../redux/actions/notifications_action'

import { useTranslation } from 'react-i18next'

import { isManager } from '../../helpers/roles'

import CreateNotification from './createNotification'
import Targets from './targets'
import UpdateNotification from './updateNotification'

import CustomMaterialTable from '../shared/customMaterialTable'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const NotificationsManagement = ({
  isOpen,
  onClose,
  usersData,
  usersType,
  notificationsReducer,
  operatorReducer,
  getAllNotifications,
  insertNotification,
  updateNotification,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isEditNotificationOpen, setisEditNotificationOpen] = useState(false)
  const [isCreateNotificationsOpen, setisCreateNotificationsOpen] =
    useState(false)
  const [isTargetsDialogOpen, setisTargetsDialogOpen] = useState(false)
  const [targets, settargets] = useState([])
  useEffect(() => {
    getAllNotifications(!isManager() && operatorReducer.dataOperatorLogged.id)
  }, [])

  const handleClose = () => {
    onClose()
  }

  const handleCreateNotification = (body) => {
    setisCreateNotificationsOpen(false)
    const updatedBody = isManager()
      ? body
      : { ...body, sharingOperatorId: operatorReducer.dataOperatorLogged.id }

    insertNotification(updatedBody)
  }

  const handleNotificationDetails = (rowData) => {
    settargets(rowData)
    setisTargetsDialogOpen(true)
  }
  const handleNotificationEdit = (rowData) => {
    settargets(rowData)
    setisEditNotificationOpen(true)
  }
  const handleUpdateNotification = (bodyParams) => {
    const {
      ScheduledEndDate,
      ScheduledStartDate,
      body,
      name,
      targetIds,
      targetType,
      title,
    } = bodyParams
    const bodyUpdate = {
      name: name,
      targetIds: targetIds,
      title: title,
      body: body,
      scheduledStartDate: ScheduledStartDate,
      scheduledEndDate: ScheduledEndDate,
      notificationId: targets.id,
    }
    updateNotification(bodyUpdate)
    setisEditNotificationOpen(false)
  }
  const customFilter = (term, value) => {
    return value ? value.toString().includes(term) : false
  }
  const customerColumns = [
    {
      title: t('customers.page.columns.firstName'),
      field: 'customerData.firstName',
      render: (rowData) =>
        rowData.hasOwnProperty('customerData')
          ? rowData.customerData.firstName
          : rowData.firstName,
    },
    {
      title: t('customers.page.columns.lastName'),
      field: 'customerData.lastName',
      render: (rowData) =>
        rowData.hasOwnProperty('customerData')
          ? rowData.customerData.lastName
          : rowData.lastName,
    },
    {
      title: t('customers.page.columns.email'),
      render: (rowData) =>
        isManager()
          ? rowData.hasOwnProperty('customerData')
            ? rowData.customerData.email
            : rowData.email
          : rowData.hasOwnProperty('customerData')
          ? rowData.customerData.email
          : rowData.customerKey,

      customFilterAndSearch: (term, rowData) => {
        return customFilter(
          term,
          rowData.hasOwnProperty('customerData')
            ? rowData.customerData.email
            : rowData.customerKey,
        )
      },
    },
    {
      title: t('customers.page.columns.mobilePhone'),
      field: 'customerData.mobilePhone',
      render: (rowData) =>
        rowData.hasOwnProperty('customerData')
          ? rowData.customerData.mobilePhone
          : rowData.mobilePhone,
    },
  ]
  const technicianColumns = [
    {
      title: t('technicians.page.table.columns.name'),
      field: 'firstName',
    },
    {
      title: t('technicians.page.table.columns.lastName'),
      field: 'lastName',
    },
    {
      title: t('technicians.page.table.columns.mobilePhone'),
      field: 'mobilePhone',
    },
    {
      title: t('technicians.page.table.columns.email'),
      field: 'email',
    },
  ]

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {usersType === 'Customer'
                ? t('notifications.index.customertoolbarText')
                : t('notifications.index.technicianbarText')}
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <CustomMaterialTable
            data={notificationsReducer.results.filter(
              (n) => n.targetType === usersType,
            )} //targetType filtrar
            columns={[
              {
                title: t('notifications.index.table.columns.name'),
                field: 'name',
              },
              {
                title: t('notifications.index.table.columns.title'),
                field: 'title',
              },
              {
                title: t('notifications.index.table.columns.subTitle'),
                field: 'subTitle',
              },
              {
                title: t('notifications.index.table.columns.body'),
                field: 'body',
                // cellStyle: {
                //   backgroundColor: "#039be5",
                //   color: "#FFF",
                //   overflow: "auto",
                // },
              },
              {
                title: t('notifications.index.table.columns.startDate'),
                field: 'scheduledStartDate',
              },
              {
                title: t('notifications.index.table.columns.endDate'),
                field: 'scheduledEndDate',
              },
              {
                title: t('notifications.index.table.columns.isPending'),
                field: 'isPending',
                addlookup: true,
              },
            ]}
            title={t('notifications.index.table.title')}
            actions={[
              {
                icon: 'add',
                tooltip: t('notifications.index.table.actions.add'),
                isFreeAction: true,
                onClick: (event) => setisCreateNotificationsOpen(true),
              },
              (rowData) => ({
                // icon: "edit",
                // tooltip: "Edit",
                icon: 'dehaze',
                tooltip: 'Details',
                onClick: (event, rowData) => handleNotificationDetails(rowData),
              }),
              (rowData) => ({
                // icon: "edit",
                // tooltip: "Edit",
                icon: 'edit',
                tooltip: 'Edit',
                onClick: (event, rowData) => handleNotificationEdit(rowData),
              }),
            ]}
          />
        </div>
      </Dialog>
      <Targets
        isOpen={isTargetsDialogOpen}
        onClose={() => setisTargetsDialogOpen(false)}
        data={targets}
        updateNotification={updateNotification}
      />
      <CreateNotification
        isOpen={isCreateNotificationsOpen}
        onClose={() => setisCreateNotificationsOpen(false)}
        onConfirm={handleCreateNotification}
        targetData={usersData}
        targetType={usersType}
        customerColumns={customerColumns}
        technicianColumns={technicianColumns}
      />
      <UpdateNotification
        isOpen={isEditNotificationOpen}
        onClose={() => setisEditNotificationOpen(false)}
        onConfirm={handleUpdateNotification}
        targetData={usersData}
        notificationData={targets}
        targetType={usersType}
        customerColumns={customerColumns}
        technicianColumns={technicianColumns}
      />
    </div>
  )
}

function mapState(state) {
  const { notificationsReducer, operatorReducer } = state
  return { notificationsReducer, operatorReducer }
}

const actionCreators = {
  getAllNotifications: notificationsActions.getAll,
  insertNotification: notificationsActions.insert,
  updateNotification: notificationsActions.update,
}

export default connect(mapState, actionCreators)(NotificationsManagement)
