import { tariffsConstant } from '../../constants/tariffs_constant'

const initialState = {
  loadingResults: false,
  results: [],
  currentByLoggedOperator: {},
  byOperator: {},
}

export function tariffsReducer(state = initialState, action) {
  switch (action.type) {
    case tariffsConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case tariffsConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case tariffsConstant.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }

    case tariffsConstant.GET_BY_LOGGED_OPERATOR_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case tariffsConstant.GET_BY_LOGGED_OPERATOR_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        currentByLoggedOperator: action.results,
      }
    case tariffsConstant.GET_BY_LOGGED_OPERATOR_FAILURE:
      return {
        ...state,
        loadingResults: false,
        currentByLoggedOperator: {},
        error: action.error,
      }

    case tariffsConstant.GET_BY_OPERATOR_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
        byOperator: {},
      }
    case tariffsConstant.GET_BY_OPERATOR_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        byOperator: action.results,
      }
    case tariffsConstant.GET_BY_OPERATOR_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        byOperator: {},
        error: action.error,
      }

    default:
      return state
  }
}
