import React from 'react'
import { connect } from 'react-redux'
import Dashboard from '../../dashboard'
import CompleteDataOperatorView from './page'

const CompleteDataOperator = ({ operatorReducer }) => {
  return (
    !operatorReducer.loadingResults && (
      <Dashboard component={<CompleteDataOperatorView />} />
    )
  )
}

function mapState(state) {
  const { operatorReducer } = state
  return { operatorReducer }
}

export default connect(mapState, null)(CompleteDataOperator)
