import { Container } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { cauActions } from '../../redux/actions/cau_actions'
import CustomMaterialTable from '../shared/customMaterialTable'
import ModalConfirm from '../shared/modalconfirm'
import { useManageCauUsers } from './_hooks/useManageCauUsers'
import CreateCAU from './_modals/create'
import UpdateCAU from './_modals/update'

const CauUsersPage = () => {
  const { t } = useTranslation()
  const [isActionsModalOpened, setIsActionsModalOpened] = useState({
    create: false,
    update: false,
    delete: false,
  })
  const [selectedUserId, setSelectedUserId] = useState('')

  const handleClose = () => {
    setIsActionsModalOpened({
      create: false,
      update: false,
      delete: false,
    })
  }

  const dispatch = useDispatch()
  const { users, loading, updating, creating } = useManageCauUsers()

  return (
    <Container maxWidth="xl">
      <CustomMaterialTable
        title={t('cau.table.title')}
        columns={[
          { title: t('cau.table.columns.name'), field: 'fullName' },
          { title: t('cau.table.columns.email'), field: 'email' },
          {
            title: t('cau.table.columns.document'),
            field: 'identificationDocumentCode',
          },
          { title: t('cau.table.columns.phone'), field: 'phoneNumber' },
        ]}
        actions={[
          {
            icon: 'add',
            tooltip: t('cau.table.actions.create'),
            isFreeAction: true,
            onClick: () =>
              setIsActionsModalOpened((data) => ({ ...data, create: true })),
          },
          {
            icon: 'edit',
            tooltip: t('cau.table.actions.edit'),
            onClick: (_, user) => {
              dispatch(cauActions.getById(user.id))
              setIsActionsModalOpened((data) => ({ ...data, update: true }))
            },
          },
          {
            icon: 'delete',
            tooltip: t('cau.table.actions.delete'),
            onClick: (_, user) => {
              setSelectedUserId(user.id)
              setIsActionsModalOpened((data) => ({ ...data, delete: true }))
            },
          },
        ]}
        data={users}
        loading={loading}
      />
      <CreateCAU
        open={isActionsModalOpened.create}
        key="create"
        onClose={handleClose}
        loading={creating}
      />
      <UpdateCAU
        open={isActionsModalOpened.update}
        key="update"
        onClose={handleClose}
        onSubmit={(user) => dispatch(cauActions.update(user, handleClose))}
        title="Update SAC user"
        actionTitle="Update"
        loading={updating}
      />
      <ModalConfirm
        isOpen={isActionsModalOpened.delete}
        onClose={() =>
          setIsActionsModalOpened((data) => ({ ...data, delete: false }))
        }
        title={t('cau.modals.titleDelete')}
        body={t('cau.modals.bodyDelete')}
        onConfirm={() => dispatch(cauActions.remove(selectedUserId))}
      />
    </Container>
  )
}

export default CauUsersPage
