import 'react-image-gallery/styles/css/image-gallery.css'

import { CircularProgress, IconButton } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import FullScreenIcon from '@material-ui/icons/Fullscreen'
import RotateLeft from '@material-ui/icons/RotateLeft'
import RotateRight from '@material-ui/icons/RotateRight'
import i18next from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import ImageGallery from 'react-image-gallery'
import DocumentZoom from '../../modal/documentZoom'
import useStyles from './styles'

import './styles.css'

const DocumentsCarousel = ({
  documents = [],
  documentsRotation,
  setRotation,
  documentsReducer,
}) => {
  const sliderRef = useRef()
  const selectedImageRef = useRef()
  const classes = useStyles()

  const [zoomModalOpened, setIsZoomModalOpened] = useState(false)
  const [zoomImageUrl, setZoomImageUrl] = useState('')
  const [startIndex, setStartIndex] = useState(0)

  const handleRotateDocument = (id, from = 'right') => {
    let rotation = documentsRotation[id] + (from === 'left' ? -90 : 90)

    if (rotation > 360) rotation = 90
    if (rotation < 0) rotation = 270

    setRotation({
      ...documentsRotation,
      selected: id,
      [id]: rotation,
    })
  }

  const isVertical = (rotation) =>
    rotation == 360 || rotation == 180 || rotation == 0

  useEffect(() => {
    console.log('dentro')
    console.log('dentro', selectedImageRef)
  }, [selectedImageRef.current])

  const imageItems = () => {
    const docs = documents
      .filter((doc) => !doc.isBackup)
      .map((document) => {
        const imageUrl = `data:image/jpeg;base64,${document.fileBinary}`
        const rotation = documentsRotation[document.code]

        return {
          original: imageUrl,
          thumbnail: imageUrl,
          originalClass: classes.imageContainer,
          thumbnailClass: classes.thumbnailContainer,
          renderThumbInner: () => (
            <div className={classes.thumbnailImgContainer}>
              <img
                src={imageUrl}
                style={{
                  flex: 1,
                  objectFit: 'cover',
                  width: 'inherit',
                  height: 'inherit',
                  transform: `rotate(${rotation}deg)`,
                }}
              />
            </div>
          ),
          renderItem: () => (
            <>
              <div
                style={{ height: '50vh', padding: '5%', position: 'relative' }}
              >
                <img
                  ref={selectedImageRef}
                  src={imageUrl}
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                    minHeight: isVertical(rotation) ? 'unset' : '70vh',
                    transform: `rotate(${rotation}deg)`,
                    position: 'absolute',
                    inset: 0,
                    margin: 'auto',
                  }}
                />
              </div>
              <div>
                <span>
                  <IconButton
                    onClick={() => {
                      handleRotateDocument(document.code, 'left')
                      sliderRef.current.slideToIndex(
                        sliderRef.current.getCurrentIndex(),
                      )
                    }}
                  >
                    <RotateLeft />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setIsZoomModalOpened(true)
                      setRotation({
                        ...documentsRotation,
                        selected: document.code,
                      })
                      setZoomImageUrl(imageUrl)
                    }}
                  >
                    <FullScreenIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRotateDocument(document.code)}
                  >
                    <RotateRight />
                  </IconButton>
                </span>
              </div>
            </>
          ),

          // renderItem: () => <div style={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%', alignItems: 'center', flexDirection: 'column' }}>
          //         <div style={{ height: '90%', maxWidth: '70vh', transform: `rotate(${rotation}deg)`,}}>
          //             <img src={imageUrl} style={{ objectFit: 'cover', height: '100%', width: '50%', }} />
          //         </div>
          //     <div style={{ height: '10%', width: '85%' }}>
          //         <span>
          //             <IconButton onClick={() => {
          //                 handleRotateDocument(document.code, "left")
          //                 sliderRef.current.slideToIndex(sliderRef.current.getCurrentIndex())
          //             }}>
          //                 <RotateLeft />
          //             </IconButton>
          //             <IconButton onClick={() => {
          //                 setIsZoomModalOpened(true)
          //                 setRotation({ ...documentsRotation, selected: document.code })
          //                 setZoomImageUrl(imageUrl)
          //             }}>
          //                 <FullScreenIcon />
          //             </IconButton>
          //             <IconButton onClick={() => handleRotateDocument(document.code)}>
          //                 <RotateRight />
          //             </IconButton>
          //         </span>
          //     </div>
          // </div>
        }
      })

    return docs
  }

  const renderLeftNav = (onClick, disabled) => (
    <IconButton
      className={classes.leftNav}
      disabled={disabled}
      onClick={onClick}
    >
      <ChevronLeft />
    </IconButton>
  )

  const renderRightNav = (onClick, disabled) => (
    <IconButton
      className={classes.rightNav}
      disabled={disabled}
      onClick={onClick}
    >
      <ChevronRight />
    </IconButton>
  )

  return documentsReducer.loadingResults ? (
    <div className={classes.spinnerContainer}>
      <CircularProgress />
    </div>
  ) : documents.length > 1 ? (
    <>
      <ImageGallery
        items={imageItems()}
        showPlayButton={false}
        showFullscreenButton={false}
        ref={sliderRef}
        startIndex={startIndex}
        onSlide={(index) => setStartIndex(index)}
        renderLeftNav={(onClick, disabled) => renderLeftNav(onClick, disabled)}
        renderRightNav={(onClick, disabled) =>
          renderRightNav(onClick, disabled)
        }
      />
      <DocumentZoom
        open={zoomModalOpened}
        rotateDegDocuments={documentsRotation}
        onClose={() => setIsZoomModalOpened(false)}
        imageUrl={zoomImageUrl}
      />
    </>
  ) : (
    <p> {i18next.t('customers.documents.noDocumentsUploaded')} </p>
  )
}

export default DocumentsCarousel
