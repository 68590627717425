import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { customerActions } from '../../../redux/actions/customers_actions'
import { documentsActions } from '../../../redux/actions/documents_action'
import DialogConfirmAction from '../../shared/modalConfirmAction'
import ModalConfirm from '../../shared/modalconfirm'
import OldDocuments from '../modal/oldDocuments'
import DocumentsCarousel from './carousel/index'
import { setupUserState } from './helpers/user'
import LicensesFieldset from './inputs/licenses'
import useStyles from './styles'

const CustomerDocumentationDialog = ({
  isOpen,
  documentsReducer,
  customerReducer,
  customerId,
  getCustomerData,
  getDocumentsById,
  validateAndUpdate,
  updateForAdmin,
  operatorReducer,
  onClose,
  from = 'Customers',
}) => {
  const INPUT_LABEL_FONT_SIZE = '1.25rem'
  const INPUT_FIELD_FONT_SIZE = '0.9rem'

  const dispatch = useDispatch()
  const classes = useStyles()

  const [userData, setUserData] = useState({})
  const [oldUserData, setOldUserData] = useState({})
  const [rotateDegDocuments, setRotateDegDocuments] = useState({
    // properties are not in camelCase to compare them easily with documents response 'code' property
    selected: '',
    DniFront: 270,
    DniBack: 270,
    DrivingLicenseFront: 270,
    DrivingLicenseBack: 270,
  })

  const [errors, setErrors] = useState({
    emissionDate: { result: false, message: '' },
    validDates: { result: false, message: '' },
    nif: { result: false, message: '' },
    drivingLicense: { result: false, message: '' },
    mobilePhone: { result: false, message: '' },
  })

  const [validateInBaas, setValidateInBaaS] = useState(false)
  const [isOldDocumentsOpen, setIsOldDocumentsOpen] = useState(false)
  const [
    isConfirmInvalidateDocumentsOpen,
    setIsConfirmInvalidateDocumentsOpen,
  ] = useState(false)
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] =
    useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (customerId) {
      getCustomerData(customerId)
      getDocumentsById(customerId)
    }
  }, [])

  useEffect(() => {
    setUserData(setupUserState(customerReducer))
    setOldUserData(setupUserState(customerReducer))
  }, [customerReducer.selected])

  useEffect(() => {
    setRotateDegDocuments({
      ...rotateDegDocuments,
      DniFront: customerReducer.selected?.documentsRotation?.dniFront ?? 270,
      DniBack: customerReducer.selected?.documentsRotation?.dniBack ?? 270,
      DrivingLicenseBack:
        customerReducer.selected?.documentsRotation?.drivingLicenseFront ?? 270,
      DrivingLicenseFront:
        customerReducer.selected?.documentsRotation?.drivingLicenseBack ?? 270,
    })
  }, [customerReducer.selected?.documentsRotation])

  const handleRefreshData = () => {
    getCustomerData(customerId)
    getDocumentsById(customerId)
  }

  const validateForm = () => {
    let newErrors = errors

    // Validations only if licenses are informed
    if (userData.drivingLicense.drivingLicenseTypes?.length > 0) {
      if (userData.drivingLicense.drivingLicenseTypes?.includes(3)) {
        if (
          !userData.drivingLicense.bDrivingLicenseEmissionDate ||
          !moment(userData.drivingLicense.bDrivingLicenseEmissionDate).isValid()
        ) {
          newErrors.emissionDate = {
            result: false,
            message: t('customers.documents.emissionDateNecessary'),
          }
        } else {
          newErrors.emissionDate = { result: true, message: '' }
        }
        newErrors.validDates = validateDates(
          userData.drivingLicense.bDrivingLicenseEmissionDate,
          userData.drivingLicense.drivingLicenseExpirationDate,
        )
      } else {
        newErrors.emissionDate = { result: true, message: '' }
        newErrors.validDates = { result: true, message: '' }
      }
      if (
        moment(userData.drivingLicense.bDrivingLicenseEmissionDate).isAfter(
          moment(),
        )
      ) {
        newErrors.emissionDate = {
          result: false,
          message: t('customers.documents.emissionBiggerThanToday'),
        }
      }

      newErrors.drivingLicense = validations.expiredDate(
        userData.drivingLicense.drivingLicenseExpirationDate,
        'customers.documents.invalidDrivingLicenseDate',
      )
      newErrors.nif = validations.expiredDate(
        userData.drivingLicense.identityDocumentExpirationDate,
        'customers.documents.invalidDocumentDate',
      )
    } else {
      newErrors.emissionDate = { result: true, message: '' }
      newErrors.drivingLicense = { result: true, message: '' }
      newErrors.nif = { result: true, message: '' }
      newErrors.validDates = { result: true, message: '' }
    }

    newErrors.mobilePhone = { result: true, message: '' }

    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const validateDates = (date1, date2) => {
    if (!date1 || !date2) {
      return { result: true, message: '' }
    }

    date1 = new Date(date1)
    date2 = new Date(date2)

    if (date1 < date2) {
      return { result: true, message: '' }
    }

    return {
      result: false,
      message: t('customers.documents.emissionLowerThanExpiration'),
    }
  }

  const handleDataChange = (id, value) => {
    if (id.includes('.')) {
      let drivingLicense = userData.drivingLicense

      drivingLicense = { ...drivingLicense, [id.split('.')[1]]: value }
      setUserData({ ...userData, drivingLicense: { ...drivingLicense } })
    } else {
      setUserData({ ...userData, [id]: value })
    }
  }

  const handleUnsavedChanges = () => {
    return JSON.stringify(oldUserData) === JSON.stringify(userData)
  }

  const handleClose = () => {
    handleUnsavedChanges() ? onClose() : setIsUnsavedChangesDialogOpen(true)
  }

  const handleUpdateForAdmin = () => {
    const { email } = customerReducer?.selected
    const { selected, ...documents } = rotateDegDocuments

    const { drivingLicense } = userData
    const {
      drivingLicenseExpirationDate,
      bDrivingLicenseEmissionDate,
      identityDocumentExpirationDate,
    } = drivingLicense

    drivingLicense.drivingLicenseExpirationDate = sanitizeDate(
      drivingLicenseExpirationDate,
    )
    drivingLicense.bDrivingLicenseEmissionDate = sanitizeDate(
      bDrivingLicenseEmissionDate,
    )
    drivingLicense.identityDocumentExpirationDate = sanitizeDate(
      identityDocumentExpirationDate,
    )

    setUserData({ ...userData, drivingLicense })

    const body = {
      email,
      from,
      operatorId: operatorReducer.dataOperatorLogged.id,
      userData: JSON.stringify({
        ...userData,
        country: userData.country?.twoLetterISORegionName,
        documentsRotation: { ...documents },
      }),
    }

    updateForAdmin(body)

    setOldUserData({
      ...userData,
      drivingLicense,
    })
  }

  const handleSaveChanges = () => {
    validateForm()

    if (isFormValid()) handleValidateDocument()
  }

  const handleValidateDocument = () => {
    const { email } = customerReducer?.selected

    const { selected, ...documents } = rotateDegDocuments

    const body = {
      customerValidationRequest: {
        canOperate: true,
        drivingLicenses: userData.drivingLicense.drivingLicenseTypes,
        drivingLicenseExpirationDate: sanitizeDate(
          userData.drivingLicense.drivingLicenseExpirationDate,
        ),
        identityDocumentExpirationDate: sanitizeDate(
          userData.drivingLicense.identityDocumentExpirationDate,
        ),
        bDrivingLicenseEmissionDate: sanitizeDate(
          userData.drivingLicense.bDrivingLicenseEmissionDate,
        ),
        cannotOperateReason: '',
      },
      customerUpdateRequest: {
        ...userData,
        country: userData.country?.twoLetterISORegionName,
      },
      documentsRotation: {
        dniFront: documents.DniFront,
        dniBack: documents.DniBack,
        drivingLicenseFront: documents.DrivingLicenseFront,
        drivingLicenseBack: documents.DrivingLicenseBack,
      },
      validateInBaas,
      from,
    }

    // Validate only if customer can not operate and update anyways
    dispatch(customerActions.closeValidationsModal())
    !customerReducer?.selected.canOperate
      ? validateAndUpdate(email, JSON.stringify(body))
      : handleUpdateForAdmin()
  }

  const sanitizeDate = (date) => (moment(date).isValid() ? date : null)

  const handleInvalidateButton = () => {
    let newErrors = errors

    if (!userData.mobilePhone?.length) {
      newErrors.mobilePhone = {
        result: false,
        message: t('customers.documents.mobilePhoneRequired'),
      }
    } else {
      newErrors.mobilePhone = { result: true, message: '' }
      setIsConfirmInvalidateDocumentsOpen(true)
    }

    setErrors({ ...newErrors })
  }

  const confirmInvalidateDocuments = (message) => {
    const { email } = customerReducer?.selected
    const body = {
      customerValidationRequest: {
        canOperate: false,
        cannotOperateReason: message,
      },
      customerUpdateRequest: {
        ...userData,
        country: userData.country?.twoLetterISORegionName,
      },
    }

    if (message) {
      validateAndUpdate(email, JSON.stringify(body))
      dispatch(customerActions.closeValidationsModal())
    }
  }

  return (
    isOpen && (
      <Container disableGutters maxWidth="xl" className={classes.modalWrapper}>
        <Container
          maxWidth="xl"
          disableGutters
          className={classes.modalContainer}
        >
          <Grid container>
            <Grid
              xs={12}
              container
              direction="row"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: 50,
              }}
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => handleClose()}
                  style={{ marginRight: 10 }}
                >
                  <Close />
                </IconButton>
                <Typography className={classes.label}>
                  {' '}
                  {t('customers.documents.title')}{' '}
                </Typography>
              </Grid>
              <Grid item className={classes.headerButtonsContainer}>
                <Button
                  color="inherit"
                  style={{
                    height: 'fit-content',
                    backgroundColor: '#e41622',
                    color: 'white',
                  }}
                  onClick={() => handleRefreshData()}
                >
                  {t('customers.documents.refreshData')}
                </Button>
                <Button
                  autoFocus
                  color="inherit"
                  style={{
                    height: 'fit-content',
                    backgroundColor: '#e41622',
                    color: 'white',
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  onClick={() => setIsOldDocumentsOpen(true)}
                >
                  {t('operator.modalOperatorValidate.toolbar.oldDocuments')}
                </Button>
                <Button
                  autoFocus
                  color="white"
                  style={{
                    height: 'fit-content',
                    backgroundColor: '#e41622',
                    color: 'white',
                    marginRight: 10,
                  }}
                  onClick={() => handleInvalidateButton()}
                >
                  {t('operator.modalOperatorValidate.toolbar.invalidatebtn')}
                </Button>
                <Button
                  autoFocus
                  style={{
                    height: 'fit-content',
                    backgroundColor: '#e41622',
                    color: 'white',
                  }}
                  onClick={handleSaveChanges}
                >
                  {t('operator.modalOperatorValidate.toolbar.update')}
                </Button>
              </Grid>
            </Grid>
            <Grid style={{ height: '80vh', padding: 8 }}>
              <Grid container xs={12} style={{ height: '100%' }}>
                <Grid xs style={{ display: 'flex', justifyContent: 'center' }}>
                  <DocumentsCarousel
                    documents={documentsReducer.results}
                    documentsRotation={rotateDegDocuments}
                    setRotation={setRotateDegDocuments}
                    documentsReducer={documentsReducer}
                  />
                </Grid>
                <Grid
                  spacing={1}
                  container
                  xs
                  style={{ margin: 0 }}
                  direction="row"
                >
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      select
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.idDocType')}
                      id="idDocType"
                      value={userData?.idDocType ?? ''}
                      onChange={(e) =>
                        handleDataChange('idDocType', e.target.value)
                      }
                    >
                      {documentsReducer.docTypes.map((document) => (
                        <MenuItem
                          key={document}
                          id={'idDocType'}
                          value={document}
                        >
                          {document}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      labelPlacement="end"
                      label={
                        <Typography
                          style={{
                            fontSize: INPUT_FIELD_FONT_SIZE,
                            color: 'rgba(0, 0, 0, 0.54)',
                            fontWeight: 700,
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {' '}
                          {t('customers.documents.validateInBaas')}{' '}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          value={validateInBaas}
                          onChange={() => setValidateInBaaS(!validateInBaas)}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      value={userData?.firstName ?? ''}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.fname')}
                      id="firstName"
                      onChange={(e) =>
                        handleDataChange(e.target.id, e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      value={userData?.lastName ?? ''}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.lname')}
                      id="lastName"
                      onChange={(e) =>
                        handleDataChange(e.target.id, e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      value={userData.idDocCode}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.nif')}
                      id="idDocCode"
                      onChange={(e) =>
                        handleDataChange(e.target.id, e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      type="date"
                      label={t('customers.documents.expirationNifDate')}
                      id="drivingLicense.identityDocumentExpirationDate"
                      error={errors.nif.message.length === 0 ? false : true}
                      helperText={errors.nif.message}
                      value={
                        userData.drivingLicense?.identityDocumentExpirationDate
                          ? moment(
                              userData.drivingLicense
                                .identityDocumentExpirationDate,
                            ).format('YYYY-MM-DD')
                          : ''
                      }
                      onChange={(e) => {
                        let date = moment(e.target.value)
                        if (date != null && !date.isValid()) date = null
                        handleDataChange(
                          e.target.id,
                          date?.format('YYYY-MM-DDTHH:mm:ss'),
                        )
                        errors.nif.message = ''
                        setErrors(errors)
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      type="date"
                      label={t('customers.documents.birthday')}
                      id="birthday"
                      value={
                        (userData?.birthday &&
                          moment(userData.birthday).format('YYYY-MM-DD')) ||
                        ''
                      }
                      onChange={(e) => {
                        let date = moment(e.target.value)
                        if (date != null && !date.isValid()) date = null
                        handleDataChange(
                          e.target.id,
                          date?.format('YYYY-MM-DDTHH:mm:ss'),
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      select
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.gender')}
                      id="gender"
                      value={userData?.gender ?? ''}
                      onChange={(e) =>
                        handleDataChange('gender', e.target.value)
                      }
                    >
                      <MenuItem key={'male'} id={'male'} value={'male'}>
                        {t('customers.documents.male')}
                      </MenuItem>
                      <MenuItem key={'female'} id={'female'} value={'female'}>
                        {t('customers.documents.female')}
                      </MenuItem>
                      <MenuItem
                        key={'undisclosed'}
                        id={'other'}
                        value={'undisclosed'}
                      >
                        {t('customers.documents.other')}
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      value={userData?.email ?? ''}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.email')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      value={userData?.mobilePhone ?? ''}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.mobilePhone')}
                      id="mobilePhone"
                      onChange={(e) =>
                        handleDataChange(e.target.id, e.target.value)
                      }
                      helperText={errors.mobilePhone.message}
                      error={
                        errors.mobilePhone.message.length === 0 ? false : true
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      value={userData?.address ?? ''}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.address')}
                      id="address"
                      onChange={(e) =>
                        handleDataChange(e.target.id, e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      value={userData?.city ?? ''}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.city')}
                      id="city"
                      onChange={(e) =>
                        handleDataChange(e.target.id, e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      value={userData?.postalCode ?? ''}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.postalCode')}
                      id="postalCode"
                      onChange={(e) =>
                        handleDataChange(e.target.id, e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      select
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      label={t('customers.documents.country')}
                      id="idDocType"
                      onChange={(e) =>
                        handleDataChange('country', e.target.value)
                      }
                      value={userData.country ?? ''}
                    >
                      {customerReducer.countries.map((country) => (
                        <MenuItem
                          key={country.code}
                          id={'country'}
                          value={country}
                        >
                          {country.displayName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      type="date"
                      label={t('customers.documents.emissionDrivingDate')}
                      id="drivingLicense.bDrivingLicenseEmissionDate"
                      value={
                        userData?.drivingLicense?.bDrivingLicenseEmissionDate
                          ? moment(
                              userData.drivingLicense
                                .bDrivingLicenseEmissionDate,
                            ).format('YYYY-MM-DD')
                          : ''
                      }
                      helperText={errors.emissionDate.message}
                      error={
                        errors.emissionDate.message.length === 0 ? false : true
                      }
                      onChange={(e) => {
                        let date = moment(e.target.value)
                        if (date != null && !date.isValid()) date = null
                        handleDataChange(
                          e.target.id,
                          date?.format('YYYY-MM-DDTHH:mm:ss'),
                        )
                        errors.emissionDate.message = ''
                        errors.validDates.message = ''
                        setErrors(errors)
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                      InputProps={{ className: classes.input }}
                      type="date"
                      label={t('customers.documents.expirationDrivingDate')}
                      id="drivingLicense.drivingLicenseExpirationDate"
                      value={
                        userData.drivingLicense?.drivingLicenseExpirationDate
                          ? moment(
                              userData.drivingLicense
                                .drivingLicenseExpirationDate,
                            ).format('YYYY-MM-DD')
                          : ''
                      }
                      onChange={(e) => {
                        let date = moment(e.target.value)
                        if (date != null && !date.isValid()) date = null
                        handleDataChange(
                          e.target.id,
                          date?.format('YYYY-MM-DDTHH:mm:ss'),
                        )
                        errors.drivingLicense.message = ''
                        errors.validDates.message = ''
                        setErrors(errors)
                      }}
                      helperText={errors.drivingLicense.message}
                      error={
                        errors.drivingLicense.message.length === 0
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  <Grid item xs={10} style={{ padding: 0 }}>
                    <LicensesFieldset
                      documentsReducer={documentsReducer}
                      userLicenses={
                        userData?.drivingLicense?.drivingLicenseTypes ?? []
                      }
                      setUserData={setUserData}
                      userData={userData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <OldDocuments
            open={isOldDocumentsOpen}
            onClose={() => setIsOldDocumentsOpen(false)}
            dataDocumentation={documentsReducer.results}
            t={t}
          />
          <DialogConfirmAction
            isOpen={isConfirmInvalidateDocumentsOpen}
            onClose={() => setIsConfirmInvalidateDocumentsOpen(false)}
            onConfirm={confirmInvalidateDocuments}
            data={{
              header: t('customers.documents.confirmAction.header'),
              body: t('customers.documents.confirmAction.body'),
            }}
            hasNote={true}
            label={'Comentario'}
            addNote={true}
            isMandatory
          />
          <ModalConfirm
            isOpen={isUnsavedChangesDialogOpen}
            title={t('customers.documents.unhandledChanges.title')}
            body={t('customers.documents.unhandledChanges.body')}
            onConfirm={() => {
              handleUpdateForAdmin()
            }}
            onClose={() => {
              setIsUnsavedChangesDialogOpen(false)
              onClose()
            }}
            cancelOption
            onCancel={() => setIsUnsavedChangesDialogOpen(false)}
          />
        </Container>
      </Container>
    )
  )
}

function mapState(state) {
  const { documentsReducer, customerReducer, operatorReducer } = state
  return { documentsReducer, customerReducer, operatorReducer }
}

const actionCreators = {
  getDocumentsById: documentsActions.getByCustomerId,
  getCustomerData: customerActions.getById,
  updateForAdmin: customerActions.updateForAdmin,
  validate: customerActions.validate,
  validateAndUpdate: customerActions.validateAndUpdate,
}

export default connect(mapState, actionCreators)(CustomerDocumentationDialog)
