import React, { useEffect, useState } from 'react'
import useStyles from './styles'

import { connect } from 'react-redux'
import TableResults from '../operator/tableResults/tableResults'

import { scooterActions } from '../../redux/actions/scooter_actions'

import { useTranslation } from 'react-i18next'
import { history } from '../../helpers/history'

const BillView = ({ scooterReducer, getAll, getTripsByScooterId }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [dataOperatorSelected, setdataOperatorSelected] = useState([])
  const [isModalOperatorOpen, setisModalOperatorOpen] = useState(false)
  const [isModalCreateBonusOpen, setisModalCreateBonusOpen] = useState(false)

  const handleClickAction = (event, rowData) => {
    const { id } = rowData
    history.push('/bills/' + id)
    // setdataOperatorSelected(rowData)
    // getTripsByScooterId(rowData.id)
    // setisModalOperatorOpen(true)
  }

  useEffect(() => {
    // getAll()
  }, [])

  const DUMMY_DATA = [
    {
      id: 'iddidi',
      matricula: 'kmj 3322',
      nombre: 'Silence 1',
      modelo: 's02',
      imei: 'fndifidfdf',
      firmware: '1.2',
      min: '100',
      km: '200',
      importe: 500,
    },
  ]

  return (
    <div>
      <TableResults
        data={DUMMY_DATA}
        columns={[
          { title: 'matricula', field: 'matricula' },
          { title: 'nombre', field: 'nombre' },
          { title: 'modelo', field: 'modelo' },
          { title: 'imei', field: 'imei' },
          { title: 'firmware', field: 'firmware' },
          { title: 'min', field: 'min' },
          { title: 'km', field: 'km' },
          { title: 'importe', field: 'importe' },
        ]}
        title={'Lista Facturas'}
        actionsColumnIndex={9}
        actions={[
          {
            icon: 'view_list',
            tooltip: 'Acciones',
            onClick: (event, rowData) => handleClickAction(event, rowData),
          },
        ]}
      />
    </div>
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

const actionCreators = {
  getAll: scooterActions.getAll,
  getTripsByScooterId: scooterActions.getTripsByScooterId,
}

export default connect(mapState, actionCreators)(BillView)
