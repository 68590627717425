import React from 'react'
import Dashboard from '../../dashboard'
import Page from './operatorUser'

class OperatorUserUpdate extends React.Component {
  render() {
    return <Dashboard component={<Page />} />
  }
}

export default OperatorUserUpdate
