import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import { isManager } from '../../helpers/roles'

import { useTranslation } from 'react-i18next'

import { scooterActions } from '../../redux/actions/scooter_actions'
import { servicesActions } from '../../redux/actions/services_actions'

import ScooterTrip from '../scooters/dialogs/scooterTrip'

import CustomMaterialTable from '../shared/customMaterialTable'

import DatePickerCustom from '../shared/datePickerCustom'
const ReportView = ({
  getAll,
  getAllByOperatorId,
  operatorReducer,
  servicesReducer,
  scooterReducer,
  getTrips,
}) => {
  const { t } = useTranslation()

  const [isTripsListModalOpen, setisTripsListModalOpen] = useState(false)
  const [selectedRow, setselectedRow] = useState({})

  useEffect(() => {
    // isManager()
    //   ? getAll()
    //   : getAllByOperatorId(operatorReducer.dataOperatorLogged.id);
  }, [])

  const handleRowClick = (rowData) => {
    setselectedRow(rowData)
    setisTripsListModalOpen(true)
  }

  return (
    <div>
      <DatePickerCustom searchAction={getTrips} rangeRequired={true} />
      <CustomMaterialTable
        saveFilters={true}
        addSummaryRow={true}
        isLoading={scooterReducer.loadingResults}
        title={t('reports.page.tableTitle')}
        columns={[
          {
            title: t('servicesPage.page.table.columns.tripId'),
            field: 'tripId',
          },
          {
            title: t('servicesPage.page.table.columns.sharingOperatorName'),
            field: 'sharingOperatorName',
            hidden: !isManager(),
          },
          {
            title: t('servicesPage.page.table.columns.plate'),
            field: 'vehiclePlate',
          },
          {
            title: t('servicesPage.page.table.columns.vin'),
            field: 'vehicleVin',
          },
          {
            title: t('servicesPage.page.table.columns.from'),
            field: 'originMapLink',
            render: (rowData) => (
              <a href={rowData.originMapLink} target={'blank'}>
                {t('scooters.details.trips.page.detailTrip.from')}
              </a>
            ),
            // formatDateToDisplay(rowData.reserveStartTimestamp),
          },
          {
            title: t('servicesPage.page.table.columns.to'),
            field: 'destinationMapLink',
            render: (rowData) => (
              <a href={rowData.destinationMapLink} target={'blank'}>
                {t('scooters.details.trips.page.detailTrip.to')}
              </a>
            ),
            // formatDateToDisplay(rowData.reserveStartTimestamp),
          },
          {
            title: t('servicesPage.page.table.columns.scooterName'),
            field: 'vehicleName',
          },
          {
            title: t('servicesPage.page.table.columns.customerFullName'),
            field: 'customerFullName',
          },
          {
            title: t('servicesPage.page.table.columns.customerEmail'),
            field: 'customerEmail',
          },
          {
            title: t('servicesPage.page.table.columns.tripStartDay'),
            field: 'startDay',
          },
          {
            title: t('servicesPage.page.table.columns.tripEndDay'),
            field: 'endDay',
          },
          {
            title: t('servicesPage.page.table.columns.distance'),
            field: 'distanceKms',
          },
          {
            title: t('servicesPage.page.table.columns.drivenMinutes'),
            field: 'drivenMinutes',
          },
          {
            title: t('servicesPage.page.table.columns.batteryUsage'),
            field: 'batteryUsage',
          },
          {
            title: t('servicesPage.page.table.columns.startBatterySoc'),
            field: 'startBattery',
          },
          {
            title: t('servicesPage.page.table.columns.endBatterySoc'),
            field: 'endBattery',
          },
          {
            title: t('servicesPage.page.table.columns.coSaved'),
            field: 'co2Saving',
          },
          {
            title: t('servicesPage.page.table.columns.avgSpeed'),
            field: 'speedAvg',
          },
          {
            title: t('servicesPage.page.table.columns.maxSpeed'),
            field: 'speedMax',
          },
        ]}
        data={scooterReducer.tripsDataReport}
        onRowClick={handleRowClick}
        rowClickAvaiable={true}
      />

      <ScooterTrip
        scooterId={selectedRow && selectedRow.vehicleId}
        isOpen={isTripsListModalOpen}
        onClose={() => {
          setisTripsListModalOpen(false)
        }}
        // data={selectedRow}
        preSelectTrip={selectedRow}
      />
    </div>
  )
}
function mapState(state) {
  const { scooterReducer, operatorReducer, servicesReducer } = state
  return { scooterReducer, operatorReducer, servicesReducer }
}

const actionCreators = {
  getAll: servicesActions.getAll,
  getAllByOperatorId: servicesActions.getAllByOperatorId,
  getTrips: scooterActions.getTrips,
}

export default connect(mapState, actionCreators)(ReportView)
