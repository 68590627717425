import React, { Fragment, useState } from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

import SectionDialog from './section/index'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '2%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  headerSection: {
    marginBottom: '2%',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '5%',
  },
  titleLinks: {
    margin: '2% 0%',
  },
  link: {
    cursor: 'pointer',
    marginLeft: '2%',
  },
  sections: {
    marginTop: '5%',
  },
}))

const HelpView = () => {
  const classes = useStyles()

  const { t } = useTranslation()

  const [selectedQuestion, setselectedQuestion] = useState()
  const [isDialogSectionOpen, setisDialogSectionOpen] = useState(false)
  const handleOpenQuestion = (data) => {
    setselectedQuestion(data)
    setisDialogSectionOpen(true)
  }

  const links = [
    {
      title: t('help.page.links.clients.title'),
      link: [
        {
          question: t('help.page.links.clients.link.1.question'),
          section: {
            list: [
              t('help.page.links.clients.link.1.sections.1'),
              t('help.page.links.clients.link.1.sections.2'),
              t('help.page.links.clients.link.1.sections.3'),
              t('help.page.links.clients.link.1.sections.4'),
            ],
          },
        },
        {
          question: t('help.page.links.clients.link.2.question'),
          section: {
            list: [
              t('help.page.links.clients.link.2.sections.1'),
              t('help.page.links.clients.link.2.sections.2'),
            ],
          },
        },
        {
          question: t('help.page.links.clients.link.3.question'),
          section: {
            list: [
              t('help.page.links.clients.link.3.sections.1'),
              t('help.page.links.clients.link.3.sections.2'),
            ],
          },
        },
        {
          question: t('help.page.links.clients.link.4.question'),
          section: {
            list: [
              t('help.page.links.clients.link.4.sections.1'),
              t('help.page.links.clients.link.4.sections.2'),
            ],
          },
        },
        {
          question: t('help.page.links.clients.link.5.question'),
          section: {
            list: [
              t('help.page.links.clients.link.5.sections.1'),
              t('help.page.links.clients.link.5.sections.2'),
              t('help.page.links.clients.link.5.sections.3'),
            ],
          },
        },
        {
          question: t('help.page.links.clients.link.6.question'),
          section: {
            list: [
              t('help.page.links.clients.link.6.sections.1'),
              t('help.page.links.clients.link.6.sections.2'),
              t('help.page.links.clients.link.6.sections.3'),
            ],
          },
        },
      ],
    },
    {
      title: t('help.page.links.vehicles.title'),
      link: [
        {
          question: t('help.page.links.vehicles.link.1.question'),
          section: {
            list: [
              t('help.page.links.vehicles.link.1.sections.1'),
              t('help.page.links.vehicles.link.1.sections.2'),
            ],
          },
        },
        {
          question: t('help.page.links.vehicles.link.2.question'),
          section: {
            list: [
              t('help.page.links.vehicles.link.2.sections.1'),
              t('help.page.links.vehicles.link.2.sections.2'),
              t('help.page.links.vehicles.link.2.sections.3'),
            ],
          },
        },
        {
          question: t('help.page.links.vehicles.link.3.question'),
          section: {
            list: [
              t('help.page.links.vehicles.link.3.sections.1'),
              t('help.page.links.vehicles.link.3.sections.2'),
              t('help.page.links.vehicles.link.3.sections.3'),
              t('help.page.links.vehicles.link.3.sections.4'),
            ],
          },
        },
        {
          question: t('help.page.links.vehicles.link.4.question'),
          section: {
            list: [
              t('help.page.links.vehicles.link.4.sections.1'),
              t('help.page.links.vehicles.link.4.sections.2'),
              t('help.page.links.vehicles.link.4.sections.3'),
            ],
          },
        },
        {
          question: t('help.page.links.vehicles.link.5.question'),
          section: {
            list: [
              t('help.page.links.vehicles.link.5.sections.1'),
              t('help.page.links.vehicles.link.5.sections.2'),
              t('help.page.links.vehicles.link.5.sections.3'),
            ],
          },
        },
      ],
    },
    {
      title: t('help.page.links.rates.title'),
      link: [
        {
          question: t('help.page.links.rates.link.1.question'),
          section: {
            list: [
              t('help.page.links.rates.link.1.sections.1'),
              t('help.page.links.rates.link.1.sections.2'),
              t('help.page.links.rates.link.1.sections.3'),
              t('help.page.links.rates.link.1.sections.4'),
            ],
          },
        },
      ],
    },
    {
      title: t('help.page.links.discounts.title'),
      link: [
        {
          question: t('help.page.links.discounts.link.1.question'),
          section: {
            list: [
              t('help.page.links.discounts.link.1.sections.1'),
              t('help.page.links.discounts.link.1.sections.2'),
            ],
          },
        },
        {
          question: t('help.page.links.discounts.link.2.question'),
          section: {
            list: [t('help.page.links.discounts.link.2.sections.1')],
          },
        },
        {
          question: t('help.page.links.discounts.link.3.question'),
          section: {
            list: [
              t('help.page.links.discounts.link.3.sections.1'),
              t('help.page.links.discounts.link.3.sections.2'),
              t('help.page.links.discounts.link.3.sections.3'),
            ],
          },
        },
        {
          question: t('help.page.links.discounts.link.4.question'),
          section: {
            list: [
              t('help.page.links.discounts.link.4.sections.1'),
              t('help.page.links.discounts.link.4.sections.2'),
              t('help.page.links.discounts.link.4.sections.3'),
              t('help.page.links.discounts.link.4.sections.4'),
              t('help.page.links.discounts.link.4.sections.5'),
            ],
          },
        },
      ],
    },

    {
      title: t('help.page.links.technicians.title'),
      link: [
        {
          question: t('help.page.links.technicians.link.1.question'),
          section: {
            list: [
              t('help.page.links.technicians.link.1.sections.1'),
              t('help.page.links.technicians.link.1.sections.2'),
              t('help.page.links.technicians.link.1.sections.3'),
            ],
          },
        },
        {
          question: t('help.page.links.technicians.link.2.question'),
          section: {
            list: [
              t('help.page.links.technicians.link.2.sections.1'),
              t('help.page.links.technicians.link.2.sections.2'),
            ],
          },
        },
      ],
    },
    {
      title: t('help.page.links.supervisors.title'),
      link: [
        {
          question: t('help.page.links.supervisors.link.1.question'),
          section: {
            list: [
              t('help.page.links.supervisors.link.1.sections.1'),
              t('help.page.links.supervisors.link.1.sections.2'),
              t('help.page.links.supervisors.link.1.sections.3'),
            ],
          },
        },
        {
          question: t('help.page.links.supervisors.link.2.question'),
          section: {
            list: [
              t('help.page.links.supervisors.link.2.sections.1'),
              t('help.page.links.supervisors.link.2.sections.2'),
            ],
          },
        },
      ],
    },
    {
      title: t('help.page.links.trips.title'),
      link: [
        {
          question: t('help.page.links.trips.link.1.question'),
          section: {
            list: [
              t('help.page.links.trips.link.1.sections.1'),
              t('help.page.links.trips.link.1.sections.2'),
              t('help.page.links.trips.link.1.sections.3'),
              t('help.page.links.trips.link.1.sections.4'),
              t('help.page.links.trips.link.1.sections.5'),
            ],
          },
        },
      ],
    },
    {
      title: t('help.page.links.incidents.title'),
      link: [
        {
          question: t('help.page.links.incidents.link.1.question'),
          section: {
            list: [
              t('help.page.links.incidents.link.1.sections.1'),
              t('help.page.links.incidents.link.1.sections.2'),
              t('help.page.links.incidents.link.1.sections.3'),
              t('help.page.links.incidents.link.1.sections.4'),
            ],
          },
        },
      ],
    },
    {
      title: t('help.page.links.profileData.title'),
      link: [
        {
          question: t('help.page.links.profileData.link.1.question'),
          section: {
            list: [
              t('help.page.links.profileData.link.1.sections.1'),
              t('help.page.links.profileData.link.1.sections.2'),
              t('help.page.links.profileData.link.1.sections.3'),
            ],
          },
        },
      ],
    },
    {
      title: t('help.page.links.personalData.title'),
      link: [
        {
          question: t('help.page.links.personalData.link.1.question'),
          section: {
            list: [
              t('help.page.links.personalData.link.1.sections.1'),
              t('help.page.links.personalData.link.1.sections.2'),
              t('help.page.links.personalData.link.1.sections.3'),
              t('help.page.links.personalData.link.1.sections.4'),
            ],
          },
        },
        {
          question: t('help.page.links.personalData.link.2.question'),
          section: {
            list: [
              t('help.page.links.personalData.link.2.sections.1'),
              t('help.page.links.personalData.link.2.sections.2'),
              t('help.page.links.personalData.link.2.sections.3'),
              t('help.page.links.personalData.link.2.sections.4'),
              t('help.page.links.personalData.link.2.sections.5'),
            ],
          },
        },
      ],
    },
  ]

  return (
    <div className={classes.root}>
      <div className={classes.headerSection}>
        <Typography variant="h3" component="h3">
          {t('help.page.title')}
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          {t('help.page.subtitle')} (mail & tel)
        </Typography>
      </div>

      <div className={classes.links}>
        {links.map((l) => {
          return (
            <Fragment>
              <Typography
                variant="h5"
                component="h5"
                className={classes.titleLinks}
              >
                {l.title}
              </Typography>
              {l.link.map((i) => {
                return (
                  <Link
                    className={classes.link}
                    onClick={() => handleOpenQuestion(i)}
                  >
                    {i.question}
                  </Link>
                )
              })}
            </Fragment>
          )
        })}
      </div>
      <SectionDialog
        isOpen={isDialogSectionOpen}
        onClose={() => setisDialogSectionOpen(false)}
        data={selectedQuestion}
      />
    </div>
  )
}

export default HelpView
