import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'

import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import { customerActions } from '../../../redux/actions/customers_actions'

import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'

import TableResults from '../../operator/tableResults/tableResults'

const useStyles = makeStyles((theme) => ({
  paper: {
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  submitBtn: {
    marginTop: '2%',
  },
}))

const BillDetailsView = ({ dataTrip, data, customerReducer, getAll }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // const handleAction = (event, rowData) => {
  //   console.log('rowData', rowData)
  // }
  useEffect(() => {
    getAll()
  }, [])

  return (
    <div className={classes.paper}>
      <form className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              // variant='standard'
              required
              fullWidth
              label={'Client'}
              autoFocus
              // value={name}
              // onChange={e => setname(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={'producto'}
              // value={nif}
              // onChange={e => setnif(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={'fecha inicio'}
              // value={cc}
              // onChange={e => setcc(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={'fecha final'}
              // value={cc}
              // onChange={e => setcc(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={'descuento'}
              type="string"
              // value={primaryColor}
              // onChange={e => setprimaryColor(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={'forma de pago'}
              type="string"
              // value={secondaryColor}
              // onChange={e => setsecondaryColor(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TableResults
              data={customerReducer.results}
              columns={[
                { title: 'eMail', field: 'eMail' },
                { title: 'gender', field: 'gender' },
                { title: 'telephone', field: 'telephone' },
              ]}
              title={'Lista Facturas'}
              actionsColumnIndex={9}
              // actions={[
              //     {
              //         icon: 'view_list',
              //         tooltip: 'Acciones',
              //         onClick: (event, rowData) => handleAction(event, rowData)
              //     }
              // ]}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

function mapState(state) {
  const { customerReducer } = state
  return { customerReducer }
}

const actionCreators = {
  getAll: customerActions.getAll,
}

export default connect(mapState, actionCreators)(BillDetailsView)
