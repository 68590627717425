import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import MapsGeofence from '../../shared/geofence/mapsGeofence'
import ScootersToValidate from '../modal/scootersToValidateInsideModalOperatorValidate/scootersToValidate'
import DataOperator from './data'
import SubOperatorsData from './data/subOperators'

import { connect } from 'react-redux'

import { useTranslation } from 'react-i18next'
import { tariffsActions } from '../../../redux/actions/tariffs_action'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Rates from '../profile/rates/rates'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
  },
  mainContainerContent: {
    padding: '1%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DetailsOperator = ({
  isOpen,
  onClose,
  data,
  getByOperatorId,
  tariffsReducer,
  operatorReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    areas,
    canOperate,
    cannotOperateReason,
    chargeTypes,
    cif,
    circles,
    colour1,
    colour2,
    contactPhone,
    discounts,
    ecoCount,
    group,
    iban,
    id,
    isHelmetIncluded,
    isPrivate,
    isStationBase,
    isSupportDerived,
    kmsCount,
    logoBinary,
    managerEmail,
    name,
    servicesCount,
  } = data || {}

  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleClose = () => {
    onClose(false)
  }

  useEffect(() => {
    data && isOpen && getByOperatorId(id)
  }, [data])

  return (
    <div>
      {data && (
        <Dialog
          fullScreen
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {t('operator.detailsOperator.toolbarText')}: {name}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {t('operator.detailsOperator.accordionSummaryDataText')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ maxWidth: '60%', margin: 'auto' }}>
                <DataOperator data={data} />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>
                  {t('operator.detailsOperator.accordionSummaryGeofenceText')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MapsGeofence
                  toolsAvaiable={false}
                  data={data}
                  displayOnly={false}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.heading}>
                  {t('operator.detailsOperator.accordionSummaryTariffsText')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Rates displayOnly={true} isUpdatingData={false} />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.heading}>
                  {t('operator.detailsOperator.accordionSummaryScootersText')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ScootersToValidate operatorId={data.id} displayOnly />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className={classes.heading}>
                  {t('operator.detailsOperator.accordionSummarySubOperators')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SubOperatorsData
                  operatorReducer={operatorReducer}
                  data={data}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </Dialog>
      )}
    </div>
  )
}

function mapState(state) {
  const { operatorReducer, tariffsReducer } = state
  return { operatorReducer, tariffsReducer }
}

const actionCreators = {
  getByOperatorId: tariffsActions.getByOperatorId,
}

export default connect(mapState, actionCreators)(DetailsOperator)
