import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { scooterActions } from '../../../../redux/actions/scooter_actions'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const ConfigParams = ({ isOpen, onClose, scooterReducer, onSave }) => {
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  const [showOutOfService, setshowOutOfService] = useState(false)
  const [minutesDataReceived, setminutesDataReceived] = useState(0)

  useEffect(() => {
    const {
      showOnlyVehiclesWithDataReceivedLastMinutes,
      showOutOfServiceVehicles,
      id,
    } = scooterReducer.paramsConfig[0] || {}
    if (isOpen) {
      setshowOutOfService(showOutOfServiceVehicles)
      setminutesDataReceived(showOnlyVehiclesWithDataReceivedLastMinutes)
    }
  }, [isOpen])

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('scooters.location.dialogs.configParams.title')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {t('scooters.location.dialogs.configParams.description')}
          </Typography>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showOutOfService}
                  onChange={(e) => setshowOutOfService(e.target.checked)}
                />
              }
              label={t(
                'scooters.location.dialogs.configParams.labelOtOfService',
              )}
            />
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <TextField
                label={t('scooters.location.dialogs.configParams.labelMinutes')}
                type={'number'}
                value={minutesDataReceived?.toString()}
                onChange={(e) => setminutesDataReceived(e.target.value)}
              />
              <p style={{ marginLeft: '1%' }}>
                {t('scooters.location.dialogs.configParams.showVehiclesText')}
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            {t('scooters.location.dialogs.configParams.closeBtn')}
          </Button>
          <Button
            autoFocus
            onClick={() =>
              onSave({
                showOnlyVehiclesWithDataReceivedLastMinutes:
                  parseInt(minutesDataReceived),
                showOutOfServiceVehicles: showOutOfService,
                id: scooterReducer.paramsConfig[0].id,
              })
            }
            color="primary"
          >
            {t('scooters.location.dialogs.configParams.saveBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

const actionCreators = {
  getParamsConfig: scooterActions.getParamsConfig,
  setParamsConfig: scooterActions.setParamsConfig,
}

export default connect(mapState, actionCreators)(ConfigParams)
