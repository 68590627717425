export const fotaConstants = {
  FILTER_REQUEST: 'FOTA_FILTER_REQUEST',
  FILTER_SUCCESS: 'FOTA_FILTER_SUCCESS',
  FILTER_FAILURE: 'FOTA_FILTER_FAILURE',

  GETALL_REQUEST: 'FOTA_GETALL_REQUEST',
  GETALL_SUCCESS: 'FOTA_GETALL_SUCCESS',
  GETALL_FAILURE: 'FOTA_GETALL_FAILURE',

  GETALL_PROCESSID_REQUEST: 'FOTA_GETALL_PROCESSID_REQUEST',
  GETALL_PROCESSID_SUCCESS: 'FOTA_GETALL_PROCESSID_SUCCESS',
  GETALL_PROCESSID_FAILURE: 'FOTA_GETALL_PROCESSID_FAILURE',

  DETAILS_REQUEST: 'FOTA_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'FOTA_DETAILS_SUCCESS',
  DETAILS_FAILURE: 'FOTA_DETAILS_FAILURE',

  CLEAR_RESULTS: 'FOTA_CLEAR_RESULTS',

  ADD_REQUEST: 'FOTA_ADD_REQUEST',
  ADD_SUCCESS: 'FOTA_ADD_SUCCESS',
  ADD_FAILURE: 'FOTA_ADD_FAILURE',

  GET_MASTER_DATA_REQUEST: 'FOTA_GET_MASTER_DATA_REQUEST',
  GET_MASTER_DATA_SUCCESS: 'FOTA_GET_MASTER_DATA_SUCCESS',
  GET_MASTER_DATA_FAILURE: 'FOTA_GET_MASTER_DATA_FAILURE',

  CANCEL_REQUEST: 'FOTA_CANCEL_REQUEST',
  CANCEL_SUCCESS: 'FOTA_CANCEL_SUCCESS',
  CANCEL_FAILURE: 'FOTA_CANCEL_FAILURE',
}
