import React from 'react'

import useStyles from './style'

import SpecificationScooter from './spec'

import Link from '@material-ui/core/Link'

import { history } from '../../../helpers/history'

const DetailsViewScooter = (props) => {
  const classes = useStyles()

  const { t } = props

  const handleGoBack = () => {
    history.goBack()
    props.cleanTripsView()
  }
  return (
    <div className={classes.root}>
      <Link
        onClick={handleGoBack}
        style={{
          cursor: 'pointer',
          margin: '1%',
          display: 'flex',
          justifyContent: 'flex-end',
          fontSize: '2em',
        }}
      >
        {t('scooters.details.spec.page.vehicule.goBack')}
      </Link>
      <SpecificationScooter {...props} />
    </div>
  )
}

export default DetailsViewScooter
