import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'

import { operatorActions } from '../../../redux/actions/operator_action'
import { ticketsActions } from '../../../redux/actions/tickets_action'

import { connect } from 'react-redux'

import { useTranslation } from 'react-i18next'
import IncidentsDetailsView from './page'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const IncidentsDetails = ({
  isOpen,
  onClose,
  data,
  getById,
  ticketsReducer,
  operatorReducer,
  getAll,
}) => {
  const classes = useStyles()

  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      getById(data.id)
      getAll(data.sharingOperatorId)
    }
  }, [isOpen])

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('incidents.incidentsDetails.toolbarText')}
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <IncidentsDetailsView data={data} onClose={onClose} />
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { ticketsReducer, scooterReducer, operatorReducer } = state
  return { ticketsReducer, scooterReducer, operatorReducer }
}

const actionCreators = {
  addNote: ticketsActions.addNote,
  getById: ticketsActions.getById,
  closeTicket: ticketsActions.closeTicket,
  getAll: operatorActions.getAll,
}

export default connect(mapState, actionCreators)(IncidentsDetails)
