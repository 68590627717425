import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  topButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '2%',
  },
}))

export default useStyles
