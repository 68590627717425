import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import DialogConfirmAction from '../../shared/modalConfirmAction'
import OperatorsList from './operatorsList'

import { formatDateToDisplay } from '../../shared/formatDate'

import { isManager } from '../../../helpers/roles'
import CustomMaterialTable from '../../shared/customMaterialTable'
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  tableContainer: {
    padding: '1%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ActionsToOperators = ({
  isOpen,
  onClose,
  customerSelectedData,
  operatorData,
  onUnassign,
  privateOperatorsData,
  onGetAllPrivateOperators,
  onAssign,
  cbo,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isOperatorListDialogOpen, setisOperatorListDialogOpen] =
    useState(false)
  const [isDialogConfirmActionOpen, setisDialogConfirmActionOpen] =
    useState(false)
  const [selectedOperator, setselectedOperator] = useState({})
  const handleClose = () => {
    onClose()
  }

  const [displayList, setdisplayList] = useState([])
  const handleOpenAssignToOperator = (event, rowData) => {
    onGetAllPrivateOperators()
    setisOperatorListDialogOpen(true)
  }
  const handleConfirmAssignToOperator = (
    operator,
    validatedSince,
    validatedUntil,
  ) => {
    setisOperatorListDialogOpen(false)
    onAssign(operator, validatedSince, validatedUntil)
  }

  const handleUnassignOperator = (rowData) => {
    setselectedOperator(rowData)
    setisDialogConfirmActionOpen(true)
  }
  const handleConfirmUnassignOperator = () => {
    setisDialogConfirmActionOpen(false)
    onUnassign(selectedOperator)
  }
  const mountdisplayList = () => {
    let updatedList = []
    operatorData.map((o) => {
      cbo.map((i) => {
        i.customerId === customerSelectedData.customerId &&
          o.id === i.sharingOperatorId &&
          updatedList.push({ ...o, cbo: i })
      })
    })
    setdisplayList(updatedList)
  }

  useEffect(() => {
    isOpen && mountdisplayList()
  }, [isOpen, operatorData, cbo])

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('customers.modals.ActionsToOperators.toolbarText')}{' '}
              {customerSelectedData.customerFullName}
            </Typography>
            {/* <Button
              autoFocus
              color="inherit"
              onClick={handleOpenAssignToOperator}
            >
              {t("customers.modals.ActionsToOperators.assignBtn")}
            </Button> */}
          </Toolbar>
        </AppBar>
        <div className={classes.tableContainer}>
          <CustomMaterialTable
            title={t('customers.modals.ActionsToOperators.table.title')}
            columns={[
              {
                title: t(
                  'customers.modals.ActionsToOperators.table.columns.name',
                ),
                field: 'name',
              },
              {
                title: t(
                  'customers.modals.ActionsToOperators.table.columns.email',
                ),
                field: 'managerEmail',
              },
              {
                title: t(
                  'customers.modals.ActionsToOperators.table.columns.mobilePhone',
                ),
                field: 'contactPhone',
              },
              {
                title: t(
                  'customers.modals.ActionsToOperators.table.columns.since',
                ),
                field: 'cbo.canOperateStart',
                render: (rowData) =>
                  rowData.cbo && rowData.cbo.canOperateStart
                    ? formatDateToDisplay(rowData.cbo.canOperateStart, true)
                    : '',
              },
              {
                title: t(
                  'customers.modals.ActionsToOperators.table.columns.until',
                ),
                field: 'cbo.canOperateEnd',
                render: (rowData) =>
                  rowData.cbo && rowData.cbo.canOperateEnd
                    ? formatDateToDisplay(rowData.cbo.canOperateEnd, true)
                    : '',
              },
              {
                title: t(
                  'customers.modals.ActionsToOperators.table.columns.operative',
                ),
                field: 'canOperate',
                addlookup: true,
              },
              {
                title: t(
                  'customers.modals.ActionsToOperators.table.columns.private',
                ),
                field: 'isPrivate',
                addlookup: true,
              },
            ]}
            data={displayList}
            actions={[
              (rowData) => ({
                icon: 'assignment_late',
                tooltip: t(
                  'customers.modals.ActionsToOperators.table.actions.unasignClient',
                ),
                onClick: (event, rowData) => handleUnassignOperator(rowData),
              }),
              {
                icon: 'add',
                tooltip: t('customers.modals.ActionsToOperators.assignBtn'),
                isFreeAction: true,
                hidden: !isManager(),
                onClick: (event) => handleOpenAssignToOperator(true),
              },
            ]}
          />
        </div>
      </Dialog>
      <OperatorsList
        isOpen={isOperatorListDialogOpen}
        onClose={() => setisOperatorListDialogOpen(false)}
        onConfirm={handleConfirmAssignToOperator}
        data={privateOperatorsData}
      />
      <DialogConfirmAction
        isOpen={isDialogConfirmActionOpen}
        onClose={() => setisDialogConfirmActionOpen(false)}
        onConfirm={handleConfirmUnassignOperator}
        data={{
          header: t('customers.modals.ActionsToOperators.confirmAction.header'),
          body: t('customers.modals.ActionsToOperators.confirmAction.body'),
        }}
        hasNote={false}
        addNote={false}
      />
    </div>
  )
}
export default ActionsToOperators
