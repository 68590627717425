import { alertConstants } from '../../constants/alert_constants'

export function alertReducer(state = { messages: [] }, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        messages: [...state.messages, action.message],
      }
    case alertConstants.ERROR:
      return {
        type: 'error',
        messages: [...state.messages, action.message],
      }
    case alertConstants.WARNING:
      return {
        type: 'warning',
        messages: [action.message],
      }
    case alertConstants.CLEAR:
      return { ...state, messages: [] }
    default:
      return state
  }
}
