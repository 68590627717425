import { handleCall } from './handleResponse'
import { loginService } from './loginService'

export const userService = {
  changePassword,
  resetPassword,
  subscribeToTopic,
  unSubscribeToTopic,
  deleteCustomer,
}

function changePassword(body) {
  return handleCall(`/customers/v1/Customers/UpdatePassword`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}
function resetPassword(body) {
  return handleCall(`/users/v1/SendResetPasswordEmail`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function subscribeToTopic(token) {
  return handleCall(`/operators/v1/Operators/SubscribeToTopic?token=${token}`, {
    method: 'POST',
    body: null,
  })
}

function unSubscribeToTopic() {
  const token = loginService.getWithExpiry('token_notifications')
  return handleCall(
    `/operators/v1/Operators/UnSubscribeFromTopic?token=${token}`,
    {
      method: 'POST',
      body: null,
    },
  )
}

function deleteCustomer(email) {
  return handleCall(`/customers/v1/Customers/Delete?email=${email}`, {
    method: 'DELETE',
    body: null,
  })
}

// function handleResponse(response) {
//   // console.log('handleResponse', response)
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       const error = (data && data.message) || text;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }
