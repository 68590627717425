import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: '2%',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    // marginBottom: "2%",
    // padding: "2%",
  },
  heatContainerFitler: {
    padding: '2%',
  },
  dataGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoWindowContent: {
    width: '250px',
  },
  infowindowTitlesRows: {
    fontWeight: 'bold',
  },
  filterLayersInsideMap: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '50px',
    // paddingTop: '10px'
  },
  btnFilter: {
    background: '#ffffff',
    color: '#666666',
    width: '40px',
    height: '40px',
    margin: '10px 10px',
  },
  btnFilter2: {
    background: '#ffffff',
    color: '#666666',
    width: '40px',
    height: '40px',
    margin: '-40px 2px',
  },
  btnActive: {
    color: '#88c100',
  },
  autocomplete: {
    width: '30%',
    margin: 'auto',
    paddingTop: '10px',
    borderColor: 'black',
  },
  inputFilterOperator: {
    background: '#ffffff',
  },
  cssOutlinedInput: {
    borderColor: `black !important`,
  },
  cardInfoOperatorArea: {
    right: '1%',
    marginTop: '2%',
    background: '#ffffff',
    minWidth: 275,
    position: 'absolute',
    boxShadow: '0px 0px 5px 0px #909090',
  },
  link: {
    cursor: 'pointer',
  },
  containerBtnConfigParams: {
    display: 'flex',
    // justifyContent: "flex-end",
    padding: '1%',
  },
  linkGroup: {
    display: 'flex',
    marginTop: '5%',
    justifyContent: 'space-around',
  },
}))

export default useStyles
