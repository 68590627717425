import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import AssessmentIcon from '@material-ui/icons/Assessment'
import BuildIcon from '@material-ui/icons/Build'
import ContactsIcon from '@material-ui/icons/Contacts'
import EditIcon from '@material-ui/icons/Edit'
import EvStationIcon from '@material-ui/icons/EvStation'
import ExploreIcon from '@material-ui/icons/Explore'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import HomeIcon from '@material-ui/icons/Home'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import LanguageIcon from '@material-ui/icons/Language'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import PaymentIcon from '@material-ui/icons/Payment'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import StorageIcon from '@material-ui/icons/Storage'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import React from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { checkRelationRoleAndLinks } from '../../helpers/drawerList'
import {
  isCauUser,
  isManager,
  isSacUser,
  isUserWithRole,
  rolesConstants,
} from '../../helpers/roles'
import CustomDivider from './items/customDivider'

const ListItems = ({ t, authenticationReducer, operatorReducer }) => {
  const location = useLocation()

  const listItemStyle = (path) => {
    return {
      background: location.pathname === path ? '#e41622' : 'inherit',
    }
  }
  const iconStyle = (path) => {
    return {
      color: location.pathname === path ? 'white' : '#e41622',
    }
  }

  return (
    <div>
      {checkRelationRoleAndLinks(authenticationReducer, '/home') && (
        <Tooltip title={t('dashboard.items.home')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/home"
            style={listItemStyle('/home')}
          >
            <ListItemIcon>
              <HomeIcon style={iconStyle('/home')} />
            </ListItemIcon>
            <ListItemText
              style={{
                color: 'white',
              }}
              primary={t('dashboard.items.home')}
            />
          </ListItem>
        </Tooltip>
      )}
      {checkRelationRoleAndLinks(authenticationReducer, '/operator/validate') &&
        !isUserWithRole([rolesConstants.SAC_USER, rolesConstants.CAU_USER]) && (
          <Tooltip
            title={t('dashboard.items.validateOperator')}
            placement="right"
            arrow
          >
            <ListItem
              button
              component={Link}
              to="/operators/validate"
              style={listItemStyle('/operators/validate')}
            >
              <ListItemIcon>
                <HowToRegIcon style={iconStyle('/operators/validate')} />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: 'white',
                }}
                primary={t('dashboard.items.validateOperator')}
              />
            </ListItem>
          </Tooltip>
        )}

      {checkRelationRoleAndLinks(
        authenticationReducer,
        '/scooters/locations',
      ) &&
        !isManager() && (
          <Tooltip
            title={t('dashboard.items.locations')}
            placement="right"
            arrow
          >
            <ListItem
              button
              component={Link}
              to="/scooters/locations"
              style={listItemStyle('/scooters/locations')}
            >
              <ListItemIcon>
                <LanguageIcon style={iconStyle('/scooters/locations')} />
              </ListItemIcon>
              <ListItemText
                style={{ color: 'white' }}
                primary={t('dashboard.items.locations')}
              />
            </ListItem>
          </Tooltip>
        )}
      {checkRelationRoleAndLinks(authenticationReducer, '/operator/validate') &&
        isUserWithRole([rolesConstants.SAC_USER, rolesConstants.CAU_USER]) && (
          <Tooltip
            title={t('dashboard.items.validateOperator')}
            placement="right"
            arrow
          >
            <ListItem
              button
              component={Link}
              to="/operators/validate"
              style={listItemStyle('/operators/validate')}
            >
              <ListItemIcon>
                <HowToRegIcon style={iconStyle('/operators/validate')} />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: 'white',
                }}
                primary={t('dashboard.items.validateOperator')}
              />
            </ListItem>
          </Tooltip>
        )}
      {checkRelationRoleAndLinks(authenticationReducer, '/customers') && (
        <Tooltip title={t('dashboard.items.customers')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/customers"
            style={listItemStyle('/customers')}
          >
            <ListItemIcon>
              <ContactsIcon style={iconStyle('/customers')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.customers')}
            />
          </ListItem>
        </Tooltip>
      )}
      {checkRelationRoleAndLinks(authenticationReducer, '/cau') && (
        <Tooltip title={'CAU'} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/cau"
            style={listItemStyle('/cau')}
          >
            <ListItemIcon>
              <ContactsIcon style={iconStyle('/cau')} />
            </ListItemIcon>
            <ListItemText style={{ color: 'white' }} primary={'CAU'} />
          </ListItem>
        </Tooltip>
      )}
      {checkRelationRoleAndLinks(authenticationReducer, '/sac') && (
        <Tooltip title={'SAC'} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/sac"
            style={listItemStyle('/sac')}
          >
            <ListItemIcon>
              <ContactsIcon style={iconStyle('/sac')} />
            </ListItemIcon>
            <ListItemText style={{ color: 'white' }} primary={'SAC'} />
          </ListItem>
        </Tooltip>
      )}
      <CustomDivider />
      {checkRelationRoleAndLinks(
        authenticationReducer,
        '/scooters/locations',
      ) &&
        isManager() && (
          <Tooltip
            title={t('dashboard.items.locations')}
            placement="right"
            arrow
          >
            <ListItem
              button
              component={Link}
              to="/scooters/locations"
              style={listItemStyle('/scooters/locations')}
            >
              <ListItemIcon>
                <LanguageIcon style={iconStyle('/scooters/locations')} />
              </ListItemIcon>
              <ListItemText
                style={{ color: 'white' }}
                primary={t('dashboard.items.locations')}
              />
            </ListItem>
          </Tooltip>
        )}
      {checkRelationRoleAndLinks(
        authenticationReducer,
        '/operator/scooterList',
      ) &&
        isUserWithRole([
          rolesConstants.MANAGER,
          rolesConstants.CAU_USER,
          rolesConstants.SAC_USER,
        ]) && (
          <Tooltip
            title={t('dashboard.items.operatorsScooters')}
            placement="right"
            arrow
          >
            <ListItem
              button
              component={Link}
              to="/operator/scooterList"
              style={listItemStyle('/operator/scooterList')}
            >
              <ListItemIcon>
                <MotorcycleIcon style={iconStyle('/operator/scooterList')} />
              </ListItemIcon>
              <ListItemText
                style={{ color: 'white' }}
                primary={t('dashboard.items.operatorsScooters')}
              />
            </ListItem>
          </Tooltip>
        )}
      {checkRelationRoleAndLinks(authenticationReducer, '/trips') && (
        <Tooltip title={t('dashboard.items.trips')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/trips"
            style={listItemStyle('/trips')}
          >
            <ListItemIcon>
              <ExploreIcon style={iconStyle('/trips')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.trips')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/services') && (
        <Tooltip title={t('dashboard.items.services')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/services"
            style={listItemStyle('/services')}
          >
            <ListItemIcon>
              <LocationCityIcon style={iconStyle('/services')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.services')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/monthlyDetails') && (
        <Tooltip
          title={t('dashboard.items.monthlyDetails')}
          placement="right"
          arrow
        >
          <ListItem
            button
            component={Link}
            to="/monthlyDetails"
            style={listItemStyle('/monthlyDetails')}
          >
            <ListItemIcon>
              <AssessmentIcon style={iconStyle('/monthlyDetails')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.monthlyDetails')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/incidents') && (
        <Tooltip title={t('dashboard.items.incidents')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/incidents"
            style={listItemStyle('/incidents')}
          >
            <ListItemIcon>
              <ReportProblemIcon style={iconStyle('/incidents')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.incidents')}
            />
          </ListItem>
        </Tooltip>
      )}
      {checkRelationRoleAndLinks(authenticationReducer, '/rates') && (
        <Tooltip title={t('dashboard.items.rates')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/rates"
            style={listItemStyle('/rates')}
          >
            <ListItemIcon>
              <LocalAtmIcon style={iconStyle('/rates')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.rates')}
            />
          </ListItem>
        </Tooltip>
      )}

      <CustomDivider />
      {checkRelationRoleAndLinks(authenticationReducer, '/bonus') && (
        <Tooltip title={t('dashboard.items.bonus')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/bonus"
            style={listItemStyle('/bonus')}
          >
            <ListItemIcon>
              <LocalAtmIcon style={iconStyle('/bonus')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.bonus')}
            />
          </ListItem>
        </Tooltip>
      )}
      {checkRelationRoleAndLinks(authenticationReducer, '/payments') && (
        <Tooltip title={t('dashboard.items.payment')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/payments"
            style={listItemStyle('/payments')}
          >
            <ListItemIcon>
              <PaymentIcon style={iconStyle('/payments')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.payment')}
            />
          </ListItem>
        </Tooltip>
      )}
      {isManager() && <CustomDivider />}
      {checkRelationRoleAndLinks(authenticationReducer, '/validations') && (
        <Tooltip
          title={t('dashboard.items.validations')}
          placement="right"
          arrow
        >
          <ListItem
            button
            component={Link}
            to="/validations"
            style={listItemStyle('/validations')}
          >
            <ListItemIcon>
              <HowToRegIcon style={iconStyle('/validations')} />
            </ListItemIcon>
            <ListItemText
              style={{
                color: 'white',
              }}
              primary={t('dashboard.items.validations')}
            />
          </ListItem>
        </Tooltip>
      )}
      {checkRelationRoleAndLinks(
        authenticationReducer,
        '/operator/scooterList',
      ) &&
        !isManager() &&
        !isSacUser() &&
        !isCauUser() && (
          <Tooltip
            title={t('dashboard.items.operatorsScooters')}
            placement="right"
            arrow
          >
            <ListItem
              button
              component={Link}
              to="/operator/scooterList"
              style={listItemStyle('/operator/scooterLis')}
            >
              <ListItemIcon>
                <EvStationIcon style={iconStyle('/operator/scooterLis')} />
              </ListItemIcon>
              <ListItemText
                style={{ color: 'white' }}
                primary={t('dashboard.items.operatorsScooters')}
              />
            </ListItem>
          </Tooltip>
        )}
      {checkRelationRoleAndLinks(authenticationReducer, '/operatorsUsers') && (
        <Tooltip
          title={t('dashboard.items.operatorUser')}
          placement="right"
          arrow
        >
          <ListItem
            button
            component={Link}
            to="/operatorsUsers"
            style={listItemStyle('/operatorsUsers')}
          >
            <ListItemIcon>
              <SupervisedUserCircleIcon style={iconStyle('/operatorsUsers')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.operatorUser')}
            />
          </ListItem>
        </Tooltip>
      )}
      {checkRelationRoleAndLinks(authenticationReducer, '/technicians') && (
        <Tooltip
          title={t('dashboard.items.technicians')}
          placement="right"
          arrow
        >
          <ListItem
            button
            component={Link}
            to="/technicians"
            style={listItemStyle('/technicians')}
          >
            <ListItemIcon>
              <BuildIcon style={iconStyle('/technicians')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.technicians')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(
        authenticationReducer,
        '/operator/profile',
      ) && (
        <Tooltip title={t('dashboard.items.data')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/operator/profile"
            style={listItemStyle('/operator/profile')}
          >
            <ListItemIcon>
              <StorageIcon style={iconStyle('/operator/profile')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.data')}
            />
          </ListItem>
        </Tooltip>
      )}

      {checkRelationRoleAndLinks(authenticationReducer, '/help') && (
        <Tooltip title={t('dashboard.items.help')} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/help"
            style={listItemStyle('/help')}
          >
            <ListItemIcon>
              <HelpOutlineIcon style={iconStyle('/help')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={t('dashboard.items.help')}
            />
          </ListItem>
        </Tooltip>
      )}
      {checkRelationRoleAndLinks(
        authenticationReducer,
        '/UpdateDataTechnician',
      ) && (
        <Tooltip title={'Actualizar datos'} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/UpdateDataTechnician"
            style={listItemStyle('/UpdateDataTechnician')}
          >
            <ListItemIcon>
              <EditIcon style={iconStyle('/UpdateDataTechnician')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={'Actualizar datos'}
            />
          </ListItem>
        </Tooltip>
      )}
      {checkRelationRoleAndLinks(
        authenticationReducer,
        '/UpdateDataOperatorUser',
      ) && (
        <Tooltip title={'Actualizar datos'} placement="right" arrow>
          <ListItem
            button
            component={Link}
            to="/UpdateDataOperatorUser"
            style={listItemStyle('/UpdateDataOperatorUser')}
          >
            <ListItemIcon>
              <EditIcon style={iconStyle('/UpdateDataOperatorUser')} />
            </ListItemIcon>
            <ListItemText
              style={{ color: 'white' }}
              primary={'Actualizar datos'}
            />
          </ListItem>
        </Tooltip>
      )}
    </div>
  )
}

function mapState(state) {
  const { authenticationReducer, operatorReducer } = state
  return { authenticationReducer, operatorReducer }
}

export default connect(mapState, null)(ListItems)
