import React, { Fragment, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

import { operatorUserActions } from '../../redux/actions/operatorUser_actions'

import { connect } from 'react-redux'

import FormNewOperatorUser from './new/formNewOperatorUser'

import ModalConfirm from '../shared/modalconfirm'

import CustomMaterialTable from '../shared/customMaterialTable'
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const OperatorUserView = ({
  getAll,
  operatorUserReducer,
  operatorReducer,
  update,
  remove,
}) => {
  const { t } = useTranslation()

  const [selectedRow, setselectedRow] = useState({})
  const [isModalConfirmRemoveOpUserOpen, setisModalConfirmRemoveOpUserOpen] =
    useState(false)
  const [isNewOperatorUserDialogOpen, setisNewOperatorUserDialogOpen] =
    useState(false)

  useEffect(() => {
    const operatorId = operatorReducer.dataOperatorLogged.id
    getAll(operatorId)
  }, [])

  const handleUpdateTechnician = (newData, oldData) => {
    const { firstName, lastName, mobilePhone, id, email } = newData
    const body = {
      operatorId: operatorReducer.dataOperatorLogged.id,
      operatorUserId: id,
      email: email,
      mobilePhone: mobilePhone.includes('+34')
        ? mobilePhone
        : `+34${mobilePhone}`,
      firstName: firstName,
      lastName: lastName,
    }

    update(body)
  }

  const handleActionRemoveOperatorUser = (rowData) => {
    setselectedRow(rowData)
    setisModalConfirmRemoveOpUserOpen(true)
  }

  const handleConfirmRemoveOperatorUser = () => {
    const removeBody = {
      operatorId: operatorReducer.dataOperatorLogged.id,
      opUserId: selectedRow.id,
    }
    remove(removeBody)
  }

  return (
    <Fragment>
      <CustomMaterialTable
        title={t('operatorUser.page.table.title')}
        data={operatorUserReducer.results}
        saveFilters={true}
        columns={[
          {
            title: t('operatorUser.page.table.columns.name'),
            field: 'firstName',
            editable: 'onUpdate',
          },
          {
            title: t('operatorUser.page.table.columns.lastName'),
            field: 'lastName',
            editable: 'onUpdate',
          },
          {
            title: t('operatorUser.page.table.columns.mobilePhone'),
            field: 'mobilePhone',
            editable: 'onUpdate',
          },
          {
            title: t('operatorUser.page.table.columns.email'),
            field: 'email',
            editable: 'never',
          },
        ]}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              handleUpdateTechnician(newData, oldData)
              resolve()
            }),
        }}
        actions={[
          {
            icon: 'add',
            tooltip: t('operatorUser.page.table.actions.addUser'),
            isFreeAction: true,
            onClick: (event) => setisNewOperatorUserDialogOpen(true),
          },
          {
            icon: 'delete',
            tooltip: t('operatorUser.page.table.actions.remove'),
            onClick: (event, rowData) =>
              handleActionRemoveOperatorUser(rowData),
          },
        ]}
      />

      <FormNewOperatorUser
        isOpen={isNewOperatorUserDialogOpen}
        onClose={() => setisNewOperatorUserDialogOpen(false)}
        operatorReducer={operatorReducer}
      />

      <ModalConfirm
        isOpen={isModalConfirmRemoveOpUserOpen}
        onClose={() => setisModalConfirmRemoveOpUserOpen(false)}
        onConfirm={handleConfirmRemoveOperatorUser}
        title={`${t('operatorUser.page.modalConfirmRemoveOpUser.title')} (${
          selectedRow.username
        })`}
        inputAvaiable={false}
      />
    </Fragment>
  )
}

function mapState(state) {
  const { operatorUserReducer, operatorReducer } = state
  return { operatorUserReducer, operatorReducer }
}

const actionCreators = {
  getAll: operatorUserActions.getAll,
  update: operatorUserActions.update,
  remove: operatorUserActions.remove,
}

export default connect(mapState, actionCreators)(OperatorUserView)
