import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import TextField from '@material-ui/core/TextField'

import { connect } from 'react-redux'

import TripsScooterView from '../details/trips/page'

import { scooterActions } from '../../../redux/actions/scooter_actions'

import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import { servicesActions } from '../../../redux/actions/services_actions'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '50%',
    padding: '1%',
    marginBottom: '2%',
  },
  containerBody: {
    padding: '1%',
    // marginBottom: "2%",
  },
  textField: {
    marginLeft: '2%',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '2%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ScooterTrips = ({
  isOpen,
  onClose,
  data,
  getTrips,
  getTripDetail,
  getDynamyc,
  scooterReducer,
  cleanTripsView,
  getScooterById,
  scooterId,
  preSelectTrip,
  getTripsByServiceId,
  clearTripsData,
  initialLastTrips,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isSpinnerOn, setisSpinnerOn] = useState(false)
  const [since, setsince] = useState()
  const [until, setuntil] = useState()

  const handleClose = () => {
    onClose()
    cleanTripsView()
  }

  const handleOnOpen = () => {
    getScooterById(scooterId, false)
    initialLastTrips && getTrips(data.id || data.scooterId || scooterId)
    if (preSelectTrip) {
      // console.log("preSelectTrip", preSelectTrip);
      getTripsByServiceId(preSelectTrip.serviceId)
    }

    // data && getTrips(data.id)
  }

  const handleSearchTrips = () => {
    let baseDate = new Date()
    const sincePast = `${
      baseDate.getFullYear() - 100
    }-${baseDate.getMonth()}-${baseDate.getDay()}`

    const untilFuture = `${
      baseDate.getFullYear() + 100
    }-${baseDate.getMonth()}-${baseDate.getDay()}`

    if (data) {
      if (!since && !until)
        getTrips(data.id || data.scooterId || scooterId, since, until)
      if (since && !until)
        getTrips(data.id || data.scooterId || scooterId, since, untilFuture)
      if (until && !since)
        getTrips(data.id || data.scooterId || scooterId, sincePast, until)
      if (until && since)
        getTrips(data.id || data.scooterId || scooterId, since, until)

      setisSpinnerOn(true)
    }
  }

  useEffect(() => {
    setisSpinnerOn(false)
  }, [scooterReducer])

  useEffect(() => {
    return () => {
      clearTripsData()
    }
  }, [])

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onEnter={handleOnOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('scooters.dialogs.scooterTrips.scooterTrips')} (
              {(data && data.plate) ||
                (preSelectTrip && preSelectTrip.vehiclePlate) ||
                (scooterReducer.scooter && scooterReducer.scooter.plate)}
              )
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.containerBody}>
          {!preSelectTrip && (
            <div className={classes.datePickerContainer}>
              <TextField
                id="date"
                label={t('scooters.dialogs.scooterTrips.since')}
                type="date"
                value={since}
                onChange={(e) => setsince(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date"
                label={t('scooters.dialogs.scooterTrips.until')}
                type="date"
                value={until}
                className={classes.textField}
                onChange={(e) => setuntil(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.textField}
                onClick={() => {
                  handleSearchTrips(true)
                }}
                disabled={!since || !until}
              >
                {t('scooters.dialogs.scooterTrips.search')}
              </Button>
            </div>
          )}

          {!isSpinnerOn ? (
            scooterReducer.trips.length > 0 ? (
              <TripsScooterView
                idScooter={
                  (data && data.id) ||
                  (preSelectTrip && preSelectTrip.vehicleId)
                }
                getTripDetail={getTripDetail}
                scooterReducer={scooterReducer}
                scooterData={preSelectTrip ? scooterReducer.scooter : data}
                preSelectTrip={preSelectTrip}
                scooter={scooterReducer.scooter}
              />
            ) : (
              <p>{t('scooters.dialogs.scooterTrips.noResults')}</p>
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return { scooterReducer }
}

const actionCreators = {
  getTrips: scooterActions.getTripsByScooterId, //desde otros
  getTripDetail: scooterActions.getTripDetails,
  getDynamyc: scooterActions.getDynamicsById,
  cleanTripsView: scooterActions.cleanTripsView,
  getScooterById: scooterActions.getId,
  getTripsByServiceId: servicesActions.getTrips, //se usa desde service
  clearTripsData: scooterActions.clearTripsData,
}

export default connect(mapState, actionCreators)(ScooterTrips)
