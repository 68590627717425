import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import i18next from 'i18next'
import React from 'react'
import { WEB_VERSION } from '../../constants/global_constants'
import useStyles from './styles'

const AboutDialog = ({ open, onClose }) => {
  const handleClose = () => onClose()
  const styles = useStyles()

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={styles.container}>
          <img
            style={{ alignSelf: 'center', marginBottom: 20 }}
            src={process.env.PUBLIC_URL + '/content/img/logo.png'}
            width={250}
          />
          <DialogContentText className={styles.text}>
            Silence Fleet Management
          </DialogContentText>
          <DialogContentText className={styles.text}>
            v{WEB_VERSION}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            variant="contained"
            color="primary"
            className={styles.acceptButton}
            onClick={handleClose}
          >
            {i18next.t('dashboard.about.accept')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AboutDialog
