import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { operatorActions } from '../../../../redux/actions/operator_action'

import { connect } from 'react-redux'

import CustomMaterialTable from '../../../shared/customMaterialTable'

const SubOperatorsData = ({ operatorReducer, data, getAllChilds }) => {
  const { t } = useTranslation()

  useEffect(() => {
    data.id && getAllChilds(data.id)
  }, [])
  const displayData = Array.isArray(operatorReducer.childs)
    ? operatorReducer.childs
    : []
  return (
    <div>
      <CustomMaterialTable
        data={displayData.filter((o) => o.parentId === data.id)}
        columns={[
          {
            title: t('operator.validate.name'),
            field: 'name',
          },
          { title: t('operator.validate.primaryColor'), field: 'colour1' },
          { title: t('operator.validate.secondaryColor'), field: 'colour2' },
          { title: t('operator.validate.iban'), field: 'iban' },
          { title: t('operator.validate.email'), field: 'managerEmail' },
          {
            title: t('operator.validate.canOperate'),
            field: 'canOperate',
            addlookup: true,
          },
        ]}
        title={''}
      />
    </div>
  )
}

const actionCreators = {
  getAllChilds: operatorActions.getAllChilds,
}

export default connect(null, actionCreators)(SubOperatorsData)
