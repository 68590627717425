import { alertConstants } from '../../constants'

export const alertActions = {
  success,
  error,
  clear,
  warning,
}

function success(msg) {
  const message = {
    type: 'success',
    message: msg,
  }

  return { type: alertConstants.SUCCESS, message }
}

function error(msg) {
  const message = {
    type: 'error',
    message: msg,
  }

  return { type: alertConstants.ERROR, message }
}

function clear() {
  return { type: alertConstants.CLEAR }
}

function warning(message) {
  return { type: alertConstants.WARNING, message }
}
