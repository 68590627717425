import { customerConstants } from '../../constants/customer_constants'
import { servicesConstants } from '../../constants/services_constants'
import { servicesService } from '../../services/servicesService'
import { alertActions } from './alert_actions'
import { customerActions } from './customers_actions'
import { operatorActions } from './operator_action'
import { scooterActions } from './scooter_actions'

import i18next from 'i18next'
import { isManager, isManagerReadOnly } from '../../helpers/roles'

export const servicesActions = {
  getAll,
  getAllByOperatorId,
  getAllByVehicleId,
  getTrips,
  cleanService,
  getTotalsByCustomers, //Gets all services totals by customer. Only for Silence roles.
  getOperatorTotalsByCustomers, //Gets operator's services totals by customer. Only for Silence and Operator roles.
  //   getTotalsByOperator, //Gets all services totals by operator. Only for Silence roles.
  getCustomerTotalsByOperator, //Gets customer's services totals by operator. Only for Silence roles.
  getCustomerByServiceId,
  getByCustomerAndDates,
  getById,
  getStatisticsByDates,
  getMonthlySummary,
  getReportPDF,
  createRefund,
  cancelPayment,
  endTrip,
}

function getMonthlySummary() {
  return (dispatch) => {
    dispatch(request())
    servicesService.getMonthlySummary().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: servicesConstants.GET_MONTHLY_SUMMARY_REQUEST }
  }

  function success(results) {
    return { type: servicesConstants.GET_MONTHLY_SUMMARY_SUCCESS, results }
  }

  function failure(error) {
    return { type: servicesConstants.GET_MONTHLY_SUMMARY_FAILURE, error }
  }
}

function getReportPDF(operator, year, month) {
  return (dispatch) => {
    dispatch(request())
    servicesService.getPDFReport(operator, year, month).then(
      (res) => {
        if (res)
          res.blob().then((blob) => {
            dispatch(success(res))
            var file = window.URL.createObjectURL(blob)
            window.open(file, '_blank')
          })
        else {
          dispatch(
            alertActions.error(i18next.t('services.services.reportDateError')),
          )
          dispatch(failure(i18next.t('services.services.reportDateError')))
        }
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
      },
    )
  }

  function request() {
    return { type: servicesConstants.GET_REPORT_REQUEST }
  }

  function success(results) {
    return { type: servicesConstants.GET_REPORT_SUCCESS, results }
  }

  function failure(error) {
    return { type: servicesConstants.GET_REPORT_FAILURE, error }
  }
}

function getStatisticsByDates(body) {
  return (dispatch) => {
    dispatch(request())
    servicesService.getStatisticsByDates(body).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error('error get all services action. Message:' + error),
        )
      },
    )
  }

  function request() {
    return { type: servicesConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: servicesConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: servicesConstants.GETALL_FAILURE, error }
  }
}

function getById(scooterId) {
  return (dispatch) => {
    dispatch(request())
    servicesService.getById(scooterId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error('error get services by customers:' + error))
      },
    )
  }

  function request() {
    return { type: servicesConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: servicesConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: servicesConstants.GETALL_FAILURE, error }
  }
}

function getByCustomerAndDates(customerId, since = null, until = null) {
  return (dispatch) => {
    dispatch(request())
    servicesService.getByCustomerAndDates(customerId, since, until).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error('error get services by customers:' + error))
      },
    )
  }

  function request() {
    return { type: servicesConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: servicesConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: servicesConstants.GETALL_FAILURE, error }
  }
}

function getCustomerByServiceId(serviceId) {
  return (dispatch) => {
    if (serviceId) {
      dispatch(request())
      servicesService.getCustomerByServiceId(serviceId).then(
        (results) => {
          // console.log("getCustomerByServiceId results", results);
          const { customerId } = results
          dispatch(customerActions.getById(customerId))
        },
        (error) => {
          // dispatch(failure(error));
          // dispatch(
          //   alertActions.error("error get totals by customers Message:" + error)
          // );
        },
      )
    }
  }

  function request() {
    return { type: customerConstants.GET_BY_ID_REQUEST }
  }

  // function success(results) {
  //   return {
  //     type: servicesConstants.GET_OPERATOR_TOTALS_BY_CUSTOMERS_SUCCESS,
  //     results,
  //   };
  // }

  // function failure(error) {
  //   return {
  //     type: servicesConstants.GET_OPERATOR_TOTALS_BY_CUSTOMERS_FAILURE,
  //     error,
  //   };
  // }
}

function getOperatorTotalsByCustomers(
  sharingOperatorId,
  since = null,
  until = null,
) {
  return (dispatch) => {
    dispatch(request())
    servicesService
      .getOperatorTotalsByCustomers(sharingOperatorId, since, until)
      .then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(
            alertActions.error(
              'error get totals by customers Message:' + error,
            ),
          )
        },
      )
  }

  function request() {
    return {
      type: servicesConstants.GET_OPERATOR_TOTALS_BY_CUSTOMERS_REQUEST,
    }
  }

  function success(results) {
    return {
      type: servicesConstants.GET_OPERATOR_TOTALS_BY_CUSTOMERS_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: servicesConstants.GET_OPERATOR_TOTALS_BY_CUSTOMERS_FAILURE,
      error,
    }
  }
}

function getTotalsByCustomers(since = null, until = null) {
  return (dispatch) => {
    dispatch(request())
    servicesService.getTotalsByCustomers(since, until).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error('error get totals by customers Message:' + error),
        )
      },
    )
  }

  function request() {
    return {
      type: servicesConstants.GET_TOTALS_BY_CUSTOMER_ID_REQUEST,
    }
  }

  function success(results) {
    return {
      type: servicesConstants.GET_TOTALS_BY_CUSTOMER_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: servicesConstants.GET_TOTALS_BY_CUSTOMER_ID_FAILURE,
      error,
    }
  }
}

function getCustomerTotalsByOperator(customerId) {
  return (dispatch) => {
    dispatch(request())
    servicesService.getCustomerTotalsByOperator(customerId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            'error get CUSTOMERS TOTALS BY OPERATOR Message:' + error,
          ),
        )
      },
    )
  }

  function request() {
    return {
      type: servicesConstants.GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_REQUEST,
    }
  }

  function success(results) {
    return {
      type: servicesConstants.GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: servicesConstants.GET_CUSTOMERS_TOTALS_BY_OPERATOR_ID_FAILURE,
      error,
    }
  }
}

function cleanService(serviceId, operatorId = null) {
  return (dispatch) => {
    servicesService.cleanService(serviceId).then(
      (results) => {
        if (isManager() || isManagerReadOnly()) {
          dispatch(scooterActions.getAll())
          dispatch(operatorActions.getAll())
        } else {
          dispatch(getAll())
          dispatch(scooterActions.getAll(operatorId))
        }
        dispatch(
          alertActions.success(
            'Se ha limpiado los servicios de la moto correctamente',
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error('error cleanService action. Message:' + error),
        )
      },
    )
  }
}

function getTrips(serviceId) {
  return (dispatch) => {
    if (serviceId) {
      dispatch(request())
      servicesService.getTrips(serviceId).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(
            alertActions.error(
              'error get all trips by service id action. Message:' + error,
            ),
          )
        },
      )
    }
  }

  function request() {
    return { type: servicesConstants.GET_ALL_TRIPS_BY_SERVICE_ID_REQUEST }
  }

  function success(results) {
    return {
      type: servicesConstants.GET_ALL_TRIPS_BY_SERVICE_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: servicesConstants.GET_ALL_TRIPS_BY_SERVICE_ID_FAILURE,
      error,
    }
  }
}

function getAllByVehicleId(vehicleId) {
  return (dispatch) => {
    dispatch(request())
    servicesService.getAllByVehicleId(vehicleId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            'error get all services by operator id action. Message:' + error,
          ),
        )
      },
    )
  }

  function request() {
    return { type: servicesConstants.GET_ALL_BY_OPERATOR_ID_REQUEST }
  }

  function success(results) {
    return { type: servicesConstants.GET_ALL_BY_OPERATOR_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: servicesConstants.GET_ALL_BY_OPERATOR_ID_FAILURE, error }
  }
}

function getAllByOperatorId(operatorId) {
  return (dispatch) => {
    dispatch(request())
    if (operatorId) {
      servicesService.getAllByOperatorId(operatorId).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(
            alertActions.error(
              'error get all services by operator id action. Message:' + error,
            ),
          )
        },
      )
    }
  }

  function request() {
    return { type: servicesConstants.GET_ALL_BY_OPERATOR_ID_REQUEST }
  }

  function success(results) {
    return { type: servicesConstants.GET_ALL_BY_OPERATOR_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: servicesConstants.GET_ALL_BY_OPERATOR_ID_FAILURE, error }
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    servicesService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error('error get all services action. Message:' + error),
        )
      },
    )
  }

  function request() {
    return { type: servicesConstants.GETALL_REQUEST }
  }

  function success(results) {
    return { type: servicesConstants.GETALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: servicesConstants.GETALL_FAILURE, error }
  }
}

function createRefund(serviceId) {
  return (dispatch) => {
    dispatch(request())
    servicesService.refund(serviceId).then(
      (results) => {
        dispatch(success(results))
        dispatch(alertActions.success('Refund created successfully'))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error('Error get all services action. Message:' + error),
        )
      },
    )
  }

  function request() {
    return { type: servicesConstants.REFUND_REQUEST }
  }

  function success(results) {
    return { type: servicesConstants.REFUND_SUCCESS, results }
  }

  function failure(error) {
    return { type: servicesConstants.REFUND_FAILURE, error }
  }
}

function cancelPayment(serviceId) {
  return (dispatch) => {
    dispatch(request())
    servicesService.cancelPayment(serviceId).then(
      (results) => {
        dispatch(success(results))
        dispatch(alertActions.success('Service payment cancelled successfully'))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            'Error cancel service payment action. Message:' + error,
          ),
        )
      },
    )
  }

  function request() {
    return { type: servicesConstants.CANCEL_PAYMENT_REQUEST }
  }

  function success(results) {
    return { type: servicesConstants.CANCEL_PAYMENT_SUCCESS, results }
  }

  function failure(error) {
    return { type: servicesConstants.CANCEL_PAYMENT_FAILURE, error }
  }
}

function endTrip(serviceId, operatorId = null) {
  return (dispatch) => {
    servicesService.endTrip(serviceId).then(
      (results) => {
        if (isManager() || isManagerReadOnly()) {
          dispatch(scooterActions.getAllDynamics())
          dispatch(operatorActions.getAll())
        } else {
          dispatch(getAll())
          dispatch(scooterActions.getAllDynamicByOperator(operatorId))
        }
        dispatch(
          alertActions.success(i18next.t('services.services.endTripSuccess')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.services.endTripError')),
        )
      },
    )
  }
}
