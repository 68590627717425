import { handleCall, handleCallDownload } from './handleResponse'

export const servicesService = {
  getAll,
  getAllByOperatorId,
  getAllByVehicleId,
  getTrips,
  cleanService,
  getCustomerTotalsByOperator,
  getTotalsByCustomers,
  getOperatorTotalsByCustomers,
  getCustomerByServiceId,
  getByCustomerAndDates,
  getById,
  getStatisticsByDates,
  getMonthlySummary,
  getPDFReport,
  refund,
  cancelPayment,
  endTrip,
}

function getMonthlySummary() {
  return handleCall(`/sharing/v1/Services/GetMonthlySummary`, {
    method: 'GET',
    body: null,
  })
}

function getPDFReport(operatorId, year, month) {
  return handleCallDownload(
    `/sharing/v1/ReportOfServicesByOperator/Download?sharingOperatorId=${operatorId}&year=${year}&month=${month}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getStatisticsByDates(body) {
  const { since, until } = body || { since: null, until: null }

  const filter = since && until ? `?since=${since}&until=${until}` : ''
  return handleCall(`/sharing/v1/Services/GetStatisticsByDates${filter}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // const filter = since && until ? `?since=${since}&until=${until}` : "";
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services/GetStatisticsByDates${filter}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getById(serviceId) {
  return handleCall(`/sharing/v1/Services/${serviceId}`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services/${serviceId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getByCustomerAndDates(customerId, since, until) {
  const params = since && until ? `&since=${since}&until=${until}` : ''
  return handleCall(
    `/sharing/v1/Services/GetByCustomerAndDates?customerId=${customerId}${params}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // const params = since && until ? `&since=${since}&until=${until}` : "";
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services/GetByCustomerAndDates?customerId=${customerId}${params}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getCustomerByServiceId(serviceId) {
  return handleCall(`/sharing/v1/Services/${serviceId}`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services/${serviceId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getOperatorTotalsByCustomers(sharingOperatorId, since, until) {
  const params = since && until ? `?since=${since}&until=${until}` : ''
  return handleCall(
    `/sharing/v1/Services/GetOperatorTotalsByCustomer?sharingOperatorId=${sharingOperatorId}${params}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // const params = since && until ? `?since=${since}&until=${until}` : "";
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services/GetOperatorTotalsByCustomer?sharingOperatorId=${sharingOperatorId}${params}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getTotalsByCustomers(since, until) {
  const params = since && until ? `?since=${since}&until=${until}` : ''
  return handleCall(`/sharing/v1/Services/GetTotalsByCustomer${params}`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // const params = since && until ? `?since=${since}&until=${until}` : "";
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services/GetTotalsByCustomer${params}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getCustomerTotalsByOperator(customerId) {
  return handleCall(
    `/sharing/v1/Services/GetCustomerTotalsByOperator?customerId=${customerId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services/GetCustomerTotalsByOperator?customerId=${customerId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function cleanService(serviceId) {
  return handleCall(`/sharing/v1/Services/${serviceId}/Clean`, {
    method: 'PUT',
    body: null,
  })
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services/${serviceId}/Clean`,
  //   requestOptions
  // ).then(handleResponse);
}

function endTrip(serviceId) {
  return handleCall(`/sharing/v1/Services/EndTrip/${serviceId}`, {
    method: 'PUT',
    body: null,
  })
}

function getTrips(serviceId) {
  return handleCall(`/sharing/v1/Services/${serviceId}/GetTrips`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services/${serviceId}/GetTrips`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllByVehicleId(vehicleId) {
  return handleCall(`/sharing/v1/Services?field=VehicleId&value=${vehicleId}`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services?field=VehicleId&value=${vehicleId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAllByOperatorId(operatorId) {
  return handleCall(
    `/sharing/v1/Services?field=SharingOperatorId&value=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services?field=SharingOperatorId&value=${operatorId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAll() {
  return handleCall(`/sharing/v1/Services`, {
    method: 'GET',
    body: null,
  })
  // const requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/sharing/v1/Services`,
  //   requestOptions
  // ).then(handleResponse);
}

function refund(serviceId) {
  return handleCall(`/sharing/v1/Services/${serviceId}/Refund`, {
    method: 'POST',
    body: null,
  })
}

function cancelPayment(serviceId) {
  return handleCall(`/sharing/v1/Services/${serviceId}/CancelPayment`, {
    method: 'POST',
    body: '""',
  })
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     if (response.status === 204 || response.status === 404) {
//       return [];
//     }
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       if (response.status === 401) {
//         var refreshToken = loginService.getWithExpiry("refresh_token");
//         loginService.refreshToken(refreshToken).then((token) => {
//           loginService.setWithExpiry(
//             "token",
//             token.access_token,
//             token.expires_in
//           );
//         });
//       }
//       const error = (data && data.message) || text;
//       return Promise.reject(error);
//     }

//     // check if the token is close to expire
//     var date = new Date();
//     var now_utc = Date.UTC(
//       date.getUTCFullYear(),
//       date.getUTCMonth(),
//       date.getUTCDate(),
//       date.getUTCHours(),
//       date.getUTCMinutes(),
//       date.getUTCSeconds()
//     );
//     var now = new Date(now_utc);
//     now.setMinutes(now.getMinutes() + 20);
//     var token_expire = new Date(loginService.getWithExpiry("token_dateExpiry"));
//     // if in 10 minutes expire, renew the token
//     if (now > token_expire) {
//       refreshToken = loginService.getWithExpiry("refresh_token");
//       loginService.refreshToken(refreshToken).then((token) => {
//         loginService.setWithExpiry(
//           "token",
//           token.access_token,
//           token.expires_in
//         );
//       });
//     }
//     return data;
//   });
// }
