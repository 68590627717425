import React, { useEffect, useState } from 'react'
// import Map from "google-map-react";
import { useTranslation } from 'react-i18next'

import stringConstants from '../../../constants/strings'
import { selectIconMarkerMap } from '../../shared/selectIconMap'

import {
  Circle,
  GoogleApiWrapper,
  Map,
  Marker,
  Polygon,
} from 'google-maps-react'
import { formatDateToDisplay } from '../../shared/formatDate'

const DynamicsOnMap = ({
  dynamic = { dynamicData: { gps: { latitude: 0, longitude: 0 } } },
  areas,
  circles,
  google,
}) => {
  const { t } = useTranslation()

  const hasDynamicData = dynamic && dynamic.dynamicData != undefined
  const [gps, setgps] = useState({ latitude: 0, longitude: 0 })
  const [googleApiObj, setGoogleApiObj] = useState(null)
  const [mapAreas, setMapAreas] = useState(areas)
  const [mapCircles, setMapCircles] = useState(circles)
  const [defaultProps, setdefaultProps] = useState({
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 11,
  })

  useEffect(() => {
    setMapAreas(areas)
    setMapCircles(circles)
  }, [circles, areas])

  useEffect(() => {
    const gpsData = {
      latitude: hasDynamicData
        ? dynamic.dynamicData.gps && dynamic.dynamicData.gps.latitude
        : 0,
      longitude: hasDynamicData
        ? dynamic.dynamicData.gps && dynamic.dynamicData.gps.longitude
        : 0,
    }
    setgps(gpsData)
    const defaultPropsData = {
      center: {
        lat: gpsData.latitude,
        lng: gpsData.longitude,
      },
      zoom: 11,
    }
    setdefaultProps(defaultPropsData)
  }, [dynamic])

  return hasDynamicData ? (
    <div>
      <div
        style={{
          height: '30vh',
          border: '3px solid rgba(0, 0, 0, 0.08)',
          position: 'relative',
        }}
      >
        <Map
          shouldUnregisterMapOnUnmount
          google={google}
          // onGoogleApiLoaded={onApiLoaded}
          mapTypeControl={false}
          streetViewControl={false}
          defaultZoom={defaultProps.zoom}
          center={defaultProps.center}
        >
          {/* <ScooterMarker
            lat={defaultProps.center.lat}
            lng={defaultProps.center.lng}
          /> */}
          <Marker
            position={{
              lat: defaultProps.center.lat,
              lng: defaultProps.center.lng,
            }}
            icon={{
              url: hasDynamicData
                ? selectIconMarkerMap(dynamic.dynamicData)
                : '',
              scaledSize: new google.maps.Size(32, 32),
            }}
          />
          {mapCircles.map((circle) => (
            <Circle
              strokeColor="red"
              fillColor="#95D6AF"
              radius={circle.radius}
              center={{ lat: circle.lat, lng: circle.lon }}
            />
          ))}
          {mapAreas.map((area) => {
            let paths = []
            area.map((path) => paths.push({ lat: path.lat, lng: path.lon }))
            return (
              <Polygon strokeColor="red" fillColor="#95D6AF" paths={paths} />
            )
          })}
        </Map>
      </div>
      <p>
        {t('home.items.dynamisOnMap.lastConnectionText')}:{' '}
        {formatDateToDisplay(dynamic.dynamicData.lastConnectionTimestamp, true)}
      </p>
    </div>
  ) : (
    <p>{t('home.items.dynamisOnMap.dataNotFoundText')}</p>
  )
}

export default GoogleApiWrapper({
  apiKey: stringConstants.GOOGLE_API_KEY,
})(DynamicsOnMap)
