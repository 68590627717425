import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { connect } from 'react-redux'

import { useTranslation } from 'react-i18next'

import { techniciansActions } from '../../../redux/actions/technicians_action'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const TechnicianUpdate = ({ authenticationReducer, update }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [name, setname] = useState('')
  const [contactPhone, setcontactPhone] = useState('')
  const [lastName, setlastName] = useState('')

  useEffect(() => {
    const { firstName, lastName, mobilePhone } = authenticationReducer.user
    setname(firstName)
    setlastName(lastName)
    setcontactPhone(mobilePhone)
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    const technicianId = authenticationReducer.user.id
    const body = {
      firstName: name,
      mobilePhone: contactPhone.includes('+34')
        ? contactPhone
        : `+34${contactPhone}`,
      lastName,
      technicianId,
    }
    // console.log("body", body);
    update(body)

    // setname("");
    // setlastName("");
    // setcontactPhone("");
  }

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Actualizar datos
        </Typography>
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                // variant='standard'
                required
                fullWidth
                label={'Nombre'}
                autoFocus
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // variant="outlined"
                required
                fullWidth
                label={'Apellidos'}
                value={lastName}
                onChange={(e) => setlastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // variant="outlined"
                required
                fullWidth
                label={'Teléfono'}
                type="string"
                value={contactPhone}
                onChange={(e) => setcontactPhone(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {'Actualizar'}
          </Button>
        </form>
      </div>
    </Container>
  )
}

function mapState(state) {
  const { authenticationReducer } = state
  return { authenticationReducer }
}

const actionCreators = {
  update: techniciansActions.update,
}

export default connect(mapState, actionCreators)(TechnicianUpdate)
