import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // maxWidth: "50%",
    // margin: "auto",
    marginBottom: '5%',
  },
}))

export default useStyles
