import React from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const DetailsView = ({ data }) => {
  // const  useStyles();
  const { t } = useTranslation()

  const {
    areas,
    canOperate,
    cannotOperateReason,
    chargeTypes,
    cif,
    circles,
    colour1,
    colour2,
    contactPhone,
    discounts,
    ecoCount,
    group,
    iban,
    id,
    isHelmetIncluded,
    isPrivate,
    isStationBase,
    isSupportDerived,
    kmsCount,
    logoBinary,
    managerEmail,
    name,
    servicesCount,
    costByVehicle,
    isFree,
    serviceCommissionPercent,
  } = data || {}

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            // variant='standard'
            fullWidth
            // disabled
            label={t('operator.validate.name')}
            value={name || 'undefined'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            // variant="outlined"
            fullWidth
            // disabled
            label={t('operator.validate.email')}
            value={managerEmail || 'undefined'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            // variant="outlined"
            fullWidth
            // disabled
            label={t('operator.validate.contactPhone')}
            value={contactPhone || 'undefined'}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('operator.register.operatorManager.costByVehicle')}
            value={costByVehicle || 'undefined'}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t(
              'operator.register.operatorManager.serviceCommissionPercent',
            )}
            value={serviceCommissionPercent || 'undefined'}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            // variant="outlined"
            fullWidth
            // disabled
            label={t('operator.validate.canOperate')}
            value={canOperate.toString() || 'undefined'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            // variant="outlined"
            fullWidth
            // disabled
            label={t('operator.validate.iban')}
            value={iban || 'undefined'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            // variant="outlined"
            fullWidth
            // disabled
            label={t('operator.validate.nif')}
            value={cif || 'undefined'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex' }}>
            <div>{t('operator.validate.primaryColor')}</div>
            <div style={{ background: colour1, width: '100%' }}>{colour1}</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex' }}>
            <div>{t('operator.validate.secondaryColor')}</div>
            <div style={{ background: colour2, width: '100%' }}>{colour2}</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            // variant="outlined"
            fullWidth
            // disabled
            label={t('operator.validate.isPrivated')}
            value={isPrivate.toString() || 'undefined'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            // variant="outlined"
            fullWidth
            // disabled
            label={t('operator.validate.stationBase')}
            value={isStationBase.toString() || 'undefined'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            // variant="outlined"
            fullWidth
            // disabled
            label={t('operator.validate.kmcount')}
            value={kmsCount || 'undefined'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            // variant="outlined"
            fullWidth
            // disabled
            label={t('operator.validate.ecoCount')}
            value={ecoCount || 'undefined'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            // variant="outlined"
            fullWidth
            // disabled
            label={t('operator.validate.free')}
            value={
              isFree ? t('operator.validate.yes') : t('operator.validate.no')
            }
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default DetailsView
