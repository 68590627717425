import { Dialog, Zoom } from '@material-ui/core'
import React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom direction="up" ref={ref} {...props} />
})

const EndingImageExpander = ({ isOpen, onDismiss, endingImage }) => {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={onDismiss}
      maxWidth={'lg'}
    >
      <img
        src={endingImage}
        style={{
          flex: 1,
          objectFit: 'center',
        }}
      />
    </Dialog>
  )
}

export default EndingImageExpander
