import { handleCall } from './handleResponse'

export const sacService = {
  getAll,
  create,
  update,
  remove,
  getById,
}

function getAll() {
  return handleCall(`/operators/v1/SacUsers/GetSacUsers`, {
    method: 'GET',
    body: null,
  })
}

function create(user) {
  return handleCall(`/operators/v1/SacUsers/Create`, {
    method: 'POST',
    body: JSON.stringify(user),
  })
}

function update(id, data) {
  return handleCall(`/operators/v1/SacUsers/${id}/Update`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

function remove(id) {
  return handleCall(`/operators/v1/SacUsers/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function getById(id) {
  return handleCall(`/operators/v1/SacUsers/${id}`, {
    method: 'GET',
    body: null,
  })
}
