import { firmwareConstants } from '../../../constants/fota/firmware_constants'
import { fotaConstants } from '../../../constants/fota/fota_constants'

const initialState = {
  showResults: true,
  loadingResults: false,
  results: [],
}

export function firmwareReducer(state = initialState, action) {
  switch (action.type) {
    case firmwareConstants.FILTER_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case firmwareConstants.FILTER_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case firmwareConstants.FILTER_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case firmwareConstants.DETAILS_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case firmwareConstants.DETAILS_SUCCESS:
      return {
        ...state,
        firmware: action.firmware,
        loadingResults: false,
      }
    case firmwareConstants.DETAILS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        firmware: {},
        error: action.error,
      }
    case firmwareConstants.GETALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case firmwareConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case firmwareConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case firmwareConstants.GETCOMPATIBLE_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case firmwareConstants.GETCOMPATIBLE_SUCCESS:
      return {
        ...state,
        results: action.results,
        firmware: action.firmware,
        loadingResults: false,
      }
    case firmwareConstants.GETCOMPATIBLE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case firmwareConstants.DELETE_REQUEST:
      return {
        ...state,
      }
    case firmwareConstants.DELETE_SUCCESS:
      return {
        ...state,
        results: state.results.filter((x) => x.id !== action.id),
      }
    case firmwareConstants.DELETE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case fotaConstants.GET_MASTER_DATA_REQUEST:
      return {
        ...state,
      }
    case fotaConstants.GET_MASTER_DATA_SUCCESS:
      return {
        ...state,
        targetTypes: action.result.targetTypeValues,
        hardwareTypes: action.result.hardwareTypeValues,
      }
    case firmwareConstants.ADD_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case firmwareConstants.ADD_SUCCESS:
      return {
        ...state,
        loadingResults: false,
      }
    case firmwareConstants.ADD_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case firmwareConstants.EDIT_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case firmwareConstants.EDIT_SUCCESS:
      return {
        ...state,
        loadingResults: false,
      }
    case firmwareConstants.EDIT_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    default:
      return state
  }
}
