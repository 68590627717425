const cleanBaseUrl = () => {
  const protocol = window.location.protocol
  const hostSplit = window.location.host.split('-')

  const environment =
    process.env.REACT_APP_ENV == 'dev'
      ? 'dev-'
      : process.env.REACT_APP_ENV === 'pre'
      ? 'pre-'
      : ''

  if (hostSplit[0] === 'localhost:3000') {
    return `https://${environment}fleet.connectivity.silence.eco`
  } else {
    const urlToBackend = 'fleet.connectivity.silence.eco'
    if (hostSplit.length === 1) {
      return `${protocol}//${urlToBackend}`
    } else {
      return `${protocol}//${hostSplit[0]}-${urlToBackend}`
    }
  }
}

const baseURL = cleanBaseUrl()

const apiKey = process.env.REACT_APP_FIREBASE_APIKEY
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
const appId = process.env.REACT_APP_FIREBASE_APP_ID
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY

const stringConstants = {
  URL_React: window.location + '/home',
  TIME_REFRESH_MAPS: 15000,
  URL_ApiGateway: `${baseURL}/api`,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  firebaseConfig: {
    apiKey,
    authDomain,
    databaseURL,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId,
  },
  vapidKey,
}

export default stringConstants
