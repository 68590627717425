import { handleCall } from './handleResponse'

export const filesService = {
  get,
}

function get(fileId) {
  return handleCall(`/files/v1/files/${fileId}`, {
    method: 'GET',
    body: null,
  })
}
