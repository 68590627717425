import { documentsConstant } from '../../constants/documents_constant'
import { documentsService } from '../../services/documentsService'

import i18next from 'i18next'
import { alertActions } from './alert_actions'

export const documentsActions = {
  getByCustomerId,
  getDrivingLicenses,
  getIdDocTypes,
}

function getByCustomerId(customerId) {
  return (dispatch) => {
    dispatch(request())
    documentsService.getByCustomerId(customerId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.documents.getAllError')),
        )
      },
    )
  }

  function request() {
    return { type: documentsConstant.GET_BY_CUSTOMERS_ID_REQUEST }
  }

  function success(results) {
    return { type: documentsConstant.GET_BY_CUSTOMERS_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: documentsConstant.GET_BY_CUSTOMERS_ID_FAILURE, error }
  }
}

function getDrivingLicenses() {
  return (dispatch) => {
    dispatch(request())
    documentsService.getDrivingLicenses().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: documentsConstant.GET_DRIVING_LICENSES_REQUEST }
  }

  function success(results) {
    return { type: documentsConstant.GET_DRIVING_LICENSES_SUCCESS, results }
  }

  function failure(error) {
    return { type: documentsConstant.GET_DRIVING_LICENSES_FAILURE, error }
  }
}

function getIdDocTypes() {
  return (dispatch) => {
    dispatch(request())
    documentsService.getIdDocTypes().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: documentsConstant.GET_ID_DOC_TYPES_REQUEST }
  }

  function success(results) {
    return { type: documentsConstant.GET_ID_DOC_TYPES_SUCCESS, results }
  }

  function failure(error) {
    return { type: documentsConstant.GET_ID_DOC_TYPES_FAILURE, error }
  }
}
