import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React from 'react'
import { withTranslation } from 'react-i18next'

class AlertDialog extends React.Component {
  render() {
    const { t } = this.props
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClickCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.onClickCancel}
              variant="contained"
              size="small"
              color="primary"
            >
              {t('alert-dialog.cancel')}
            </Button>
            <Button
              onClick={this.props.onClickAccept}
              variant="contained"
              size="small"
              color="primary"
              autoFocus
            >
              {t('alert-dialog.accept')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withTranslation('common')(AlertDialog)
