export function authHeader() {
  // return authorization header with jwt token
  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    var date = new Date()
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
    const now = new Date(now_utc)
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }
  const token = getWithExpiry('sharing_token')
  // return { 'Authorization': 'Bearer ' + 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkpCRFRIM3dzejExelhna0NGVV9iNFlNOHJNUSJ9.eyJhdWQiOiI4YjU1N2Y5Yy00YTlmLTRjZjQtYmFhNC1mYjdlMTFiYTY0ZTQiLCJleHAiOjE2MDAzNTQ5MTQsImlhdCI6MTYwMDM1MTMxNCwiaXNzIjoic2lsZW5jZS5lY28iLCJzdWIiOiJjZWE3OTEzNC01MjZjLTRjNDktYjRjMS1hNjQyZWI5MTg5NzkiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJSRUZSRVNIX1RPS0VOIiwiZW1haWwiOiJ0ZXN0X3NoYXJpbmdfMkBzaWxlbmNlLmVjbyIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0X3NoYXJpbmdfMkBzaWxlbmNlLmVjbyIsImFwcGxpY2F0aW9uSWQiOiI4YjU1N2Y5Yy00YTlmLTRjZjQtYmFhNC1mYjdlMTFiYTY0ZTQiLCJyb2xlcyI6WyJDdXN0b21lciIsIk1hbmFnZXIiXX0.Zz4Tgtm4vSrkVPaxyLkpQgi5Ee-wNVXMMGQRELU5ADFIodjo5vQFNGaIMhh3oRj6qup3hQVBUfRRdkLGEL4jw4M5ut5YJ2Ky4AAAHxU6thVNLMJiGKxrDOPUPNhc2saq8P_m8aeJL5TQCalP7e7gAJrMdE3PQKFG5eKPKjlLaIS14D4wMcQZ1Zu1a4wBlaJlrs12rXnse4aTC7R7yiEja-yXhO7rh6iTxtARW2oldemOysmPX0XRty_Ga35y8ix0E1Y1woE87ldw_Hk6MxNwl5TUg_kePcEdLGRd_2yJKB9PU4ymOu_NGVOQBhZx3YBMlo8I-p89cJwyTFxvH_isBw' };

  return {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  }
}
