import React from 'react'
import Dashboard from '../dashboard'
import MonthlyDetailsView from './page'

class MonthlyDetails extends React.Component {
  render() {
    return <Dashboard component={<MonthlyDetailsView />} />
  }
}

export default MonthlyDetails
