import i18next from 'i18next'
import React from 'react'
import DashboardView from './page'

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lng: i18next.language,
    }
  }

  changeLanguage = (lng) => {
    this.setState({ lng: lng }, () => {
      i18next.changeLanguage(lng)
      localStorage.setItem('lang', lng)
    })
  }

  render() {
    return (
      <DashboardView
        component={this.props.component}
        changeLanguage={this.changeLanguage}
      />
    )
  }
}
