export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  TOKEN_REQUEST: 'USERS_TOKEN_REQUEST',
  TOKEN_SUCCESS: 'USERS_TOKEN_SUCCESS',
  TOKEN_FAILURE: 'USERS_TOKEN_FAILURE',

  REFRESH_REQUEST: 'USERS_REFRESH_REQUEST',
  REFRESH_SUCCESS: 'USERS_REFRESH_SUCCESS',
  REFRESH_FAILURE: 'USERS_REFRESH_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  INFO_REQUEST: 'USERS_INFO_REQUEST',
  INFO_SUCCESS: 'USERS_INFO_SUCCESS',
  INFO_FAILURE: 'USERS_INFO_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  AUTH_TRUE: 'AUTH_TRUE',

  MAIL_EXIST_REQUEST: 'USERS_MAIL_EXIST_REQUEST',
  MAIL_EXIST_SUCCESS: 'USERS_MAIL_EXIST_SUCCESS',
  MAIL_EXIST_FAILURE: 'USERS_MAIL_EXIST_FAILURE',

  SELECTED_USER_SUCCESS: 'USERS_SELECTED_ROLE_SUCCESS',
}
