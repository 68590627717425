import { handleCall } from './handleResponse'

export const tariffsService = {
  getAll,
  getByOperatorId,
  setTariffs,
  getByLoggedOperator,
  deleteDiscount,
}

function getByLoggedOperator() {
  return handleCall(
    `/operators/v1/RateHistorics/GetCurrentRateOfLoggedOperator`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/RateHistorics/GetCurrentRateOfLoggedOperator`,
  //   requestOptions
  // ).then(handleResponse);
}
function setTariffs(body) {
  return handleCall(
    `/operators/v1/RateHistorics/UpdateCurrentRateOfLoggedOperator`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
    },
  )
  // var requestOptions = {
  //   method: "PUT",
  //   headers: authHeader(),
  //   body: JSON.stringify(body),
  //   redirect: "follow",
  // };
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/RateHistorics/UpdateCurrentRateOfLoggedOperator`,
  //   requestOptions
  // ).then(handleResponse);
}

function getAll() {
  return handleCall(`/operators/v1/RateHistorics`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/RateHistorics`,
  //   requestOptions
  // ).then(handleResponse);
}

function getByOperatorId(operatorId) {
  return handleCall(
    `/operators/v1/RateHistorics/GetCurrentRateOfOperator?sharingOperatorId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/RateHistorics/GetCurrentRateOfOperator?sharingOperatorId=${operatorId}`,
  //   requestOptions
  // ).then(handleResponse);
}

function deleteDiscount(discountId) {
  return handleCall(`/operators/v1/Discounts/${discountId}`, {
    method: 'DELETE',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/operators/v1/RateHistorics/GetCurrentRateOfOperator?sharingOperatorId=${operatorId}`,
  //   requestOptions
  // ).then(handleResponse);
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     if (response.status === 204 || response.status === 404) {
//       return [];
//     }
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       if (response.status === 401) {
//         var refreshToken = loginService.getWithExpiry("refresh_token");
//         loginService.refreshToken(refreshToken).then((token) => {
//           loginService.setWithExpiry(
//             "token",
//             token.access_token,
//             token.expires_in
//           );
//         });
//       }
//       const error = (data && data.message) || text;
//       return Promise.reject(error);
//     }

//     // check if the token is close to expire
//     var date = new Date();
//     var now_utc = Date.UTC(
//       date.getUTCFullYear(),
//       date.getUTCMonth(),
//       date.getUTCDate(),
//       date.getUTCHours(),
//       date.getUTCMinutes(),
//       date.getUTCSeconds()
//     );
//     var now = new Date(now_utc);
//     now.setMinutes(now.getMinutes() + 20);
//     var token_expire = new Date(loginService.getWithExpiry("token_dateExpiry"));
//     // if in 10 minutes expire, renew the token
//     if (now > token_expire) {
//       refreshToken = loginService.getWithExpiry("refresh_token");
//       loginService.refreshToken(refreshToken).then((token) => {
//         loginService.setWithExpiry(
//           "token",
//           token.access_token,
//           token.expires_in
//         );
//       });
//     }
//     return data;
//   });
// }
