import { cauConstants } from '../../constants/cau_constants'

const initialState = {
  users: [],
  user: {},
  loadingUsers: false,
  loadingCreate: false,
  loadingUpdate: false,
  error: null,
}

export function cauReducer(state = initialState, action) {
  switch (action.type) {
    case cauConstants.GET_CAU_USERS_REQUEST:
      return { ...state, loadingUsers: true }
    case cauConstants.GET_CAU_USERS_SUCCESS:
      return { ...state, users: action.users, loadingUsers: false }
    case cauConstants.GET_CAU_USERS_FAILURE:
      return { ...state, error: action.error, loadingUsers: false }
    case cauConstants.CAU_CREATE_USER_REQUEST:
      return { ...state, loadingCreate: true }
    case cauConstants.CAU_CREATE_USER_SUCCESS:
      return { ...state, loadingCreate: false }
    case cauConstants.CAU_CREATE_USER_FAILURE:
      return { ...state, loadingCreate: false, error: action.error }
    case cauConstants.CAU_UPDATE_USER_REQUEST:
      return { ...state, loadingUpdate: true }
    case cauConstants.CAU_UPDATE_USER_FAILURE:
      return { ...state, loadingUpdate: false, error: action.error }
    case cauConstants.CAU_UPDATE_USER_SUCCESS:
      return { ...state, loadingUpdate: false }
    case cauConstants.GET_CAU_USER_BY_ID_SUCCESS:
      return { ...state, user: action.user }
    case cauConstants.CLEAR_USER_DATA:
      return { ...state, user: {} }
    default:
      return state
  }
}
