import { bonusConstants } from '../../constants/bonus_constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
  detailsFromHistoric: {},
}

export function bonusReducer(state = initialState, action) {
  switch (action.type) {
    case bonusConstants.GET_DATA_FROM_HISTORIC_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
        detailsFromHistoric: {},
      }
    case bonusConstants.GET_DATA_FROM_HISTORIC_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        detailsFromHistoric: action.results,
      }
    case bonusConstants.GET_DATA_FROM_HISTORIC_FAILURE:
      return {
        ...state,
        loadingResults: false,
        detailsFromHistoric: [],
        error: action.error,
      }

    case bonusConstants.GETALL_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case bonusConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case bonusConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    default:
      return state
  }
}
