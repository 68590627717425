import { scooterConstants } from '../../constants/scooter_constants'
import { servicesConstants } from '../../constants/services_constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  loadingDynamics: false,
  results: [],
  dynamics: [],
  dynamic: [],
  scooterSelected: {},
  missingDynaimcs: [],
  tripDetail: {},
  paramsConfig: {},
  trips: [],
  mapPosition: {
    zoom: 10,
    center: {
      lat: 41.390205,
      lng: 2.154007,
    },
  },
  heatMapData: [],
  tripsDataReport: [],
}

export function scooterReducer(state = initialState, action) {
  switch (action.type) {
    case scooterConstants.GET_TRIPS_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.GET_TRIPS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        tripsDataReport: action.results,
      }
    case scooterConstants.GET_TRIPS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        tripsDataReport: [],
        error: action.error,
      }

    case servicesConstants.GET_ALL_TRIPS_BY_SERVICE_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case servicesConstants.GET_ALL_TRIPS_BY_SERVICE_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        trips: action.results,
      }
    case servicesConstants.GET_ALL_TRIPS_BY_SERVICE_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        trips: [],
        error: action.error,
      }

    case scooterConstants.CLEAN_TRIP_DATA:
      return {
        ...state,
        trips: [],
        tripDetail: {},
      }

    case scooterConstants.CLEAN_HEAT_MAP_DATA:
      return {
        ...state,
        heatMapData: [],
      }

    case scooterConstants.GET_HEAT_MAP_OF_SELECTED_OPERATOR_REQUEST:
      return {
        ...state,
        heatMapData: [],
      }

    case scooterConstants.GET_HEAT_MAP_OF_SELECTED_OPERATOR_SUCCESS:
      return {
        ...state,
        heatMapData: action.results,
      }

    case scooterConstants.GET_HEAT_MAP_OF_SELECTED_OPERATOR_FAILURE:
      return {
        ...state,
        showResults: false,
        loadingResults: false,
        heatMapData: [],
      }

    case scooterConstants.CLEEN_TRIPS_VIEW_MAP:
      return {
        ...state,
        tripDetail: {},
        trips: [],
        scooter: {},
      }

    case scooterConstants.SAVE_CENTER_MAP:
      return {
        ...state,
        mapPosition: action.body,
      }

    case scooterConstants.GET_PARAMS_CONFIG_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.GET_PARAMS_CONFIG_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        paramsConfig: action.results,
      }
    case scooterConstants.GET_PARAMS_CONFIG_FAILURE:
      return {
        ...state,
        loadingResults: false,
        paramsConfig: {},
        error: action.error,
      }

    case scooterConstants.GET_MISSING_DYNAMICS_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.GET_MISSING_DYNAMICS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        missingDynaimcs: action.results,
      }
    case scooterConstants.GET_MISSING_DYNAMICS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        missingDynaimcs: [],
        error: action.error,
      }
    case scooterConstants.GET_DATA_SCOOTER_BY_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.GET_DATA_SCOOTER_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        scooterSelected: action.results,
      }
    case scooterConstants.GET_DATA_SCOOTER_BY_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        scooterSelected: {},
        error: action.error,
      }

    case scooterConstants.GETALL_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.GETALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case scooterConstants.GETALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case scooterConstants.CLEAR_RESULTS:
      return {
        ...state,
        results: [],
        showResults: false,
        loadingResults: false,
      }
    case scooterConstants.FILTER_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case scooterConstants.FILTER_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case scooterConstants.FILTER_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case scooterConstants.DETAILS_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case scooterConstants.DETAILS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        scooter: action.scooter,
      }
    case scooterConstants.DETAILS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case scooterConstants.GETALL_DYNAMIC_REQUEST:
      return {
        ...state,
        loadingDynamics: true,
        showResults: false,
        refreshMap: state.dynamics.length === 0 ? false : true,
      }
    case scooterConstants.GETALL_DYNAMIC_SUCCESS:
      return {
        ...state,
        loadingDynamics: false,
        showResults: true,
        dynamics: action.dynamics,
      }
    case scooterConstants.GETALL_DYNAMIC_FAILURE:
      return {
        ...state,
        loadingDynamics: false,
        showResults: true,
        dynamics: [],
        error: action.error,
      }
    case scooterConstants.GET_DYNAMIC_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case scooterConstants.GET_DYNAMIC_SUCCESS:
      return {
        ...state,
        dynamic: action.dynamic,
        loadingResults: false,
      }
    case scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        mapByOperator: [],
        error: action.error,
      }
    case scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case scooterConstants.GET_ALL_MAP_BY_OPERATOR_ID_SUCCESS:
      return {
        ...state,
        mapByOperator: action.dynamic,
        loadingResults: false,
      }
    case scooterConstants.GET_DYNAMIC_FAILURE:
      return {
        ...state,
        loadingResults: false,
        dynamic: {},
        error: action.error,
      }
    case scooterConstants.TRIPS_REQUEST:
      return {
        ...state,
        trips: [],
        loadingResults: true,
      }
    case scooterConstants.TRIPS_SUCCESS:
      return {
        ...state,
        trips: action.trips,
        loadingResults: false,
      }
    case scooterConstants.TRIPS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        trips: [],
        error: action.error,
      }
    case scooterConstants.TRIPS_DETAIL_REQUEST:
      return {
        ...state,
        tripDetail: [],
        loadingResults: true,
      }
    case scooterConstants.TRIPS_DETAIL_SUCCESS:
      return {
        ...state,
        tripDetail: action.tripDetail,
        loadingResults: false,
      }
    case scooterConstants.TRIPS_DETAIL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        tripDetail: {},
        error: action.error,
      }
    case scooterConstants.CLEAR_STATE:
      return initialState
    default:
      return state
  }
}
