import React, { Fragment, useEffect, useState } from 'react'
import useStyles from './styles'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { useTranslation } from 'react-i18next'

import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import { operatorActions } from '../../../../redux/actions/operator_action'

const RegisterOperatorUser = ({
  insertOperatorUsers,
  operatorReducer,
  userReducer,
  getUserDataByEmail,
  onRegister,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { insertPending, insertFailure, insertSuccess } = operatorReducer
  const [mail, setmail] = useState('')
  const [password, setpassword] = useState('')
  const [phoneNumber, setphoneNumber] = useState('')
  const [repeatPassword, setrepeatPassword] = useState('')
  const [helpertextrepeatPassword, sethelpertextrepeatPassword] =
    useState(false)
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  useEffect(() => {
    sethelpertextrepeatPassword(password === repeatPassword)
  }, [repeatPassword, password])

  const handleSubmit = () => {
    const body = {
      firstName,
      lastName,
      sharingOperatorId: operatorReducer.dataOperatorLogged.id,
      username: mail,
      password,
      email: mail,
      mobilePhone: phoneNumber.includes('+34')
        ? phoneNumber
        : `+34${phoneNumber}`,
    }
    insertOperatorUsers(body)
  }

  useEffect(() => {
    if (insertSuccess) {
      setmail('')
      setpassword('')
      setphoneNumber('')
      setfirstName('')
      setlastName('')
      // onRegister();
    }
  }, [insertSuccess])

  const handleChangeMail = (email) => {
    setmail(email)
  }

  useEffect(() => {
    getUserDataByEmail(mail)
  }, [mail])

  useEffect(() => {
    operatorReducer.userPendingToCreateExist && setpassword(null)
  }, [operatorReducer.userPendingToCreateExist])

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        {/* <Typography component="h1" variant="h5">
          {t("operator.register.operatorUser.header")}
        </Typography> */}
        {insertPending ? (
          <div style={{ 'margin-top': '10%' }}>
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  // variant="outlined"
                  required
                  fullWidth
                  label={t('operator.register.technicianUser.firstName')}
                  value={firstName}
                  onChange={(e) => setfirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  // variant="outlined"
                  required
                  fullWidth
                  label={t('operator.register.technicianUser.lastName')}
                  value={lastName}
                  onChange={(e) => setlastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* {userReducer.userMailRequestPending ? <LinearProgress style={{ marginTop: "30px" }} /> : 'textField'} */}
                <TextField
                  // variant='standard'
                  required
                  fullWidth
                  label={t('operator.register.operatorUser.email')}
                  autoFocus
                  value={mail}
                  onChange={(e) => handleChangeMail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  // variant='standard'
                  required
                  fullWidth
                  type="password"
                  label={t('operator.register.operatorUser.password')}
                  disabled={operatorReducer.userPendingToCreateExist}
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  helperText={
                    operatorReducer.userPendingToCreateExist
                      ? t(
                          'operator.register.operatorUser.helperTextPasswordField',
                        )
                      : ''
                  }
                  error={operatorReducer.userPendingToCreateExist}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* {userReducer.userMailRequestPending ? <LinearProgress style={{ marginTop: "30px" }} /> : 'textField'} */}
                <TextField
                  // variant='standard'
                  required
                  fullWidth
                  type="password"
                  label={t('operator.register.operatorManager.password')}
                  disabled={operatorReducer.userPendingToCreateExist}
                  value={repeatPassword}
                  onChange={(e) => setrepeatPassword(e.target.value)}
                  helperText={
                    !helpertextrepeatPassword &&
                    !operatorReducer.userPendingToCreateExist &&
                    t(
                      'operator.register.operatorManager.helperTextRepeatPassword',
                    )
                  }
                  error={
                    !helpertextrepeatPassword &&
                    !operatorReducer.userPendingToCreateExist
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // variant="outlined"
                  required
                  fullWidth
                  label={t('operator.register.operatorUser.phoneNumber')}
                  value={phoneNumber}
                  onChange={(e) => setphoneNumber(e.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleSubmit()}
            >
              {t('operator.register.operatorUser.submitBtn')}
            </Button>
          </Fragment>
        )}
      </div>
    </Container>
  )
}

function mapState(state) {
  const { operatorReducer, userReducer } = state
  return { operatorReducer, userReducer }
}
const actionCreators = {
  insertOperatorUsers: operatorActions.insertOperatorUsers,
  getUserDataByEmail: operatorActions.getUserDataByEmail,
  getUserDataByEmail: operatorActions.getUserDataByEmail,
}

export default connect(mapState, actionCreators)(RegisterOperatorUser)
