export const tariffsConstant = {
  GET_ALL_REQUEST: 'TARIFFS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'TARIFFS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'TARIFFS_GET_ALL_FAILURE',

  GET_BY_LOGGED_OPERATOR_REQUEST: 'TARIFFS_GET_BY_LOGGED_OPERATOR_REQUEST',
  GET_BY_LOGGED_OPERATOR_SUCCESS: 'TARIFFS_GET_BY_LOGGED_OPERATOR_SUCCESS',
  GET_BY_LOGGED_OPERATOR_FAILURE: 'TARIFFS_GET_BY_LOGGED_OPERATOR_FAILURE',

  GET_BY_OPERATOR_ID_REQUEST: 'TARIFFS_GET_BY_OPERATOR_ID_REQUEST',
  GET_BY_OPERATOR_ID_SUCCESS: 'TARIFFS_GET_BY_OPERATOR_ID_SUCCESS',
  GET_BY_OPERATOR_ID_FAILURE: 'TARIFFS_GET_BY_OPERATOR_ID_FAILURE',

  REMOVE_DISCOUNTS_BY_ID_REQUEST: 'REMOVE_DISCOUNTS_BY_ID_REQUEST',
  REMOVE_DISCOUNTS_BY_ID_SUCCESS: 'REMOVE_DISCOUNTS_BY_ID_SUCCESS',
  REMOVE_DISCOUNTS_BY_ID_FAILURE: 'REMOVE_DISCOUNTS_BY_ID_FAILURE',

  ADD_CUSTOMER_EMAIL: 'ADD_CUSTOMER_EMAIL_TO_DISCOUNT',
}
