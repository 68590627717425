import { useEffect, useState } from 'react'

const setupValidationErrors = (properties) =>
  properties.reduce(function (resultObj, string) {
    resultObj[string] = { result: true, message: '' }
    return resultObj
  }, {})

export const useActionsModal = (user, _errors = []) => {
  const [data, setData] = useState(user)
  const [errors, setErrors] = useState(setupValidationErrors(_errors))

  useEffect(() => {
    setData(user)
  }, [user])

  const clearData = () => setData({})

  const clearErrors = () => setErrors(setupValidationErrors(_errors))

  const isFormValid = () => {
    let valid = true
    if (!errors) return false
    let properties = Object.getOwnPropertyNames(errors)

    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const isError = (error) => (error.message.length === 0 ? false : true)

  const updateErrors = (errors) => setErrors(errors)

  const onChange = (e) => {
    const { name, value } = e.target
    setData((userData) => ({ ...userData, [name]: value }))
  }

  return {
    data,
    onChange,
    clearData,
    errors,
    updateErrors,
    isFormValid,
    isError,
    clearErrors,
  }
}
