import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import {
  isCauUser,
  isManager,
  isSacUser,
  isUserWithRole,
  rolesConstants,
} from '../../helpers/roles'

import { useTranslation } from 'react-i18next'

import { scooterActions } from '../../redux/actions/scooter_actions'
import { servicesActions } from '../../redux/actions/services_actions'

import ScooterTrips from '../scooters/dialogs/scooterTrips'

import CustomMaterialTable from '../shared/customMaterialTable'

import DatePickerCustom from '../shared/datePickerCustom'

import moment from 'moment'
import { formatDateToDisplay } from '../shared/formatDate'
import ModalConfirm from '../shared/modalconfirm'
import ServiceDetails from './detailsServices'

const ServicesView = ({
  getAll,
  getAllByOperatorId,
  operatorReducer,
  servicesReducer,
  scooterReducer,
  getStatisticsByDates,
  clearTripsData,
  createRefund,
  cancelServicePayment,
}) => {
  const { t } = useTranslation()

  const [isTripsListModalOpen, setisTripsListModalOpen] = useState(false)
  const [isServiceDetailsDialogOpen, setisServiceDetailsDialogOpen] =
    useState(false)
  const [displayDataDetails, setDisplayDataDetails] = useState({})
  const [selectedRow, setselectedRow] = useState({})
  const [updatedData, setUpdatedData] = useState(false)
  const [isRefundModalOpen, setisRefundModalOpen] = useState(false)
  const [isCancelModalOpen, setisCancelModalOpen] = useState(false)
  const [selectedService, setSelectedService] = useState({})

  const handleRowClick = (rowData) => {
    setselectedRow(rowData)
    setisTripsListModalOpen(true)
  }

  const handleOpenRefund = (rowData) => {
    setSelectedService(rowData)
    setisRefundModalOpen(true)
  }

  const handleOpenCancel = (rowData) => {
    setSelectedService(rowData)
    setisCancelModalOpen(true)
  }

  const handleRefundConfirm = async () => {
    await Promise.all([createRefund(selectedService.serviceId)])

    setUpdatedData((data) => !data)
    getStatisticsByDates()
  }

  const handleCancelConfirm = async () => {
    await Promise.all([cancelServicePayment(selectedService.serviceId)])

    setUpdatedData((data) => !data)
    getStatisticsByDates()
  }

  const handleDetailsDialog = (rowData) => {
    setDisplayDataDetails(rowData)
    setisServiceDetailsDialogOpen(true)
  }

  useEffect(() => {
    getStatisticsByDates()
  }, [])

  return (
    <div>
      <DatePickerCustom
        searchAction={getStatisticsByDates}
        update={updatedData}
      />
      <CustomMaterialTable
        saveFilters={true}
        addSummaryRow={true}
        title={t('servicesPage.page.table.title')}
        isLoading={servicesReducer.loadingResults}
        columns={[
          {
            title: t('servicesPage.page.table.columns.serviceId'),
            field: 'serviceId',
          },
          {
            title: t('servicesPage.page.table.columns.sharingOperatorName'),
            field: 'operatorName',
            hidden: !(isManager() || isCauUser() || isSacUser()),
          },
          {
            title: t('servicesPage.page.table.columns.plate'),
            field: 'vehiclePlate',
          },
          // {
          //   title: "trip from to",
          //   field: "trip from to",
          // },
          {
            title: t('servicesPage.page.table.columns.customerFullName'),
            field: 'customerFullName',
          },
          {
            title: t('servicesPage.page.table.columns.customerEmail'),
            field: 'customerEmail',
          },
          {
            title: t('servicesPage.page.table.columns.reserveStartTimestamp'),
            field: 'reserveStartTimestamp',
            render: (rowData) =>
              formatDateToDisplay(rowData.reserveStartTimestamp),
          },
          {
            title: t('servicesPage.page.table.columns.tripStartDay'),
            field: 'tripStartDay',
            tooltip: t('servicesPage.page.table.columns.tripStartDayTooltip'),
            render: (rowData) =>
              moment(rowData.tripStartDay).format('DD-MM-YYYY'),
          },
          {
            title: t('servicesPage.page.table.columns.tripStartHour'),
            field: 'tripStartHour',
            tooltip: t('servicesPage.page.table.columns.tripStartHourTooltip'),
            render: (rowData) =>
              moment
                .parseZone(rowData.tripStartHour)
                .local()
                .format('HH:mm:ss'),
          },
          {
            title: t('servicesPage.page.table.columns.tripEndDay'),
            field: 'tripEndDay',
            tooltip: t('servicesPage.page.table.columns.tripEndDayTooltip'),
            render: (rowData) =>
              moment(rowData.tripEndDay).format('DD-MM-YYYY'),
          },
          {
            title: t('servicesPage.page.table.columns.tripEndHour'),
            field: 'tripEndHour',
            tooltip: t('servicesPage.page.table.columns.tripEndHourTooltip'),
            render: (rowData) =>
              moment.parseZone(rowData.tripEndHour).local().format('HH:mm:ss'),
          },
          {
            title: t('servicesPage.page.table.columns.distance'),
            field: 'distanceKms',
            tooltip: t('servicesPage.page.table.columns.distanceTooltip'),
          },
          {
            title: t('servicesPage.page.table.columns.drivenMinutes'),
            field: 'drivenMinutes',
            tooltip: t('servicesPage.page.table.columns.drivenMinutesTooltip'),
          },
          {
            title: t('servicesPage.page.table.columns.pausedMinutes'),
            field: 'pausedMinutes',
            tooltip: t('servicesPage.page.table.columns.pausedMinutesTooltip'),
            render: (rowData) => parseFloat(rowData.pausedMinutes).toFixed(2),
          },
          {
            title: t('servicesPage.page.table.columns.batteryUsage'),
            field: 'batteryUsage',
            tooltip: t('servicesPage.page.table.columns.batteryUsageTooltip'),
          },
          {
            title: `${t('servicesPage.page.table.columns.totalPrice')}`,
            field: 'price',
            render: (rowData) => `${rowData.price} €`,
          },
          {
            title: t('servicesPage.page.table.columns.paymentStatus'),
            field: 'paymentStatus',
          },
        ]}
        actions={[
          (rowData) => ({
            icon: 'view_list',
            tooltip: t('servicesPage.page.table.actions.details'),
            onClick: (event, rowData) => handleDetailsDialog(rowData),
          }),
          (rowData) => ({
            icon: 'refresh',
            tooltip: t('servicesPage.page.table.actions.refund'),
            hidden: !(
              rowData.paymentStatus == 'Success' &&
              isUserWithRole([
                rolesConstants.MANAGER,
                rolesConstants.OPERATOR_MANAGER,
                rolesConstants.SAC_USER,
              ])
            ),
            onClick: (event, rowData) => handleOpenRefund(rowData),
          }),
          (rowData) => ({
            icon: 'cancel',
            tooltip: t('servicesPage.page.table.actions.cancelPayment'),
            hidden: rowData.paymentStatus != 'Failed',
            onClick: (event, rowData) => handleOpenCancel(rowData),
          }),
        ]}
        data={servicesReducer.results}
        onRowClick={handleRowClick}
        rowClickAvaiable={true}
      />
      <ModalConfirm
        isOpen={isRefundModalOpen}
        onClose={() => setisRefundModalOpen(false)}
        onConfirm={() => handleRefundConfirm()}
        title={`${t('servicesPage.page.table.modal.title')} ${
          selectedService.price
        }€`}
        body={`${t('servicesPage.page.table.modal.body')}`}
      />
      <ModalConfirm
        isOpen={isCancelModalOpen}
        onClose={() => setisCancelModalOpen(false)}
        onConfirm={() => handleCancelConfirm()}
        title={`${t('servicesPage.page.table.cancelModal.title')} ${
          selectedService.price
        }€`}
        body={`${t('servicesPage.page.table.cancelModal.body')}`}
      />
      <ScooterTrips
        scooterId={selectedRow && selectedRow.vehicleId}
        isOpen={isTripsListModalOpen}
        onClose={() => {
          setisTripsListModalOpen(false)
        }}
        // data={selectedRow}
        preSelectTrip={selectedRow}
      />
      <ServiceDetails
        isOpen={isServiceDetailsDialogOpen}
        dataCustomer={displayDataDetails}
        onClose={() => setisServiceDetailsDialogOpen(false)}
      />
    </div>
  )
}
function mapState(state) {
  const { scooterReducer, operatorReducer, servicesReducer } = state
  return { scooterReducer, operatorReducer, servicesReducer }
}

const actionCreators = {
  getAll: servicesActions.getAll,
  getAllByOperatorId: servicesActions.getAllByOperatorId,
  getStatisticsByDates: servicesActions.getStatisticsByDates,
  createRefund: servicesActions.createRefund,
  clearTripsData: scooterActions.clearTripsData,
  cancelServicePayment: servicesActions.cancelPayment,
}

export default connect(mapState, actionCreators)(ServicesView)
