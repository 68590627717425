import React from 'react'
import { BallBeat } from 'react-pure-loaders'

const styleSpinner = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

class Spinner extends React.Component {
  render() {
    return (
      <div style={styleSpinner}>
        <BallBeat loading={this.props.loading} color={'#e41622'} size={50} />
      </div>
    )
  }
}

export default Spinner
