export const bonusConstants = {
  GETALL_REQUEST: 'BONUS_GETALL_REQUEST',
  GETALL_SUCCESS: 'BONUS_GETALL_SUCCESS',
  GETALL_FAILURE: 'BONUS_GETALL_FAILURE',

  GET_DATA_FROM_HISTORIC_REQUEST: 'BONUS_GET_DATA_FROM_HISTORIC_REQUEST',
  GET_DATA_FROM_HISTORIC_SUCCESS: 'BONUS_GET_DATA_FROM_HISTORIC_SUCCESS',
  GET_DATA_FROM_HISTORIC_FAILURE: 'BONUS_GET_DATA_FROM_HISTORIC_FAILURE',

  INSERT_REQUEST: 'BONUS_INSERT_REQUEST',
  INSERT_SUCCESS: 'BONUS_INSERT_SUCCESS',
  INSERT_FAILURE: 'BONUS_INSERT_FAILURE',

  UPDATE_REQUEST: 'BONUS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'BONUS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'BONUS_UPDATE_FAILURE',
}
