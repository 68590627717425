import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  phoneInput: {
    width: '100%',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottomColor: '#CACACA',
    borderBottomWidth: 1,
  },
}))

export default useStyles
