import { tariffsConstant } from '../../constants/tariffs_constant'
import { tariffsService } from '../../services/tariffsService'

import i18next from 'i18next'
import { alertActions } from './alert_actions'

export const tariffsActions = {
  getAll,
  getByOperatorId,
  setTariffs,
  getByLoggedOperator,
  deleteDiscount,
  addCustomerEmail,
}

function getByLoggedOperator() {
  return (dispatch) => {
    dispatch(request())
    tariffsService.getByLoggedOperator().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all tarifs by operator action. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: tariffsConstant.GET_BY_LOGGED_OPERATOR_REQUEST }
  }

  function success(results) {
    return { type: tariffsConstant.GET_BY_LOGGED_OPERATOR_SUCCESS, results }
  }

  function failure(error) {
    return { type: tariffsConstant.GET_BY_LOGGED_OPERATOR_FAILURE, error }
  }
}

function setTariffs(body) {
  return (dispatch) => {
    tariffsService.setTariffs(body).then(
      (results) => {
        dispatch(getByLoggedOperator())
        dispatch(
          alertActions.success(i18next.t('services.rates.setTariffsSuccess')),
        )
      },
      (error) => {
        console.log('error =>', error)
        if (error?.length > 0) {
          error.forEach((error) => dispatch(alertActions.error(error)))
        }
      },
    )
  }
}

function addCustomerEmail(email) {
  return (dispatch) => {
    dispatch(request(email))
  }

  function request(email) {
    return { type: tariffsConstant.ADD_CUSTOMER_EMAIL, email }
  }
}

function getByOperatorId(operatorId) {
  return (dispatch) => {
    dispatch(request())
    tariffsService.getByOperatorId(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all tarifs by operator action. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: tariffsConstant.GET_BY_OPERATOR_ID_REQUEST }
  }

  function success(results) {
    return { type: tariffsConstant.GET_BY_OPERATOR_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: tariffsConstant.GET_BY_OPERATOR_ID_REQUEST, error }
  }
}

function deleteDiscount(discountId) {
  return (dispatch) => {
    dispatch(request())
    tariffsService.deleteDiscount(discountId).then(
      (result) => {
        dispatch(success(discountId, result))
        dispatch(
          alertActions.success(i18next.t('services.rates.deleteRateSuccess')),
        )
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.success(i18next.t('services.rates.deleteRateError')),
        )
      },
    )
  }

  function request() {
    return { type: tariffsConstant.REMOVE_DISCOUNTS_BY_ID_REQUEST }
  }

  function success(id, results) {
    return {
      type: tariffsConstant.REMOVE_DISCOUNTS_BY_ID_SUCCESS,
      results,
      id,
    }
  }

  function failure(error) {
    return { type: tariffsConstant.REMOVE_DISCOUNTS_BY_ID_FAILURE, error }
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    tariffsService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(
        //     "error get all ticksts by operator action. Message:" + error
        //   )
        // );
      },
    )
  }

  function request() {
    return { type: tariffsConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: tariffsConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: tariffsConstant.GET_ALL_FAILURE, error }
  }
}
