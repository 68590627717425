import React from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import Dashboard from '../dashboard'
import CauUsersPage from './page'

const CauUsers = (props) => {
  return <Dashboard component={<CauUsersPage {...props} />} />
}

export default compose(withTranslation('common'))(CauUsers)
