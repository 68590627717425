import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { useActionsModal } from '../../../hooks/useActionsModal'
import { cauActions } from '../../../redux/actions/cau_actions'

const UpdateCAU = ({ open, onClose, onSubmit, loading }) => {
  const { t } = useTranslation()
  const { user } = useSelector((state) => state.cauReducer)
  const errorKeys = ['firstName', 'lastName', 'phoneNumber']

  const memoizedUser = useMemo(
    () => ({
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      phoneNumber: user.phoneNumber ?? '',
      identificationDocumentCode: user.identificationDocumentCode ?? '',
      id: user.id,
    }),
    [user],
  )

  const {
    data,
    onChange,
    clearData,
    errors,
    updateErrors,
    isFormValid,
    isError,
    clearErrors,
  } = useActionsModal(memoizedUser, errorKeys)

  const dispatch = useDispatch()

  const validateForm = () => {
    let _errors = errors

    errors.firstName = validations.required(data.firstName)
    errors.lastName = validations.required(data.lastName)
    errors.phoneNumber = data.phoneNumber
      ? validations.validatePrefix(data.phoneNumber)
      : { result: true, message: '' }

    updateErrors({ ..._errors })
  }

  const handleSubmit = () => {
    validateForm()

    if (isFormValid()) {
      onSubmit(data)
      onClose()
      dispatch(cauActions.clearUserData())
    }
  }

  const handleClose = () => {
    onClose()
    clearData()
    clearErrors()
    dispatch(cauActions.clearUserData())
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('cau.modals.titleUpdate')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t('cau.modals.firstName')}
              name="firstName"
              value={data.firstName}
              error={isError(errors.firstName)}
              helperText={errors.firstName.message}
              onChange={(e) => onChange(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t('cau.modals.lastName')}
              name="lastName"
              value={data.lastName}
              error={isError(errors.lastName)}
              helperText={errors.lastName.message}
              onChange={(e) => onChange(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t('cau.modals.documentNumber')}
              name="identificationDocumentCode"
              value={data.identificationDocumentCode}
              onChange={(e) => onChange(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={t('cau.modals.phoneNumber')}
              name="phoneNumber"
              error={isError(errors.phoneNumber)}
              helperText={errors.phoneNumber.message}
              value={data.phoneNumber}
              onChange={(e) => onChange(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            fullWidth
            onClick={handleSubmit}
            style={{ backgroundColor: 'red', color: 'white', marginTop: 10 }}
            disabled={loading}
          >
            {!loading ? 'UPDATE' : <CircularProgress color="white" size={24} />}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateCAU
