import React, { Fragment, useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'

const genericRow = (field, value) => {
  return (
    <Fragment key={Math.random() * Math.random()}>
      <Grid item md={6} xs={12} sm={6} lg={6}>
        <span>{field}</span>
      </Grid>
      <Grid item md={6} xs={12} sm={6} lg={6}>
        <span>{value}</span>
      </Grid>
    </Fragment>
  )
}

const ScooterDetails = ({ scooter, customerReducer, scooterReducer }) => {
  const { t } = useTranslation()
  const { selected } = customerReducer
  const { dynamic } = scooterReducer
  const [res, setres] = useState([])

  useEffect(() => {
    const {
      frameNo,
      model,
      name,
      plate,
      imei,
      sharing,
      dynamicData,
      color,
      manufactureDate,
    } = scooter
    const { scutum, data } = dynamic || { scutum: {}, data: {} }
    setres([
      {
        field: t('home.items.scooterDetails.outOfService'),
        value:
          sharing && sharing.outOfService
            ? t('home.items.scooterDetails.outOfServiceValueTrue')
            : t('home.items.scooterDetails.outOfServiceValueFalse'),
      },
      {
        field: t('home.items.scooterDetails.customer'),
        value:
          (sharing &&
            sharing.activeServiceId &&
            selected &&
            selected.fullName) ||
          t('home.items.scooterDetails.scooterFreeToUse'),
      },
      {
        field: t('home.items.scooterDetails.on'),
        value:
          scutum && scutum.isVehicleOn
            ? t('home.items.scooterDetails.scooterChargingTrue')
            : t('home.items.scooterDetails.scooterChargingFalse'),
      },
      {
        field: t('home.items.scooterDetails.model'),
        value: model,
      },
      {
        field: t('home.items.scooterDetails.color'),
        value: color,
      },
      {
        field: t('home.items.scooterDetails.manufacturingDate'),
        value: manufactureDate,
      },
      {
        field: t('home.items.scooterDetails.name'),
        value: name,
      },
      {
        field: t('home.items.scooterDetails.odo'),
        value: `${scutum && scutum.totalOdometer} m`,
      },
      {
        field: t('home.items.scooterDetails.plate'),
        value: plate,
      },
      {
        field: t('home.items.scooterDetails.vin'),
        value: frameNo,
      },
      {
        field: t('home.items.scooterDetails.imei'),
        value: imei,
      },
      {
        field: t('home.items.scooterDetails.batteryId'),
        value: scutum && scutum.batteryPackId,
      },
      {
        field: t('home.items.scooterDetails.percentBattery'),
        value: `${scutum && scutum.batterySoc}%`,
      },
      {
        field: t('home.items.scooterDetails.estimatedAutonomyKm'),
        // value: `${autonomyKms}Km`,
        value: `Km`,
      },
      {
        field: t('home.items.scooterDetails.charging'),
        value:
          scutum && scutum.batteryCharging
            ? t('home.items.scooterDetails.scooterChargingTrue')
            : t('home.items.scooterDetails.scooterChargingFalse'),
      },
    ])
  }, [dynamic, scooter, selected])

  return (
    <Grid container spacing={2}>
      {res.map((d) => {
        return genericRow(d.field, d.value)
      })}
    </Grid>
  )
}

function mapState(state) {
  const { scooterReducer } = state
  return {
    scooterReducer,
  }
}

export default connect(mapState, null)(ScooterDetails)
