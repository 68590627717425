import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import i18next from 'i18next'
import React, { useState } from 'react'

const ShiftDaysValidation = ({
  item,
  errors,
  setShiftDay,
  setErrors,
  index,
  length,
  add,
  remove,
  checkUpdates,
  customerReducer,
}) => {
  const [radioValue, setradioValue] = useState('')

  const handleChange = (event) => {
    setradioValue(event.target.value)
    checkUpdates(null, event.target.value)
  }

  const onChangeWeekDays = (body) => {
    checkUpdates(body)
    setradioValue('')
  }

  return (
    <React.Fragment>
      <Grid item xs={2}>
        <TextField
          label={i18next.t('validations.shiftDay.labels.start')}
          fullWidth
          value={item.earliestStart}
          error={
            errors.shiftDay[index].earliestStart.message === '' ? false : true
          }
          helperText={errors.shiftDay[index].earliestStart.message}
          name={'earliestStart_' + index}
          onChange={(e) => {
            setShiftDay(e, index)
            errors.shiftDay[index].earliestStart.message = ''
            setErrors(errors)
          }}
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={i18next.t('validations.shiftDay.labels.end')}
          fullWidth
          value={item.latestArrival}
          error={
            errors.shiftDay[index].latestArrival.message.length === 0
              ? false
              : true
          }
          helperText={errors.shiftDay[index].latestArrival.message}
          name={'latestArrival_' + index}
          onChange={(e) => {
            setShiftDay(e, index)
            errors.shiftDay[index].latestArrival.message = ''
            setErrors(errors)
          }}
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={5} xs={7}>
        {customerReducer && customerReducer.weekDays.length > 0 && (
          <React.Fragment>
            <Autocomplete
              options={customerReducer && customerReducer.weekDays}
              freeSolo
              multiple
              value={item.weekDays}
              onChange={(event, newValue) => {
                onChangeWeekDays(newValue)
              }}
              getOptionSelected={(option, value) => option.id == value}
              getOptionLabel={(option) =>
                option && option.description
                  ? option && option.description
                  : customerReducer.weekDays.find((x) => x.key == option)
                      .description
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={'outlined'}
                  label={i18next.t('validations.shiftDay.labels.weekDays')}
                  helperText={errors.shiftDay[index].weekDays.message}
                  error={
                    errors.shiftDay[index].weekDays.message.length === 0
                      ? false
                      : true
                  }
                />
              )}
            />

            <FormControl component="fieldset">
              <RadioGroup row value={radioValue} onChange={handleChange}>
                <FormControlLabel
                  value={'2'}
                  control={
                    <Radio
                      checked={
                        JSON.stringify(item.weekDays) ==
                        JSON.stringify([0, 1, 2, 3, 4, 5, 6])
                      }
                      color="primary"
                    />
                  }
                  label={i18next.t('validations.shiftDay.labels.allDays')}
                />
                <FormControlLabel
                  value={'3'}
                  control={
                    <Radio
                      checked={
                        JSON.stringify(item.weekDays) ==
                        JSON.stringify([0, 1, 2, 3, 4])
                      }
                      color="primary"
                    />
                  }
                  label={i18next.t('validations.shiftDay.labels.workDays')}
                />
                <FormControlLabel
                  value={'1'}
                  control={
                    <Radio
                      checked={
                        JSON.stringify(item.weekDays) == JSON.stringify([5, 6])
                      }
                      color="primary"
                    />
                  }
                  label={i18next.t('validations.shiftDay.labels.weekend')}
                />
              </RadioGroup>
            </FormControl>
          </React.Fragment>
        )}
      </Grid>
      <Grid
        item
        xs={1}
        sm={1}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {index + 1 == length ? (
          <React.Fragment>
            {length > 1 && (
              <IconButton onClick={() => remove(index)}>
                <Remove />
              </IconButton>
            )}
            <IconButton onClick={() => add()}>
              <Add />
            </IconButton>
          </React.Fragment>
        ) : (
          <>
            <IconButton onClick={() => remove(index)}>
              <Remove />
            </IconButton>
          </>
        )}
      </Grid>
      <Grid item xs={2} sm={2}></Grid>
    </React.Fragment>
  )
}

export default ShiftDaysValidation
