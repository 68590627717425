import { uiConstants } from '../../constants/ui_constant'

import { history } from '../../helpers/history'

export const uiActions = {
  setOpenDrawer,
  setFilterTable,
}

function setOpenDrawer(bool) {
  return { type: uiConstants.OPEN_DRAWER, bool }
}
function setFilterTable(obj) {
  const currentLocation = history.location.pathname.split('/:')[0]
  const object = { ...obj, currentLocation }
  return { type: uiConstants.SET_FILTER, object }
}
