import store from '../redux/store'

export const rolesConstants = {
  MANAGER: 'Sharing.Admin',
  MANAGER_READONLY: 'Sharing.AdminReadOnly',
  OPERATOR_MANAGER: 'Sharing.OperatorManager',
  OPERATOR: 'Sharing.Operator',
  TECHNICIAN: 'Sharing.Technician',
  CUSTOMER: 'Sharing.Customer',
  SAC_USER: 'Sharing.Sac',
  CAU_USER: 'Sharing.Cau',
}

export const rolesAllowed = [
  rolesConstants.MANAGER,
  rolesConstants.MANAGER_READONLY,
  rolesConstants.OPERATOR_MANAGER,
  rolesConstants.OPERATOR,
  rolesConstants.TECHNICIAN,
  rolesConstants.CAU_USER,
  rolesConstants.SAC_USER,
]

export const isRoleAllowedToAccess = (role) => {
  return rolesAllowed.filter((ra) => role === ra).length > 0
}

export const allowAccessRole = (rolesDetected) => {
  let rolesAllowedResult = []
  rolesDetected.map((i) => {
    rolesAllowed.map((roleConstant) => {
      roleConstant === i.role && rolesAllowedResult.push(i)
    })
  })
  return rolesAllowedResult
}

export const isManager = () => {
  const { authenticationReducer } = store.getState()

  return (
    authenticationReducer.user &&
    authenticationReducer.user.role === rolesConstants.MANAGER
  )
}

export const isManagerReadOnly = () => {
  const { authenticationReducer } = store.getState()

  return (
    authenticationReducer.user &&
    authenticationReducer.user.role === rolesConstants.MANAGER_READONLY
  )
}

export const isOperatorManager = () => {
  const { authenticationReducer } = store.getState()

  return (
    authenticationReducer.user &&
    authenticationReducer.user.role === rolesConstants.OPERATOR_MANAGER
  )
}
export const isTechnician = () => {
  const { authenticationReducer } = store.getState()

  return (
    authenticationReducer.user &&
    authenticationReducer.user.role === rolesConstants.TECHNICIAN
  )
}
export const isSupervisor = () => {
  const { authenticationReducer } = store.getState()

  return (
    authenticationReducer.user &&
    authenticationReducer.user.role === rolesConstants.OPERATOR
  )
}

export const isSacUser = () => {
  const { authenticationReducer } = store.getState()

  return (
    authenticationReducer.user &&
    authenticationReducer.user.role === rolesConstants.SAC_USER
  )
}

export const isCauUser = () => {
  const { authenticationReducer } = store.getState()

  return (
    authenticationReducer.user &&
    authenticationReducer.user.role === rolesConstants.CAU_USER
  )
}

export const isUserWithRole = (roles) => {
  const { authenticationReducer } = store.getState()
  const { role = '' } = authenticationReducer.user
  return roles.includes(role)
}

store.subscribe(isManager)
