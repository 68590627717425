import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bonusActions } from '../../../redux/actions/bonus_actions'

import { formatDateToInsertIntoComponent } from '../../shared/formatDate'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  submitBtn: {
    marginTop: '2%',
  },
}))

const ModalManagerBonus = ({ data, isOpen, onClose, update, bonusReducer }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  const { id, ofert, price, validSince, validUntil, text: textprop } = data

  useEffect(() => {
    const { ofert, price, validSince, validUntil } = data
    setdateSinceValue(formatDateToInsertIntoComponent(validSince))
    setdateUntilValue(formatDateToInsertIntoComponent(validUntil))
    setpriceValue(price)
    setofferValue(ofert)
    setname('Bonus ' + price)
    settext(textprop)
  }, [data])

  const [name, setname] = useState('')
  const [priceValue, setpriceValue] = useState()
  const [dateSinceValue, setdateSinceValue] = useState()
  const [dateUntilValue, setdateUntilValue] = useState()
  const [offerValue, setofferValue] = useState()
  const [text, settext] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    ///// validate body

    const body = {
      id: id,
      name: name,
      price: parseFloat(priceValue),
      validSince: dateSinceValue,
      validUntil: dateUntilValue,
      ofert: parseFloat(offerValue),
      text,
    }

    update(body)

    onClose()
  }

  const handleSetPrice = (e) => {
    setpriceValue(e.target.value)
    setname(`Bonus ${e.target.value}`)
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <div>
              <h2 id="transition-modal-title">
                {t('bonus.modalManagerBonus.header')}
              </h2>
              <p id="transition-modal-description">
                {t('bonus.modalManagerBonus.description')}
              </p>
            </div>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                required
                label={t('bonus.modalCreateBonus.name')}
                autoFocus
                value={name}
                disabled
              />
              <TextField
                required
                label={t('bonus.modalManagerBonus.priceLabel')}
                autoFocus
                value={priceValue}
                onChange={(e) => handleSetPrice(e)}
              />
              <TextField
                label={t('bonus.modalManagerBonus.validatedSinceLabel')}
                type="date"
                value={dateSinceValue}
                onChange={(e) => setdateSinceValue(e.target.value)}
              />
              <TextField
                label={t('bonus.modalManagerBonus.validatedUntilLabel')}
                type="date"
                value={dateUntilValue}
                onChange={(e) => setdateUntilValue(e.target.value)}
              />
              <TextField
                required
                label={t('bonus.modalManagerBonus.offerLabel')}
                autoFocus
                value={offerValue}
                onChange={(e) => setofferValue(e.target.value)}
              />
              <TextField
                required
                label={t('bonus.modalCreateBonus.textLabel')}
                autoFocus
                value={text}
                onChange={(e) => settext(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.submitBtn}
                type="submit"
              >
                {t('bonus.modalManagerBonus.updateBonusBtn')}
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
function mapState(state) {
  const { bonusReducer } = state
  return { bonusReducer }
}

const actionCreators = {
  update: bonusActions.update,
}

export default connect(mapState, actionCreators)(ModalManagerBonus)
