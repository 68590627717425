import React, { useEffect, useState } from 'react'

import {
  isManager,
  isManagerReadOnly,
  isUserWithRole,
  rolesConstants,
} from '../../helpers/roles'
import { formatDateToDisplay, formatToUtc } from '../shared/formatDate'
import DialogConfirmAction from '../shared/modalConfirmAction'

import { connect, useDispatch } from 'react-redux'
import { handleTrueFalsyTermSwitch } from '../../helpers/filters'
import { customerActions } from '../../redux/actions/customers_actions'
import { operatorActions } from '../../redux/actions/operator_action'
import { servicesActions } from '../../redux/actions/services_actions'

import NotificationsManagement from '../notifications'
import ActionsToOperators from './modal/actionsToOperators'
import AssignManualy from './modal/assignManualy'
import CustomerMovements from './modal/customerMovements'
import DiscountDetails from './modal/discountDetails'
import EditCustomerAssignationRange from './modal/editCustomerAsignationRange'
import OperatorsList from './modal/operatorsList'

import CustomMaterialTable from '../shared/customMaterialTable'

import { Container } from '@material-ui/core'
import moment from 'moment'
import { alertActions } from '../../redux/actions/alert_actions'
import { documentsActions } from '../../redux/actions/documents_action'
import { userService } from '../../services/userService'
import ModalConfirm from '../shared/modalconfirm'
import CustomerDocumentationDialog from './documentation'

const CustomerView = ({
  customerReducer,
  servicesReducer,
  t,
  filter,
  getAll,
  getAllByOperatorId,
  assignCustomersToOperator,
  operatorReducer,
  customersByOperators,
  customersByOperatorsById,
  unassignCustomersOperator,
  getOperatorsByCustomers,
  getAllPrivateOperators,
  customersByPrivateOperatorId,
  getTotalsByCustomers,
  updateAssignOperatorDates,
  getAllOperators,
  getByCustomerAndDates,
  getDrivingLicenses,
  editAssignCustomersToOperator,
  getAllCountries,
  getIdDocTypes,
}) => {
  const [isDialogEditRange, setisDialogEditRange] = useState(false)
  const [isValidateDialogOpen, setisValidateDialogOpen] = useState(false)
  const [isCustomerMovementsOpen, setisCustomerMovementsOpen] = useState(false)
  const [isOperatorListDialogOpen, setisOperatorListDialogOpen] =
    useState(false)
  const [isNotificationsManagementOpen, setisNotificationsManagementOpen] =
    useState(false)
  const [isDialogUnassignCustomerOpen, setisDialogUnassignCustomerOpen] =
    useState(false)
  const [assignCustomerDialog, setassignCustomerDialog] = useState()
  const [isDialogDiscountDetailsOpen, setisDialogDiscountDetailsOpen] =
    useState(false)
  const [isDialogDisableClientOpen, setisDialogDisableClientOpen] =
    useState(false)
  const [isDialogAssignUserManualyOpen, setisDialogAssignUserManualyOpen] =
    useState(false)
  const [
    isDialogEditAssignUserManualyOpen,
    setisDialogEditAssignUserManualyOpen,
  ] = useState(false)
  const [isDialogAssignToMySelfOpen, setisDialogAssignToMySelfOpen] =
    useState(false)
  const [isDialogActionsToOperatorOpen, setisDialogActionsToOperatorOpen] =
    useState(false)
  const [displayList, setdisplayList] = useState([])
  const [customerSelected, setcustomerSelected] = useState({})
  const [textDialog, settextDialog] = useState({})
  const [actionclient, setactionclient] = useState()

  const [bodyAssignManualy, setbodyAssignManualy] = useState()

  const [deleteUserModalOpened, setDeleteUserModalOpened] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    const operatorId = operatorReducer.dataOperatorLogged.id
    getAllByOperatorId(operatorId)
    getDrivingLicenses()
    getAllCountries()
    getIdDocTypes()

    return () => dispatch(customerActions.closeValidationsModal())
  }, [])

  //   useEffect(() => {
  //     //mountDisplayList();
  //   }, [
  //     customerReducer.results,
  //     customerReducer.customersByOperator,
  //     operatorReducer,
  //   ]);

  const mountDisplayList = () => {
    const { customersByOperator, results } = customerReducer
    let customerListWithCboData = []
    /* customersByOperator.map((cbo) => {
                results.map((c) => {
                    cbo.customerId === c.id &&
                        customerListWithCboData.push({
                            customerData: c,
                            ...cbo,
                            firebaseUID: c.firebaseUID,
                        });
                });
            });
    
            const finalList = [
                ...customerListWithCboData,
                ...customersByOperator,
            ].filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    
            const finalList2 = [...customerListWithCboData, ...results].filter(
                (v, i, a) => a.findIndex((t) => t.firebaseUID === v.firebaseUID) === i
            ); */

    setdisplayList(results)
  }

  const handleOpenValidateDocuments = (event, rowData) => {
    setcustomerSelected(rowData)
    // setisValidateDialogOpen(true);
    dispatch(customerActions.openValidationsModal())
  }

  // UNUSED - 20/10/2022
  //
  // const handleDisableClient = (event, rowData) => {
  //     settextDialog({
  //         header: t("customers.page.textConfirmActions.confirmActionHeader"),
  //         body: t("customers.page.textConfirmActions.unableUser"),
  //     });
  //     setactionclient(true);
  //     setisDialogDisableClientOpen(true);
  //     setcustomerSelected(rowData);
  // };

  // const handleEnableClient = (event, rowData) => {
  //     settextDialog({
  //         header: t("customers.page.textConfirmActions.confirmActionHeader"),
  //         body: t("customers.page.textConfirmActions.enableUser"),
  //     });
  //     setactionclient(false);
  //     setisDialogDisableClientOpen(true);
  //     setcustomerSelected(rowData);
  // };

  const handleConfirmDialogClient = (comment) => {
    const body = {
      customerId: customerSelected.hasOwnProperty('customerData')
        ? customerSelected.customerData.id
        : null,
      documentsVerified: customerSelected.hasOwnProperty('customerData')
        ? customerSelected.customerData.documentsVerified ?? false
        : false,
      canOperate: !actionclient,
      cannotOperateReason: ` ${comment}`,
    }
  }

  const handleConfirmAssignManualyUser = (
    customerEmail,
    validatedSince,
    validatedUntil,
  ) => {
    setassignCustomerDialog(true)
    getAllPrivateOperators()
    setbodyAssignManualy({ customerEmail, validatedSince, validatedUntil })
    isManager() || isManagerReadOnly()
      ? setisOperatorListDialogOpen(true) //history.push("/customers/assignToOperator/" + customerEmail)
      : setisDialogAssignToMySelfOpen(true)
  }

  const handleAssignToMySelf = () => {
    const { customerEmail, validatedSince, validatedUntil } = bodyAssignManualy

    let body = {
      sharingOperatorId: operatorReducer.dataOperatorLogged.id,
      customerKey: customerEmail,
      canOperateStart: validatedSince,
      canOperateEnd: validatedUntil,
    }
    assignCustomersToOperator(body)
  }

  const handleOpenDiscountDetails = (event, rowData) => {
    setcustomerSelected(rowData)
    setisDialogDiscountDetailsOpen(true)
  }

  const handleConfirmUnassignOperator = () => {
    setisDialogUnassignCustomerOpen(true)

    const body = {
      sharingOperatorId: operatorReducer.dataOperatorLogged.id,
      customerKey: customerSelected.customerEmail,
    }
    unassignCustomersOperator(body)
  }
  const handleUnassignCustomer = (event, rowData) => {
    setcustomerSelected(rowData)
    setisDialogUnassignCustomerOpen(true)
  }

  const handleDeleteCustomer = () => {
    userService.deleteCustomer(selectedEmail).then(
      (result) => {
        console.log('result deleteCustomer => ', result)
        //IF OK -> REFRESH DATA
        const operatorId = operatorReducer.dataOperatorLogged.id
        getAllByOperatorId(operatorId)
        dispatch(alertActions.success(t('customers.page.delete.success')))
      },
      (error) => {
        console.log('error in deleteCustomer => ', error)
        //show error message
        dispatch(alertActions.error(t('customers.page.delete.error')))
      },
    )
  }

  const handleConfirmOperatorListAction = (
    operator,
    validatedSince,
    validatedUntil,
  ) => {
    console.log('bodyAssignManually =>', JSON.stringify(bodyAssignManualy))

    setisOperatorListDialogOpen(false)
    if (assignCustomerDialog) {
      operator.map((o) => {
        const bodyAssign = {
          sharingOperatorId: o.id,
          customerKey: bodyAssignManualy.customerEmail,
          canOperateStart: validatedSince,
          canOperateEnd: validatedUntil,
        }
        // console.log("bodyAssignTest", bodyAssignTest);
        assignCustomersToOperator(bodyAssign)
      })
    } else {
      const bodyUnassign = {
        sharingOperatorId: operator.id,
        customerKey: bodyAssignManualy.customerKey,
      }
      // console.log("bodyUnassignTest", bodyUnassignTest);
      unassignCustomersOperator(bodyUnassign)
    }
    getAllByOperatorId('')
  }
  const handleActionsToOperator = (event, rowData) => {
    getOperatorsByCustomers(
      //rowData.hasOwnProperty("customerData") && rowData.customerData.id
      rowData.customerId,
    )
    setcustomerSelected(rowData)
    setisDialogActionsToOperatorOpen(true)
  }

  const handleUnassignOperator = (operator) => {
    const body = {
      sharingOperatorId: operator.id,
      customerKey: customerSelected.customerKey,
      customerId: customerSelected.hasOwnProperty('customerData')
        ? customerSelected.customerData.id
        : null,
    }
    unassignCustomersOperator(body)
  }

  const handleAssignOperator = (operator, validatedSince, validatedUntil) => {
    console.log('ASDADSA')
    console.log('customerSelected =>', JSON.stringify(customerSelected))

    operator.map((o) => {
      const body = {
        sharingOperatorId: o.id,
        customerKey: customerSelected.email ?? customerSelected.customerEmail,
        canOperateStart: validatedSince
          ? formatToUtc(validatedSince) //new Date(validatedSince).toUTCString()// toISOString() //2021-05-16T06:46:00.000Z
          : null,
        canOperateEnd: validatedSince
          ? formatToUtc(validatedUntil) //new Date(validatedUntil).toUTCString()
          : null,
        customerId: customerSelected.hasOwnProperty('customerData')
          ? customerSelected.customerData.id
          : customerSelected.id,
      }
      assignCustomersToOperator(body)
    })
  }

  const isPrivateOperator = () => {
    if (isManager() || isManagerReadOnly()) return true
    else if (operatorReducer?.dataOperatorLogged?.isPrivate) return true
    else return false
  }

  const customFilter = (term, value) => {
    return value ? value.toString().includes(term) : false
  }

  const handleEditRangeAssignation = (event, rowData) => {
    // console.log("rowData", rowData);
    setcustomerSelected(rowData)
    setisDialogEditRange(true)
  }
  const handleEditRangeCall = (body) => {
    setisDialogEditRange(false)
    const bodyRange = {
      customerKey: customerSelected.customerEmail,
      operatorId: operatorReducer.dataOperatorLogged.id,
      canOperateStart: body.start,
      canOperateEnd: body.end,
    }
    updateAssignOperatorDates(bodyRange)
  }
  const handleFindFirstRegister = (rowData) => {
    if (isManager() || isManagerReadOnly()) {
      const { sharingOperatorId } = rowData

      const operatorData = operatorReducer?.results.find(
        (o) => o.id == sharingOperatorId,
      )
      if (operatorData) {
        if (operatorData.isPrivate) {
          return formatDateToDisplay(
            rowData?.canOperateStart || rowData?.assigned,
          )
        } else {
          return formatDateToDisplay(rowData?.customerData?.createDate)
        }
      } else {
        return 'N/A'
      }
    } else {
      if (operatorReducer?.dataOperatorLogged?.isPrivate) {
        return formatDateToDisplay(
          rowData?.canOperateStart || rowData?.assigned,
        )
      } else {
        return formatDateToDisplay(rowData?.customerData?.createDate)
      }
    }
  }

  const handleCustomerMovements = (rowData) => {
    setcustomerSelected(rowData)
    getByCustomerAndDates(rowData.customerId)
    setisCustomerMovementsOpen(true)
  }

  return (
    <Container maxWidth="xl">
      <CustomMaterialTable
        addSummaryRow={true}
        saveFilters={true}
        isLoading={customerReducer.loadingResultsAll}
        title={t('customers.page.title')}
        data={customerReducer.results}
        columns={[
          {
            title: t('customers.page.columns.dateFirstRegister'),
            field: 'created',
            render: (rowData) =>
              rowData.created
                ? moment(rowData.created).format('DD/MM/YYYY')
                : '',
          },
          {
            title: t('customers.page.columns.dateValidated'),
            field: 'canOperateStart',
            render: (rowData) =>
              rowData.canOperateStart
                ? moment(rowData.canOperateStart).format('DD/MM/YYYY')
                : '',
          },
          /* {
                                        title: t("customers.page.columns.enabled"),
                                        field: "enabled",
                                        render: (rowData) =>
                                            rowData.customerData && rowData.customerData.canOperate
                                                ? t("customers.page.columns.yes")
                                                : t("customers.page.columns.no"),
                                        customFilterAndSearch: (term, rowData) =>
                                            rowData.customerData && rowData.customerData.canOperate
                                                ? t("customers.page.columns.yes")
                                                : t("customers.page.columns.no").toString().includes(term),
                                    }, */
          {
            title: t('customers.page.columns.nOfTrips'),
            field: 'servicesCount',
          },
          {
            title: t('customers.page.columns.firstName'),
            field: 'customerFullName',
          },
          {
            title: t('customers.page.columns.email'),
            field: 'customerEmail',
          },
          {
            title: t('customers.page.columns.mobilePhone'),
            field: 'customerPhone',
          },
          {
            title: t('Validado por silence'),
            field: 'canOperate',
            lookup: { 0: t('shared.yes'), 1: t('shared.no') },
            customFilterAndSearch: (term, rowData) =>
              handleTrueFalsyTermSwitch(term, rowData.canOperate),
            render: (rowData) =>
              rowData.canOperate
                ? t('customers.page.columns.yes')
                : t('customers.page.columns.no'),
          },
          {
            title: t('customers.page.columns.cannotOperateReason'),
            field: 'cannotOperateReason',
          },
          /*  {
                                         title: "Balance (€)",
                                         field: "customerData.balance",
                                         // render: (rowData) =>
                                         //   rowData.customerData && rowData.customerData.balance,
                                         // customFilterAndSearch: (term, rowData) =>
                                         //   rowData.customerData &&
                                         //   rowData.customerData.balance.toString().includes(term),
                                         hidden: !isManager(),
                                     }, */
        ]}
        actions={[
          (rowData) => ({
            icon: 'attach_file',
            hidden: !isUserWithRole([
              rolesConstants.MANAGER,
              rolesConstants.SAC_USER,
            ]),
            tooltip: rowData.canOperate
              ? t('customers.page.actions.reviewDocuments')
              : t('customers.page.actions.validateDocuments'),
            onClick: (event, rowData) => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
              handleOpenValidateDocuments(event, rowData)
            },
            iconProps: {
              style: {
                color: rowData.canOperate ? 'green' : 'red',
              },
            },
          }),
          (rowData) => ({
            hidden: isUserWithRole([
              rolesConstants.MANAGER,
              rolesConstants.SAC_USER,
              rolesConstants.CAU_USER,
            ]),
            icon: 'attach_money',
            tooltip: t('customers.page.actions.discounts'),
            onClick: (event, rowData) =>
              handleOpenDiscountDetails(event, rowData),
          }),
          (rowData) => ({
            //disabled: !rowData.hasOwnProperty("customerData"),
            icon: 'assignment',
            tooltip: t('customers.page.actions.operators'),
            onClick: (event, rowData) =>
              handleActionsToOperator(event, rowData),
            hidden: isUserWithRole([
              rolesConstants.MANAGER,
              rolesConstants.CAU_USER,
              rolesConstants.SAC_USER,
            ])
              ? false
              : !rowData.isPrivate,
          }),
          (rowData) => ({
            //disabled: !rowData.hasOwnProperty("customerData"),
            icon: 'moving',
            tooltip: t('customers.page.actions.movements'),
            onClick: (event, rowData) => handleCustomerMovements(rowData),
          }),
          {
            icon: 'add',
            tooltip: t('customers.page.toolbar.btnAssignClientByMail'),
            isFreeAction: true,
            hidden: !isPrivateOperator(),
            onClick: (event) => setisDialogAssignUserManualyOpen(true),
          },
          {
            icon: 'add_alert',
            tooltip: t('customers.page.actions.addNotification'),
            isFreeAction: true,
            onClick: (event) => setisNotificationsManagementOpen(true),
          },
          (rowData) => ({
            icon: 'edit',
            tooltip: t('customers.page.actions.edit_dates'),
            onClick: (event, rowData) =>
              handleEditRangeAssignation(event, rowData),
            hidden: rowData?.isPrivate || !isPrivateOperator() || isManager(),
          }),
          (rowData) => ({
            icon: 'unsubscribe',
            tooltip: t('customers.page.actions.unsubscribe'),
            onClick: (event, rowData) => handleUnassignCustomer(event, rowData),
            hidden: rowData?.isPrivate || !isPrivateOperator() || isManager(),
          }),
          (rowData) => ({
            icon: 'delete',
            tooltip: t('customers.page.actions.delete'),
            onClick: (event, rowData) => {
              setDeleteUserModalOpened(true)
              setSelectedEmail(rowData.customerEmail)
            },
            hidden: !isUserWithRole([
              rolesConstants.MANAGER,
              rolesConstants.SAC_USER,
            ]),
          }),
        ]}
      />

      <NotificationsManagement
        isOpen={isNotificationsManagementOpen}
        onClose={() => setisNotificationsManagementOpen(false)}
        usersData={displayList}
        usersType={'Customer'}
      />

      <DialogConfirmAction
        isOpen={isDialogDisableClientOpen}
        onClose={() => setisDialogDisableClientOpen(false)}
        onConfirm={handleConfirmDialogClient}
        data={textDialog}
        hasNote={actionclient}
        addNote={actionclient}
      />
      <AssignManualy
        isOpen={isDialogAssignUserManualyOpen}
        onClose={() => setisDialogAssignUserManualyOpen(false)}
        onConfirm={handleConfirmAssignManualyUser}
      />
      <DialogConfirmAction
        isOpen={isDialogAssignToMySelfOpen}
        onClose={() => setisDialogAssignToMySelfOpen(false)}
        onConfirm={handleAssignToMySelf}
        addNote={false}
        data={{
          header: t('customers.page.textConfirmActions.confirmActionHeader'),
          body: t('customers.page.textConfirmActions.assignCustomer'),
        }}
      />
      <DiscountDetails
        isOpen={isDialogDiscountDetailsOpen}
        onClose={() => setisDialogDiscountDetailsOpen(false)}
        data={customerSelected}
      />
      <OperatorsList
        assignUser={assignCustomerDialog}
        isOpen={isOperatorListDialogOpen}
        onClose={() => setisOperatorListDialogOpen(false)}
        onConfirm={handleConfirmOperatorListAction}
        data={operatorReducer.results}
        cbo={customerReducer.customersByOperator}
      />
      <ActionsToOperators
        operatorData={operatorReducer.resultsByCustomers}
        isOpen={isDialogActionsToOperatorOpen}
        onClose={() => setisDialogActionsToOperatorOpen(false)}
        customerSelectedData={customerSelected}
        onUnassign={handleUnassignOperator}
        onGetAllPrivateOperators={() => getAllPrivateOperators()}
        privateOperatorsData={operatorReducer.results}
        onAssign={handleAssignOperator}
        cbo={customerReducer.customersByOperator}
      />
      <DialogConfirmAction
        isOpen={isDialogUnassignCustomerOpen}
        onClose={() => setisDialogUnassignCustomerOpen(false)}
        onConfirm={handleConfirmUnassignOperator}
        data={{
          header: t('customers.page.textConfirmActions.headerUnassignOperator'),
          body: t('customers.page.textConfirmActions.unassignOperator'),
        }}
        hasNote={false}
        addNote={false}
      />
      {customerReducer.validationModalOpened && (
        <CustomerDocumentationDialog
          isOpen={customerReducer.validationModalOpened}
          customerData={customerSelected}
          customerId={customerSelected.customerId}
          from="Customers"
          onClose={() => dispatch(customerActions.closeValidationsModal())}
        />
      )}
      <EditCustomerAssignationRange
        isOpen={isDialogEditRange}
        onClose={() => setisDialogEditRange(false)}
        data={customerSelected}
        onConfirm={handleEditRangeCall}
      />
      <CustomerMovements
        isOpen={isCustomerMovementsOpen}
        onClose={() => setisCustomerMovementsOpen(false)}
        customerData={customerSelected}
        servicesReducer={servicesReducer}
        onSearch={getByCustomerAndDates}
      />
      <ModalConfirm
        isOpen={deleteUserModalOpened}
        onConfirm={() => {
          handleDeleteCustomer()
          setDeleteUserModalOpened(false)
        }}
        onClose={() => setDeleteUserModalOpened(false)}
        title={t('customers.page.delete.title')}
      />
    </Container>
  )
}

function mapState(state) {
  const {
    operatorReducer,
    customerReducer,
    servicesReducer,
    documentsReducer,
  } = state
  return { operatorReducer, customerReducer, servicesReducer, documentsReducer }
}

const actionCreators = {
  assignCustomersToOperator: customerActions.assignCustomersToOperator,
  getAllByOperatorId: customerActions.getAllByOperatorId,
  getAll: customerActions.getAll,
  customersByOperators: customerActions.customersByOperators,
  customersByOperatorsById: customerActions.customersByOperatorsById,
  unassignCustomersOperator: customerActions.unassignCustomersOperator,
  getOperatorsByCustomers: operatorActions.getByCustomers,
  getAllPrivateOperators: operatorActions.getAllPrivateOperators,
  customersByPrivateOperatorId: customerActions.customersByPrivateOperatorId,
  getTotalsByCustomers: servicesActions.getTotalsByCustomers,
  updateAssignOperatorDates: customerActions.updateAssignOperatorDates,
  getAllOperators: operatorActions.getAll,
  getByCustomerAndDates: servicesActions.getByCustomerAndDates,
  getDrivingLicenses: documentsActions.getDrivingLicenses,
  getAllCountries: customerActions.getAllCountries,
  getIdDocTypes: documentsActions.getIdDocTypes,
}

export default connect(mapState, actionCreators)(CustomerView)
