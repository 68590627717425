import { handleCall } from './handleResponse'

export const documentsService = {
  getByCustomerId,
  getDrivingLicenses,
  getIdDocTypes,
}

function getByCustomerId(customerId) {
  const filter = `?field=CustomerId&value=${customerId}`
  return handleCall(`/customers/v1/Documents${filter}`, {
    method: 'GET',
    body: null,
  })
  // var requestOptions = {
  //   method: "GET",
  //   headers: authHeader(),
  //   redirect: "follow",
  // };

  // const filter = `?field=CustomerId&value=${customerId}`;
  // return fetch(
  //   `${stringConstants.URL_ApiGateway}/customers/v1/Documents${filter}`,
  //   requestOptions
  // ).then(handleResponse);
}

function getDrivingLicenses() {
  return handleCall(`/customers/v1/Documents/DrivingLicenseTypes`, {
    method: 'GET',
    body: null,
  })
}

function getIdDocTypes() {
  return handleCall(`/customers/v1/Documents/GetIdDocTypes`, {
    method: 'GET',
    body: null,
  })
}

// function handleResponse(response) {
//     return response.text().then(text => {
//         if (response.status === 204 || response.status === 404) {
//             return []
//         }
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 var refreshToken = loginService.getWithExpiry('refresh_token')
//                 loginService.refreshToken(refreshToken)
//                     .then(
//                         token => {
//                             loginService.setWithExpiry('token', token.access_token, token.expires_in);
//                         }
//                     )
//             }
//             const error = (data && data.message) || text;
//             return Promise.reject(error);
//         }

//         // check if the token is close to expire
//         var date = new Date();
//         var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
//             date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
//         var now = new Date(now_utc);
//         now.setMinutes(now.getMinutes() + 20)
//         var token_expire = new Date(loginService.getWithExpiry('token_dateExpiry'));
//         // if in 10 minutes expire, renew the token
//         if (now > token_expire) {
//             refreshToken = loginService.getWithExpiry('refresh_token')
//             loginService.refreshToken(refreshToken)
//                 .then(
//                     token => {
//                         loginService.setWithExpiry('token', token.access_token, token.expires_in);

//                     }
//                 )
//         }
//         return data;
//     });
// }
