import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'

const DialogConfirmAction = ({
  isOpen,
  onClose,
  onConfirm,
  data,
  hasNote,
  label = 'Nota',
  isMandatory = false, // If true, comment input must not be empty
  addNote = true,
}) => {
  const [newComment, setnewComment] = useState('')
  const [error, setError] = useState('')

  const { header, body } = data
  const handleClose = () => {
    onClose()
  }

  const handleMandatorySubmit = () => {
    //console.log("newComment", newComment)
    //console.log("isFormValid", isFormValid())
    validateForm()
    //console.log("error", JSON.stringify(error))
    if (isFormValid()) {
      onConfirm(newComment)
      setnewComment('')
      onClose()
    }
  }

  const handleConfirm = () => {
    if (isMandatory) {
      handleMandatorySubmit()
      return
    }

    onConfirm(newComment)
    setnewComment('')
    onClose()
  }

  const validateForm = () =>
    setError(!!newComment.trim() ? '' : 'Has de introducir comentario')

  const isFormValid = () => {
    return !!newComment.trim()
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText>{body}</DialogContentText>
          {addNote && <p>Añade una nota</p>}
          {hasNote && (
            <TextField
              autoFocus
              margin="dense"
              label={label}
              fullWidth
              value={newComment}
              onChange={(e) => {
                setnewComment(e.target.value)
                setError('')
              }}
              error={isMandatory && !!!newComment.trim()}
              helperText={error}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogConfirmAction
