import React from 'react'

import { Grid } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
// import SaveIcon from "@material-ui/icons/Save";

import { useTranslation } from 'react-i18next'
import { isUserWithRole, rolesConstants } from '../../../helpers/roles'
import DynamicsOnMap from './dynamicsOnMap'
import ScooterDetails from './scooterDetails'

const useStyles = makeStyles((theme) => ({
  tripLink: {
    color: '#000096',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  scooterDetailsContainer: {
    margin: '5% 0%',
  },
  divider: {
    marginTop: '5%',
  },
  button: {
    height: '50px',
  },
}))

const DetailsContainer = ({
  selectedVehicle,
  onOpenTripsList,
  onOpenLastTrip,
  getId,
  customerReducer,
  onCreateTicket,
  onCleanService,
  onBlockScooter,
  onShowIncidents,
  scooter,
  operatorReducer,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return selectedVehicle && scooter ? (
    <div>
      <DynamicsOnMap
        dynamic={selectedVehicle}
        areas={operatorReducer.selectedOperator.areas ?? []}
        circles={operatorReducer.selectedOperator.circles ?? []}
      />
      <Divider />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          {/* <p className={classes.tripLink} onClick={onOpenLastTrip}>
            {t("home.items.details.lasTripText")}
          </p> */}
          <p
            className={classes.tripLink}
            onClick={() => getId(selectedVehicle.id)}
          >
            {t('scooters.location.page.moreAbout')}
          </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p className={classes.tripLink} onClick={onOpenTripsList}>
            {t('home.items.details.tripsListText')}
          </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            className={classes.button}
            onClick={() => onCreateTicket(selectedVehicle)}
          >
            {t('incidents.scooterManagement.table.actions.createTicket')}
          </Button>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            className={classes.button}
            onClick={onShowIncidents}
          >
            {t('incidents.scooterManagement.table.actions.openTickets')}
          </Button>
        </Grid>
        {isUserWithRole([
          rolesConstants.MANAGER,
          rolesConstants.OPERATOR,
          rolesConstants.OPERATOR_MANAGER,
          rolesConstants.SAC_USER,
        ]) && (
          <Grid item md={6} xs={12} sm={6} lg={6}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              className={classes.button}
              onClick={onCleanService}
              disabled={selectedVehicle?.sharing.activeServiceId == null}
            >
              {t(
                'incidents.scooterManagement.table.actions.cleanActiveServices',
              )}
            </Button>
          </Grid>
        )}
        {isUserWithRole([
          rolesConstants.MANAGER,
          rolesConstants.OPERATOR,
          rolesConstants.OPERATOR_MANAGER,
          rolesConstants.SAC_USER,
        ]) && (
          <Grid item md={6} xs={12} sm={6} lg={6}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              className={classes.button}
              onClick={onBlockScooter}
            >
              {scooter && scooter.sharing && scooter.sharing.outOfService
                ? t('incidents.scooterManagement.table.actions.unblockScooter')
                : t('incidents.scooterManagement.table.actions.blockScooter')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.scooterDetailsContainer}>
        {scooter && (
          <ScooterDetails scooter={scooter} customerReducer={customerReducer} />
        )}
      </div>
      {/* <Divider />
      <p>indicencia de la moto</p> */}
    </div>
  ) : (
    <p>{t('home.items.details.selectVehicleText')}</p>
  )
}

export default DetailsContainer
