import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from '@material-ui/core'
import ExploreIcon from '@material-ui/icons/Explore'
import GoogleMapReact from 'google-map-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import stringConstants from '../../../../constants/strings'
import useStyles from './styles'

import { useTranslation } from 'react-i18next'

const TripScooterView = ({
  idScooter,
  getTripDetail,
  scooterReducer,
  scooterData,
  scooter,
  preSelectTrip,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [todaysTrip, settodaysTrip] = useState([])
  const { tripDetail, loadingResults, trips } = scooterReducer || {
    tripDetail: { points: [] },
    trips: [],
  }
  const defaultProps = {
    center: {
      lat: 41.390205,
      lng: 2.154007,
    },
    zoom: 11,
  }

  const handleApiLoaded = (map, maps) => {
    var latlngList = []

    var points = []
    tripDetail.points &&
      tripDetail.points.forEach((element) => {
        points.push({
          lat: element.lat,
          lng: element.lon,
        })
        latlngList.push(new maps.LatLng(element.lat, element.lon))
      })

    var bounds = new maps.LatLngBounds()

    latlngList.forEach(function (n) {
      bounds.extend(n)
    })

    map.setCenter(bounds.getCenter())
    map.fitBounds(bounds)

    new maps.Marker({
      map: map,
      animation: maps.Animation.DROP,
      icon: 'http://maps.google.com/mapfiles/kml/paddle/go.png',
      position: points[0],
    })

    new maps.Marker({
      map: map,
      animation: maps.Animation.DROP,
      icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-circle.png',
      position: points[points.length - 1],
    })

    const trip = new maps.Polyline({
      path: points,
      geodesic: true,
      strokeColor: 'black',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    })
    trip.setMap(map)
  }

  useEffect(() => {
    const reverseTrips = trips.map(
      (val, index, array) => array[array.length - 1 - index],
    )
    settodaysTrip(reverseTrips)
    // trips.length > 0 && getTrip(idScooter, trips[0].id);
  }, [])

  return (
    <React.Fragment>
      <Grid container justify="center">
        <Grid item md={4}>
          <strong>{t('scooters.details.trips.page.titleTrips')}</strong>
          <Paper style={{ maxHeight: 563, overflow: 'auto' }}>
            <List>
              <ListItem button>
                <ListItemAvatar>
                  <Avatar className={classes.activeTrip}>
                    <ExploreIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  secondary={`${t('scooters.details.trips.page.user')}: ${
                    tripDetail.customerEmail
                  }`}
                  primary={
                    `${preSelectTrip && preSelectTrip.startDay} ${
                      preSelectTrip &&
                      moment(preSelectTrip.startTimestamp).format('HH:mm')
                    }` +
                    ' ' +
                    `${preSelectTrip && preSelectTrip.distanceKms} km`
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item md={4}>
          {tripDetail && Object.keys(tripDetail).length != 0 && (
            <div className={classes.tripDetails}>
              <Grid container spacing={0}>
                <Grid item md={12} style={{ marginBottom: '5%' }}>
                  <strong>
                    {t('scooters.details.trips.page.detailTrip.title')}
                  </strong>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.serviceId')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{tripDetail.serviceId}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.tripId')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{tripDetail.tripId}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.trip')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <Grid container spacing={1}>
                    <Grid item md={6}>
                      <a target="blank" href={tripDetail.originMapLink}>
                        {t('scooters.details.trips.page.detailTrip.from')}
                      </a>
                    </Grid>
                    <Grid item md={6}>
                      <a target="blank" href={tripDetail.destinationMapLink}>
                        {t('scooters.details.trips.page.detailTrip.to')}
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.plate')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{scooterData.plate}</span>
                </Grid>
                <Grid item md={6}>
                  <span>{t('scooters.details.trips.page.detailTrip.vin')}</span>
                </Grid>
                <Grid item md={6}>
                  <span>{scooter.frameNo}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.name')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{scooterData.name}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.userEmail')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{tripDetail.customerEmail}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.userName')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{tripDetail.customerFullName}</span>
                </Grid>
                <Grid item md={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.startDate')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{tripDetail.startDay}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.startHour')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {moment(tripDetail.startTimestamp).format('HH:mm')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.endDate')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{tripDetail.endDay}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.endHour')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{moment(tripDetail.endTimestamp).format('HH:mm')}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.time')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{`${tripDetail.drivenMinutes} min`}</span>
                </Grid>
                <Grid item md={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.batteryUsage')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{tripDetail.batteryUsage}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.batteryStart')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{`${tripDetail.startBattery} %`}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.batteryEnd')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{`${tripDetail.endBattery} %`}</span>
                </Grid>
                <Grid item md={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.distance')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{`${
                    parseFloat(tripDetail.distanceKms) * 1000
                  } m`}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.coSave')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{`${tripDetail.co2Saving.toFixed(2)} kg`}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.speed_avg')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{`${tripDetail.speedAvg.toFixed(2)} km/h`}</span>
                </Grid>
                <Grid item md={6}>
                  <span>
                    {t('scooters.details.trips.page.detailTrip.speed_max')}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <span>{`${tripDetail.speedMax.toFixed(2)} km/h`}</span>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
        <Grid item md={4}>
          <div style={{ height: '60vh', width: '100%' }}>
            {!loadingResults &&
            tripDetail &&
            Object.keys(tripDetail).length != 0 ? (
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: stringConstants.GOOGLE_API_KEY,
                  // libraries: ["drawing", "visualization"],
                  libraries: ['drawing,visualization'],
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
              ></GoogleMapReact>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default TripScooterView
