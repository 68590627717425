import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const BonusDetails = ({ isOpen, onClose, data, selectedPayment }) => {
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t('bonus.details.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container spacing={0}>
              <Grid item md={6}>
                <span>{`${t('bonus.details.customer')}: `}</span>
              </Grid>
              <Grid item md={6}>
                <span>{selectedPayment.customerEmail}</span>
              </Grid>
              <Grid item md={6}>
                <span>{`${t('bonus.details.price')}: `}</span>
              </Grid>
              <Grid item md={6}>
                <span>{`${data.price}€`}</span>
              </Grid>
              <Grid item md={6}>
                <span>{`${t('bonus.details.offer')}: `}</span>
              </Grid>
              <Grid item md={6}>
                <span>{data.ofert}</span>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('bonus.details.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default BonusDetails
