import { Divider } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React, { useState } from 'react'
import useStyles from './styles'

import TextField from '@material-ui/core/TextField'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { ticketsActions } from '../../../redux/actions/tickets_action'

import ConfirmCloseTicket from '../modal/confirmCloseTicket'

import { formatDateToDisplay } from '../../shared/formatDate'

const IncidentsDetailsView = ({
  getById,
  addNote,
  data,
  closeTicket,
  ticketsReducer,
  operatorReducer,
  onClose,
}) => {
  const idTicket = window.location.href.split('details/')[1]
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    closeTimestamp,
    created,
    userCreateName,
    id,
    isClosed,
    notes,
    serviceId,
    sharingOperatorId,
    tableData,
    technicianId,
    title,
    vehicleId,
  } = ticketsReducer.selectedDetails || {}

  const [newComment, setnewComment] = useState('')
  const [closeTicketCheckboxValue, setcloseTicketCheckboxValue] =
    useState(false)
  const [isModalConfirmCloseTicketOpen, setisModalConfirmCloseTicketOpen] =
    useState(false)

  const handleSendComment = () => {
    const body = {
      ticketId: id,
      noteText: newComment,
    }

    addNote(body)
    setnewComment('')
  }

  const handleAcceptButton = () => {
    setisModalConfirmCloseTicketOpen(true)
  }

  const handleConfirmCloseTicket = (
    closeTicketComment,
    isBlockScooterChecked,
  ) => {
    const body = {
      ticketId: id,
      noteText: closeTicketComment,
      bodyVehicleCanOperate: {
        sharingOperatorId: data.sharingOperatorId,
        vehicleIds: [data.vehicleId],
        outOfService: isBlockScooterChecked,
      },
    }
    closeTicket(body)
    setisModalConfirmCloseTicketOpen(false)
    onClose()
    // history.goBack();
  }

  return data ? (
    <div className={classes.mainContainer}>
      <div className={classes.headerMainContainer}>
        <h2>{t('incidents.incidentsDetailsView.titleModal')}</h2>
        <p>{t('incidents.incidentsDetailsView.subtitleModal')}</p>
      </div>
      <div className={classes.dataDescriptionSection}>
        <div className={classes.summary}>
          <p>
            {t('incidents.incidentsDetailsView.summary.title')}: {title}
          </p>
          <p>
            {t('incidents.incidentsDetailsView.summary.created')}:{' '}
            {formatDateToDisplay(data.created, true)}
          </p>
          <p>
            {t('incidents.incidentsDetailsView.summary.closed')}:{' '}
            {formatDateToDisplay(data.closeTimestamp, true)}
          </p>
          <p>
            {t('incidents.incidentsDetailsView.summary.user')}: {userCreateName}
          </p>
          <p>{isClosed}</p>
          <Divider />
          <p>
            {t('incidents.incidentsDetailsView.summary.plate')}:{' '}
            {(data.scooterData && data.scooterData.plate) || ''}{' '}
          </p>
          <p>
            {t('incidents.incidentsDetailsView.summary.scooterName')}:{' '}
            {(data.scooterData && data.scooterData.name) || ''}{' '}
          </p>
          <Divider />
          <p>
            {t('incidents.incidentsDetailsView.summary.operatorName')}:{' '}
            {operatorReducer.results.name || ''}
          </p>
          <p>
            {t('incidents.incidentsDetailsView.summary.operatorEmail')}:{' '}
            {operatorReducer.results.managerEmail || ''}
          </p>
          <p>
            {t('incidents.incidentsDetailsView.summary.operatorMobilePhone')}:{' '}
            {operatorReducer.results.contactPhone || ''}
          </p>
        </div>
        <div className={classes.commentSection}>
          <div className={classes.commentHistorySection}>
            {notes ? (
              notes.map((n) => {
                const { created, createUser, text } = n
                return (
                  <div key={Math.random()}>
                    <div className={classes.commentCard}>
                      <div className={classes.headerComentCard}>
                        <p>{createUser}</p>
                        <p>{formatDateToDisplay(created, true)}</p>
                      </div>
                      <p>
                        {t(
                          'incidents.incidentsDetailsView.commentSection.comment',
                        )}
                        : {text}
                      </p>
                    </div>
                    {/* <Divider/> */}
                  </div>
                )
              })
            ) : (
              <p>
                {t('incidents.incidentsDetailsView.commentSection.noComments')}
              </p>
            )}
          </div>
          {isClosed || (
            <div className={classes.inputNewCommentSection}>
              <TextField
                label="Comentario"
                fullWidth
                value={newComment}
                onChange={(e) => setnewComment(e.target.value)}
              />
              <Button color="primary" onClick={handleSendComment}>
                {t('incidents.incidentsDetailsView.commentSection.sendBtn')}
              </Button>
            </div>
          )}
        </div>
      </div>
      {isClosed || (
        <div className={classes.buttonsSection}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptButton}
          >
            {t('incidents.incidentsDetailsView.closeTicketBtn')}
          </Button>
        </div>
      )}
      <ConfirmCloseTicket
        data={data}
        isOpen={isModalConfirmCloseTicketOpen}
        onClose={() => setisModalConfirmCloseTicketOpen(false)}
        onConfirm={handleConfirmCloseTicket}
      />
    </div>
  ) : (
    <div></div>
  )
}

function mapState(state) {
  const { ticketsReducer, scooterReducer, operatorReducer } = state
  return { ticketsReducer, scooterReducer, operatorReducer }
}

const actionCreators = {
  addNote: ticketsActions.addNote,
  getById: ticketsActions.getById,
  closeTicket: ticketsActions.closeTicket,
}

export default connect(mapState, actionCreators)(IncidentsDetailsView)
