import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core'
import React from 'react'

const LicensesFieldset = ({
  documentsReducer,
  userLicenses,
  setUserData,
  userData,
}) => {
  const LICENSES_ORDER = ['AM', 'A1', 'A2', 'A', 'B1', 'B']

  const handleDrivingLicenseChange = (key, value) => {
    const { drivingLicense } = userData
    const { drivingLicenseTypes } = drivingLicense

    if (drivingLicenseTypes && drivingLicenseTypes.includes(key)) {
      const licensePos = drivingLicenseTypes.findIndex(
        (license) => license === key,
      )
      drivingLicenseTypes.splice(licensePos, 1)
    } else {
      drivingLicenseTypes.push(Number(key))
    }

    setUserData({ ...userData, drivingLicense })
  }

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      style={{ display: 'flex' }}
    >
      <FormGroup
        style={{ flexDirection: 'row', justifyContent: 'space-around' }}
      >
        {Object.entries(documentsReducer.drivingLicenses)
          .sort(
            (a, b) =>
              LICENSES_ORDER.indexOf(a[1]) - LICENSES_ORDER.indexOf(b[1]),
          )
          .map(([key, license]) => {
            const licensePermission = Number(key)
            return (
              <FormControlLabel
                style={{ width: 'auto', marginRight: 0 }}
                control={
                  <Checkbox
                    checked={
                      userLicenses && userLicenses?.includes(licensePermission)
                    }
                    name={license}
                    key={licensePermission}
                    onChange={(e) =>
                      handleDrivingLicenseChange(
                        licensePermission,
                        e.target.checked,
                      )
                    }
                  />
                }
                // label={<Typography style={{ fontWeight: 700, fontSize: 12 }}> { license } </Typography>}
                label={license}
              />
            )
          })}
      </FormGroup>
    </FormControl>
  )
}

export default LicensesFieldset
