import React from 'react'
import SpecificationScooterView from './page'

class SpecificationScooter extends React.Component {
  render() {
    return <SpecificationScooterView {...this.props} />
  }
}

export default SpecificationScooter
