import { makeStyles } from '@material-ui/core/styles'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { operatorActions } from '../../redux/actions/operator_action'
import { servicesActions } from '../../redux/actions/services_actions'
import Spinner from '../shared/Spinner'
import SearchItem from './search'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const MonthlyDetailsView = ({
  getReportPDF,
  getOperators,
  operatorReducer,
  authenticationReducer,
  servicesReducer,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [selectedRow, setselectedRow] = useState({})

  return (
    <Fragment>
      <SearchItem
        getReportPDF={getReportPDF}
        getOperators={getOperators}
        operatorReducer={operatorReducer}
        authenticationReducer={authenticationReducer}
        servicesReducer={servicesReducer}
      />
      <Spinner loading={servicesReducer.loadingResults} />
    </Fragment>
  )
}

function mapState(state) {
  const { operatorReducer, authenticationReducer, servicesReducer } = state
  return { operatorReducer, authenticationReducer, servicesReducer }
}

const actionCreators = {
  getReportPDF: servicesActions.getReportPDF,
  getOperators: operatorActions.getAll,
}
export default connect(mapState, actionCreators)(MonthlyDetailsView)
