import React, { useEffect, useState } from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { bonusActions } from '../../redux/actions/bonus_actions'
import { paymentsActions } from '../../redux/actions/payments_action'
import { servicesActions } from '../../redux/actions/services_actions'
import { displayWithTooltip } from '../shared/enshortenLargeData'

import { formatDateToDisplay } from '../shared/formatDate'
import BonusDetails from './dialogs/detailsBonus'
import DetailsPayment from './dialogs/detailsPayment'

import CustomMaterialTable from '../shared/customMaterialTable'
import DatePickerCustom from '../shared/datePickerCustom'
import ModalConfirm from '../shared/modalconfirm'

const PaymentsView = ({
  paymentsReducer,
  servicesReducer,
  bonusReducer,
  getAll,
  getTrips,
  refundPayment,
  getBonusDataFromBonusHistoricId,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    getAll()
  }, [])

  const [selectedPayment, setselectedPayment] = useState({})
  const [isPaymentDetailsOpen, setisPaymentDetailsOpen] = useState(false)
  const [isBonusDetailsOpen, setisBonusDetailsOpen] = useState(false)
  const [isReturnPaymentOpen, setisReturnPaymentOpen] = useState(false)

  const handleOpenPaymentDetails = (rowData) => {
    setselectedPayment(rowData)
    if (rowData.refKey === 'Trip') {
      getTrips(rowData.refId)
      setisPaymentDetailsOpen(true)
    } else {
      getBonusDataFromBonusHistoricId(rowData.refId)
      setisBonusDetailsOpen(true)
    }
  }

  const handleOpenReturnPayment = (rowData) => {
    setselectedPayment(rowData)
    setisReturnPaymentOpen(true)
  }

  return (
    <div>
      <DatePickerCustom searchAction={getAll} />
      <CustomMaterialTable
        saveFilters={true}
        addSummaryRow={true}
        isLoading={paymentsReducer.loadingResults}
        title={t('payments.page.table.title')}
        columns={[
          {
            title: t('payments.page.table.columns.created'),
            field: 'createdTimestamp',
            render: (rowData) =>
              formatDateToDisplay(rowData.createdTimestamp, true),
          },
          {
            title: t('payments.page.table.columns.amount'),
            field: 'amount',
            render: (rowData) => {
              return '€' + rowData.amount
            },
          },
          {
            title: t('payments.page.table.columns.customerEmail'),
            field: 'customerEmail',
          },
          {
            title: t('payments.page.table.columns.status'),
            field: 'status',
          },
          {
            title: t('payments.page.table.columns.success'),
            field: 'successTimestamp',
            render: (rowData) =>
              rowData.status === 'Success'
                ? formatDateToDisplay(rowData.successTimestamp, true)
                : '',
          },
          {
            title: t('payments.page.table.columns.failed'),
            field: 'failedTimestamp',
            render: (rowData) =>
              rowData.status === 'Failed'
                ? formatDateToDisplay(rowData.failedTimestamp, true)
                : '',
          },
          {
            title: t('payments.page.table.columns.failedReason'),
            field: 'failedReason',
            render: (rowData) =>
              rowData.failedReason
                ? displayWithTooltip(rowData.failedReason)
                : '',
          },
          {
            title: t('payments.page.table.columns.refKey'),
            field: 'refKey',
          },
        ]}
        data={paymentsReducer.results}
        actions={[
          /* {
                        icon: "dehaze",
                        tooltip: t("payments.page.table.actions.details"),
                        onClick: (event, rowData) => handleOpenPaymentDetails(rowData),
                        // hidden: (rowData) => rowData.refKey != "Trip",
                    }, */
          (rowData) => ({
            icon: 'replay',
            tooltip: t('payments.page.table.actions.return'),
            onClick: (event, rowData) => handleOpenReturnPayment(rowData),
            hidden: rowData.status != 'Success' || rowData.refKey != 'Bonus',
          }),
        ]}
      />
      <ModalConfirm
        isOpen={isReturnPaymentOpen}
        onClose={() => setisReturnPaymentOpen(false)}
        onConfirm={() => refundPayment(selectedPayment.id)}
        title={
          t('payments.page.modal.title') +
          `(€${selectedPayment && selectedPayment.amount})`
        }
        body={t('payments.page.modal.body')}
      />
      <DetailsPayment
        isOpen={isPaymentDetailsOpen}
        onClose={() => setisPaymentDetailsOpen(false)}
        servicesReducer={servicesReducer}
        selectedPayment={selectedPayment}
      />
      <BonusDetails
        isOpen={isBonusDetailsOpen}
        onClose={() => setisBonusDetailsOpen(false)}
        data={bonusReducer.detailsFromHistoric}
        selectedPayment={selectedPayment}
      />
    </div>
  )
}
function mapState(state) {
  const { paymentsReducer, servicesReducer, bonusReducer } = state
  return { paymentsReducer, servicesReducer, bonusReducer }
}

const actionCreators = {
  getAll: paymentsActions.getAll,
  refundPayment: paymentsActions.refundPayment,
  getTrips: servicesActions.getTrips,
  getBonusDataFromBonusHistoricId: bonusActions.getBonusDataFromBonusHistoricId,
}

export default connect(mapState, actionCreators)(PaymentsView)
