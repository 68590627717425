export const upgradeConstants = {
  FILTER_REQUEST: 'UPGRADE_FILTER_REQUEST',
  FILTER_SUCCESS: 'UPGRADE_FILTER_SUCCESS',
  FILTER_FAILURE: 'UPGRADE_FILTER_FAILURE',

  GETALL_REQUEST: 'UPGRADE_GETALL_REQUEST',
  GETALL_SUCCESS: 'UPGRADE_GETALL_SUCCESS',
  GETALL_FAILURE: 'UPGRADE_GETALL_FAILURE',

  DETAILS_REQUEST: 'UPGRADE_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'UPGRADE_DETAILS_SUCCESS',
  DETAILS_FAILURE: 'UPGRADE_DETAILS_FAILURE',

  CLEAR_RESULTS: 'UPGRADE_CLEAR_RESULTS',

  DELETE_REQUEST: 'UPGRADE_DELETE_REQUEST',
  DELETE_SUCCESS: 'UPGRADE_DELETE_SUCCESS',
  DELETE_FAILURE: 'UPGRADE_DELETE_FAILURE',

  ADD_REQUEST: 'UPGRADE_ADD_REQUEST',
  ADD_SUCCESS: 'UPGRADE_ADD_SUCCESS',
  ADD_FAILURE: 'UPGRADE_ADD_FAILURE',

  EDIT_REQUEST: 'UPGRADE_EDIT_REQUEST',
  EDIT_SUCCESS: 'UPGRADE_EDIT_SUCCESS',
  EDIT_FAILURE: 'UPGRADE_EDIT_FAILURE',

  /* UPGRADE_REQUEST: 'UPGRADE_UPGRADE_REQUEST',
    UPGRADE_SUCCESS: 'UPGRADE_UPGRADE_SUCCESS',
    UPGRADE_FAILURE: 'UPGRADE_UPGRADE_FAILURE', */
}
