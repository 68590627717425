import React, { useEffect, useRef, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddBox'
import { handleTrueFalsyTermSwitch } from '../../../../../helpers/filters'

import { connect } from 'react-redux'
import { customerActions } from '../../../../../redux/actions/customers_actions'
import { operatorActions } from '../../../../../redux/actions/operator_action'

import { useTranslation } from 'react-i18next'
import { formatDateToInsertIntoComponent } from '../../../../shared/formatDate'

import { alertActions } from '../../../../../redux/actions/alert_actions'
import { tariffsActions } from '../../../../../redux/actions/tariffs_action'
import store from '../../../../../redux/store'
import { operatorService } from '../../../../../services/operatorService'
import CustomMaterialTable from '../../../../shared/customMaterialTable'

import { PERCENTAGE_REGEX } from '../../../../../constants/regex'
import { validations } from '../../../../../helpers/validation'

const useStyles = makeStyles((theme) => ({
  inputSection: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  customerSection: {
    marginTop: '5%',
    // padding: '0% 2%'
  },
}))

const EditDiscount = ({
  isOpen,
  onClose,
  data,
  getAllByOperatorId,
  operatorReducer,
  customerReducer,
  applyOperatorDiscount,
  isPrivatedOperator,
  updateDiscount,
  preSelectCustomer,
  getAssignableCustomersToDiscount,
  addCustomerEmail,
  getAll,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const contentRef = useRef()

  const [editable, setEditable] = useState()
  const [validatedSince, setvalidatedSince] = useState('')
  const [validatedUntil, setvalidatedUntil] = useState('')
  const [promoText, setPromoText] = useState('')
  const [percent, setPercent] = useState('')
  const [name, setName] = useState('')
  const [promoCode, setPromoCode] = useState(null)
  const [errors, setErrors] = useState({
    name: { result: false, message: '' },
    percent: { result: false, message: '' },
    validatedSince: { result: false, message: '' },
    validatedUntil: { result: false, message: '' },
  })

  const [selectedCustomerData, setselectedCustomerData] = useState()
  const [displayList, setDisplayList] = useState([])

  useEffect(() => {
    const {
      id,
      percent,
      applySince,
      applyUntil,
      name,
      promoText,
      promotionalCode,
    } = data

    setvalidatedSince(formatDateToInsertIntoComponent(applySince))
    setvalidatedUntil(formatDateToInsertIntoComponent(applyUntil))
    setPercent(percent)
    setName(name)
    setPromoText(promoText)
    setPromoCode(promotionalCode)
    getAssignableCustomersToDiscount(id)
  }, [data])

  useEffect(() => {
    const updatedDisplayList = customerReducer.results_assignable.map((c) => {
      const { customers } = data || { customers: [] }
      return { ...c, tableData: { checked: c.discountAssigned } }
    })
    setselectedCustomerData(
      updatedDisplayList.filter((u) => u.tableData.checked),
    )
    setDisplayList(updatedDisplayList)
  }, [customerReducer.results_assignable])

  const handleClose = () => {
    onClose()

    const {
      id,
      percent,
      applySince,
      applyUntil,
      name,
      promoText,
      promotionalCode,
    } = data

    // In case to open and close same modal, keep data
    setvalidatedSince(formatDateToInsertIntoComponent(applySince))
    setvalidatedUntil(formatDateToInsertIntoComponent(applyUntil))
    setPercent(percent)
    setName(name)
    setPromoText(promoText)
    setPromoCode(promotionalCode)
    getAssignableCustomersToDiscount(id)

    setErrors({
      name: { result: false, message: '' },
      percent: { result: false, message: '' },
      validatedSince: { result: false, message: '' },
      validatedUntil: { result: false, message: '' },
    })
  }

  const validateForm = () => {
    let newErrors = errors

    newErrors.name = validations.required(name)
    newErrors.percent = validations.validatePercentage(percent)
    newErrors.validatedSince = validations.required(validatedSince)
    newErrors.validatedUntil = validations.required(validatedUntil)

    if (validatedSince && validatedUntil) {
      newErrors.validatedUntil = validations.compareDate(
        validatedSince,
        validatedUntil,
      )
    }

    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  const handleUpdateDiscountData = () => {
    validateForm()

    if (isFormValid()) {
      const bodyUpdateDiscount = {
        discountId: data.id,
        name: name,
        applySince: validatedSince,
        applyUntil: validatedUntil,
        percent: parseFloat(percent),
        customerEmails: selectedCustomerData.map((s) => s.customerEmail),
        enable: true,
        promoText: promoText,
        preSelectCustomer,
        promotionalCode: promoCode,
      }
      updateDiscount(bodyUpdateDiscount, () => handleClose())
    } else {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const tableRef = useRef()

  const checkEmailExists = (email) => {
    let finded = false
    for (let index = 0; index < displayList.length; index++) {
      const element = displayList[index]
      if (element.customerEmail == email) {
        finded = true
      }
    }
    return finded
  }
  const handleAddRow = () => {
    tableRef.current.state.showAddRow = true
    setEditable({
      onRowAdd: (newData) =>
        operatorService
          .GetAssignableCustomerByEmail(newData.customerEmail)
          .then(
            (result) => {
              const emailExists = checkEmailExists(newData.customerEmail)
              if (emailExists == false) {
                newData.tableData = { checked: true }
                setDisplayList([...displayList, newData])
                addCustomerEmail(newData)
              } else {
                store.dispatch(
                  alertActions.error(
                    t('services.rates.customerEmailRepeatInList'),
                  ),
                )
              }
            },
            (error) => {
              store.dispatch(
                alertActions.error(t('services.rates.customerEmailRepeat')),
              )
            },
          ),
    })
  }

  const handleSelectionTableChange = (rows) => {
    setselectedCustomerData(rows)
  }

  const filterDiscountAssigned = (term, rowData) => {
    const { discountAssigned } = rowData
    return t(
      discountAssigned
        ? 'operator.profile.editDiscounts.table.columns.yes'
        : 'operator.profile.editDiscounts.table.columns.no',
    )
  }

  const Wrapper = () => {
    return isPrivatedOperator ? (
      <></>
    ) : (
      <React.Fragment>
        <Tooltip
          title={t('operator.profile.editDiscounts.table.actions.addClient')}
        >
          <IconButton onClick={handleAddRow}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        maxWidth="100%"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('operator.profile.editDiscounts.dialogTitle')}
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <DialogContentText>
            {t('operator.profile.editDiscounts.contentText')}
          </DialogContentText>
          <Grid container spacing={2} className={classes.inputSection}>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label={t('operator.profile.editDiscounts.name')}
                value={name}
                error={errors.name.message.length === 0 ? false : true}
                helperText={errors.name.message}
                onChange={(e) => {
                  setName(e.target.value)
                  errors.name.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label={t('operator.profile.editDiscounts.labelSince')}
                type="date"
                value={validatedSince}
                error={
                  errors.validatedSince.message.length === 0 ? false : true
                }
                helperText={errors.validatedSince.message}
                onChange={(e) => {
                  setvalidatedSince(e.target.value)
                  errors.validatedSince.message = ''
                  setErrors(errors)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label={t('operator.profile.editDiscounts.labelUntil')}
                type="date"
                value={validatedUntil}
                error={
                  errors.validatedUntil.message.length === 0 ? false : true
                }
                helperText={errors.validatedUntil.message}
                onChange={(e) => {
                  setvalidatedUntil(e.target.value)
                  errors.validatedUntil.message = ''
                  setErrors(errors)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label={t('operator.profile.editDiscounts.labelpercent')}
                type="text"
                value={percent}
                error={errors.percent.message.length === 0 ? false : true}
                helperText={errors.percent.message}
                onChange={(e) => {
                  const { value } = e.target
                  if (PERCENTAGE_REGEX.test(value) || !value) setPercent(value)
                  errors.percent.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label={t('operator.profile.newDiscount.promoText')}
                value={promoText}
                onChange={(e) => setPromoText(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label={t('operator.profile.newDiscount.promoCode')}
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value ? e.target.value : null)
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.customerSection}>
            <CustomMaterialTable
              selection={true}
              saveFilters
              tableRef={tableRef}
              preSelectedRows={
                selectedCustomerData ? selectedCustomerData : null
              }
              showSelectAllCheckbox={false}
              editable={editable}
              buttons={<Wrapper />}
              onSelectionChange={(rows) => handleSelectionTableChange(rows)}
              title={t('operator.profile.editDiscounts.table.title')}
              columns={[
                {
                  title: t(
                    'operator.profile.editDiscounts.table.columns.email',
                  ),
                  field: 'customerEmail',
                },
                {
                  title: t(
                    'operator.profile.editDiscounts.table.columns.discountAssigned',
                  ),
                  field: 'discountAssigned',
                  editable: 'never',
                  lookup: { 0: t('shared.yes'), 1: t('shared.no') },
                  customFilterAndSearch: (term, rowData) =>
                    handleTrueFalsyTermSwitch(term, rowData?.discountAssigned),
                  render: (rowData) =>
                    rowData?.discountAssigned
                      ? t('shared.yes')
                      : t('shared.no'),
                },
              ]}
              data={displayList}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('operator.profile.editDiscounts.cancelBtn')}
          </Button>
          <Button color="primary" onClick={handleUpdateDiscountData}>
            {t('operator.profile.editDiscounts.confirmBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { operatorReducer, customerReducer } = state
  return { operatorReducer, customerReducer }
}

const actionCreators = {
  getAllByOperatorId: customerActions.getAllByOperatorId,
  applyOperatorDiscount: customerActions.applyOperatorDiscount,
  createDiscount: operatorActions.createDiscount,
  updateDiscount: operatorActions.updateDiscount,
  getAssignableCustomersToDiscount:
    operatorActions.getAssignableCustomersToDiscount,
  getAll: customerActions.getAll,
  addCustomerEmail: tariffsActions.addCustomerEmail,
}

export default connect(mapState, actionCreators)(EditDiscount)
