import React, { useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslation } from 'react-i18next'

import { validations } from '../../../../helpers/validation'
import CustomMaterialTable from '../../../shared/customMaterialTable'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContainer: {
    padding: '2%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function CreateNewTicket({
  isOpen,
  onClose,
  scooterReducer = { results: [] },
  onCreate,
  preSelectedScooter,
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [selectedRow, setSelectedRow] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [isBlockScooterChecked, setisBlockScooterChecked] = useState(false)
  const [errors, setErrors] = useState({
    title: { result: false, message: '' },
    description: { result: false, message: '' },
    selectedVehicle: { result: false, message: '' },
  })

  const handleClose = () => {
    onClose()
    setSelectedRow('')
    setTitle('')
    setDescription('')
    setisBlockScooterChecked(false)
    setErrors({
      title: { result: false, message: '' },
      description: { result: false, message: '' },
      selectedVehicle: { result: false, message: '' },
    })
  }

  const validateForm = () => {
    let newErrors = errors
    newErrors.title = validations.required(title)
    newErrors.description = validations.required(description)
    newErrors.selectedVehicle = validations.required(
      selectedRow,
      null,
      'incidents.scooterManagement.createNewTicket.selectVehicle',
    )
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const saveTicket = () => {
    validateForm()
    if (isFormValid()) {
      onCreate({
        description: title,
        newComment: description,
        isBlockScooterChecked,
        selectedRow,
      })
      handleClose()
    }
  }

  const handleEnter = () => {
    preSelectedScooter && setSelectedRow(preSelectedScooter)
  }

  const handleRowClickAction = (rowData) => {
    setSelectedRow(rowData)
  }

  return (
    <Dialog
      fullScreen
      onEnter={handleEnter}
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('incidents.scooterManagement.createNewTicket.dialogTitle')}
          </Typography>
          <Button autoFocus color="inherit" onClick={saveTicket}>
            {t('incidents.scooterManagement.createNewTicket.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>

      <Grid container spacing={2} className={classes.dialogContainer}>
        <Grid item md={8}>
          <CustomMaterialTable
            title={t('incidents.scooterManagement.table.title')}
            columns={[
              {
                title: t(
                  'incidents.scooterManagement.createNewTicket.table.columns.vin',
                ),
                field: 'frameNo',
              },
              {
                title: t(
                  'incidents.scooterManagement.createNewTicket.table.columns.name',
                ),
                field: 'name',
              },
              {
                title: t(
                  'incidents.scooterManagement.createNewTicket.table.columns.plate',
                ),
                field: 'plate',
              },
            ]}
            data={scooterReducer.results}
            onRowClick={(rowData) => handleRowClickAction(rowData)}
            rowClickAvaiable={true}
          />
        </Grid>
        <Grid item md={4}>
          <Grid item xs={12} style={{ margin: 5 }}>
            {selectedRow ? (
              <Typography>
                {' '}
                {t(
                  'incidents.scooterManagement.createNewTicket.selectedVehicle',
                  { selectedVehicle: selectedRow.plate ?? 'N/A' },
                )}{' '}
              </Typography>
            ) : (
              <Typography style={{ color: 'red' }}>
                {errors.selectedVehicle.message}
              </Typography>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormControlLabel
                className={classes.switchBlockScooterAsOperator}
                control={
                  <Switch
                    checked={isBlockScooterChecked}
                    onChange={() => {
                      setisBlockScooterChecked((prev) => !prev)
                    }}
                    color="primary"
                  />
                }
                label={t(
                  'incidents.scooterManagement.createNewTicket.labelSwitch',
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                multiline
                fullWidth
                margin="dense"
                label={t(
                  'incidents.scooterManagement.createNewTicket.titleLabel',
                )}
                value={title}
                error={errors.title.message.length === 0 ? false : true}
                helperText={errors.title.message}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setTitle(e.target.value)
                  errors.title.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                multiline
                fullWidth
                margin="dense"
                label={t(
                  'incidents.scooterManagement.createNewTicket.commentLabel',
                )}
                value={description}
                error={errors.description.message.length === 0 ? false : true}
                helperText={errors.description.message}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setDescription(e.target.value)
                  errors.description.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}
