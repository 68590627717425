import React, { Fragment, useEffect, useRef, useState } from 'react'

import useStyles from './styles'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'

import FormControlLabel from '@material-ui/core/FormControlLabel'

import { formatDateToDisplay } from '../../../shared/formatDate'

import { operatorActions } from '../../../../redux/actions/operator_action'
import { tariffsActions } from '../../../../redux/actions/tariffs_action'
import { userActions } from '../../../../redux/actions/user_actions'

import { connect } from 'react-redux'

import DialogConfirmAction from '../../../shared/modalConfirmAction'
import EditDiscount from './dialogs/editDiscount'
import NewDiscount from './dialogs/newDiscount'

import { validations } from '../../../../helpers/validation'
import CustomMaterialTable from '../../../shared/customMaterialTable'
import RatesByDays from './ratesByDays'
import RatesByHours from './ratesByHours'

import { PERCENTAGE_REGEX } from '../../../../constants/regex'

const Rates = ({
  isUpdatingData = true,
  onChangesValues,
  tariffsReducer,
  operatorReducer,
  setTariffs,
  deleteDiscount,
  getAllDiscountsOfLoggedOperator,
  getByLoggedOperator,
  displayOnly = false,
  setGenericDiscount,
  getGenericDiscountOfLoggedOperator,
  rateDays,
  rateHours,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const focusRef = useRef()

  const [discountGenericDisabled, setDiscountGenericDisabled] = useState(true)
  const [genericDiscountEnabled, setGenericDiscountEnabled] = useState(false)
  const [currencyState, setCurrencyState] = useState('')
  const [mode, setMode] = useState('')
  const [pricePerMode, setPricePerMode] = useState()
  const [pricePerModePause, setPricePerModePause] = useState()
  const [promoText, setPromoText] = useState('')
  const [dailyMaximumPrice, setDailyMaximumPrice] = useState(null)
  const [freePauseMinutes, setFreePauseMinutes] = useState(null)

  const [percent, setPercent] = useState()

  const [free, setFree] = useState(false)
  const [isDialogConfirmActionOpen, setIsDialogConfirmActionOpen] =
    useState(false)

  const [isDialogDeleteActionOpen, setIsDialogDeleteActionOpen] =
    useState(false)

  const [isNewDiscountDialogOpen, setIsNewDiscountDialogOpen] = useState(false)
  const [isEditDiscountDialogOpen, setIsEditDiscountDialogOpen] =
    useState(false)
  const [selectedDiscount, setSelectedDiscount] = useState({})

  const isPrivatedOperator = operatorReducer.dataOperatorLogged.isPrivate

  const [rateDay, setRateDay] = useState(
    !rateDays
      ? [
          {
            days: null,
            pricePerDay: null,
          },
        ]
      : rateDays,
  )

  const [rateHour, setRateHour] = useState(
    !rateHours
      ? [
          {
            hours: null,
            pricePerHour: null,
          },
        ]
      : rateHours,
  )

  const [genericDiscountErrors, setGenericDiscountErrors] = useState({
    percent: { result: true, message: '' },
  })

  const [errors, setErrors] = useState({
    rateMinutes: {
      maximumPriceDay: { result: true, message: '' },
      freePauseMinutes: { result: true, message: '' },
    },
    rateDay: !rateDays
      ? [
          {
            days: { result: true, message: '' },
            pricePerDay: { result: true, message: '' },
          },
        ]
      : rateDays.map((item) => {
          return {
            days: { result: true, message: '' },
            pricePerDay: { result: true, message: '' },
          }
        }),
    rateHour: !rateHours
      ? [
          {
            hours: { result: true, message: '' },
            pricePerHour: { result: true, message: '' },
          },
        ]
      : rateHours.map((item) => {
          return {
            hours: { result: true, message: '' },
            pricePerHour: { result: true, message: '' },
          }
        }),
  })

  // useEffect(() => {
  //     setIsFormValid(isModeFormValid(mode))
  // }, [errors])

  useEffect(() => {
    if (!displayOnly) {
      if (isUpdatingData) {
        getAllDiscountsOfLoggedOperator()
        getGenericDiscountOfLoggedOperator()
      }
    }
  }, [])

  useEffect(() => {
    // const genericDiscount = operatorReducer.genericDiscountLoggedOperator.sort((a,b) => new Date(b.created) - new Date(a.created))?.[0]
    const genericDiscount = operatorReducer.genericDiscountLoggedOperator
    const { percent = '', enable = false, promoText = '' } = genericDiscount
    setPercent(percent)
    setGenericDiscountEnabled(enable)
    setPromoText(promoText || '')
  }, [operatorReducer.genericDiscountLoggedOperator])

  useEffect(() => {
    if (!displayOnly) {
      !operatorReducer.loadingResults && getByLoggedOperator()
    }
  }, [operatorReducer.loadingResults])

  useEffect(() => {
    !isUpdatingData &&
      !displayOnly &&
      onChangesValues({
        currency: currencyState,
        mode,
        pricePerMode,
        pricePerModePause,
        rateDays: mode === 'days' ? rateDay : null,
        rateHours: mode === 'hour' ? rateHour : null,
        free,
        maxPricePerDay: dailyMaximumPrice,
        freePauseMinutes,
      })
  }, [
    currencyState,
    mode,
    pricePerMode,
    pricePerModePause,
    rateDay,
    rateHour,
    free,
    dailyMaximumPrice,
    freePauseMinutes,
  ])

  useEffect(() => {
    const {
      billingUnit,
      currency,
      priceDays,
      priceHours,
      priceMinute,
      pricePauseMinute,
      maxPricePerDay,
      freePauseMinutes,
    } = tariffsReducer.currentByLoggedOperator || {
      billingUnit: null,
      currency: null,
      priceDays: null,
      priceHours: null,
      priceMinute: null,
      pricePauseMinute: null,
      maxPricePerDay: null,
      freePauseMinutes: null,
    }

    const { isFree } = operatorReducer.dataOperatorLogged || false

    let propsMode = ''
    if (billingUnit === 'minute') propsMode = priceMinute
    if (billingUnit === 'hour') propsMode = priceHours
    if (billingUnit === 'days') propsMode = priceDays

    setCurrencyState(currency)
    setMode(billingUnit)
    setPricePerMode(propsMode)
    setFree(isFree)

    if (isFree) {
      setPricePerModePause(0)
      setDailyMaximumPrice(0)
      setFreePauseMinutes(0)
      setPricePerMode(0)

      setRateDay(
        priceDays?.length > 0 ? priceDays : [{ days: null, pricePerDay: null }],
      )

      setRateHour(
        priceHours?.length > 0
          ? priceHours
          : [{ hours: null, pricePerHour: null }],
      )
    } else {
      setPricePerModePause(pricePauseMinute)
      setDailyMaximumPrice(maxPricePerDay)
      setFreePauseMinutes(freePauseMinutes)
      setRateDay(
        priceDays ?? [
          {
            days: null,
            pricePerDay: null,
          },
        ],
      )
      setRateHour(
        priceHours ?? [
          {
            hours: null,
            pricePerHour: null,
          },
        ],
      )
    }
  }, [tariffsReducer.currentByLoggedOperator])

  useEffect(() => {
    const {
      billingUnit,
      currency,
      priceDays,
      priceHours,
      priceMinute,
      pricePauseMinute,
      maxPricePerDay,
      freePauseMinutes,
    } = tariffsReducer.byOperator || {
      billingUnit: null,
      currency: null,
      priceDays: null,
      priceHours: null,
      priceMinute: null,
      pricePauseMinute: null,
      maxPricePerDay: null,
      freePauseMinutes: null,
    }

    const { isFree } = operatorReducer.dataOperatorLogged

    let propsMode = ''
    if (billingUnit === 'minute') propsMode = priceMinute
    if (billingUnit === 'hour') propsMode = priceHours
    if (billingUnit === 'days') propsMode = priceDays
    setCurrencyState(currency)
    setMode(billingUnit)
    setFree(isFree)
    setPricePerMode(propsMode)
    setPricePerModePause(pricePauseMinute)
    setDailyMaximumPrice(maxPricePerDay)
    setFreePauseMinutes(freePauseMinutes)
  }, [tariffsReducer.byOperator])

  const handleEditAction = (event, rowData) => {
    setSelectedDiscount(rowData)
    setIsEditDiscountDialogOpen(true)
  }

  const handleDeleteAction = (event, rowData) => {
    setSelectedDiscount(rowData)
    setIsDialogDeleteActionOpen(true)
  }
  const handleCreateNewDiscout = () => {
    setIsNewDiscountDialogOpen(true)
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    setIsDialogConfirmActionOpen(true)
  }

  const handleConfirmAction = () => {
    validateMode(mode)
    if (isModeFormValid(mode)) {
      const bodyRates = {
        currency: currencyState,
        billingUnit: mode,
        freeFractionMinutes: 0,
        priceMinute: mode === 'minute' ? parseFloat(pricePerMode) : null,
        pricePauseMinute: parseFloat(pricePerModePause),
        priceHours: mode === 'hour' ? rateHour : null,
        priceDays: mode === 'days' ? rateDay : null,
        maxPricePerDay: dailyMaximumPrice
          ? parseFloat(Number(dailyMaximumPrice).toFixed(2))
          : null,
        freePauseMinutes: freePauseMinutes ? parseInt(freePauseMinutes) : null,
      }
      setTariffs(bodyRates)
    }
  }

  const handleDeleteConfirm = () => {
    deleteDiscount(selectedDiscount.id)
  }

  const handleSetGenericDiscount = (e) => {
    e.preventDefault()
    validateGenericDiscountForm()

    const bodyGenericDiscount = {
      percent: parseFloat(percent),
      enable: genericDiscountEnabled,
      promoText: promoText,
    }
    if (isFormValid(genericDiscountErrors)) {
      setGenericDiscount(bodyGenericDiscount)
      setDiscountGenericDisabled(true)
    }
  }

  const validateGenericDiscountForm = () => {
    let newErrors = genericDiscountErrors
    newErrors.percent = validations.validatePercentage(String(percent))
    setGenericDiscountErrors({ ...newErrors })
  }

  const validateMode = (mode) => {
    let newErrors = errors
    if (mode === 'minute') {
      newErrors.rateMinutes.maximumPriceDay = validations.validateHigher(
        0,
        dailyMaximumPrice,
      )
      newErrors.rateMinutes.freePauseMinutes = validations.validateHigherInt(
        0,
        freePauseMinutes,
      )
    }

    setErrors({ ...newErrors })
  }

  const isModeFormValid = (mode) => {
    let valid = true
    let propertyErrorMode = ''

    if (mode === 'minute') {
      propertyErrorMode = 'rateMinutes'
    }

    if (!propertyErrorMode) return valid

    let properties = Object.getOwnPropertyNames(errors[propertyErrorMode])

    properties.forEach((prop) => {
      if (!errors[propertyErrorMode][prop].result) {
        valid = false
      }
    })

    return valid
  }

  const isFormValid = (errors) => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  const addRateDay = () => {
    errors.rateDay.push({
      days: { result: true, message: '' },
      pricePerDay: { result: true, message: '' },
    })
    setErrors({ ...errors })
    rateDay.push({
      days: '',
      pricePerDay: free ? 0 : null,
    })
    setRateDay([...rateDay])
  }

  const addRateHour = () => {
    errors.rateHour.push({
      hours: { result: true, message: '' },
      pricePerHour: { result: true, message: '' },
    })
    setErrors({ ...errors })

    rateHour.push({
      hours: null,
      pricePerHour: free ? 0 : null,
    })
    setRateHour([...rateHour])
  }

  const removeRateDay = (index) => {
    setRateDay(rateDay.filter((_, i) => i !== index))
    errors.rateDay = errors.rateDay.filter((_, i) => i !== index)
    setErrors({ ...errors })
  }

  const removeRateHour = (index) => {
    setRateHour(rateHour.filter((_, i) => i !== index))
  }

  const setRateDays = (event, index) => {
    let { name, value } = event.target
    var array = name.split('_')
    let updatedRateDays = rateDay
    updatedRateDays[index][array[0]] = parseFloat(value)
    setRateDay([...updatedRateDays])
  }

  const setRateHours = (event, index) => {
    let { name, value } = event.target
    var array = name.split('_')
    let updatedRateHours = rateHour
    updatedRateHours[index][array[0]] = parseFloat(value)
    setRateHour([...updatedRateHours])
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.formSection}>
        <p className={classes.title}>
          {t('operator.profile.newDiscount.rate')}
        </p>
        <form
          className={classes.form}
          onSubmit={
            focusRef.current === 'generic'
              ? handleSetGenericDiscount
              : handleUpdate
          }
        >
          <Grid container>
            <div
              onFocus={() => (focusRef.current = '')}
              style={{ width: '100vw' }}
            >
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <TextField
                    select
                    fullWidth
                    InputProps={{
                      readOnly: displayOnly,
                    }}
                    label={t('operator.profile.rates.labelsInputs.currency')}
                    value={currencyState}
                    onChange={(e) => setCurrencyState(e.target.value)}
                  >
                    {[
                      {
                        value: 'eur',
                        label: 'EUR',
                      },
                      {
                        value: 'usd',
                        label: 'USD',
                      },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={4}>
                  <TextField
                    select
                    fullWidth
                    InputProps={{
                      readOnly: displayOnly,
                    }}
                    label={t('operator.profile.rates.labelsInputs.mode')}
                    value={mode}
                    onChange={(e) => {
                      console.log(
                        'e.target.value (preu/dia) => ',
                        e.target.value,
                      )
                      setMode(e.target.value)
                    }}
                  >
                    {[
                      {
                        value: 'minute',
                        label: t('operator.profile.rates.labelsInputs.minute'),
                      },
                      {
                        value: 'hour',
                        label: t('operator.profile.rates.labelsInputs.hour'),
                      },
                      {
                        value: 'days',
                        label: t('operator.profile.rates.labelsInputs.day'),
                      },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                style={{ marginTop: 10, paddingTop: 15 }}
              >
                {mode === 'minute' && (
                  <Grid item md={4}>
                    <TextField
                      fullWidth
                      InputProps={{
                        readOnly: displayOnly,
                      }}
                      InputLabelProps={{ shrink: true }}
                      label={t(
                        'operator.profile.rates.labelsInputs.servicePrice',
                      )} //Precio de servicio por minuto en marcha
                      value={Array.isArray(pricePerMode) ? '' : pricePerMode}
                      disabled={mode === '' || free}
                      onChange={(e) => setPricePerMode(e.target.value)}
                    />
                  </Grid>
                )}

                <Grid item md={4}>
                  {mode === 'minute' && (
                    <TextField
                      InputProps={{
                        readOnly: displayOnly,
                      }}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label={t(
                        'operator.profile.rates.labelsInputs.servicePricePause',
                      )}
                      value={pricePerModePause}
                      onChange={(e) => setPricePerModePause(e.target.value)}
                      disabled={free}
                    />
                  )}
                </Grid>
                <Grid item md={4}></Grid>
                {mode === 'days' && (
                  <Grid container spacing={3} style={{ padding: 15 }}>
                    {rateDay &&
                      rateDay.map((item, index) => (
                        <Grid
                          key={index}
                          container
                          spacing={3}
                          style={{ padding: 2, marginTop: 10 }}
                        >
                          <RatesByDays
                            item={item}
                            key={index}
                            index={index}
                            displayOnly={displayOnly}
                            isFree={free}
                            length={rateDay.length}
                            errors={errors}
                            setErrors={setErrors}
                            setRateDay={setRateDays}
                            add={() => addRateDay()}
                            remove={() => removeRateDay(index)}
                          />
                        </Grid>
                      ))}
                    <Grid item md={4}></Grid>
                    <Grid item md={4}></Grid>
                  </Grid>
                )}
                {mode === 'hour' && (
                  <Grid container spacing={3} style={{ padding: 15 }}>
                    {rateHour &&
                      rateHour.map((item, index) => (
                        <Grid
                          key={index}
                          container
                          spacing={3}
                          style={{ padding: 2, marginTop: 10 }}
                        >
                          <RatesByHours
                            item={item}
                            key={index}
                            index={index}
                            isFree={free}
                            displayOnly={displayOnly}
                            length={rateHour.length}
                            errors={errors}
                            setErrors={setErrors}
                            setRateDay={setRateHours}
                            add={() => addRateHour()}
                            remove={() => removeRateHour(index)}
                          />
                        </Grid>
                      ))}
                    <Grid item md={4}></Grid>
                    <Grid item md={4}></Grid>
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                style={{ marginTop: 10, paddingTop: 15 }}
                spacing={1}
              >
                {mode === 'minute' && (
                  <>
                    <Grid item md={4}>
                      <TextField
                        fullWidth
                        label={t(
                          'operator.profile.rates.labelsInputs.maxPriceDay',
                        )}
                        InputLabelProps={{ shrink: true }}
                        value={dailyMaximumPrice}
                        InputProps={{ readOnly: displayOnly }}
                        disabled={free}
                        error={
                          errors.rateMinutes.maximumPriceDay.message.length ===
                          0
                            ? false
                            : true
                        }
                        helperText={errors.rateMinutes.maximumPriceDay.message}
                        onChange={(e) => {
                          setDailyMaximumPrice(e.target.value)
                          errors.rateMinutes.maximumPriceDay.message = ''
                          setErrors(errors)
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        fullWidth
                        label={t(
                          'operator.profile.rates.labelsInputs.freePauseMinutes',
                        )}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          'aria-hidden': true,
                          autoCorrect: false,
                          readOnly: displayOnly,
                        }}
                        onInvalid={() => 'return false'}
                        value={freePauseMinutes}
                        error={
                          errors.rateMinutes.freePauseMinutes.message.length ===
                          0
                            ? false
                            : true
                        }
                        helperText={errors.rateMinutes.freePauseMinutes.message}
                        onChange={(e) => {
                          setFreePauseMinutes(e.target.value)
                          errors.rateMinutes.freePauseMinutes.message = ''
                          setErrors(errors)
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              {isUpdatingData && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  // disabled={isBtnUpdateDisabled}
                  className={classes.submit}
                >
                  {t('operator.profile.rates.updateBtn')}
                </Button>
              )}
            </div>
            {isUpdatingData && (
              <Fragment>
                <Grid item xs={12} sm={12} className={classes.divider}>
                  <Divider />
                </Grid>
                <div
                  onFocus={() => {
                    focusRef.current = 'generic'
                  }}
                  style={{ width: '100vw' }}
                >
                  <Grid
                    container
                    spacing={1}
                    style={{ alignItems: 'flex-end' }}
                  >
                    <Grid item xs={12}>
                      <p className={classes.title}>
                        {t('operator.profile.newDiscount.genericDiscount')}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        fullWidth
                        label={t('operator.profile.newDiscount.percentlabel')}
                        value={percent}
                        onChange={(e) => {
                          const { value } = e.target
                          if (PERCENTAGE_REGEX.test(value) || !value) {
                            setPercent(value)
                          }
                        }}
                        disabled={discountGenericDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={genericDiscountEnabled}
                            onChange={(e) =>
                              setGenericDiscountEnabled(e.target.checked)
                            }
                            name="checkedB"
                            color="primary"
                            disabled={discountGenericDisabled}
                          />
                        }
                        label={
                          genericDiscountEnabled
                            ? t('operator.profile.newDiscount.enabled')
                            : t('operator.profile.newDiscount.disabled')
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        fullWidth
                        label={t('operator.profile.newDiscount.promoText')}
                        value={promoText}
                        disabled={discountGenericDisabled}
                        onChange={(e) => setPromoText(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Button
                        color={'primary'}
                        variant="contained"
                        onClick={
                          discountGenericDisabled
                            ? () => setDiscountGenericDisabled(false)
                            : handleSetGenericDiscount
                        } //handleSetGenericDiscount}
                      >
                        {discountGenericDisabled
                          ? t('operator.profile.newDiscount.btnEdit')
                          : t('operator.profile.newDiscount.save')}
                      </Button>
                    </Grid>
                    {genericDiscountErrors.percent.message && (
                      <Grid item xs={12} sm={3}>
                        <p style={{ color: 'red' }}>
                          {' '}
                          {genericDiscountErrors.percent.message}{' '}
                        </p>
                      </Grid>
                    )}
                  </Grid>
                </div>

                <Grid item xs={12} sm={12} className={classes.divider}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <CustomMaterialTable
                    disableToolbarGutters
                    data={operatorReducer.discountsLoggedOperator.filter(
                      (i) => !i.isGeneric,
                    )}
                    // data={operatorReducer.discountsLoggedOperator}
                    saveFilters={true}
                    tab={'currentDiscounts'}
                    columns={[
                      {
                        title: t(
                          'operator.profile.dialogDiscountManagement.table.columns.name',
                        ),
                        field: 'name',
                      },
                      {
                        title: t(
                          'operator.profile.dialogDiscountManagement.table.columns.validateSince',
                        ),
                        field: 'applySince',
                        render: (rowData) =>
                          formatDateToDisplay(rowData.applySince, true),
                      },
                      {
                        title: t(
                          'operator.profile.dialogDiscountManagement.table.columns.validateUntil',
                        ),
                        field: 'applyUntil',
                        render: (rowData) =>
                          formatDateToDisplay(rowData.applyUntil, true),
                      },
                      {
                        title: t(
                          'operator.profile.dialogDiscountManagement.table.columns.percentage',
                        ),
                        field: 'percent',
                        render: (rowData) => rowData && rowData.percent + '%',
                        addCustomFilterComponentNumberCells: 'percent',
                      },
                      {
                        title: 'Promo code',
                        field: 'promotionalCode',
                      },
                    ]}
                    title={t(
                      'operator.profile.dialogDiscountManagement.table.title',
                    )}
                    actions={[
                      (rowData) => ({
                        icon: `edit`,
                        tooltip: t(
                          'operator.profile.dialogDiscountManagement.table.actions.modifyDiscount',
                        ),
                        onClick: (event, rowData) =>
                          handleEditAction(event, rowData),
                      }),
                      (rowData) => ({
                        icon: `delete`,
                        tooltip: t(
                          'operator.profile.dialogDiscountManagement.table.actions.deleteDiscount',
                        ),
                        onClick: (event, rowData) =>
                          handleDeleteAction(event, rowData),
                      }),
                      {
                        icon: 'add',
                        tooltip: t(
                          'operator.profile.dialogDiscountManagement.table.actions.add',
                        ),
                        isFreeAction: true,
                        onClick: (event) => handleCreateNewDiscout(),
                      },
                    ]}
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </form>
      </div>
      <DialogConfirmAction
        isOpen={isDialogConfirmActionOpen}
        onClose={() => setIsDialogConfirmActionOpen(false)}
        onConfirm={handleConfirmAction}
        addNote={false}
        data={{
          header: t('operator.profile.rates.confirmAction.header'),
          body: t('operator.profile.rates.confirmAction.body'),
        }}
      />
      <DialogConfirmAction
        isOpen={isDialogDeleteActionOpen}
        onClose={() => setIsDialogDeleteActionOpen(false)}
        onConfirm={handleDeleteConfirm}
        addNote={false}
        data={{
          header: t('operator.profile.rates.confirmDeleteAction.header'),
          body: t('operator.profile.rates.confirmDeleteAction.body'),
        }}
      />
      {/* <DialogDiscountManagement
        isOpen={isDialogDiscountManagementOpen}
        onClose={() => setisDialogDiscountManagementOpen(false)}
      /> */}
      <NewDiscount
        isOpen={isNewDiscountDialogOpen}
        onClose={() => setIsNewDiscountDialogOpen(false)}
        isPrivatedOperator={isPrivatedOperator}
      />
      <EditDiscount
        isOpen={isEditDiscountDialogOpen}
        onClose={() => setIsEditDiscountDialogOpen(false)}
        isPrivatedOperator={isPrivatedOperator}
        data={selectedDiscount}
      />
    </div>
  )
}

function mapState(state) {
  const { operatorReducer, tariffsReducer, customerReducer } = state
  return { operatorReducer, tariffsReducer, customerReducer }
}

const actionCreators = {
  setTariffs: tariffsActions.setTariffs,
  deleteDiscount: tariffsActions.deleteDiscount,
  validateOperator: operatorActions.validateOperator,
  logout: userActions.logout,
  getAllDiscountsOfLoggedOperator:
    operatorActions.getAllDiscountsOfLoggedOperator,
  getByLoggedOperator: tariffsActions.getByLoggedOperator,
  setGenericDiscount: operatorActions.setGenericDiscount,
  getGenericDiscountOfLoggedOperator:
    operatorActions.getGenericDiscountOfLoggedOperator,
}

export default connect(mapState, actionCreators)(Rates)
