export const documentsConstant = {
  GET_BY_CUSTOMERS_ID_REQUEST: 'DOCUMENTS_GET_BY_CUSTOMERS_ID_REQUEST',
  GET_BY_CUSTOMERS_ID_SUCCESS: 'DOCUMENTS_GET_BY_CUSTOMERS_ID_SUCCESS',
  GET_BY_CUSTOMERS_ID_FAILURE: 'DOCUMENTS_GET_BY_CUSTOMERS_ID_FAILURE',

  GET_DRIVING_LICENSES_REQUEST: 'GET_DRIVING_LICENSES_REQUEST',
  GET_DRIVING_LICENSES_SUCCESS: 'GET_DRIVING_LICENSES_SUCCESS',
  GET_DRIVING_LICENSES_FAILURE: 'GET_DRIVING_LICENSES_FAILURE',

  GET_ID_DOC_TYPES_REQUEST: 'GET_ID_DOC_TYPES',
  GET_ID_DOC_TYPES_SUCCESS: 'GET_ID_DOC_TYPES_SUCCESS',
  GET_ID_DOC_TYPES_FAILURE: 'GET_ID_DOC_TYPES_FAILURE',
}
