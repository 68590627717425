import { getCenter } from 'geolib'

export const DEFAULT_EUROPE_COORDINATES = { lat: 48.241304, lng: 8.587969 }
const DEFAULT_BCN_COORDINATES = { lat: 41.390205, lng: 2.154007 }

export const findGeofencesCenter = (areas = [], circles = []) => {
  let center = DEFAULT_EUROPE_COORDINATES // Centered Europe coordinates

  let geofenceCoordinates = []

  if (areas.length > 0) {
    geofenceCoordinates = [...areas.map((polygon) => getCenter(polygon.coord))]
  }
  if (circles.length > 0) {
    geofenceCoordinates = [
      ...geofenceCoordinates,
      ...circles.map((circle) => ({
        latitude: circle.lat,
        longitude: circle.lng,
      })),
    ]
  }

  if (geofenceCoordinates.length > 0) {
    center = { ...center, ...getCenter(geofenceCoordinates) }
    center.lat = center.latitude
    center.lng = center.longitude
    delete center.latitude
    delete center.longitude
  }

  return center
}

export const calculateMapBounds = (areas = [], circles = []) => {
  let geofenceCoordinates = []

  if (areas.length > 0) {
    geofenceCoordinates = areas
      .map((area) =>
        area.coord.map((point) => ({ lat: point.lat, lng: point.lng })),
      )
      .flat()
  }

  if (circles.length > 0) {
    geofenceCoordinates = [
      ...geofenceCoordinates,
      ...circles.map((circle) => ({ lat: circle.lat, lng: circle.lng })),
    ]
  }

  return geofenceCoordinates
}
