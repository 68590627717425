import React from 'react'
import { useTranslation } from 'react-i18next'

import Rates from '../operator/profile/rates/rates'
const RatesView = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Rates />
    </div>
  )
}

export default RatesView
